var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Button, Form, Label, FormGroup, Input, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import { isEmpty } from '../utils';
import { useForm } from '../hooks';
import { useAPI } from '../state';
import { MessageBox, PASSWORD_PATTERN, PASSWORD_RULE_MSG, PASSWORD_DESCRIPTION } from '../common';
var ConfirmResetPasswordContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 500px;\n  margin: 20px auto;\n"], ["\n  width: 500px;\n  margin: 20px auto;\n"])));
var FormWrapper = styled(Form)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 380px;\n  margin: 20px auto;\n"], ["\n  width: 380px;\n  margin: 20px auto;\n"])));
var H5Style = styled.h5(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center !important;\n  margin-bottom: 1.5rem !important;\n"], ["\n  text-align: center !important;\n  margin-bottom: 1.5rem !important;\n"])));
var Description = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: -0.5rem 0 0.75rem;\n  font-size: 80%;\n"], ["\n  margin: -0.5rem 0 0.75rem;\n  font-size: 80%;\n"])));
var ButtonGroup = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center !important;\n"], ["\n  text-align: center !important;\n"])));
var validationSchema = Yup.lazy(function (values) {
    return Yup.object().shape({
        newPassword: Yup.string().matches(PASSWORD_PATTERN, PASSWORD_RULE_MSG).required('Required'),
        passwordConfirmation: Yup.lazy(function (value) {
            return isEmpty(value) ? Yup.string().required('Required') : Yup.string().oneOf([values.newPassword], 'Passwords do not match');
        }),
        verificationCode: Yup.string().required('Required'),
    });
});
export var ConfirmResetPasswordComponent = function (props) {
    var _a = useAPI('/api/user/confirmResetPassword', undefined, 'POST'), requestData = _a[0], confirmResetPassword = _a[1], resetStore = _a[2];
    var _b = useForm({ validationSchema: validationSchema }), setInitialValues = _b.setInitialValues, values = _b.values, handleChange = _b.handleChange, handleBlur = _b.handleBlur, handleSubmit = _b.handleSubmit, isSubmitting = _b.isSubmitting, getError = _b.getError;
    useEffect(function () {
        if (props.show) {
            setInitialValues({
                newPassword: '',
                passwordConfirmation: '',
            });
            return resetStore;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);
    useEffect(function () {
        if (requestData.data) {
            props.toggle();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData.data]);
    var onSubmit = handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirmResetPassword({
                        username: props.username,
                        password: values.newPassword,
                        verificationCode: values.verificationCode,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    if (!props.show)
        return null;
    return (React.createElement(ConfirmResetPasswordContent, null,
        React.createElement(H5Style, null, "Reset Password Required"),
        React.createElement(MessageBox, { error: requestData.error }),
        React.createElement(FormWrapper, { onSubmit: onSubmit },
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Username"),
                React.createElement(Input, { type: "email", name: "username", placeholder: "Username", plaintext: true, disabled: true, value: props.username })),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "New password"),
                React.createElement(Description, null, PASSWORD_DESCRIPTION),
                React.createElement(Input, { type: "password", name: "newPassword", placeholder: "New password", value: values.newPassword, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('newPassword') }),
                React.createElement(FormFeedback, null, getError('newPassword'))),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Re-type new password"),
                React.createElement(Input, { type: "password", name: "passwordConfirmation", placeholder: "Re-type new password", value: values.passwordConfirmation, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('passwordConfirmation') }),
                React.createElement(FormFeedback, null, getError('passwordConfirmation'))),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Verification Code"),
                React.createElement(Input, { type: "password", name: "verificationCode", placeholder: "Verification Code", value: values.verificationCode, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('verificationCode') }),
                React.createElement(FormFeedback, null, getError('verificationCode'))),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { type: "button", disabled: isSubmitting, onClick: props.toggle }, "Cancel"),
                ' ',
                React.createElement(Button, { color: "primary", type: "submit", disabled: isSubmitting }, "Change password")))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
