var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { Input as RsInput } from 'reactstrap';
export var Input = function (props) {
    var _a = props.min, min = _a === void 0 ? Number.MIN_SAFE_INTEGER : _a, _b = props.max, max = _b === void 0 ? Number.MAX_SAFE_INTEGER : _b, _c = props.type, type = _c === void 0 ? 'text' : _c;
    var setFieldValue = props.setFieldValue, setFieldTouched = props.setFieldTouched, resprops = __rest(props, ["setFieldValue", "setFieldTouched"]);
    var handleChange = function (event) {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, event);
        var value = type === 'checkbox' ? event.target.checked : event.target.value;
        if (type === 'number') {
            value = /^\d+$/.test(value) ? parseInt(event.target.value, 10) : undefined;
            if (value === undefined || value <= max) {
                setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(value, event.target.name);
            }
        }
        else {
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(value, event.target.name);
        }
    };
    var handleKeyPress = function (event) {
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (type === 'number' && !/^\d*$/.test(key)) {
            event.preventDefault();
            return false;
        }
        return true;
    };
    var handleBlur = function (event) {
        var _a;
        (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
        if (type === 'number' && props.value !== undefined && (props.value < min || props.value > max)) {
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(undefined, props.name || '');
        }
        setFieldTouched === null || setFieldTouched === void 0 ? void 0 : setFieldTouched(true, props.name);
    };
    return (React.createElement(RsInput, __assign({}, resprops, { value: props.value === undefined ? '' : props.value, checked: !props.checked && ['checkbox', 'radio'].includes(type) ? false : props.checked, onChange: handleChange, onKeyPress: handleKeyPress, onBlur: handleBlur })));
};
