var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useFilterSetting, exportFilterSettingPayload, useViewValue } from '../../state';
import { useMapRef, useViewport } from '../mapprovider';
import { useDebounce, useDebounceValue } from '../../hooks';
import { LocationLayer } from '../locationlayer';
import { useProviderSetting, useProviderData } from '../../selectors';
import { RightPanelTypeEnum } from '../../enums';
var VIEW_PORT_DEBOUNCE_TIME = 500;
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 550 !important;\n"], ["\n  font-weight: 550 !important;\n"])));
var RowLocationName = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 0.75rem;\n"], ["\n  font-size: 0.75rem;\n"])));
var MenuItem = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            props.location.providerFirstName || '',
            " ",
            props.location.providerLastName || '',
            " ",
            props.location.providerCredentialText || ''),
        React.createElement(RowLocationName, null, props.location.name || '')));
};
export var ProviderLayer = React.memo(function () {
    var _a, _b;
    var filterSetting = useFilterSetting();
    var _c = useProviderData(), providerLocatorData = _c[0], fetchProviderLocatorData = _c[1], clearData = _c[2];
    var viewport = useViewport()[0];
    var mapRef = useMapRef();
    var map = (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap();
    var providerSetting = useProviderSetting()[0];
    var selectedRegion = useViewValue('selectedRegion')[0];
    var loadedUserActionsHistoryRef = useRef(false);
    var _d = providerSetting || {}, doGetPrimaryCareProvider = _d.doGetPrimaryCareProvider, doGetHospitalProvider = _d.doGetHospitalProvider, specialistProviders = _d.specialistProviders, available = _d.available;
    var show = available;
    var lngLatBounds = useDebounceValue(function () { return map === null || map === void 0 ? void 0 : map.getBounds().toArray(); }, [viewport.zoom, viewport.longitude, viewport.latitude], VIEW_PORT_DEBOUNCE_TIME);
    var locations = useMemo(function () { return (providerLocatorData.data ? Object.values(providerLocatorData.data.providers) : Object.assign([])); }, [
        providerLocatorData.data,
    ]);
    var fetchDataWrapped = function () {
        var payload = exportFilterSettingPayload(filterSetting);
        var userActionsHistory = !loadedUserActionsHistoryRef.current && (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.ProviderDetail
            ? {
                provider: selectedRegion.id,
            }
            : undefined;
        fetchProviderLocatorData(__assign(__assign({}, payload), { providerParam: {
                doGetPrimaryCareProvider: doGetPrimaryCareProvider,
                doGetHospitalProvider: doGetHospitalProvider,
                specialistProviders: specialistProviders,
                bottomLeftAndTopRightLngLatBound: lngLatBounds,
            }, userActionsHistory: userActionsHistory }));
        loadedUserActionsHistoryRef.current = true;
    };
    useDebounce(function () {
        if (filterSetting.forceUpdate && show) {
            fetchDataWrapped();
        }
        return clearData;
    }, [filterSetting.forceUpdate, lngLatBounds, doGetPrimaryCareProvider, doGetHospitalProvider, specialistProviders]);
    return (React.createElement(LocationLayer, { id: RightPanelTypeEnum.ProviderDetail, locations: locations, show: show, color: "#D00", clusterColor: "#be0712", stroke: "#000", getIcon: function (provider) {
            return provider.providerBroadSpecialty === '1' ? 'clinic-medical' : provider.providerBroadSpecialty === '5' ? 'hospital-symbol' : 'stethoscope';
        }, requesting: providerLocatorData.requesting, error: providerLocatorData.error, retry: fetchDataWrapped, totalCount: (_b = providerLocatorData.data) === null || _b === void 0 ? void 0 : _b.total, compareFn: function (a, b) { var _a, _b; return ((_a = a.providerLastName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.providerLastName || '')) || ((_b = a.providerFirstName) === null || _b === void 0 ? void 0 : _b.localeCompare(b.providerFirstName || '')) || 0; }, menuItemComponent: MenuItem, name: "providers", idFields: ['providerNpiNumber'] }));
});
var templateObject_1, templateObject_2;
