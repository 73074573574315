var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import { Select, FallbackComponent, DELIMITER_SEASON_YEAR } from '../common';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { LookupYearComponent, useMixedYear } from '../lookupdata';
import { AggregatedDataSourceComponent, useAggregatedDataSourceName } from './aggregateddatasource';
import { DATA_SOURCES } from '../configs';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { getYearsBySeason } from '../utils';
import { useMount } from '../hooks';
export var DataYearSeasonTab = function (props) {
    var indexConfig = useHostnameIndexInfo();
    var agrregatedData = useAggregatedDataSourceName()[0];
    var _a = useFilterEditingValue('statistic', 'cmsDataSource'), cmsDataSource = _a[0], setCmsDataSource = _a[1];
    var _b = useFilterEditingValue('dataYearSeasonUi'), dataYearSeasonUi = _b[0], setDataYearSeasonUi = _b[1];
    var _c = useFilterEditingValue('dataYearSeason'), dataYearSeason = _c[0], setDataYearSeason = _c[1];
    var _d = useFilterEditingValue('yearSeason'), setAggregatedYearSeason = _d[1];
    var _e = useFilterEditingValue('isAggregatedDataSource'), isAggregatedDataSource = _e[0], setIsAggregatedDataSource = _e[1];
    var _f = useFilterEditingValue('dataSourceName'), aggregatedDataSourceName = _f[0], setAggregatedDataSourceName = _f[1];
    var _g = useFilterEditingValue('patientPopulationReferenceYear'), setPatientPopulationRefYear = _g[1];
    var _h = useFilterEditingValue('dataYear'), setDataYear = _h[1];
    var _j = useMixedYear(useFilterEditing()), yearData = _j[0], fetchYearData = _j[1];
    var _k = useFilterEditingValue('primaryCoMorbidities'), selectingPrimaryConditions = _k[0], setSelectingPrimaryConditions = _k[1];
    var patientPopulationRefYearFilter = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter.patientPopulationReferenceYear;
    var isYearSeasonReady = useMemo(function () {
        var _a;
        if (((_a = yearData.data) === null || _a === void 0 ? void 0 : _a.length) && dataYearSeasonUi) {
            return yearData.data.map(function (y) { return String(y); }).includes(dataYearSeasonUi);
        }
        return false;
    }, [yearData.data, dataYearSeasonUi]);
    useMount(function () {
        setDataYear(undefined);
    });
    useEffect(function () {
        var _a = getYearsBySeason(dataYearSeasonUi), lateYear = _a[0], earlyYear = _a[1];
        var fullYearSeason = [Number(lateYear).valueOf(), Number(earlyYear).valueOf()];
        setDataYearSeason(fullYearSeason);
        setAggregatedYearSeason(fullYearSeason);
    }, [setAggregatedYearSeason, setDataYearSeason, dataYearSeasonUi]);
    useEffect(function () {
        setPatientPopulationRefYear(patientPopulationRefYearFilter);
    }, [patientPopulationRefYearFilter, setPatientPopulationRefYear]);
    useEffect(function () {
        var _a, _b;
        if (((_a = yearData.data) === null || _a === void 0 ? void 0 : _a.length) && (!dataYearSeasonUi || !isYearSeasonReady)) {
            var dataYearSeasonValueUi = String(yearData.data[yearData.data.length - 1]);
            if (Array.isArray(dataYearSeason) && dataYearSeason.length) {
                var yearSeasonInDataYear = (_b = yearData.data) === null || _b === void 0 ? void 0 : _b.find(function (year) { return String(year).includes(dataYearSeason.join(DELIMITER_SEASON_YEAR)); });
                dataYearSeasonValueUi = yearSeasonInDataYear ? String(yearSeasonInDataYear) : dataYearSeasonValueUi;
            }
            handleChangeYearSeason(dataYearSeasonValueUi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearData.data, dataYearSeasonUi]);
    var handleChangeDataSource = function (value, name, option) {
        setIsAggregatedDataSource(!!option.isAggregatedDataSource);
        if (option.isAggregatedDataSource) {
            setAggregatedDataSourceName(value);
        }
        else {
            setCmsDataSource(value);
        }
    };
    var handleChangeYearSeason = function (value) {
        var _a;
        setDataYearSeasonUi(value);
        if ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) {
            setSelectingPrimaryConditions(selectingPrimaryConditions === null || selectingPrimaryConditions === void 0 ? void 0 : selectingPrimaryConditions.filter(function (cc) { var _a, _b; return !((_b = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) === null || _b === void 0 ? void 0 : _b.includes(cc)); }));
        }
    };
    var allDataSourceOptions = useMemo(function () {
        var _a, _b;
        return __spreadArrays((((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.dataSources) === null || _a === void 0 ? void 0 : _a.map(function (v) { return DATA_SOURCES[v]; })) || []), (((_b = agrregatedData.data) === null || _b === void 0 ? void 0 : _b.map(function (value) { return ({ value: value, label: value, isAggregatedDataSource: true }); })) || []));
    }, [agrregatedData.data, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.dataSources]);
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Source"),
            React.createElement(Select, { name: "dataSource", options: allDataSourceOptions, noSort: true, value: isAggregatedDataSource ? aggregatedDataSourceName : cmsDataSource, onChange: handleChangeDataSource, isLoading: agrregatedData.requesting })),
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Year"),
            React.createElement(FallbackComponent, { spinnerSize: 50, requesting: yearData.requesting, error: yearData.error, retry: fetchYearData },
                React.createElement(Select, { name: "dataYear", options: yearData.data, noSort: true, value: dataYearSeasonUi, onChange: handleChangeYearSeason, optionsTransform: function (year) { return ({ value: year, label: "" + year }); }, maxMenuHeight: 200, menuPosition: "fixed" }))),
        React.createElement(LookupYearComponent, { dataSource: cmsDataSource }),
        React.createElement(AggregatedDataSourceComponent, null)));
};
DataYearSeasonTab.defaultProps = {
    tabId: 'DataSeasonYearTab',
    tabName: 'Data Set',
};
