var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useEffect, useMemo } from 'react';
import { useCachedData } from '../state';
import { DataSourceEnum } from '../enums';
import { useAggregatedYear } from '../filterdialog/aggregateddatasource';
import { useHostnameIndexInfo, useIndexName } from '../hostnameindexinfo';
import { getYearsBySeason } from '../utils';
var FETCH_DATA_YEAR_API_URL = '/api/general/get-list-data-year/{dataSource}/{indexName}';
export var useCMSDataYear = function (dataSource, onlyNumber) {
    if (dataSource === void 0) { dataSource = DataSourceEnum.MedicareFFS; }
    if (onlyNumber === void 0) { onlyNumber = false; }
    var indexName = useIndexName();
    var _a = useCachedData(FETCH_DATA_YEAR_API_URL, { dataSource: dataSource, indexName: indexName }), data = _a[0], res = _a.slice(1);
    var sortedYear = useMemo(function () { var _a; return (_a = data.data) === null || _a === void 0 ? void 0 : _a[dataSource].filter(function (y) { return (onlyNumber ? !isNaN(y) : true); }); }, [data.data, dataSource, onlyNumber]);
    return __spreadArrays([__assign(__assign({}, data), { data: sortedYear })], res);
};
export var useCMSDataSeasonYear = function (filterSetting) {
    var _a;
    var indexConfiguration = useHostnameIndexInfo();
    var isSeasonStatistic = ((indexConfiguration === null || indexConfiguration === void 0 ? void 0 : indexConfiguration.generalInformation) || {}).isSeasonStatistic;
    var dataSource = ((_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource) || DataSourceEnum.MedicareFFS;
    var indexName = useIndexName();
    var _b = useCachedData(FETCH_DATA_YEAR_API_URL, { dataSource: dataSource, indexName: indexName }), data = _b[0], res = _b.slice(1);
    var seasonYear = useMemo(function () {
        var _a;
        return !isSeasonStatistic ? [] : (_a = data.data) === null || _a === void 0 ? void 0 : _a[dataSource].map(function (item) {
            var rangeYear = getYearsBySeason(item);
            if (rangeYear.length < 2)
                return '';
            return rangeYear[0] + " - " + rangeYear[1];
        });
    }, [data.data, dataSource, isSeasonStatistic]);
    return __spreadArrays([__assign(__assign({}, data), { data: seasonYear })], res);
};
export var useMixedYear = function (filterSetting, onlyNumber) {
    var _a;
    var cmsYearData = useCMSDataYear((_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource, onlyNumber);
    var aggregatedYearData = useAggregatedYear(filterSetting);
    return filterSetting.isAggregatedDataSource ? aggregatedYearData : cmsYearData;
};
export var LookupYearComponent = function (props) {
    var _a = useCMSDataYear(props.dataSource), fetchData = _a[1];
    useEffect(function () {
        if (props.dataSource) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataSource]);
    return null;
};
