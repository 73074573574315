var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { StyledPanel } from './styled';
import { useMapValue } from '../mapprovider';
var StyledTransparencyControl = styled(StyledPanel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.75rem 0 0.125rem;\n  text-align: center;\n  min-width: 2.75rem;\n"], ["\n  padding: 0.75rem 0 0.125rem;\n  text-align: center;\n  min-width: 2.75rem;\n"])));
var RangeInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0; /* IE */\n  width: 100%;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n    &::-webkit-slider-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n    &::-moz-range-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n    &::-ms-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n  }\n"], ["\n  padding: 0; /* IE */\n  width: 100%;\n  cursor: pointer;\n\n  &:hover,\n  &:focus {\n    &::-webkit-slider-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n    &::-moz-range-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n    &::-ms-thumb {\n      box-shadow: 0 0 0 0.5rem rgba(122, 202, 255, 0.7);\n      border-radius: 50%;\n    }\n  }\n"])));
var LabelLeft = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  float: left!important;\n"], ["\n  float: left!important;\n"])));
var LabelRight = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  float: right!important;\n"], ["\n  float: right!important;\n"])));
export var TransparencyControl = function () {
    var _a = useMapValue('transparency'), transparency = _a[0], setTransparency = _a[1];
    var handleChange = useCallback(function (event) {
        setTransparency(event.currentTarget.value);
    }, [setTransparency]);
    return (React.createElement(StyledTransparencyControl, null,
        React.createElement(RangeInput, { type: "range", min: 0, max: 100, value: transparency, onChange: handleChange }),
        React.createElement(LabelLeft, null, "Transparent"),
        React.createElement(LabelRight, null, "Opaque")));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
