var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import moment from 'moment';
import { Authenticated, useAuth } from '../authentication';
import { DropdownToggleIcon } from '../common';
import { ClinicalTrialOptionEnum, RoleEnum, ViewModeEnum } from '../enums';
import { exportFilterSettingPayload, useFilterSetting, useViewSetting, useViewValue } from '../state';
import { useMapRef, useMapValue } from '../map';
import styled from 'styled-components';
import { getGeoLevelLabel, getStatisticShortName } from '../lookupdata';
import { useExport } from '../export';
import { useProviderSetting } from '../selectors';
var EXPORT_CSV_API = '/api/provider/export-csv';
var EXPORT_CLINICAL_TRIAL_CSV_API = '/api/clinical-trials/export-to-excel-file';
var EXPORT_QUEST_DIAGNOSTICS_SIE_CSV_API = '/api/quest-diagnostics/export-to-excel-file';
var StyledDropdownItem = styled(DropdownItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 3rem;\n  position: relative;\n"], ["\n  padding-left: 3rem;\n  position: relative;\n"])));
export var ProviderDownloadButton = function () {
    var auth = useAuth();
    var filterSetting = useFilterSetting();
    var mapRef = useMapRef();
    var viewMode = useViewValue('viewMode')[0];
    var editorRef = useMapValue('editorRef')[0];
    var providerSetting = useProviderSetting()[0];
    var _a = providerSetting || {}, doGetPrimaryCareProvider = _a.doGetPrimaryCareProvider, doGetHospitalProvider = _a.doGetHospitalProvider, specialistProviders = _a.specialistProviders, available = _a.available;
    var show = available && viewMode === ViewModeEnum.Map;
    var onClick = function () {
        var _a, _b, _c;
        var payload = exportFilterSettingPayload(filterSetting);
        var form = document.createElement('form');
        form.action = EXPORT_CSV_API;
        form.method = 'post';
        var params = {
            token: auth.jwt,
            filtering: JSON.stringify(__assign(__assign({}, payload), { providerParam: {
                    doGetPrimaryCareProvider: doGetPrimaryCareProvider,
                    doGetHospitalProvider: doGetHospitalProvider,
                    specialistProviders: specialistProviders,
                    bottomLeftAndTopRightLngLatBound: (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap().getBounds().toArray(),
                    dateTimeStringForExportingCsv: moment().format('YYYYMMDD HHmmss'),
                    polygonPoints: (_c = (_b = editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) === null || _b === void 0 ? void 0 : _b.getFeatures()[0]) === null || _c === void 0 ? void 0 : _c.geometry.coordinates[0].map(function (_a) {
                        var lng = _a[0], lat = _a[1];
                        return ({ lng: lng, lat: lat });
                    }).slice(1),
                } })),
        };
        Object.keys(params).forEach(function (key) {
            var input = document.createElement('input');
            input.name = key;
            input.value = params[key] || '';
            form.appendChild(input);
        });
        window.document.body.appendChild(form);
        form.submit();
        window.document.body.removeChild(form);
    };
    if (!show)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Providers"),
        React.createElement(StyledDropdownItem, { onClick: onClick }, "Patient Demographics for Providers in Region")));
};
export var ClinicalTrialDownloadButton = function () {
    var auth = useAuth();
    var filterSetting = useFilterSetting();
    var mapRef = useMapRef();
    var viewMode = useViewValue('viewMode')[0];
    var clinicalTrialOption = useViewValue('clinicalTrial', 'clinicalTrialOption')[0];
    var filterRecruitmentStatuses = useViewValue('clinicalTrialStatus', 'filterRecruitmentStatuses')[0];
    var editorRef = useMapValue('editorRef')[0];
    var show = !!clinicalTrialOption;
    show = show && viewMode === ViewModeEnum.Map;
    var onClick = function () {
        var _a, _b, _c;
        var payload = exportFilterSettingPayload(filterSetting);
        var form = document.createElement('form');
        form.action = EXPORT_CLINICAL_TRIAL_CSV_API;
        form.method = 'post';
        var params = {
            token: auth.jwt,
            filtering: JSON.stringify(__assign(__assign({}, payload), { primaryCoMorbidities: clinicalTrialOption === ClinicalTrialOptionEnum.AllTrials ? undefined : filterSetting.primaryCoMorbidities, bottomLeftAndTopRightLngLatBound: (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap().getBounds().toArray(), currentDate: {
                    date: moment().format('D'),
                    month: moment().format('M'),
                    year: moment().format('YYYY'),
                }, polygonPoints: (_c = (_b = editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) === null || _b === void 0 ? void 0 : _b.getFeatures()[0]) === null || _c === void 0 ? void 0 : _c.geometry.coordinates[0].map(function (_a) {
                    var lng = _a[0], lat = _a[1];
                    return ({ lng: lng, lat: lat });
                }).slice(1), filterRecruitmentStatuses: filterRecruitmentStatuses })),
        };
        Object.keys(params).forEach(function (key) {
            var input = document.createElement('input');
            input.name = key;
            input.value = params[key] || '';
            form.appendChild(input);
        });
        window.document.body.appendChild(form);
        form.submit();
        window.document.body.removeChild(form);
    };
    if (!show)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Clinical Trials"),
        React.createElement(StyledDropdownItem, { onClick: onClick }, "Trials in Region")));
};
export var QuestDiagnosticsSitesDownloadButton = function () {
    var auth = useAuth();
    var checkedQuestDiagnosticsStatus = useViewValue('checkedQuestDiagnosticsStatus')[0];
    var filterSetting = useFilterSetting();
    var viewMode = useViewValue('viewMode')[0];
    var mapRef = useMapRef();
    var editorRef = useMapValue('editorRef')[0];
    var onClick = function () {
        var _a, _b, _c;
        var payload = exportFilterSettingPayload(filterSetting);
        var form = document.createElement('form');
        form.action = EXPORT_QUEST_DIAGNOSTICS_SIE_CSV_API;
        form.method = 'post';
        var params = {
            token: auth.jwt,
            filtering: JSON.stringify(__assign(__assign({}, payload), { bottomLeftAndTopRightLngLatBound: (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap().getBounds().toArray(), currentDate: {
                    date: moment().format('D'),
                    month: moment().format('M'),
                    year: moment().format('YYYY'),
                }, polygonPoints: (_c = (_b = editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) === null || _b === void 0 ? void 0 : _b.getFeatures()[0]) === null || _c === void 0 ? void 0 : _c.geometry.coordinates[0].map(function (_a) {
                    var lng = _a[0], lat = _a[1];
                    return ({ lng: lng, lat: lat });
                }).slice(1) })),
        };
        Object.keys(params).forEach(function (key) {
            var input = document.createElement('input');
            input.name = key;
            input.value = params[key] || '';
            form.appendChild(input);
        });
        window.document.body.appendChild(form);
        form.submit();
        window.document.body.removeChild(form);
    };
    if (!checkedQuestDiagnosticsStatus || viewMode !== ViewModeEnum.Map)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Quest Diagnostics Sites"),
        React.createElement(StyledDropdownItem, { onClick: onClick }, "Quest Diagnostics in Region")));
};
export var BeneficiaryExportButton = function () {
    var _a;
    var filterSetting = useFilterSetting();
    var viewSetting = useViewSetting();
    var geoLevel = viewSetting.geoType;
    var geoLevelLabel = getGeoLevelLabel(geoLevel);
    var statistic = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var statisticName = getStatisticShortName(statistic);
    var _b = useExport(), disabled = _b[0], handleExport = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Beneficiaries"),
        React.createElement(StyledDropdownItem, { onClick: handleExport, disabled: disabled },
            statisticName,
            " by ",
            geoLevelLabel)));
};
export var DownloadButton = function (props) {
    return (React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.GeneralUser, RoleEnum.IndexManager, RoleEnum.SingleIndexUserManager, RoleEnum.SingleIndexDataManager] },
        React.createElement(UncontrolledDropdown, null,
            React.createElement(DropdownToggleIcon, { icon: "cloud-download-alt", size: "2x", placement: "top", title: "Download data" }),
            React.createElement(DropdownMenu, null,
                React.createElement(BeneficiaryExportButton, null),
                props.children))));
};
var templateObject_1;
