var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useMemo } from 'react';
import { point } from '@turf/helpers';
import styled from 'styled-components';
import _ from 'lodash';
import { useViewValue } from '../state';
import { ClusterPin, Pin } from '../common/pin';
import { MapFallback, useViewport } from '.';
import { useCluster } from './usecluster';
import { Popup } from './popup';
import { useMount } from '../hooks';
import { MemoMarker, PIN_SIZE } from './memo';
var StyledMultiplePinMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: 30rem;\n  padding: 0.625rem;\n  margin: -0.625rem -0.625rem -0.9375rem;\n  border-radius: 3px;\n  max-width: 20rem;\n"], ["\n  overflow: auto;\n  max-height: 30rem;\n  padding: 0.625rem;\n  margin: -0.625rem -0.625rem -0.9375rem;\n  border-radius: 3px;\n  max-width: 20rem;\n"])));
var StyledMultiplePinMenuItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.25rem;\n  margin-bottom: 0.5rem;\n  cursor: pointer;\n  ", ";\n"], ["\n  padding: 0.25rem;\n  margin-bottom: 0.5rem;\n  cursor: pointer;\n  ", ";\n"])), function (props) { return (props.selected ? 'background-color: #deebff;' : ''); });
var MultiplePinMenuItem = function (props) {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var selected = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === props.type && props.idFields.every(function (f) { var _a; return props.location[f] === ((_a = selectedRegion.id) === null || _a === void 0 ? void 0 : _a[f]); });
    var handleClick = function () {
        setSelectedRegion({
            type: props.type,
            id: _.pick(props.location, props.idFields),
            longitude: props.longitude,
            latitude: props.latitude,
        });
    };
    return React.createElement(StyledMultiplePinMenuItem, __assign({}, props, { selected: selected, onClick: handleClick }));
};
export var MultiplePinMenu = React.memo(function (props) {
    var _a = useViewValue('selectedMultiplePin'), selectedMultiplePin = _a[0], setSelectedMultiplePin = _a[1];
    var selectedRegion = useViewValue('selectedRegion')[0];
    var show = props.type === (selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.type);
    var longitude = selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.point.geometry.coordinates[0];
    var latitude = selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.point.geometry.coordinates[1];
    useEffect(function () {
        if (selectedRegion) {
            setSelectedMultiplePin();
        }
    }, [selectedRegion, setSelectedMultiplePin]);
    return (React.createElement(Popup, { show: show, onClose: setSelectedMultiplePin, offsetTop: -(PIN_SIZE + 3), offsetLeft: 0, captureScroll: true, sortByDepth: true, closeButton: false, longitude: longitude, latitude: latitude },
        React.createElement(StyledMultiplePinMenu, null, selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.point.properties.locations.sort(function (a, b) { return (props.compareFn ? props.compareFn(a, b) : 0); }).map(function (location, idx) { return (React.createElement(MultiplePinMenuItem, { key: idx, type: props.type, location: location, idFields: props.idFields, longitude: longitude, latitude: latitude }, props.menuItemComponent && React.createElement(props.menuItemComponent, { location: location }))); }))));
});
var SinglePinMarker = React.memo(function (props) {
    var _a;
    var _b = useViewValue('selectedRegion'), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var location = props.point.properties.location;
    var selected = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === props.type && props.idFields.every(function (f) { var _a; return location[f] === ((_a = selectedRegion.id) === null || _a === void 0 ? void 0 : _a[f]); });
    var longitude = props.point.geometry.coordinates[0];
    var latitude = props.point.geometry.coordinates[1];
    return (React.createElement(MemoMarker, { longitude: longitude, latitude: latitude },
        React.createElement(Pin, __assign({}, props, { selected: selected, onClick: setSelectedRegion, payload: { type: props.type, id: _.pick(location, props.idFields), longitude: longitude, latitude: latitude }, icon: props.icon || ((_a = props.getIcon) === null || _a === void 0 ? void 0 : _a.call(props, location)) }))));
});
var MultiplePinMarker = React.memo(function (props) {
    var selectedRegion = useViewValue('selectedRegion')[0];
    var _a = useViewValue('selectedMultiplePin'), selectedMultiplePin = _a[0], setSelectedMultiplePin = _a[1];
    var pinPayload = useMemo(function () { return ((selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.point) === props.point ? undefined : { type: props.type, point: props.point }); }, [
        props.point,
        props.type,
        selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.point,
    ]);
    var selected = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === props.type &&
        props.point.properties.locations.some(function (location) { return props.idFields.every(function (f) { var _a; return location[f] === ((_a = selectedRegion.id) === null || _a === void 0 ? void 0 : _a[f]); }); });
    return (React.createElement(MemoMarker, { longitude: props.point.geometry.coordinates[0], latitude: props.point.geometry.coordinates[1] },
        React.createElement(Pin, __assign({}, props, { icon: undefined, selected: selected, text: props.point.properties.point_count_abbreviated, payload: pinPayload, onClick: setSelectedMultiplePin }))));
});
var ClusterPinMarker = React.memo(function (props) {
    var _a = useViewport(), updateViewPort = _a[1];
    var handleClick = function () {
        var _a;
        var zoom = (_a = props.getClusterExpansionZoom) === null || _a === void 0 ? void 0 : _a.call(props, props.point.properties.cluster_id);
        var _b = props.point.geometry.coordinates, longitude = _b[0], latitude = _b[1];
        updateViewPort({ zoom: zoom, longitude: longitude, latitude: latitude });
    };
    return (React.createElement(MemoMarker, { longitude: props.point.geometry.coordinates[0], latitude: props.point.geometry.coordinates[1] },
        React.createElement(ClusterPin, __assign({}, props, { text: props.point.properties.point_count_abbreviated, onClick: handleClick }))));
});
var SimpleLocationLayer = React.memo(function (props) {
    var locations = props.locations, id = props.id, rest = __rest(props, ["locations", "id"]);
    return (React.createElement(React.Fragment, null, locations.map(function (location, idx) { return (React.createElement(SinglePinMarker, __assign({ key: idx }, rest, { type: id, point: point(location.lnglat || [location.lng, location.lat], { location: location }) }))); })));
});
var ClusterLocationLayer = React.memo(function (props) {
    var locations = props.locations, id = props.id, clusterColor = props.clusterColor, rest = __rest(props, ["locations", "id", "clusterColor"]);
    var _a = useCluster(props.locations), clusters = _a[0], getClusterExpansionZoom = _a[1];
    var _b = useViewValue('selectedMultiplePin'), selectedMultiplePin = _b[0], setSelectedMultiplePin = _b[1];
    useEffect(function () {
        if ((selectedMultiplePin === null || selectedMultiplePin === void 0 ? void 0 : selectedMultiplePin.type) === id) {
            setSelectedMultiplePin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clusters]);
    return (React.createElement(React.Fragment, null,
        clusters.map(function (c, idx) { return (React.createElement(React.Fragment, { key: idx }, !c.properties.cluster ? (React.createElement(SinglePinMarker, __assign({}, rest, { type: id, point: c }))) : c.properties.isSameLocation ? (React.createElement(MultiplePinMarker, __assign({}, rest, { type: id, point: c }))) : (React.createElement(ClusterPinMarker, __assign({}, rest, { getClusterExpansionZoom: getClusterExpansionZoom, type: id, color: clusterColor || props.color, point: c }))))); }),
        React.createElement(MultiplePinMenu, { type: props.id, compareFn: props.compareFn, menuItemComponent: props.menuItemComponent, idFields: props.idFields })));
});
var LayerCount = function (props) {
    var _a = useViewValue('mapLayerCountNotifications', props.id), setMapLayerCountNotification = _a[1];
    useEffect(function () {
        setMapLayerCountNotification(null);
        if (props.show && props.displayingCount !== undefined && props.totalCount !== undefined) {
            setMapLayerCountNotification({ displayingCount: props.displayingCount, totalCount: props.totalCount, name: props.name });
        }
        return function () { return setMapLayerCountNotification(null); };
    }, [props.displayingCount, props.name, props.show, props.totalCount, setMapLayerCountNotification]);
    return null;
};
var LocationDetailComponent = function (props) {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var isSelected = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === props.id;
    var id = isSelected ? selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.id : undefined;
    useMount(function () { return function () {
        if (isSelected) {
            setSelectedRegion();
        }
    }; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var locationMap = useMemo(function () { return _.keyBy(props.locations, function (l) { return props.idFields.map(function (f) { return l[f]; }).join('-'); }); }, [props.locations]);
    var detailInfo = id && locationMap[props.idFields.map(function (f) { return id[f]; }).join('-')];
    useEffect(function () {
        if (props.requesting === false && id && !detailInfo) {
            setSelectedRegion();
        }
    }, [detailInfo, id, props.requesting, setSelectedRegion]);
    return null;
};
export var LocationLayer = React.memo(function (props) {
    var show = props.show, _a = props.cluster, cluster = _a === void 0 ? true : _a, rest = __rest(props, ["show", "cluster"]);
    return (React.createElement(React.Fragment, null,
        props.show && (React.createElement(React.Fragment, null,
            cluster ? React.createElement(ClusterLocationLayer, __assign({}, rest)) : React.createElement(SimpleLocationLayer, __assign({}, rest)),
            React.createElement(MapFallback, { requesting: props.requesting, error: props.error, retry: props.retry }),
            React.createElement(LocationDetailComponent, __assign({}, props)))),
        React.createElement(LayerCount, { show: props.show, id: props.id, name: props.name, displayingCount: props.locations.length, totalCount: props.totalCount })));
});
var templateObject_1, templateObject_2;
