var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { TabPane, Label, ListGroupItem } from 'reactstrap';
import { CheckboxGroup, FallbackComponent } from '../common';
import { useLookupFluVaccine } from '../lookupdata';
import { useReportsDialogValue } from './reportsdialogprovider';
import { FilterSidebarConst } from '../configs';
var FluVaccineReportsTabSelector = function (props) {
    var resprops = __rest(props, []);
    var isSeasonStatistic = useReportsDialogValue('isSeasonStatistic')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: !isSeasonStatistic })));
};
export var FluVaccineReportsDialogTab = function (props) {
    var _a = useReportsDialogValue('fluVaccineCptCodes'), fluVaccineCptCodes = _a[0], setFluVaccineCptCodes = _a[1];
    var _b = useLookupFluVaccine(), lookupVaccine = _b[0], fetchLookupVaccine = _b[1];
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, "Vaccines Requirements"),
        React.createElement(FallbackComponent, { requesting: lookupVaccine.requesting, error: lookupVaccine.error, retry: fetchLookupVaccine },
            React.createElement(CheckboxGroup, { options: lookupVaccine.data, optionsTransform: function (item) { return ({ value: item.hcpcsPctCodes, label: item.hcpcsDescription }); }, noSort: true, value: fluVaccineCptCodes, onChange: setFluVaccineCptCodes }))));
};
FluVaccineReportsDialogTab.defaultProps = {
    tabId: FilterSidebarConst.InfluenzaVaccine.tabId,
    tabName: FilterSidebarConst.InfluenzaVaccine.title,
    tabField: ['fluVaccineCptCodes'],
    tabSelector: FluVaccineReportsTabSelector,
};
