/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUS from './messages.en-US.json';
var resources = {
    'en-US': { translation: enUS },
};
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: resources,
    fallbackLng: 'en-US',
    debug: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
