/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { GeoLevelEnum } from '../enums';
export var GEO_LEVELS = [
    {
        value: GeoLevelEnum.Zip5,
        label: 'Zip Code',
    },
    {
        value: GeoLevelEnum.Zip3,
        label: '3 Digit zip code',
    },
    {
        value: GeoLevelEnum.State,
        label: 'State',
    },
    {
        value: GeoLevelEnum.County,
        label: 'County',
    },
    {
        value: GeoLevelEnum.MSA,
        label: 'MSA',
    },
    {
        value: GeoLevelEnum.Congressional,
        label: 'US Congressional District',
    },
    {
        value: GeoLevelEnum.StateUpper,
        label: 'State Upper House District',
    },
    {
        value: GeoLevelEnum.StateLower,
        label: 'State Lower House District',
    },
];
export var getGeoLevelLabel = function (geoLevel) { var _a; return geoLevel && ((_a = GEO_LEVELS.find(function (option) { return option.value === geoLevel; })) === null || _a === void 0 ? void 0 : _a.label); };
