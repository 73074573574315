var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, Label, FormGroup, ListGroupItem } from 'reactstrap';
import { Select, FallbackComponent, DELIMITER_SEASON_YEAR } from '../common';
import { DATA_SOURCES } from '../configs';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { LookupYearComponent, useCMSDataYear } from '../lookupdata';
import { getYearsBySeason } from '../utils';
import { REPORT_DATA_SOURCES, useReportsDialogValue } from './reportsdialogprovider';
var DataYearSeasonReportsTabSelector = function (props) {
    var resprops = __rest(props, []);
    var isSeasonStatistic = useReportsDialogValue('isSeasonStatistic')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: !isSeasonStatistic })));
};
export var DataYearSeasonReportsDialogTab = function (props) {
    var _a = React.useState(), dataYearSeasonUi = _a[0], setDataYearSeasonUi = _a[1];
    var _b = useReportsDialogValue('dataYearSeason'), dataYearSeason = _b[0], setDataYearSeason = _b[1];
    var _c = useReportsDialogValue('dataSourceType'), dataSourceType = _c[0], setDataSourceType = _c[1];
    var lookupSeasonYearData = useCMSDataYear(dataSourceType)[0];
    var _d = useReportsDialogValue('conditions'), conditions = _d[0], setConditions = _d[1];
    var _e = useReportsDialogValue('patientPopulationReferenceYear'), setPatientPopulationRefYear = _e[1];
    var indexConfig = useHostnameIndexInfo();
    var patientPopulationRefYearFilter = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter.patientPopulationReferenceYear;
    useEffect(function () {
        setPatientPopulationRefYear(patientPopulationRefYearFilter);
    }, [patientPopulationRefYearFilter, setPatientPopulationRefYear]);
    useEffect(function () {
        var _a = getYearsBySeason(dataYearSeasonUi), lateYear = _a[0], earlyYear = _a[1];
        if (lateYear && earlyYear) {
            var fullYearSeason = [Number(lateYear).valueOf(), Number(earlyYear).valueOf()];
            setDataYearSeason(fullYearSeason);
        }
    }, [setDataYearSeason, dataYearSeasonUi]);
    useEffect(function () {
        var _a, _b;
        if (((_a = lookupSeasonYearData.data) === null || _a === void 0 ? void 0 : _a.length) && !dataYearSeasonUi) {
            var dataYearSeasonValueUi = String(lookupSeasonYearData.data[lookupSeasonYearData.data.length - 1]);
            if (Array.isArray(dataYearSeason) && dataYearSeason.length) {
                var yearSeasonInDataYear = (_b = lookupSeasonYearData.data) === null || _b === void 0 ? void 0 : _b.find(function (year) { return String(year).includes(dataYearSeason.join(DELIMITER_SEASON_YEAR)); });
                dataYearSeasonValueUi = yearSeasonInDataYear ? String(yearSeasonInDataYear) : dataYearSeasonValueUi;
            }
            if (getYearsBySeason(dataYearSeasonValueUi).length === 2)
                handleChangeYearSeason(dataYearSeasonValueUi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataYearSeasonUi, lookupSeasonYearData]);
    var handleChangeDataSource = function (value, name, option) {
        setDataSourceType(value);
    };
    var handleChangeYearSeason = function (value) {
        var _a;
        setDataYearSeasonUi(value);
        if ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) {
            setConditions(conditions === null || conditions === void 0 ? void 0 : conditions.filter(function (cc) { var _a, _b; return !((_b = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) === null || _b === void 0 ? void 0 : _b.includes(cc)); }));
        }
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Source"),
            React.createElement(Select, { name: "dataSource", options: REPORT_DATA_SOURCES, optionsTransform: function (v) { return DATA_SOURCES[v]; }, noSort: true, value: dataSourceType, onChange: handleChangeDataSource })),
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Year"),
            React.createElement(FallbackComponent, { spinnerSize: 50, requesting: lookupSeasonYearData.requesting, error: lookupSeasonYearData.error },
                React.createElement(Select, { name: "dataYearSeason", options: lookupSeasonYearData.data, noSort: true, value: dataYearSeasonUi, onChange: handleChangeYearSeason, optionsTransform: function (year) { return ({ value: year, label: "" + year }); } }))),
        React.createElement(LookupYearComponent, { dataSource: dataSourceType })));
};
DataYearSeasonReportsDialogTab.defaultProps = {
    tabId: 'DataYearSeasonTab',
    tabName: 'Data Set',
    tabField: ['dataSourceType', 'dataYearSeason'],
    tabSelector: DataYearSeasonReportsTabSelector,
};
