/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useMemo } from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { useAPI } from '../state';
var MAP_LAYER_LOCATOR_API = '/api/map-layer/{id}';
var mapLayerLocationsSelector = function (state) { return state; };
var mapLayerLocationMapSelector = createSelector(mapLayerLocationsSelector, function (mapLayerLocations) { return _.keyBy(mapLayerLocations, 'id'); });
var createMapLayerLocationSelector = function (id) { return createSelector(mapLayerLocationMapSelector, function (map) { return map["" + id]; }); };
export var useCustomMapLayerLocationData = function (mapLayerId) {
    return useAPI(MAP_LAYER_LOCATOR_API, { id: mapLayerId }, 'POST');
};
export var useCustomMapLayerDetail = function (_a) {
    var _b = _a === void 0 ? Object.assign({}) : _a, id = _b.id, mapLayerId = _b.mapLayerId;
    var customMapLayerLocationData = useCustomMapLayerLocationData(mapLayerId)[0];
    var providerDetailSelector = useMemo(function () { return createMapLayerLocationSelector(id); }, [id]);
    return providerDetailSelector(customMapLayerLocationData.data);
};
