var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FallbackComponent, DataTable } from '../common';
import { GeoLevelEnum, RightPanelTypeEnum, ViewModeEnum } from '../enums';
import { useLookupZipCodeDistrict, getStatisticShortName, getGeoLevelLabel, useLookupState } from '../lookupdata';
import { useMapData, useExtractGeoData } from '../map/patientchoroplethlayer/usemapdata';
import { useFilterValue, useViewValue } from '../state';
import { useSelectedGeoJson } from '../map';
import { compareFunc, isFeatureInAState } from '../utils';
import { getDataFieldsByMapViewLevel, getDataFieldByStatisticType } from '../map/patientchoroplethlayer/dataforgeo';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
var StyledTableViewComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem 2rem;\n"], ["\n  padding: 1rem 2rem;\n"])));
var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Body = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var getColumns = function (geoLevel, statistic, lookupState, lookupGeoData) {
    var columns = [];
    var fieldsForMapViewLevel = getDataFieldsByMapViewLevel(geoLevel, Object.assign({}), lookupState, lookupGeoData);
    var fieldsForStatistic = getDataFieldByStatisticType(Object.assign({}), statistic);
    var dataFields = __spreadArrays(fieldsForMapViewLevel, fieldsForStatistic);
    columns = dataFields.map(function (entry) { return (__assign(__assign({}, entry), { dataField: entry.key, text: entry.name, formatter: function (cell) { return cell.displayText; }, sort: true, sortFunc: function (a, b, order) { return compareFunc(a.originalValue, b.originalValue, order); } })); });
    return columns;
};
export var useExtractTableData = function () {
    var extractGeoData = useExtractGeoData();
    var lookupState = useLookupState()[0];
    var lookupGeoData = useLookupZipCodeDistrict()[0];
    var geoLevel = useViewValue('geoType')[0];
    var geoJsonData = useSelectedGeoJson()[0];
    var selectedRegion = useViewValue('selectedRegion')[0];
    var selectedFeatureId = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail ? selectedRegion.id : undefined;
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var indexConfig = useHostnameIndexInfo();
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    var extractData = useCallback(function (intersectionIds) {
        var _a;
        var isSelectedFirstLevel = selectedFeatureId === null || selectedFeatureId === void 0 ? void 0 : selectedFeatureId.endsWith('-');
        var filteredFetures = (_a = geoJsonData.data) === null || _a === void 0 ? void 0 : _a.features;
        if (intersectionIds) {
            filteredFetures = filteredFetures === null || filteredFetures === void 0 ? void 0 : filteredFetures.filter(function (f) { var _a; return intersectionIds[(_a = f.properties) === null || _a === void 0 ? void 0 : _a.id]; });
        }
        else if (isSelectedFirstLevel && geoLevel === GeoLevelEnum.Zip5) {
            filteredFetures = filteredFetures === null || filteredFetures === void 0 ? void 0 : filteredFetures.filter(function (f) { var _a; return lookupGeoData.getStateCodeOfZipCode((_a = f.properties) === null || _a === void 0 ? void 0 : _a.id) + "-" === (selectedFeatureId === null || selectedFeatureId === void 0 ? void 0 : selectedFeatureId.padStart(3, '0')); });
        }
        else if (selectedFeatureId) {
            filteredFetures = filteredFetures === null || filteredFetures === void 0 ? void 0 : filteredFetures.filter(function (f) { var _a, _b; return isSelectedFirstLevel ? (_a = f.properties) === null || _a === void 0 ? void 0 : _a.id.startsWith(selectedFeatureId) : ((_b = f.properties) === null || _b === void 0 ? void 0 : _b.id) === selectedFeatureId; });
        }
        else if (singleState) {
            filteredFetures = filteredFetures === null || filteredFetures === void 0 ? void 0 : filteredFetures.filter(function (f) { var _a; return isFeatureInAState(singleState, f.properties, geoLevel || GeoLevelEnum.State, (_a = lookupGeoData.data) === null || _a === void 0 ? void 0 : _a.stateByZipCode5); });
        }
        var columns = getColumns(geoLevel, statistic, lookupState, lookupGeoData);
        var data = [];
        filteredFetures === null || filteredFetures === void 0 ? void 0 : filteredFetures.sort(function (f1, f2) { var _a, _b; return compareFunc((_a = f1.properties) === null || _a === void 0 ? void 0 : _a.id.padStart(6, '0'), (_b = f2.properties) === null || _b === void 0 ? void 0 : _b.id.padStart(6, '0')); }).forEach(function (f) {
            var _a;
            var geoData = extractGeoData((_a = f.properties) === null || _a === void 0 ? void 0 : _a.id);
            var fieldsForMapViewLevel = getDataFieldsByMapViewLevel(geoLevel, f.properties, lookupState, lookupGeoData);
            var fieldsForStatistic = getDataFieldByStatisticType(geoData, statistic);
            var dataFields = __spreadArrays(fieldsForMapViewLevel, fieldsForStatistic);
            var item = dataFields.reduce(function (acc, curr) {
                var _a;
                var _b;
                return (__assign(__assign({}, acc), (_a = {}, _a[curr.key] = {
                    displayText: curr.value,
                    originalValue: curr.originalValue,
                }, _a.id = (_b = f.properties) === null || _b === void 0 ? void 0 : _b.id, _a)));
            }, {});
            data.push(item);
        });
        return [columns, data];
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extractGeoData, selectedFeatureId, geoJsonData.data, lookupState.data, lookupGeoData.data]);
    return extractData;
};
export var TableViewComponent = function () {
    var t = useTranslation().t;
    var mapData = useMapData()[0];
    var lookupGeoData = useLookupZipCodeDistrict()[0];
    var geoLevel = useViewValue('geoType')[0];
    var geoJsonData = useSelectedGeoJson()[0];
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticName = getStatisticShortName(statistic);
    var viewMode = useViewValue('viewMode')[0];
    var extractTableData = useExtractTableData();
    var _a = useState(), tableColumns = _a[0], setTableColumns = _a[1];
    var _b = useState(), tableData = _b[0], setTableData = _b[1];
    var isShow = viewMode === ViewModeEnum.Table;
    useEffect(function () {
        if (!isShow)
            return;
        var _a = extractTableData(), columns = _a[0], data = _a[1];
        setTableColumns(columns);
        setTableData(data);
    }, [isShow, extractTableData]);
    if (!isShow)
        return null;
    return (React.createElement(StyledTableViewComponent, null,
        React.createElement(Title, null, t('tableview.title', [statisticName, getGeoLevelLabel(geoLevel)])),
        React.createElement(Body, null,
            React.createElement(FallbackComponent, { requesting: mapData.requesting || geoJsonData.requesting || lookupGeoData.requesting, error: mapData.error || lookupGeoData.error || lookupGeoData.error }, !!(tableColumns === null || tableColumns === void 0 ? void 0 : tableColumns.length) && tableData && React.createElement(DataTable, { hover: true, columns: tableColumns, data: tableData, sizePerPage: 100, keyField: "id", pagination: true })))));
};
var templateObject_1, templateObject_2, templateObject_3;
