var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Collapse, Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import styled from 'styled-components';
import { LicenseAgreementModal } from './licenseagreementmodal';
import { IconButton, useAPI, useMount, useToggle } from '..';
var CreditsTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 1.25rem;\n  cursor: pointer;\n"], ["\n  font-weight: 700;\n  font-size: 1.25rem;\n  cursor: pointer;\n"])));
var CollapseSectionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.25rem;\n  ", "\n"], ["\n  padding: 0.25rem;\n  ", "\n"])), function (props) { return (props.isOpen ? 'background-color: #f2f2f2;' : ''); });
var CollapseToggle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])));
var CollapseToggleTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 1rem;\n  flex: 1;\n"], ["\n  font-weight: 700;\n  font-size: 1rem;\n  flex: 1;\n"])));
var LicensesText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: 'Courier New', monospace;\n  margin-top: 1rem;\n  font-size: 0.875rem;\n  white-space: pre-wrap;\n"], ["\n  font-family: 'Courier New', monospace;\n  margin-top: 1rem;\n  font-size: 0.875rem;\n  white-space: pre-wrap;\n"])));
var StyledPaginate = styled.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  list-style: none;\n  justify-content: center;\n  padding: 0.5rem 0;\n  margin: 0;\n  li {\n    padding: 0.25rem;\n    &.active {\n      font-weight: 700;\n    }\n  }\n"], ["\n  display: flex;\n  list-style: none;\n  justify-content: center;\n  padding: 0.5rem 0;\n  margin: 0;\n  li {\n    padding: 0.25rem;\n    &.active {\n      font-weight: 700;\n    }\n  }\n"])));
var CollapseSection = function (props) {
    var isOpen = props.expandedId === props.sectionId;
    var handleClick = function () {
        props.setExpandedId(isOpen ? undefined : props.sectionId);
    };
    return (React.createElement(CollapseSectionContainer, { isOpen: isOpen },
        React.createElement(CollapseToggle, { onClick: handleClick },
            React.createElement(CollapseToggleTitle, null, props.title),
            React.createElement(IconButton, { icon: isOpen ? 'angle-up' : 'angle-down', size: "2x", color: "#000" })),
        React.createElement(Collapse, { isOpen: isOpen }, props.children)));
};
var PageButton = function (props) {
    var handlePageClick = function () {
        var _a;
        if (!props.disabled) {
            (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.index);
        }
    };
    return (React.createElement("li", { className: props.className + " " + (props.disabled ? 'disabled' : '') },
        React.createElement("a", { tabIndex: props.disabled ? -1 : 0, role: "button", "aria-disabled": props.disabled ? 'true' : 'false', "aria-label": props.ariaLabel, onClick: handlePageClick }, props.label)));
};
var Paginate = function (props) {
    var _a = useState(0), selected = _a[0], setSelected = _a[1];
    var handlePageClick = function (index) {
        setSelected(index);
        props.onPageChange(index);
    };
    var pagination = function () {
        var items = [];
        var pageRangeDisplayed = 5;
        var marginPagesDisplayed = 2;
        var createPageView = function (index) { return (React.createElement(PageButton, { key: index, className: index === selected ? 'active' : '', ariaLabel: "Page " + (index + 1), index: index, label: index + 1, onClick: handlePageClick })); };
        var createBreakView = function (index) { return (React.createElement(PageButton, { key: index, index: index < selected ? Math.max(selected - pageRangeDisplayed, 0) : Math.min(selected + pageRangeDisplayed, props.pageCount - 1), label: "...", onClick: handlePageClick })); };
        if (props.pageCount <= pageRangeDisplayed) {
            for (var index = 0; index < props.pageCount; index++) {
                items.push(createPageView(index));
            }
        }
        else {
            var leftSide = pageRangeDisplayed / 2;
            var rightSide = pageRangeDisplayed - leftSide;
            if (selected > props.pageCount - pageRangeDisplayed / 2) {
                rightSide = props.pageCount - selected;
                leftSide = pageRangeDisplayed - rightSide;
            }
            else if (selected < pageRangeDisplayed / 2) {
                leftSide = selected;
                rightSide = pageRangeDisplayed - leftSide;
            }
            for (var index = 0; index < props.pageCount; index++) {
                var adjustedRightSide = !selected ? rightSide - 1 : rightSide;
                var page = index + 1;
                var isPageStart = page <= marginPagesDisplayed;
                var isPageEnd = page > props.pageCount - marginPagesDisplayed;
                var isPageRange = index >= selected - leftSide && index <= selected + adjustedRightSide;
                var isPageStartNoBreak = page === marginPagesDisplayed + 1 && index + 1 >= selected - leftSide;
                var isPageEndNoBreak = page === props.pageCount - marginPagesDisplayed && index - 1 <= selected + adjustedRightSide;
                if (isPageStart || isPageEnd || isPageRange || isPageStartNoBreak || isPageEndNoBreak) {
                    items.push(createPageView(index));
                    continue;
                }
                if (page === marginPagesDisplayed + 1) {
                    items.push(createBreakView(index));
                    continue;
                }
                if (page === props.pageCount - marginPagesDisplayed) {
                    items.push(createBreakView(index));
                    continue;
                }
            }
        }
        return items;
    };
    if (!props.pageCount || props.pageCount === 1)
        return null;
    return (React.createElement(StyledPaginate, { className: "pagination" },
        React.createElement(PageButton, { disabled: !selected, className: "previous", ariaLabel: "Previous page", index: selected - 1, label: "previous", onClick: handlePageClick }),
        pagination(),
        React.createElement(PageButton, { disabled: selected === props.pageCount - 1, className: "next", ariaLabel: "Next page", index: selected + 1, label: "next", onClick: handlePageClick })));
};
var Licenses = React.memo(function (props) {
    var _a = useState(''), expandedId = _a[0], setExpandedId = _a[1];
    var _b = useState({
        offset: 0,
        numberPerPage: 100,
        pageCount: 0,
        currentData: [],
    }), pagination = _b[0], setPagination = _b[1];
    useEffect(function () {
        setPagination(function (prevState) { return (__assign(__assign({}, prevState), { pageCount: Math.ceil(props.licenses.length / prevState.numberPerPage), currentData: props.licenses.slice(pagination.offset, pagination.offset + pagination.numberPerPage) })); });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.numberPerPage, pagination.offset]);
    var handlePageClick = function (selected) {
        var offset = selected * pagination.numberPerPage;
        setPagination(__assign(__assign({}, pagination), { offset: offset }));
    };
    return (React.createElement(Collapse, { isOpen: props.isOpen, className: "flex-grow-1 overflow-auto" },
        React.createElement("div", { className: "h-100 d-flex flex-column" },
            React.createElement("div", { className: "flex-grow-1 overflow-auto" }, pagination.currentData &&
                pagination.currentData.map(function (item, index) { return (React.createElement(CollapseSection, { key: index, sectionId: item.componentName, title: item.componentName, expandedId: expandedId, setExpandedId: setExpandedId },
                    React.createElement("a", { href: item.homePage, target: "_blank", rel: "noopener noreferrer" }, item.homePage),
                    React.createElement(LicensesText, null, item.licenseText))); })),
            React.createElement(Paginate, { pageCount: pagination.pageCount, onPageChange: handlePageClick }))));
});
var CreditsPage = function () {
    var _a = useAPI(process.env.PUBLIC_URL + "/licenses.json"), licensesData = _a[0], fetchLicensesData = _a[1];
    var _b = useToggle(), showCredits = _b[0], toggleCredits = _b[1];
    var _c = useState(), licenses = _c[0], setLicenses = _c[1];
    useMount(function () {
        fetchLicensesData();
    });
    useEffect(function () {
        setLicenses(typeof licensesData.data === 'object'
            ? Object.entries(licensesData.data)
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return ({
                    componentName: key,
                    homePage: value.repository,
                    licenseText: value.licenseText,
                });
            })
                .sort(function (a, b) { return a.componentName.toLowerCase().localeCompare(b.componentName.toLowerCase()); })
            : undefined);
    }, [licensesData.data]);
    if (!licenses)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(CreditsTitle, { onClick: toggleCredits },
            "Credits",
            React.createElement(IconButton, { icon: showCredits ? 'angle-up' : 'angle-down', size: "2x", color: "#000" })),
        React.createElement(Licenses, { isOpen: showCredits, licenses: licenses })));
};
export var AboutModal = function (props) {
    var _a = useState(false), licenseModal = _a[0], setLicenseModal = _a[1];
    var toggleLicenseAgreementModal = function () {
        if (licenseModal) {
            props.toggle();
        }
        setLicenseModal(!licenseModal);
    };
    return (React.createElement(Fragment, null,
        React.createElement(Modal, { isOpen: props.isOpen && !licenseModal, toggle: props.toggle, scrollable: true },
            React.createElement(ModalHeader, { toggle: props.toggle }, "About"),
            React.createElement(ModalBody, { className: "d-flex flex-column" },
                React.createElement("div", null, "About " + props.title + " " + process.env.REACT_APP_VERSION),
                React.createElement("div", null,
                    "Copyright \u00A9 2018-",
                    moment.utc().year(),
                    " SHC Holdings LLC"),
                React.createElement("br", null),
                React.createElement("p", null,
                    "The use of this product is subject to the terms of the National Minority Quality Forum, Inc. (the \"Forum\")\u00A0",
                    React.createElement("a", { href: "#", onClick: toggleLicenseAgreementModal }, "License Agreement")),
                React.createElement(CreditsPage, null))),
        React.createElement(LicenseAgreementModal, { isOpen: licenseModal, toggle: toggleLicenseAgreementModal })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
