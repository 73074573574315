var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useCallback, useMemo } from 'react';
import { RESET_FILTERS, SET_FILTER_VALUE } from './reducer';
import { ALL_AGE_VALUES } from '../../lookupdata';
export function useFilterValue() {
    var keyPath = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keyPath[_i] = arguments[_i];
    }
    var dispatch = useDispatch();
    var stringifiedPath = JSON.stringify(keyPath);
    var setter = useCallback(function (value) { return dispatch({ type: SET_FILTER_VALUE, payload: { keyPath: JSON.parse(stringifiedPath), value: value } }); }, [
        dispatch,
        stringifiedPath,
    ]);
    var getter = useCallback(function (state) { return state.filter.getIn(JSON.parse(stringifiedPath)); }, [stringifiedPath]);
    return [useMappedState(getter), setter];
}
export var useResetFilter = function () {
    var dispatch = useDispatch();
    return useCallback(function (value) { return dispatch({ type: RESET_FILTERS, payload: value }); }, [dispatch]);
};
export var useFilterSetting = function () {
    var filter = useMappedState(useCallback(function (state) { return state.filter; }, []));
    return useMemo(function () { return filter.toJSON(); }, [filter]);
};
export var exportFilterSettingPayload = function (values) {
    var _a, _b, _c, _d, _e;
    if (values.isAggregatedDataSource) {
        return {
            statisticType: values.statisticType,
            statisticOptionType: values.statisticOptionType,
            dataSourceName: values.dataSourceName,
            year: values.year,
            filteringCategoryType: values.filteringCategoryType,
            filteringCategoryValue: values.filteringCategoryValue,
        };
    }
    return {
        consumptionGroupClasses: values.consumptionGroupClasses,
        additionalConditions: values.additionalConditions,
        primaryConditions: values.primaryConditions,
        primaryCoMorbidities: (_a = values.primaryCoMorbidities) === null || _a === void 0 ? void 0 : _a.slice(0, 1),
        comorbidities: Array.from(new Set(__spreadArrays((values.primaryCoMorbidities || []), (values.comorbidities || [])))).sort(),
        demographics: __assign(__assign({}, values.demographics), { ages: ((_c = (_b = values.demographics) === null || _b === void 0 ? void 0 : _b.ages) === null || _c === void 0 ? void 0 : _c.length) ? (_d = values.demographics) === null || _d === void 0 ? void 0 : _d.ages : ALL_AGE_VALUES }),
        raceMethodology: values.raceMethodology,
        dataYear: isNaN(values.dataYear || 0) ? 9999 : values.dataYear,
        dataYearSeason: values.dataYearSeason,
        statistic: values.statistic,
        claimNumber: values.claimNumber,
        claimDay: values.claimDay,
        singleStateCode: values.singleStateCode,
        singleStateAbb: values.singleStateAbb,
        vaccineCptCodes: values.vaccineCptCodes,
        fluVaccineCptCodes: values.fluVaccineCptCodes,
        primaryConditionWithClaims: (_e = values.primaryCoMorbidities) === null || _e === void 0 ? void 0 : _e.map(function (condition) {
            var _a;
            return ({
                chronicCode: Number(condition),
                minClaimNumber: (_a = values.primaryConditionWithClaims) === null || _a === void 0 ? void 0 : _a[condition],
            });
        }).filter(function (i) { return i.minClaimNumber && i.chronicCode; }),
        primaryConditionWithClaimsOptionals: values.primaryConditionWithClaimsOptionals,
        patientPopulationReferenceYear: values.patientPopulationReferenceYear,
        useMBSFChronicCondition: values.useMBSFChronicCondition,
    };
};
