var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { TabPane, Label, FormGroup, Col, ListGroupItem } from 'reactstrap';
import { useReportsDialogValue } from './reportsdialogprovider';
import { Select } from '../common';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useLookupState, useLookupZipCodeDistrict } from '../lookupdata';
import styled from 'styled-components';
import { ReportTypeEnum } from '../enums/reporttypeenum';
var StlyledLabel = styled(Label)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: calc(0.375rem + 1px);\n  padding-bottom: calc(0.375rem + 1px);\n  margin-bottom: 0;\n  margin-left: 1rem;\n  font-size: inherit;\n  line-height: 1.5;\n"], ["\n  padding-top: calc(0.375rem + 1px);\n  padding-bottom: calc(0.375rem + 1px);\n  margin-bottom: 0;\n  margin-left: 1rem;\n  font-size: inherit;\n  line-height: 1.5;\n"])));
var ShowByReportsTabSelector = function (props) {
    var resprops = __rest(props, []);
    var reportType = useReportsDialogValue('reportType')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: reportType !== ReportTypeEnum.CongressionalDetailAnalysis })));
};
export var ShowByReportsDialogTab = function (props) {
    var _a;
    var lookupStateData = useLookupState()[0];
    var districtByStateData = useLookupZipCodeDistrict()[0];
    var indexConfig = useHostnameIndexInfo();
    var _b = useReportsDialogValue('firstLevel'), firstLevel = _b[0], setFirstLevel = _b[1];
    var _c = useReportsDialogValue('secondLevel'), secondLevel = _c[0], setSecondLevel = _c[1];
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    var secondLevelOptions = useMemo(function () {
        var _a, _b;
        return firstLevel
            ? (_b = (_a = districtByStateData.data) === null || _a === void 0 ? void 0 : _a.congressionalDistrictByState[firstLevel]) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return ("" + a).padStart(5, '0').localeCompare(("" + b).padStart(5, '0')); }) : undefined;
    }, [firstLevel, districtByStateData.data]);
    useEffect(function () {
        if ((secondLevelOptions === null || secondLevelOptions === void 0 ? void 0 : secondLevelOptions.length) && (!secondLevel || !secondLevelOptions.includes(secondLevel))) {
            setSecondLevel(secondLevelOptions[0]);
        }
    }, [secondLevel, secondLevelOptions, setSecondLevel]);
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, { row: true },
            React.createElement(StlyledLabel, { className: "font-weight-bold" }, "State"),
            React.createElement(Col, { sm: 5 },
                React.createElement(Select, { options: (_a = lookupStateData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return !singleState || parseInt(i.stateCode, 10) === singleState.stateCode; }), name: "firstLevel", value: firstLevel, onChange: setFirstLevel, isLoading: lookupStateData.requesting })),
            React.createElement(StlyledLabel, { className: "font-weight-bold" }, "District"),
            React.createElement(Col, { sm: 3 },
                React.createElement(Select, { width: "6", options: secondLevelOptions, optionsTransform: function (value) { return ({ value: value, label: value }); }, noSort: true, name: "secondLevel", value: secondLevel, onChange: setSecondLevel, isLoading: !!firstLevel && districtByStateData.requesting })))));
};
ShowByReportsDialogTab.defaultProps = {
    tabId: 'geoLevelTab',
    tabName: 'Show by',
    tabField: ['firstLevel', 'secondLevel'],
    tabSelector: ShowByReportsTabSelector,
};
var templateObject_1;
