var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '../common';
import { ViewModeEnum } from '../enums';
import { useViewValue } from '../state';
import { DisplayOptionComponent, ViewModeComponent } from './displayoption';
import { GeoSearchComponent } from './geosearch';
import { ShareLinkButton } from './sharelinkbutton';
var StyledFilterBar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0.75rem;\n  // box-shadow: 0 5px 10px -5px;\n  flex: 0 0 auto !important;\n  min-width: 17rem;\n  background-color: hsla(0, 0%, 0%, 0.3);\n  z-index: 599;\n  color: #fff;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 0.75rem;\n  // box-shadow: 0 5px 10px -5px;\n  flex: 0 0 auto !important;\n  min-width: 17rem;\n  background-color: hsla(0, 0%, 0%, 0.3);\n  z-index: 599;\n  color: #fff;\n"])));
var Separator = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-self: stretch;\n  background-color: #fff;\n  width: 1px;\n  margin: 0.25rem 0.5rem;\n  display: none;\n\n  @media only screen and (max-width: 720px) {\n    display: block;\n    border-bottom: 1px solid #ffffff;\n    width: 19.5rem;\n  }\n"], ["\n  align-self: stretch;\n  background-color: #fff;\n  width: 1px;\n  margin: 0.25rem 0.5rem;\n  display: none;\n\n  @media only screen and (max-width: 720px) {\n    display: block;\n    border-bottom: 1px solid #ffffff;\n    width: 19.5rem;\n  }\n"])));
var LeftSide = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: auto;\n  display: flex;\n  justify-content: left;\n  align-items: center;\n"], ["\n  flex: auto;\n  display: flex;\n  justify-content: left;\n  align-items: center;\n"])));
var RightSide = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n\n  @media only screen and (min-width: 721px) {\n    display: none;\n  }\n"], ["\n  flex: 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n\n  @media only screen and (min-width: 721px) {\n    display: none;\n  }\n"])));
var Navbar = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n\n  @media only screen and (max-width: 720px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    padding-left: 2rem;\n    padding-bottom: 0.5rem;\n    top: 3.875rem;\n    width: 100%;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    flex-direction: column;\n    align-items: flex-start;\n    ", "\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n\n  @media only screen and (max-width: 720px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    padding-left: 2rem;\n    padding-bottom: 0.5rem;\n    top: 3.875rem;\n    width: 100%;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    flex-direction: column;\n    align-items: flex-start;\n    ", "\n  }\n"])), function (_a) {
    var show = _a.show;
    return show ? 'display: flex;' : '';
});
var HamburgerButton = styled(IconButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: none;\n\n  @media only screen and (max-width: 940px) {\n    display: flex;\n  }\n"], ["\n  display: none;\n\n  @media only screen and (max-width: 940px) {\n    display: flex;\n  }\n"])));
var ListIcon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (min-width: 721px) and (max-width: 940px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    padding-left: 2rem;\n    padding-bottom: 0.5rem;\n    top: 3.875rem;\n    width: 100%;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    align-items: flex-start;\n    ", "\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (min-width: 721px) and (max-width: 940px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    padding-left: 2rem;\n    padding-bottom: 0.5rem;\n    top: 3.875rem;\n    width: 100%;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    align-items: flex-start;\n    ", "\n  }\n"])), function (_a) {
    var show = _a.show;
    return show ? 'display: flex;' : '';
});
var StyledComponentWithScreen = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  @media only screen and (min-width: 721px) {\n    display: none;\n  }\n"], ["\n  @media only screen and (min-width: 721px) {\n    display: none;\n  }\n"])));
export var FilterBar = function (props) {
    var location = useLocation();
    var viewMode = useViewValue('viewMode')[0];
    var _a = useState(false), show = _a[0], setShow = _a[1];
    return (React.createElement(StyledFilterBar, null,
        React.createElement(LeftSide, null,
            React.createElement(HamburgerButton, { icon: show ? 'times' : 'bars', size: '2x', onClick: function () { return setShow(!show); } }),
            React.createElement(Navbar, { show: show },
                React.createElement(ListIcon, { show: show },
                    React.createElement(IconButton, { icon: "filter", size: "2x", onClick: function () { return props.toggleFilterDialog(); }, title: "Set filter criteria", placement: "top" }),
                    props.children,
                    React.createElement(ShareLinkButton, null),
                    React.createElement(IconButton, { icon: "share", size: "2x", tag: "a", href: location.search, target: "_blank", rel: "opener", title: "Open current view in new tab", placement: "top" }),
                    React.createElement(StyledComponentWithScreen, null,
                        React.createElement(ViewModeComponent, null))),
                React.createElement(Separator, null),
                React.createElement(DisplayOptionComponent, null))),
        React.createElement(RightSide, null, (viewMode === ViewModeEnum.Map || viewMode === ViewModeEnum.Table) && React.createElement(GeoSearchComponent, null))));
};
export * from './downloadbutton';
export * from './useeffectfiltercount';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
