var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo } from 'react';
import { UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import styled from 'styled-components';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useViewValue, useFilterValue, useFilterSetting } from '../state';
import { VIEW_MODES, CHART_VIEW_OPTIONS } from '../configs';
import { DropdownToggleIcon, DropdownItem, Select } from '../common';
import { ViewModeEnum, ChartViewEnum, RightPanelTypeEnum } from '../enums';
import { GeoSearchComponent } from './geosearch';
import { useAggregatedGeoLevel } from '../filterdialog/aggregateddatasource';
import { getStatisticShortName, GEO_LEVELS } from '../lookupdata';
import { useGeneralConfigByDataSource } from '../hooks';
var DisplayOption = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (min-width: 721px) {\n    margin-left: auto;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  @media only screen and (min-width: 721px) {\n    margin-left: auto;\n  }\n"])));
var StyledViewModeOption = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 0.5rem;\n  display: flex;\n  color: #000;\n  display: flex;\n  align-items: center;\n"], ["\n  padding: 0 0.5rem;\n  display: flex;\n  color: #000;\n  display: flex;\n  align-items: center;\n"])));
var SearchLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #fff;\n  margin-right: 0.5rem;\n"], ["\n  color: #fff;\n  margin-right: 0.5rem;\n"])));
var StyledComponentWithScreen = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media only screen and (max-width: 720px) {\n    display: none;\n  }\n"], ["\n  @media only screen and (max-width: 720px) {\n    display: none;\n  }\n"])));
export var MapViewOption = function () {
    var viewMode = useViewValue('viewMode')[0];
    var _a = useViewValue('geoType'), geoLevel = _a[0], setGeoLevel = _a[1];
    var _b = useViewValue('selectedRegion'), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var filterSettings = useFilterSetting();
    var aggregatedGeoLevelData = useAggregatedGeoLevel(filterSettings)[0];
    var availableGeography = useGeneralConfigByDataSource('availableGeography');
    var isOptionDisabled = function (option) { var _a; return !!filterSettings.isAggregatedDataSource && !((_a = aggregatedGeoLevelData.data) === null || _a === void 0 ? void 0 : _a.some(function (i) { return i === option.value; })); };
    var isOptionDisabledByGeneralConfig = function (option) { return Array.isArray(availableGeography) && !availableGeography.includes(option.value); };
    var handleChangeGeoLevel = function (value) {
        setGeoLevel(value);
        if (value !== geoLevel && (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail) {
            setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail });
        }
    };
    if (viewMode !== ViewModeEnum.Map && viewMode !== ViewModeEnum.Table)
        return null;
    return (React.createElement(StyledViewModeOption, null,
        viewMode === ViewModeEnum.Map && (React.createElement(React.Fragment, null,
            React.createElement(SearchLabel, null, "Show"),
            React.createElement(Select, { minMenuHeight: 330, maxMenuHeight: 500, width: "16", className: "mr-1", name: "geoLevel", options: GEO_LEVELS, noSort: true, value: geoLevel, onChange: handleChangeGeoLevel, isOptionDisabled: isOptionDisabledByGeneralConfig || isOptionDisabled }))),
        React.createElement(StyledComponentWithScreen, null,
            React.createElement(GeoSearchComponent, null))));
};
var StyledCheckIcon = styled(FontAwesomeIcon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  left: 1.25rem;\n"], ["\n  position: absolute;\n  left: 1.25rem;\n"])));
var StyleSelectChartView = styled(Select)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: calc(100vw - 7rem);\n"], ["\n  max-width: calc(100vw - 7rem);\n"])));
var ChartViewSearchOption = function (props) {
    return (React.createElement(components.Option, __assign({}, props, { className: props.data.isParent ? 'font-weight-bold' : 'pl-5 position-relative' }),
        props.data.checked && React.createElement(StyledCheckIcon, { className: "position-absolute", icon: "check" }),
        props.children));
};
var ChartViewDemographicValue = function (props) {
    var _a;
    var demographics = useViewValue('chartView', 'demographics')[0];
    var childrenName = (_a = props.data.children) === null || _a === void 0 ? void 0 : _a.filter(function (child) { return demographics === null || demographics === void 0 ? void 0 : demographics.some(function (v) { return v === child.value; }); }).map(function (child) { return child.label; }).join(', ');
    return (React.createElement(components.SingleValue, __assign({}, props),
        React.createElement("b", null, props.children),
        "\u00A0",
        React.createElement("span", null, childrenName)));
};
var ChartViewTrendValue = function (props) {
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticName = getStatisticShortName(statistic);
    return (React.createElement(components.SingleValue, __assign({}, props),
        React.createElement("b", null, props.children),
        "\u00A0",
        React.createElement("span", null,
            "Yearly ",
            statisticName)));
};
export var ChartViewOption = function () {
    var viewMode = useViewValue('viewMode')[0];
    var _a = useViewValue('chartView', 'type'), chartViewType = _a[0], setChartViewType = _a[1];
    var _b = useViewValue('chartView', 'demographics'), demographics = _b[0], setDemographics = _b[1];
    var selectingChildrenMap = useMemo(function () {
        var _a;
        return (_a = {},
            _a[ChartViewEnum.Demographic] = [demographics, setDemographics],
            _a[ChartViewEnum.Trends] = undefined,
            _a);
    }, [demographics, setDemographics]);
    var options = useMemo(function () {
        return CHART_VIEW_OPTIONS.flatMap(function (option) {
            var childrenSelecting = (selectingChildrenMap[option.value] || [])[0];
            return __spreadArrays([
                __assign(__assign({}, option), { isParent: true })
            ], (option.children || []).map(function (child) { return (__assign(__assign({}, child), { parent: option, checked: childrenSelecting === null || childrenSelecting === void 0 ? void 0 : childrenSelecting.some(function (v) { return v === child.value; }) })); }));
        });
    }, [selectingChildrenMap]);
    var handleChange = function (value, name, values) {
        var _a, _b;
        if (values.isParent) {
            setChartViewType(value);
        }
        else {
            var _c = selectingChildrenMap[values.parent.value] || [], childrenSelecting_1 = _c[0], setter = _c[1];
            var isChecking = !!(childrenSelecting_1 === null || childrenSelecting_1 === void 0 ? void 0 : childrenSelecting_1.some(function (v) { return v === value; }));
            if (isChecking) {
                setter === null || setter === void 0 ? void 0 : setter((_a = values.parent.children) === null || _a === void 0 ? void 0 : _a.filter(function (option) { return (childrenSelecting_1 === null || childrenSelecting_1 === void 0 ? void 0 : childrenSelecting_1.length) === 1 || (option.value !== value && (childrenSelecting_1 === null || childrenSelecting_1 === void 0 ? void 0 : childrenSelecting_1.some(function (v) { return option.value === v; }))); }).map(function (option) { return option.value; }));
            }
            else {
                setter === null || setter === void 0 ? void 0 : setter((_b = values.parent.children) === null || _b === void 0 ? void 0 : _b.filter(function (option) { return option.value === value || (childrenSelecting_1 === null || childrenSelecting_1 === void 0 ? void 0 : childrenSelecting_1.some(function (v) { return option.value === v; })); }).map(function (option) { return option.value; }));
            }
        }
    };
    if (viewMode !== ViewModeEnum.Chart)
        return null;
    return (React.createElement(StyledViewModeOption, null,
        React.createElement(SearchLabel, null, "Show"),
        React.createElement(StyleSelectChartView, { name: "chartViewType", width: "22", options: options, noSort: true, value: chartViewType, onChange: handleChange, indicatorIcon: "search", components: {
                Option: ChartViewSearchOption,
                SingleValue: chartViewType === ChartViewEnum.Demographic ? ChartViewDemographicValue : ChartViewTrendValue,
            }, isOptionDisabled: function (i) { return !!i.parent && chartViewType !== i.parent.value; } })));
};
export var ViewModeComponent = function () {
    var _a = useViewValue('viewMode'), viewMode = _a[0], setViewMode = _a[1];
    var selectingOption = VIEW_MODES[viewMode];
    return (React.createElement(UncontrolledDropdown, null,
        React.createElement(DropdownToggleIcon, { icon: selectingOption.icon, size: "2x", caret: true, title: "Change view", placement: "top" }),
        React.createElement(DropdownMenu, { right: true }, Object.values(VIEW_MODES).map(function (opt) { return (React.createElement(DropdownItem, { key: opt.value, icon: opt.icon, label: opt.label, value: opt.value, onChange: setViewMode })); }))));
};
export var DisplayOptionComponent = function () {
    return (React.createElement(DisplayOption, null,
        React.createElement(MapViewOption, null),
        React.createElement(ChartViewOption, null),
        React.createElement(StyledComponentWithScreen, null,
            React.createElement(ViewModeComponent, null))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
