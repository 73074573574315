var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { exportFilterSettingPayload, useFilterSetting, useViewValue } from '../../state';
import { useMapRef, useViewport } from '../mapprovider';
import { useDebounce, useDebounceValue } from '../../hooks';
import { ClinicalTrialOptionEnum, RightPanelTypeEnum } from '../../enums';
import { LocationLayer } from '../locationlayer';
import { useClinicalTrialGovApi } from '../../selectors';
var VIEW_PORT_DEBOUNCE_TIME = 500;
var StyledBriefTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"], ["\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"])));
var StyledFacilityName = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 550 !important;\n"], ["\n  font-weight: 550 !important;\n"])));
var MenuItem = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledBriefTitle, { key: props.location.nctId, id: props.location.nctId }, props.location.briefTitle || ''),
        React.createElement(UncontrolledTooltip, { placement: "top", target: props.location.nctId }, props.location.briefTitle),
        React.createElement(StyledFacilityName, null, props.location.facilityName || '')));
};
export var ClinicalTrialLayer = React.memo(function () {
    var _a, _b, _c;
    var filterSetting = useFilterSetting();
    var _d = useClinicalTrialGovApi(), clinicalTrialLocatorData = _d[0], fetchClinicalTrialLocatorData = _d[1], clearData = _d[2];
    var clinicalTrialOption = useViewValue('clinicalTrial', 'clinicalTrialOption')[0];
    var filterRecruitmentStatuses = useViewValue('clinicalTrialStatus', 'filterRecruitmentStatuses')[0];
    var viewport = useViewport()[0];
    var mapRef = useMapRef();
    var map = (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap();
    var show = !!clinicalTrialOption;
    var lngLatBounds = useDebounceValue(function () { return map === null || map === void 0 ? void 0 : map.getBounds().toArray(); }, [viewport.zoom, viewport.longitude, viewport.latitude], VIEW_PORT_DEBOUNCE_TIME);
    var selectedRegion = useViewValue('selectedRegion')[0];
    var loadedUserActionsHistoryRef = useRef(false);
    var fetchDataWrapped = function () {
        var payload = exportFilterSettingPayload(filterSetting);
        var userActionsHistory = !loadedUserActionsHistoryRef.current && (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.ClinicalTrialDetail
            ? {
                clinicalTrial: selectedRegion.id,
            }
            : undefined;
        fetchClinicalTrialLocatorData(__assign(__assign({}, payload), { primaryCoMorbidities: clinicalTrialOption === ClinicalTrialOptionEnum.AllTrials ? undefined : filterSetting.primaryCoMorbidities, currentDate: {
                date: moment().format('D'),
                month: moment().format('M'),
                year: moment().format('YYYY'),
            }, bottomLeftAndTopRightLngLatBound: lngLatBounds, userActionsHistory: userActionsHistory,
            filterRecruitmentStatuses: filterRecruitmentStatuses }));
        loadedUserActionsHistoryRef.current = true;
    };
    useDebounce(function () {
        if (filterSetting.forceUpdate && show) {
            fetchDataWrapped();
        }
        return clearData;
    }, [filterSetting.forceUpdate, show, lngLatBounds, clinicalTrialOption, filterRecruitmentStatuses]);
    return (React.createElement(LocationLayer, { id: RightPanelTypeEnum.ClinicalTrialDetail, locations: ((_b = clinicalTrialLocatorData.data) === null || _b === void 0 ? void 0 : _b.clinicalTrials) || [], show: show, color: "#4472C5", icon: "notes-medical", requesting: clinicalTrialLocatorData.requesting, error: clinicalTrialLocatorData.error, retry: fetchDataWrapped, totalCount: (_c = clinicalTrialLocatorData.data) === null || _c === void 0 ? void 0 : _c.totalCount, compareFn: function (a, b) { var _a, _b; return ((_a = a.briefTitle) === null || _a === void 0 ? void 0 : _a.localeCompare(b.briefTitle || '')) || ((_b = a.facilityName) === null || _b === void 0 ? void 0 : _b.localeCompare(b.facilityName || '')) || 0; }, menuItemComponent: MenuItem, name: "clinical trial sites", idFields: ['nctId', 'facilityName', 'zipcode'] }));
});
var templateObject_1, templateObject_2;
