var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import { Select, FallbackComponent } from '../common';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { ConditionType, LookupYearComponent, useMixedYear, useSynchronizedPrimaryCondition } from '../lookupdata';
import { AggregatedDataSourceComponent, useAggregatedDataSourceName } from './aggregateddatasource';
import { DATA_SOURCES } from '../configs';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
export var DataYearTab = function (props) {
    var indexConfig = useHostnameIndexInfo();
    var agrregatedData = useAggregatedDataSourceName()[0];
    var _a = useFilterEditingValue('statistic', 'cmsDataSource'), cmsDataSource = _a[0], setCmsDataSource = _a[1];
    var _b = useFilterEditingValue('dataYear'), year = _b[0], setStatisticYear = _b[1];
    var _c = useFilterEditingValue('year'), setAggregatedYear = _c[1];
    var _d = useFilterEditingValue('isAggregatedDataSource'), isAggregatedDataSource = _d[0], setIsAggregatedDataSource = _d[1];
    var _e = useFilterEditingValue('dataSourceName'), aggregatedDataSourceName = _e[0], setAggregatedDataSourceName = _e[1];
    var _f = useMixedYear(useFilterEditing()), yearData = _f[0], fetchYearData = _f[1];
    var _g = useFilterEditingValue('primaryCoMorbidities'), selectingPrimaryConditions = _g[0], setSelectingPrimaryConditions = _g[1];
    var doSynchronized = useSynchronizedPrimaryCondition([ConditionType.Other, ConditionType.Primary, ConditionType.Secondary])[0];
    useEffect(function () {
        var _a;
        if (((_a = yearData.data) === null || _a === void 0 ? void 0 : _a.length) && !yearData.data.some(function (v) { return v === year; })) {
            handleChangeYear(yearData.data[yearData.data.length - 1]);
        }
        else {
            setAggregatedYear(year);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearData.data]);
    useEffect(function () {
        doSynchronized();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);
    var handleChangeDataSource = function (value, name, option) {
        setIsAggregatedDataSource(!!option.isAggregatedDataSource);
        if (option.isAggregatedDataSource) {
            setAggregatedDataSourceName(value);
        }
        else {
            setCmsDataSource(value);
        }
    };
    var handleChangeYear = function (value) {
        var _a;
        setStatisticYear(value);
        setAggregatedYear(value);
        if ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) {
            setSelectingPrimaryConditions(selectingPrimaryConditions === null || selectingPrimaryConditions === void 0 ? void 0 : selectingPrimaryConditions.filter(function (cc) { var _a, _b; return !((_b = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) === null || _b === void 0 ? void 0 : _b.includes(cc)); }));
        }
    };
    var allDataSourceOptions = useMemo(function () {
        var _a, _b;
        return __spreadArrays((((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.dataSources) === null || _a === void 0 ? void 0 : _a.map(function (v) { return DATA_SOURCES[v]; })) || []), (((_b = agrregatedData.data) === null || _b === void 0 ? void 0 : _b.map(function (value) { return ({ value: value, label: value, isAggregatedDataSource: true }); })) || []));
    }, [agrregatedData.data, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.dataSources]);
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Source"),
            React.createElement(Select, { name: "dataSource", options: allDataSourceOptions, noSort: true, value: isAggregatedDataSource ? aggregatedDataSourceName : cmsDataSource, onChange: handleChangeDataSource, isLoading: agrregatedData.requesting })),
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Year"),
            React.createElement(FallbackComponent, { spinnerSize: 50, requesting: yearData.requesting, error: yearData.error, retry: fetchYearData },
                React.createElement(Select, { name: "dataYear", options: yearData.data, noSort: true, value: year, onChange: handleChangeYear, optionsTransform: function (year) { return ({ value: year, label: "" + year }); }, maxMenuHeight: 200, menuPosition: "fixed" }))),
        React.createElement(LookupYearComponent, { dataSource: cmsDataSource }),
        React.createElement(AggregatedDataSourceComponent, null)));
};
DataYearTab.defaultProps = {
    tabId: 'DataYearTab',
    tabName: 'Data Set',
};
