/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { Map } from 'immutable';
import { setUIValueSessionStorage } from '../../storeduivalue';
export var RESET_FILTERS = 'RESET_FILTERS';
export var SET_FILTER_VALUE = 'SET_FILTER_VALUE';
// Init state
var initialState = Map();
// Init action handlers
var actionHandlers = {};
actionHandlers[RESET_FILTERS] = function (s, a) { return Map(a.payload).set('forceUpdate', (s.get('forceUpdate') || 0) + 1); };
actionHandlers[SET_FILTER_VALUE] = function (s, a) { return (a.payload.value ? s.setIn(a.payload.keyPath, a.payload.value) : s.removeIn(a.payload.keyPath)); };
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var fn = actionHandlers[action.type];
    if (!fn)
        return state;
    var updatedState = fn(state, action);
    setUIValueSessionStorage('filterSetting', updatedState.toObject(), undefined, [
        'indexPrimaryCoMorbidities',
        'singleStateCode',
        'singleStateAbb',
        'available',
        'forceUpdate',
        'textSearch',
        'geoLevel',
    ]);
    return updatedState;
}
