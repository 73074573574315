/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ENV_MIN_PATIENT_BUSINESS_RULE } from '../env';
import { setIn } from 'formik';
import { GeoLevelEnum } from '../enums';
export var applyMinBusinessRule = function (number) { return (number && number >= ENV_MIN_PATIENT_BUSINESS_RULE ? number : undefined); };
export var getDisplayText = function (src, suffix, prefix) {
    if (suffix === void 0) { suffix = ''; }
    if (prefix === void 0) { prefix = ''; }
    if (typeof src === 'string')
        return src;
    if (src === undefined || src === null)
        return 'N/A';
    var parts = src.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return "" + prefix + parts.join('.') + suffix;
};
export var getUserInitials = function (firstName, lastName) {
    if (firstName === void 0) { firstName = ''; }
    if (lastName === void 0) { lastName = ''; }
    return "" + firstName.trim().charAt(0).toUpperCase() + lastName.trim().charAt(0).toUpperCase();
};
export var getDisplayUserName = function (firstName, lastName, middleName) {
    var arr = [];
    if (firstName) {
        arr.push(firstName);
    }
    if (middleName) {
        arr.push(middleName);
    }
    if (lastName) {
        arr.push(lastName);
    }
    return arr.join(' ');
};
export var prependingHttpToUrlIfMissing = function (url) {
    if (!url || url.match(/^[a-zA-Z]+:\/\//)) {
        return url;
    }
    return "http://" + url;
};
export var isObject = function (value) {
    var type = typeof value;
    return value != null && (type === 'object' || type === 'function');
};
export var isEmpty = function (value) {
    if (isObject(value)) {
        if (Object.keys(value).length === 0) {
            return true;
        }
        else {
            return false;
        }
    }
    return value === undefined || value === null || value === '';
};
export function cleanFormattingPhoneNumber(phoneFormated) {
    return phoneFormated.replace(/\D/g, '');
}
export function formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString) {
        return phoneNumberString;
    }
    var cleaned = cleanFormattingPhoneNumber(phoneNumberString);
    var match = cleaned.match(/^(\d{3}|\d+)(\d{3}|\d+)?(\d{4}|\d+)?$/);
    var arr = [];
    if (match) {
        var separation = '';
        for (var i = 1; i < match.length; i += 1) {
            if (match[i]) {
                arr.push(separation);
                arr.push(match[i]);
                separation = '-';
            }
        }
    }
    return arr.join('');
}
export function roundNumber(value, precision) {
    if (precision === void 0) { precision = 0; }
    if (value === undefined || value === null) {
        return undefined;
    }
    return parseFloat((value + Number.EPSILON).toFixed(precision));
}
export function safeDivide(numerator, denominator, percentage, precision) {
    if (percentage === void 0) { percentage = false; }
    if (precision === void 0) { precision = 0; }
    if (numerator !== undefined && numerator !== null && denominator) {
        return roundNumber((numerator / denominator) * (percentage ? 100 : 1), precision);
    }
    return undefined;
}
export var cookLegendBreaks = function (originPoints, percentage, precision) {
    if (originPoints === void 0) { originPoints = []; }
    if (percentage === void 0) { percentage = false; }
    if (precision === void 0) { precision = 0; }
    var gradeDelta = 1 / Math.pow(10, precision);
    var prevGrade = -1;
    return originPoints.map(function (grade, idx) {
        var currGrade = idx === 0 || idx % 2 === 1 ? grade * (percentage ? 100 : 1) : prevGrade + gradeDelta;
        currGrade = roundNumber(currGrade, precision);
        if (currGrade < prevGrade) {
            currGrade = prevGrade;
        }
        prevGrade = currGrade;
        return currGrade;
    });
};
export var getBreakIdx = function (breakPoints, value) {
    return !value || !breakPoints ? 0 : (__spreadArrays(breakPoints, [Number.MAX_SAFE_INTEGER]).findIndex(function (grade, idx) { return idx % 2 === 1 && value <= grade; }) + 1) / 2;
};
export var compareFunc = function (a, b, order) {
    if (a === void 0) { a = ''; }
    if (b === void 0) { b = ''; }
    if (order === void 0) { order = 'asc'; }
    return (a < b ? -1 : a > b ? 1 : 0) * (order === 'desc' ? -1 : 1);
};
export var separateGeoLevelId = function (id) {
    var components = (id === null || id === void 0 ? void 0 : id.split('-')) || [];
    return [components.shift(), components.join('-')];
};
export function getValidationSchema(validationSchema) {
    if (typeof validationSchema === 'function') {
        return validationSchema();
    }
    return validationSchema;
}
export function getErrors(validationSchema, values) {
    var parseErrors = function (validationError) {
        return validationError.inner.reduce(function (errors, error) { return setIn(errors, error.path, error.errors[0]); }, Object.assign({}));
    };
    try {
        validationSchema && getValidationSchema(validationSchema).validateSync(values, { abortEarly: false });
        return Object.assign({});
    }
    catch (error) {
        return parseErrors(error);
    }
}
export function getFlattenErrors(validationSchema, values) {
    var parseErrors = function (validationError) { return validationError.errors.filter(function (error, index) { return validationError.errors.indexOf(error) === index; }); };
    try {
        validationSchema && getValidationSchema(validationSchema).validateSync(values, { abortEarly: false });
        return Object.assign([]);
    }
    catch (error) {
        return parseErrors(error);
    }
}
export var getDirectionUrl = function (url) {
    var title = new URL(window.document.location.toString()).searchParams.get('title');
    return "" + url + (title !== null ? "?title=" + title : '');
};
export var isFeatureInAState = function (state, properties, geoLevel, stateByZipCode5) {
    var _a, _b;
    if (stateByZipCode5 === void 0) { stateByZipCode5 = {}; }
    if (geoLevel === GeoLevelEnum.Zip5) {
        return state.stateCode === parseInt(stateByZipCode5[properties === null || properties === void 0 ? void 0 : properties.zipCode], 10);
    }
    else if (geoLevel === GeoLevelEnum.MSA) {
        return (_b = (_a = properties === null || properties === void 0 ? void 0 : properties.name) === null || _a === void 0 ? void 0 : _a.split(', ')[1]) === null || _b === void 0 ? void 0 : _b.split('-').some(function (i) { return i === state.stateAbb; });
    }
    else {
        return state.stateCode === parseInt(properties === null || properties === void 0 ? void 0 : properties.stateCode, 10);
    }
};
export var groupBy = function (items, key) { return items === null || items === void 0 ? void 0 : items.reduce(function (result, item) {
    var _a;
    var k = typeof key === 'string' ? item[key] : key(item);
    return __assign(__assign({}, result), (_a = {}, _a[k] = __spreadArrays((result[k] || []), [item]), _a));
}, Object.assign({})); };
export var keyBy = function (array, key) { return (array || []).reduce(function (r, x) {
    var _a;
    return (__assign(__assign({}, r), (_a = {}, _a[key ? x[key] : x] = x, _a)));
}, {}); };
export var substituteParams = function (str, params) {
    return params !== undefined ? Object.keys(params).reduce(function (current, key) { return current.replace("{" + key + "}", "" + params[key]); }, str) : str;
};
export var isTouchEnabled = function () {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};
export var deviceType = function () {
    var ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'mobile';
    }
    return 'desktop';
};
export var getYearsBySeason = function (seasonYear) {
    return String(seasonYear).match(/\d+/g) || [];
};
