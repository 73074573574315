/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var _a, _b;
import { ViewModeEnum, GeoLevelEnum, ChartViewEnum, DemographicTypeEnum } from '../enums';
import { deviceType } from '../utils';
export var VIEW_MODES = (_a = {},
    _a[ViewModeEnum.Map] = {
        value: ViewModeEnum.Map,
        label: 'View Map',
        icon: 'map',
    },
    _a[ViewModeEnum.Chart] = {
        value: ViewModeEnum.Chart,
        label: 'View Chart',
        icon: 'chart-pie',
    },
    _a[ViewModeEnum.Table] = {
        value: ViewModeEnum.Table,
        label: 'View Table',
        icon: 'table',
    },
    _a);
export var DEMOGRAPHIC_CHART_OPTIONS = [
    {
        value: DemographicTypeEnum.Sex,
        label: 'Sex',
    },
    {
        value: DemographicTypeEnum.Age,
        label: 'Age',
    },
    {
        value: DemographicTypeEnum.Race,
        label: 'Race/Ethnicity',
    },
];
export var ALL_DEMOGRAPHIC_CHART_OPTIONS = DEMOGRAPHIC_CHART_OPTIONS.map(function (_a) {
    var value = _a.value;
    return value;
});
export var CHART_VIEW_OPTIONS = [
    { value: ChartViewEnum.Demographic, label: 'Demographics', children: DEMOGRAPHIC_CHART_OPTIONS },
    { value: ChartViewEnum.Trends, label: 'Trends' },
];
export var DEFAULT_VIEW_SETTING = {
    viewMode: ViewModeEnum.Map,
    geoType: GeoLevelEnum.Zip5,
    mapView: {
        showLegend: deviceType() !== 'mobile',
        showGeographyHoverDisplay: true,
    },
    chartView: (_b = {
            type: ChartViewEnum.Demographic,
            demographics: ALL_DEMOGRAPHIC_CHART_OPTIONS
        },
        _b[DemographicTypeEnum.Sex] = 'pie',
        _b[DemographicTypeEnum.Race] = 'pie',
        _b[DemographicTypeEnum.Age] = 'pie',
        _b),
    importingLocationTypes: {},
    mapLayerCountNotifications: {},
    provider: {},
    checkedQuestDiagnosticsStatus: false,
};
