/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useCallback, useMemo } from 'react';
import { useAPI, useFilterSetting, exportFilterSettingPayload } from '../state';
import { DemographicTypeEnum, ViewModeEnum, ChartViewEnum, GeoLevelEnum } from '../enums';
import { useLookupSexCode, useLookupRaceCode, mapAgeValues, stringifyAgeValue, AGE_OPTIONS, ALL_AGE_VALUES, getStatisticOption, processStatisticValue, } from '../lookupdata';
import { extractHostname, useHostnameIndexInfo } from '../hostnameindexinfo';
var FETCH_AGGREGATED_DATA_API_URL = '/api/data-file/get-aggregated-data-for-demographic-piechart';
var FETCH_CHART_DATA_API_URL = '/api/stats/{hostname}/calculate-statistic-for-demographic-piechart';
var FETCH_CHART_DATA_API_URL_PROXY = '/api/{hostname}/calculate-statistic-for-demographic-piechart';
var useStatisticData = function () {
    var _a;
    var _b, _c, _d, _e;
    var indexConfig = useHostnameIndexInfo();
    var _f = useAPI((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.calculateStatisticAttachHostname) ? FETCH_CHART_DATA_API_URL_PROXY
        : FETCH_CHART_DATA_API_URL, {
        hostname: extractHostname()
    }, 'POST'), chartData = _f[0], res = _f.slice(1);
    var filterSettings = useFilterSetting();
    var statistic = (_b = filterSettings.statistic) === null || _b === void 0 ? void 0 : _b.statisticOption;
    var statisticOption = getStatisticOption(statistic);
    var _g = useLookupSexCode((_c = filterSettings.demographics) === null || _c === void 0 ? void 0 : _c.sexCodes), lookupSexData = _g[0], resLookupSex = _g.slice(1);
    var _h = useLookupRaceCode(filterSettings.raceMethodology, (_d = filterSettings.demographics) === null || _d === void 0 ? void 0 : _d.raceCodes), lookupRaceData = _h[0], resLookupRace = _h.slice(1);
    var dataByDemographic = (_a = {},
        _a[DemographicTypeEnum.Sex] = __spreadArrays([__assign(__assign({}, lookupSexData), { data: lookupSexData.mappedValues || lookupSexData.data })], resLookupSex),
        _a[DemographicTypeEnum.Race] = __spreadArrays([__assign(__assign({}, lookupRaceData), { data: lookupRaceData.mappedValues || lookupRaceData.data })], resLookupRace),
        _a[DemographicTypeEnum.Age] = [{ data: mapAgeValues((_e = filterSettings.demographics) === null || _e === void 0 ? void 0 : _e.ages) || AGE_OPTIONS }, function () { }, function () { }],
        _a);
    var data = useMemo(function () {
        var combinedAgeKeyData = chartData.data && __assign(__assign({}, chartData.data.mapStatisticResponsingData), { age: chartData.data.mapStatisticResponsingData.age.subGroups.reduce(function (acc, curr) {
                acc[stringifyAgeValue(curr)] = curr.patientCount || curr.aggregateValue;
                return acc;
            }, Object.assign({})) });
        return [DemographicTypeEnum.Sex, DemographicTypeEnum.Race, DemographicTypeEnum.Age].reduce(function (acc, demographic) {
            var _a;
            var _b = dataByDemographic[demographic], data = _b[0], res = _b.slice(1);
            acc[demographic] = __spreadArrays([
                __assign(__assign({}, data), { data: (_a = data.data) === null || _a === void 0 ? void 0 : _a.map(function (i) { return ({
                        id: i.stringified || i.value,
                        name: i.shortName || i.label,
                        value: processStatisticValue((combinedAgeKeyData === null || combinedAgeKeyData === void 0 ? void 0 : combinedAgeKeyData[demographic][i.stringified || i.value]) || 0, false, statistic),
                        prefix: statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit,
                    }); }).filter(function (i) { return !!i.value; }) })
            ], res);
            return acc;
        }, Object.assign({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData.data, lookupSexData.data, lookupRaceData.data]);
    return __spreadArrays([
        __assign(__assign({}, chartData), { data: data })
    ], res);
};
var useAggregatedData = function () {
    var _a;
    var _b = useAPI(FETCH_AGGREGATED_DATA_API_URL, undefined, 'POST'), chartData = _b[0], res = _b.slice(1);
    var filterSettings = useFilterSetting();
    var statistic = (_a = filterSettings.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var statisticOption = getStatisticOption(statistic);
    var data = useMemo(function () {
        var _a;
        var _b;
        return (_b = chartData.data) === null || _b === void 0 ? void 0 : _b.mapStatisticResponsingData.aggregatedDataCategoryList.reduce(function (acc, curr) {
            var _a, _b;
            if (curr.dataValue >= 0 && acc[curr.dataSourceColumnDefinition.category.toLowerCase()]) {
                acc[curr.dataSourceColumnDefinition.category.toLowerCase()][0].data.push({
                    name: curr.dataSourceColumnDefinition.uiName,
                    value: processStatisticValue(curr.dataValue, (_a = chartData.data) === null || _a === void 0 ? void 0 : _a.mapStatisticResponsingData.percentage, statistic),
                    key: curr.dataSourceColumnDefinition.id,
                    prefix: statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit,
                    suffix: ((_b = chartData.data) === null || _b === void 0 ? void 0 : _b.mapStatisticResponsingData.percentage) ? '%' : '',
                });
            }
            return acc;
        }, (_a = {}, _a[DemographicTypeEnum.Sex] = [{ data: [] }], _a[DemographicTypeEnum.Race] = [{ data: [] }], _a[DemographicTypeEnum.Age] = [{ data: [] }], _a));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData.data]);
    return __spreadArrays([
        __assign(__assign({}, chartData), { data: data })
    ], res);
};
export var useChartData = function () {
    var statisticChartData = useStatisticData();
    var aggregatedChartData = useAggregatedData();
    var filterSettings = useFilterSetting();
    var _a = filterSettings.isAggregatedDataSource ? aggregatedChartData : statisticChartData, data = _a[0], fetchData = _a[1], res = _a.slice(2);
    var fetchDataWrapper = useCallback(function () {
        var _a;
        if (filterSettings.forceUpdate) {
            var ageRanges = (_a = filterSettings.demographics) === null || _a === void 0 ? void 0 : _a.ages;
            var payload = exportFilterSettingPayload(filterSettings);
            fetchData(__assign(__assign({}, payload), { displayOption: {
                    displayOptionType: ViewModeEnum.Chart,
                    mapView: { type: GeoLevelEnum.Zip5 },
                    chartView: {
                        type: ChartViewEnum.Demographic,
                        ageRanges: (ageRanges === null || ageRanges === void 0 ? void 0 : ageRanges.length) ? ageRanges : ALL_AGE_VALUES,
                    },
                } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSettings.forceUpdate]);
    return __spreadArrays([data, fetchDataWrapper], res);
};
