var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { Marker } from 'react-map-gl';
export var PIN_SIZE = 35;
export var MemoMarker = React.memo(function (props) {
    return React.createElement(Marker, __assign({ offsetLeft: -PIN_SIZE / 2, offsetTop: -PIN_SIZE }, props));
});
