var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { Spinner as RSSpinner } from 'reactstrap';
var DEFAULT_SPINNER_RADIUS = 120;
var StyledSpinner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n"])));
var StyledRSSpinner = styled(RSSpinner)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #0099db;\n  border-width: 0.125rem;\n  ", "\n"], ["\n  color: #0099db;\n  border-width: 0.125rem;\n  ", "\n"])), function (_a) {
    var _b = _a.radius, radius = _b === void 0 ? DEFAULT_SPINNER_RADIUS : _b;
    return "width: " + radius / 16 + "rem; height: " + radius / 16 + "rem;";
});
export var Spinner = function (props) {
    if (!props.show)
        return null;
    return (React.createElement(StyledSpinner, null,
        React.createElement(StyledRSSpinner, { radius: props.radius })));
};
var templateObject_1, templateObject_2;
