var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import { FallbackComponent, CheckboxGroup, Select } from '../common';
import { useLookupRaceCode, LookupRaceCodeComponent } from '../lookupdata';
import { RaceMethodologyEnum, DataSourceEnum } from '../enums';
import { useReportsDialogValue } from './reportsdialogprovider';
var METHODOLOGY_OPTIONS = [
    {
        value: RaceMethodologyEnum.Standard,
        label: 'Standard',
    },
    {
        value: RaceMethodologyEnum.RTI,
        label: 'RTI',
    },
];
var StyledMethodologySelect = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 15rem;\n"], ["\n  width: 15rem;\n"])));
export var RaceReportsDialogTab = function (props) {
    var dataSourceType = useReportsDialogValue('dataSourceType')[0];
    var _a = useReportsDialogValue('raceMethodology'), methodology = _a[0], setMethodology = _a[1];
    var _b = useReportsDialogValue('raceCodes'), raceCodes = _b[0], setRaceCodes = _b[1];
    var _c = useLookupRaceCode(methodology), lookupRaceCodeData = _c[0], fetchLookupRaceCode = _c[1];
    useEffect(function () {
        if (dataSourceType === DataSourceEnum.Medicaid) {
            setMethodology(RaceMethodologyEnum.Standard);
        }
    }, [dataSourceType, setMethodology]);
    useEffect(function () {
        if (lookupRaceCodeData.data && !(raceCodes === null || raceCodes === void 0 ? void 0 : raceCodes.length)) {
            setRaceCodes(lookupRaceCodeData.data.map(function (i) { return i.value; }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupRaceCodeData.data]);
    return (React.createElement(TabPane, { tabId: props.tabId },
        dataSourceType !== DataSourceEnum.Medicaid && (React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(Label, { className: "font-weight-bold" }, "Methodology"),
            React.createElement(StyledMethodologySelect, { name: "methodology", options: METHODOLOGY_OPTIONS, noSort: true, value: methodology, onChange: setMethodology }))),
        React.createElement(Label, { className: "font-weight-bold" }, "Race"),
        React.createElement(FallbackComponent, { requesting: lookupRaceCodeData.requesting, error: lookupRaceCodeData.error, retry: fetchLookupRaceCode },
            React.createElement(CheckboxGroup, { options: lookupRaceCodeData.data, noSort: true, value: raceCodes, onChange: setRaceCodes })),
        React.createElement(LookupRaceCodeComponent, null)));
};
RaceReportsDialogTab.defaultProps = {
    tabId: 'RaceTab',
    tabName: 'Race/Ethnicity',
    tabField: ['raceCodes'],
};
var templateObject_1;
