var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import { DropdownItem, DropdownItemCheck, DropdownToggleIcon } from '../common';
import { useAPI, useFilterSetting, useViewValue } from '../state';
import { ClinicalTrialOptionEnum } from '../enums';
var GET_AVAIABLE_MAP_LAYER_LIST_API = '/api/map-layer/get-all-that-belong-to-the-logged-user-index';
var StyledSubMenuItem = styled(UncontrolledDropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"])));
export var useMapLayerListAPI = function () {
    return useAPI(GET_AVAIABLE_MAP_LAYER_LIST_API);
};
var ImportingLocationSubType = function (props) {
    var _a = useViewValue('showMapLayerMenu'), setShowMapLayerMenu = _a[1];
    var _b = useViewValue('importingLocationTypes', "" + props.id), selectingType = _b[0], setSelectingType = _b[1];
    var checked = selectingType === props.value;
    var handleClick = function () {
        setSelectingType(checked ? undefined : props.value);
        setShowMapLayerMenu();
    };
    return React.createElement(DropdownItemCheck, { checked: checked, onChange: handleClick, value: props.value, label: props.label });
};
var ImportingLocationItem = function (props) {
    var _a = useViewValue('importingLocationTypes', "" + props.payload.id), selectingType = _a[0], setSelectingType = _a[1];
    var checked = !!selectingType;
    var handleClick = function () {
        setSelectingType(checked ? undefined : ClinicalTrialOptionEnum.TrialsRelated);
    };
    return (React.createElement(DropdownItemCheck, { disabled: props.disabled, tag: StyledSubMenuItem, checked: checked, onChange: handleClick, value: props.payload.id, label: props.payload.name, icon: props.payload.displayIcon, direction: "right" },
        React.createElement(DropdownToggleIcon, { disabled: props.disabled, onClick: function (event) { return event.stopPropagation(); }, caret: true, color: "warning", className: "py-0 ml-auto" }),
        React.createElement(DropdownMenu, { onClick: function (event) { return event.stopPropagation(); } },
            React.createElement(ImportingLocationSubType, { id: props.payload.id, value: ClinicalTrialOptionEnum.TrialsRelated, label: "Show trials related to currently selected conditions" }),
            React.createElement(ImportingLocationSubType, { id: props.payload.id, value: ClinicalTrialOptionEnum.AllTrials, label: "Show all trials" }))));
};
var ImportingLocationMenu = function () {
    var _a;
    var mapLayerData = useMapLayerListAPI()[0];
    var filterSetting = useFilterSetting();
    var disabled = !filterSetting.available;
    return (React.createElement(React.Fragment, null, (_a = mapLayerData.data) === null || _a === void 0 ? void 0 : _a.map(function (ml) { return (React.createElement(ImportingLocationItem, { disabled: disabled, key: ml.id, payload: ml })); })));
};
export var LocationMenu = function (props) {
    var _a;
    var mapLayerData = useMapLayerListAPI()[0];
    if (!props.children && !((_a = mapLayerData.data) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Locations"),
        props.children,
        React.createElement(ImportingLocationMenu, null)));
};
var templateObject_1;
