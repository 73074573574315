var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { DropdownItem, DropdownItemCheck, DropdownItemCheckList, DropdownToggleIcon, Input, ThreeDotLoading } from '../common';
import { useMount, useToggle } from '../hooks';
import { useCachedData } from '../state';
import { useProviderSetting } from '../selectors';
var GET_SPECIALISTS_API_URL = '/api/provider/get-specialists';
var StyledSpecialists = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 15.75rem;\n  overflow: auto;\n"], ["\n  max-height: 15.75rem;\n  overflow: auto;\n"])));
var ProviderSelectItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var StyledSubMenuItem = styled(UncontrolledDropdown)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"])));
export var ProviderMenu = function () {
    var _a, _b;
    var _c = useCachedData(GET_SPECIALISTS_API_URL), specialistsData = _c[0], fetchSpecialistsData = _c[1];
    var _d = useToggle(), doGetPrimaryCareProvider = _d[0], toggleDoGetPrimaryCareProvider = _d[1], setDoGetPrimaryCareProvider = _d[2];
    var _e = useToggle(), doGetHospitalProvider = _e[0], toggleDoGetHospitalProvider = _e[1], setDoGetHospitalProvider = _e[2];
    var _f = useState(), specialistProviders = _f[0], setSpecialistProviders = _f[1];
    var _g = useProviderSetting(), providerSetting = _g[0], setProviderSetting = _g[1];
    var disabled = providerSetting.disabled;
    var disabledSpecialistProvider = disabled || !specialistsData.data;
    var _h = useState(''), specialistsSearchText = _h[0], setSpecialistsSearchText = _h[1];
    var providerSettingRef = useRef();
    useMount(function () {
        fetchSpecialistsData();
    });
    useEffect(function () {
        providerSettingRef.current = {
            doGetPrimaryCareProvider: doGetPrimaryCareProvider,
            doGetHospitalProvider: doGetHospitalProvider,
            specialistProviders: specialistProviders,
        };
    }, [doGetPrimaryCareProvider, doGetHospitalProvider, specialistProviders]);
    useMount(function () { return function () {
        setProviderSetting(__assign(__assign({}, providerSetting), providerSettingRef.current));
    }; });
    useEffect(function () {
        setDoGetPrimaryCareProvider(!!(providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetPrimaryCareProvider));
        setDoGetHospitalProvider(!!(providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetHospitalProvider));
        setSpecialistProviders(providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.specialistProviders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetPrimaryCareProvider, providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetHospitalProvider, providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.specialistProviders]);
    var handleClickSpecialists = function () {
        var _a;
        setSpecialistProviders((specialistProviders === null || specialistProviders === void 0 ? void 0 : specialistProviders.length) ? [] : (_a = specialistsData.data) === null || _a === void 0 ? void 0 : _a.map(function (i) { return i.code; }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItem, { header: true }, "Providers"),
        React.createElement(DropdownItemCheck, { tag: ProviderSelectItem, toggle: false, icon: "clinic-medical", label: "Primary Care", checked: doGetPrimaryCareProvider, onChange: toggleDoGetPrimaryCareProvider, disabled: disabled }),
        React.createElement(DropdownItemCheck, { disabled: disabledSpecialistProvider, toggle: false, icon: "stethoscope", tag: StyledSubMenuItem, direction: "right", checked: !!(specialistProviders === null || specialistProviders === void 0 ? void 0 : specialistProviders.length), onChange: handleClickSpecialists, partial: (specialistProviders === null || specialistProviders === void 0 ? void 0 : specialistProviders.length) !== ((_a = specialistsData.data) === null || _a === void 0 ? void 0 : _a.length) },
            React.createElement("span", { className: "flex-grow-1" },
                "Specialists ",
                React.createElement(ThreeDotLoading, { loading: specialistsData.requesting })),
            React.createElement(DropdownToggleIcon, { disabled: disabledSpecialistProvider, onClick: function (event) { return event.stopPropagation(); }, caret: true, color: "warning", className: "py-0" }),
            React.createElement(DropdownMenu, { onClick: function (event) { return event.stopPropagation(); } },
                React.createElement("div", { className: "py-2 pr-4 pl-5" },
                    React.createElement(Input, { name: "search", placeholder: "Search....", value: specialistsSearchText, onChange: function (event) {
                            setSpecialistsSearchText(event.target.value);
                            event.stopPropagation();
                        } })),
                React.createElement(StyledSpecialists, null, (_b = specialistsData.data) === null || _b === void 0 ? void 0 : _b.sort(function (a, b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); }).filter(function (i) { return !specialistsSearchText || i.name.toLowerCase().includes(specialistsSearchText.toLowerCase()); }).map(function (item) { return (React.createElement(DropdownItemCheckList, { values: specialistProviders, onChange: setSpecialistProviders, value: item.code, key: item.code, tag: ProviderSelectItem, toggle: false, label: item.name })); })))),
        React.createElement(DropdownItemCheck, { disabled: disabled, tag: ProviderSelectItem, toggle: false, icon: "hospital-symbol", label: "Hospitals", checked: doGetHospitalProvider, onChange: toggleDoGetHospitalProvider })));
};
var templateObject_1, templateObject_2, templateObject_3;
