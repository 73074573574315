var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { PieComponent } from './pie';
import { BarComponent } from './bar';
import { useChartData } from './usechartdata';
import { ViewModeEnum, ChartViewEnum, StatisticTypeEnum } from '../enums';
import { FallbackComponent } from '../common';
import { useViewValue, useFilterValue } from '../state';
import { DEMOGRAPHIC_CHART_OPTIONS } from '../configs';
var CHART_COMPONENT_BY_TYPE = {
    pie: PieComponent,
    bar: BarComponent,
};
var StyledDemographicChart = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem;\n  width: 100%;\n"], ["\n  padding: 1rem;\n  width: 100%;\n"])));
var Title = styled.h4(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Body = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var StyledChartContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-basis: 33%;\n  padding: 0.25rem;\n"], ["\n  flex-basis: 33%;\n  padding: 0.25rem;\n"])));
var ChartContainerTitle = styled.h5(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var StyledChartComponent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  min-width: 18rem;\n  height: 18rem;\n"], ["\n  position: relative;\n  min-width: 18rem;\n  height: 18rem;\n"])));
var ChartControl = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-bottom: 0.5rem;\n  display: flex;\n  justify-content: center;\n"], ["\n  padding-bottom: 0.5rem;\n  display: flex;\n  justify-content: center;\n"])));
var StyledSwitchTypeButton = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 0 0.25rem;\n  cursor: pointer;\n  ", "\n  &:hover {\n    opacity: 0.7;\n  }\n"], ["\n  margin: 0 0.25rem;\n  cursor: pointer;\n  ", "\n  &:hover {\n    opacity: 0.7;\n  }\n"])), function (props) { return (!props.active ? 'color: #c1c1c1 !important;' : ''); });
var ChartTypeButton = function (props) {
    var onClick = props.onClick, type = props.type;
    var handleClick = useCallback(function () {
        onClick(type);
    }, [onClick, type]);
    return (React.createElement(StyledSwitchTypeButton, { active: props.activeType === props.type, onClick: handleClick },
        React.createElement(FontAwesomeIcon, { icon: props.icon })));
};
var ChartContainer = function (props) {
    var _a = useViewValue('chartView', props.type), chartType = _a[0], setChartType = _a[1];
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var ChartComponent = CHART_COMPONENT_BY_TYPE[chartType];
    var _b = props.data || [], data = _b[0], retry = _b[1];
    return (React.createElement(StyledChartContainer, null,
        React.createElement(ChartContainerTitle, null, props.title),
        React.createElement(ChartControl, null,
            React.createElement(ChartTypeButton, { activeType: chartType, type: "pie", icon: faChartPie, onClick: setChartType }),
            React.createElement(ChartTypeButton, { activeType: chartType, type: "bar", icon: faChartBar, onClick: setChartType })),
        React.createElement(StyledChartComponent, null,
            React.createElement(FallbackComponent, { requesting: data === null || data === void 0 ? void 0 : data.requesting, error: data === null || data === void 0 ? void 0 : data.error, retry: retry },
                React.createElement(ChartComponent, { inline: true, type: props.type, data: data === null || data === void 0 ? void 0 : data.data, percentage: statistic === StatisticTypeEnum.Prevalence })))));
};
export var DemographicChartComponent = function () {
    var t = useTranslation().t;
    var _a = useChartData(), chartData = _a[0], fetchChartData = _a[1];
    var viewMode = useViewValue('viewMode')[0];
    var chartType = useViewValue('chartView', 'type')[0];
    var demographics = useViewValue('chartView', 'demographics')[0];
    var isShow = viewMode === ViewModeEnum.Chart && chartType === ChartViewEnum.Demographic;
    useEffect(function () {
        fetchChartData();
    }, [fetchChartData]);
    if (!isShow)
        return null;
    return (React.createElement(StyledDemographicChart, null,
        React.createElement(Title, null, t('demographicchart.title')),
        React.createElement(Body, null,
            React.createElement(FallbackComponent, { requesting: chartData.requesting || chartData.requesting === undefined, error: chartData.error, retry: fetchChartData }, demographics === null || demographics === void 0 ? void 0 : demographics.map(function (type) {
                var _a, _b;
                return (React.createElement(ChartContainer, { key: type, type: type, title: ((_a = DEMOGRAPHIC_CHART_OPTIONS.find(function (option) { return option.value === type; })) === null || _a === void 0 ? void 0 : _a.label) || type, data: (_b = chartData.data) === null || _b === void 0 ? void 0 : _b[type] }));
            })))));
};
export * from './usechartdata';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
