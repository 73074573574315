var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useContext, useReducer, useLayoutEffect, useMemo } from 'react';
import * as jsonwebtoken from 'jsonwebtoken';
import { AuthContext } from 'cinchy';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginComponent } from './login';
import { ForgotPasswordComponent } from './forgotpassword';
import { SignUpComponent } from './signup';
export { LoginComponent } from './login';
var NMQF_SESSION_STORAGE_NAME = 'SESSION';
var API_URL_LOGIN = '/api/auth/login';
var API_URL_REFRESH_JWT = '/api/auth/token';
var JWTStrategy = /** @class */ (function () {
    function JWTStrategy() {
        window.addEventListener('storage', this.syncLogout);
        this.__jwt = null;
        this.subscribers = [];
    }
    Object.defineProperty(JWTStrategy.prototype, "user", {
        get: function () {
            if (this.jwt) {
                if (!this.__user) {
                    this.__user = jsonwebtoken.decode(this.jwt);
                }
                return this.__user;
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(JWTStrategy.prototype, "jwt", {
        get: function () {
            return this.__jwt;
        },
        set: function (value) {
            var _this = this;
            if (value) {
                localStorage.setItem(NMQF_SESSION_STORAGE_NAME, value);
            }
            else if (this.__jwt) {
                localStorage.removeItem(NMQF_SESSION_STORAGE_NAME);
                window.location.reload();
            }
            if (value && this.__jwt !== value) {
                this.__jwt = value;
                this.__user = undefined;
                this.subscribers.forEach(function (x) {
                    _this.subscribers.includes(x) && x();
                });
            }
        },
        enumerable: false,
        configurable: true
    });
    JWTStrategy.prototype.getError = function () {
        return this.error;
    };
    JWTStrategy.prototype.setError = function (error) {
        var _this = this;
        if (this.error !== error) {
            this.error = error;
            this.subscribers.forEach(function (x) {
                _this.subscribers.includes(x) && x();
            });
        }
    };
    JWTStrategy.prototype.getAuthChallenge = function () {
        return this.authChallenge;
    };
    JWTStrategy.prototype.setAuthChallenge = function (authChallenge) {
        var _this = this;
        if (this.authChallenge !== authChallenge) {
            this.authChallenge = authChallenge;
            this.subscribers.forEach(function (x) {
                _this.subscribers.includes(x) && x();
            });
        }
    };
    JWTStrategy.prototype.resetErrorAndChallenge = function () {
        this.setAuthChallenge();
        this.setError();
    };
    JWTStrategy.prototype.getRequestOptions = function (method, body) {
        var headers = {
            Authorization: this.jwt ? "Bearer " + this.jwt : '',
        };
        if (!(body instanceof FormData)) {
            headers['Content-Type'] = 'application/json';
            body = JSON.stringify(body);
        }
        return {
            method: method,
            body: body,
            headers: headers,
            referrer: window.location.href.split('?')[0],
        };
    };
    JWTStrategy.prototype.onUnauthorized = function (dispatch, getState, request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (request.status !== 401) {
                            return [2 /*return*/, false];
                        }
                        if (!this.refreshTokenPromise) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.refreshTokenPromise];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.refreshToken()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    JWTStrategy.prototype.onForbidden = function (dispatch, getState, request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, false];
            });
        });
    };
    JWTStrategy.prototype.authenticate = function (credentials) {
        return __awaiter(this, void 0, void 0, function () {
            var authResponse, loginData, _a, _b, e_1, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 7, , 8]);
                        this.resetErrorAndChallenge();
                        return [4 /*yield*/, fetch(API_URL_LOGIN, {
                                method: 'POST',
                                body: JSON.stringify(credentials),
                                headers: { 'Content-Type': 'application/json' },
                                referrer: window.location.href.split('?')[0],
                            })];
                    case 1:
                        authResponse = _c.sent();
                        if (!(authResponse.status === 200 || authResponse.status === 201)) return [3 /*break*/, 3];
                        return [4 /*yield*/, authResponse.json()];
                    case 2:
                        loginData = _c.sent();
                        if (loginData.JWT) {
                            this.jwt = loginData.JWT;
                        }
                        else {
                            this.setAuthChallenge(loginData);
                        }
                        return [3 /*break*/, 6];
                    case 3:
                        _c.trys.push([3, 5, , 6]);
                        _a = this.setError;
                        _b = [{ statusCode: authResponse.status }];
                        return [4 /*yield*/, authResponse.json()];
                    case 4:
                        _a.apply(this, [__assign.apply(void 0, _b.concat([(_c.sent())]))]);
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _c.sent();
                        this.setError({ message: 'Invalid Username or Password, please try again' });
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_2 = _c.sent();
                        this.setError(e_2);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    JWTStrategy.prototype.refreshToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var retry;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.refreshTokenPromise = fetch(API_URL_REFRESH_JWT, this.getRequestOptions('GET')).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                            var JWT;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(response.status === 200 || response.status === 201)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, response.json()];
                                    case 1:
                                        JWT = (_a.sent()).JWT;
                                        this.jwt = JWT;
                                        return [2 /*return*/, true];
                                    case 2:
                                        if (response.status === 401) {
                                            this.jwt = null;
                                        }
                                        _a.label = 3;
                                    case 3: return [2 /*return*/, false];
                                }
                            });
                        }); });
                        return [4 /*yield*/, this.refreshTokenPromise];
                    case 1:
                        retry = _a.sent();
                        this.refreshTokenPromise = undefined;
                        return [2 /*return*/, retry];
                }
            });
        });
    };
    JWTStrategy.prototype.exportToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var retry, response, JWT, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        retry = true;
                        _a.label = 1;
                    case 1:
                        if (!retry) return [3 /*break*/, 7];
                        retry = false;
                        return [4 /*yield*/, fetch('/api/auth/exporttoken', this.getRequestOptions('GET'))];
                    case 2:
                        response = _a.sent();
                        if (!((response.status === 200 || response.status === 201) && this.jwt)) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        JWT = (_a.sent()).JWT;
                        this.setError();
                        return [2 /*return*/, JWT];
                    case 4:
                        if (!(response.status === 401)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.refreshToken()];
                    case 5:
                        retry = _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 1];
                    case 7:
                        this.setError({ message: 'Something went wrong. Please try again later.' });
                        return [3 /*break*/, 9];
                    case 8:
                        e_3 = _a.sent();
                        this.setError(e_3);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/, ''];
                }
            });
        });
    };
    JWTStrategy.prototype.logout = function () {
        return __awaiter(this, void 0, void 0, function () {
            var retry, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        retry = 3;
                        _a.label = 1;
                    case 1:
                        if (!retry) return [3 /*break*/, 6];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, fetch('/api/auth/logout', this.getRequestOptions('DELETE'))];
                    case 3:
                        _a.sent();
                        retry = 0;
                        return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        retry--;
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 1];
                    case 6:
                        window.localStorage.setItem('logout', Date.now().toString());
                        this.syncLogout({ key: 'logout' });
                        return [2 /*return*/];
                }
            });
        });
    };
    JWTStrategy.prototype.syncLogout = function (event) {
        if (event.key === 'logout') {
            this.jwt = null;
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
        }
    };
    JWTStrategy.prototype.subscribe = function (fn) {
        var _this = this;
        this.subscribers.push(fn);
        return function () {
            _this.unsubscribe(fn);
        };
    };
    JWTStrategy.prototype.unsubscribe = function (fn) {
        this.subscribers = this.subscribers.filter(function (i) { return !Object.is(i, fn); });
    };
    return JWTStrategy;
}());
export { JWTStrategy };
export var AuthProvider = function (props) {
    var strategy = useMemo(function () { return new JWTStrategy(); }, []);
    return React.createElement(AuthContext.Provider, { value: strategy }, props.children);
};
export var useUser = function () {
    var strategy = useContext(AuthContext);
    var _a = useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    useLayoutEffect(function () { return strategy.subscribe(forceUpdate); }, [strategy]);
    return strategy.user;
};
export var useAuth = function () {
    var strategy = useContext(AuthContext);
    var _a = useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    useLayoutEffect(function () { return strategy.subscribe(forceUpdate); }, [strategy]);
    return strategy;
};
export var PrivateRoute = function (props) {
    var user = useUser();
    var _a = props.roles, roles = _a === void 0 ? [user === null || user === void 0 ? void 0 : user.roleName] : _a, _b = props.checkPermission, checkPermission = _b === void 0 ? function () { return true; } : _b, children = props.children, resprops = __rest(props, ["roles", "checkPermission", "children"]);
    var hasPermission = roles.some(function (i) { return i === (user === null || user === void 0 ? void 0 : user.roleName); }) && checkPermission();
    return (React.createElement(Route, __assign({}, resprops, { render: function (_a) {
            var location = _a.location;
            return hasPermission ? (children) : (React.createElement(Redirect, { to: {
                    pathname: '/',
                    state: { from: location },
                } }));
        } })));
};
export var Authenticated = function (props) {
    var user = useUser();
    var isValid = !!user;
    isValid = isValid && (!props.roles || props.roles.some(function (i) { return i === (user === null || user === void 0 ? void 0 : user.roleName); }));
    isValid = isValid && (!props.checkPermission || props.checkPermission());
    if (!isValid) {
        return null;
    }
    return React.createElement(React.Fragment, null, props.children);
};
export var Unuthenticated = function (props) {
    var user = useUser();
    if (user) {
        return null;
    }
    return React.createElement(React.Fragment, null, props.children);
};
export var Authenticate = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Unuthenticated, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true },
                    React.createElement(LoginComponent, null)),
                React.createElement(Route, { path: "/forgotpassword", exact: true },
                    React.createElement(ForgotPasswordComponent, null)),
                React.createElement(Route, { path: "/signup", exact: true },
                    React.createElement(SignUpComponent, null)),
                React.createElement(Redirect, { to: "/" }))),
        React.createElement(Authenticated, null, props.children)));
};
