/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useEffect } from 'react';
export * from './usetoggle';
export * from './useform';
export * from './usedebounce';
export * from './usegeojson';
export * from './useconfig';
export var useMount = function (effect) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
};
