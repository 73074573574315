var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { ListGroupItem } from 'reactstrap';
import styled from 'styled-components';
import { useFilterEditingValue } from '../state/filterediting';
var StyledTab = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var Dot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 1rem;\n  height: 1rem;\n  background-color: ", ";;\n  border-radius: 50%;\n  margin-left: 0.5rem;\n"], ["\n  width: 1rem;\n  height: 1rem;\n  background-color: ", ";;\n  border-radius: 50%;\n  margin-left: 0.5rem;\n"])), function (props) { return (props.active ? '#ffffff' : '#007bff'); });
export var LeftTabSelectorItem = function (props) {
    var tabId = props.tabId, tabName = props.tabName, activeTab = props.activeTab, toggleTab = props.toggleTab, tabSelector = props.tabSelector, showRequiredDot = props.showRequiredDot, resprops = __rest(props, ["tabId", "tabName", "activeTab", "toggleTab", "tabSelector", "showRequiredDot"]);
    var handleClick = function () {
        toggleTab(tabId);
    };
    var Component = tabSelector || ListGroupItem;
    return (React.createElement(Component, __assign({}, resprops, { action: true, onClick: handleClick, active: activeTab === tabId }),
        React.createElement(StyledTab, null,
            tabName,
            showRequiredDot && React.createElement(Dot, { active: activeTab === tabId }))));
};
export var CMSSourceTabSelector = function (props) {
    var _a = props.type, type = _a === void 0 ? 'hidden' : _a, resprops = __rest(props, ["type"]);
    var isAggregatedDataSource = useFilterEditingValue('isAggregatedDataSource')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { disabled: props.disabled || (isAggregatedDataSource && type === 'disabled'), hidden: isAggregatedDataSource && type === 'hidden' })));
};
export var AggregatedSourceTabSelector = function (props) {
    var _a = props.type, type = _a === void 0 ? 'hidden' : _a, resprops = __rest(props, ["type"]);
    var isAggregatedDataSource = useFilterEditingValue('isAggregatedDataSource')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { disabled: props.disabled || (!isAggregatedDataSource && type === 'disabled'), hidden: !isAggregatedDataSource && type === 'hidden' })));
};
var templateObject_1, templateObject_2;
