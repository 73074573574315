var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useMemo } from 'react';
import { Pagination as RSPagination, PaginationItem as RSPaginationItem, PaginationLink } from 'reactstrap';
var PaginationItem = function (_a) {
    var active = _a.active, disabled = _a.disabled, first = _a.first, previous = _a.previous, next = _a.next, last = _a.last, ellipsis = _a.ellipsis, page = _a.page, children = _a.children, onClick = _a.onClick;
    var handleClick = useCallback(function () {
        if (!active && !disabled && page && onClick) {
            onClick(page);
        }
    }, [onClick, page, active, disabled]);
    var content = useMemo(function () {
        if (first)
            return '«';
        if (previous)
            return '‹';
        if (next)
            return '›';
        if (last)
            return '»';
        if (ellipsis)
            return '…';
        return children;
    }, [first, previous, next, last, ellipsis, children]);
    return (React.createElement(RSPaginationItem, { active: active, disabled: disabled },
        React.createElement(PaginationLink, { href: "#", onClick: handleClick }, content)));
};
var Pagination = function (_a) {
    var _b;
    var className = _a.className, pages = _a.pages, onPageChange = _a.onPageChange, hidePageListOnlyOnePage = _a.hidePageListOnlyOnePage;
    var pageWithoutIndication = pages.filter(function (p) { return typeof p.page !== 'string'; });
    var totalPages = pageWithoutIndication.length;
    if (hidePageListOnlyOnePage && totalPages < 2)
        return null;
    var activePage = (_b = pages.find(function (p) { return p.active; })) === null || _b === void 0 ? void 0 : _b.page;
    var previousPageIsEllipsis = activePage - 3 > 1;
    var nextPageIsEllipsis = activePage + 3 < totalPages;
    var items = [];
    for (var number = 1; number <= totalPages; number += 1) {
        var active = number === activePage;
        items.push(React.createElement(PaginationItem, { key: number, active: active, page: number, onClick: onPageChange }, number));
        if (number === 1 && previousPageIsEllipsis) {
            items.push(React.createElement(PaginationItem, { key: "prev-more", ellipsis: true, disabled: true }));
            number = activePage - 3;
        }
        if (number === activePage + 2 && nextPageIsEllipsis) {
            items.push(React.createElement(PaginationItem, { key: "next-more", ellipsis: true, disabled: true }));
            number = totalPages - 1;
        }
    }
    return (React.createElement(RSPagination, { className: className },
        React.createElement(PaginationItem, { first: true, disabled: activePage === 1, onClick: onPageChange, page: 1 }),
        React.createElement(PaginationItem, { previous: true, disabled: activePage === 1, onClick: onPageChange, page: activePage - 1 }),
        items,
        React.createElement(PaginationItem, { next: true, disabled: activePage === totalPages, onClick: onPageChange, page: activePage + 1 }),
        React.createElement(PaginationItem, { last: true, disabled: activePage === totalPages, onClick: onPageChange, page: totalPages })));
};
export var Pager = function (props) { return (React.createElement("div", { className: "col-md-6 col-xs-6 col-sm-6 col-lg-6" },
    React.createElement(Pagination, __assign({ className: "float-right" }, props)))); };
