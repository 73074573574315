var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React from 'react';
import { Layer } from 'react-map-gl';
import { ENV_LEGEND_COLORS } from '../../env';
import { useMapValue } from '../mapprovider';
var patientChoroplethLayer = function (transparency) {
    if (transparency === void 0) { transparency = 70; }
    return ({
        id: 'patientchoroplethlayer',
        type: 'fill',
        paint: {
            'fill-color': ['to-color', ['at', ['get', 'breakIdx'], ['array', ['literal', ENV_LEGEND_COLORS]]]],
            'fill-opacity': transparency / 100,
        },
    });
};
export var PatientChoroplethLayer = function () {
    var transparency = useMapValue('transparency')[0];
    return React.createElement(Layer, __assign({ source: "selectedgeographysource" }, patientChoroplethLayer(transparency)));
};
export * from './infopopup';
