/* 
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved. 
 */
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  useToggle,
  FilterBar,
  FilterSidebar,
  FilterResultDashboard,
  DemographicChartComponent,
  TrendLineComponent,
  TableViewComponent,
  MapLayerMenu,
  MapProvider,
  LocationMenu,
  DownloadButton,
  ClinicalTrialMenu,
  ClinicalTrialDownloadButton,
  QuestDiagnosticsSitesDownloadButton,
  useHostnameIndexInfo,
  ProviderMenu,
  ProviderDownloadButton,
  QuestDiagnosticsSites,
} from 'nmqf-common-ui';
import { IndexFilterDialog } from '../filterdialog';
import { IndexMapComponent } from '../map';

const StyledDashboard = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;
const StyledViewMode = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const IndexDashboard: React.FC = () => {
  const [defaultActiveTab, setDefaultActiveTab] = useState<string>();
  const [showFilterDialog, toggleFilterDialog] = useToggle();
  const indexConfig = useHostnameIndexInfo();
  const { providerLayer, clinicalTrailsGovLayer, questDiagnosticsLayer } = indexConfig?.generalInformation || {};

  const handleToggleFilterDialog = (tabId?: string) => {
    setDefaultActiveTab(tabId);
    toggleFilterDialog();
  };

  return (
    <MapProvider>
      <StyledDashboard>
        <IndexFilterDialog isOpen={showFilterDialog} toggle={toggleFilterDialog} defaultActiveTab={defaultActiveTab} />
        <FilterBar toggleFilterDialog={handleToggleFilterDialog}>
          <MapLayerMenu>
            {providerLayer && <ProviderMenu />}
            <LocationMenu>
              {clinicalTrailsGovLayer && <ClinicalTrialMenu />}
              {questDiagnosticsLayer && <QuestDiagnosticsSites />}
            </LocationMenu>
          </MapLayerMenu>
          <DownloadButton>
            {providerLayer && <ProviderDownloadButton />}
            {clinicalTrailsGovLayer && <ClinicalTrialDownloadButton />}
            {questDiagnosticsLayer && <QuestDiagnosticsSitesDownloadButton />}
          </DownloadButton>
        </FilterBar>
        <FilterResultDashboard toggleFilterDialog={handleToggleFilterDialog}>
          <FilterSidebar toggleFilterDialog={handleToggleFilterDialog} />
          <StyledViewMode>
            <IndexMapComponent />
            <DemographicChartComponent />
            <TrendLineComponent />
            <TableViewComponent />
          </StyledViewMode>
        </FilterResultDashboard>
      </StyledDashboard>
    </MapProvider>
  );
};
