/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMount } from '../hooks';
import { useCachedData } from '../state';
var GET_FLU_VACCINE_CODE_LIST_API = '/api/fluvaccine/general/lookup/get-list-vaccine';
export var useLookupFluVaccine = function () {
    var _a, _b, _c;
    var _d = useCachedData(GET_FLU_VACCINE_CODE_LIST_API), data = _d[0], res = _d.slice(1);
    return __spreadArrays([__assign(__assign({}, data), { data: (_c = (_b = (_a = data.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.sort) === null || _c === void 0 ? void 0 : _c.call(_b, function (a, b) { return new Date(a.createDate).getTime() - new Date(b.createDate).getTime(); }) })], res);
};
export var LookupFluVaccineComponent = function () {
    var _a = useLookupFluVaccine(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
