var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { feature } from 'topojson-client';
import * as turf from '@turf/helpers';
import * as _ from 'lodash';
import { useCachedData } from '../state';
import { useDebounceValue } from './usedebounce';
export var combineGeoJson = function (data, filter, propertyMapping, forceClean) {
    if (forceClean || !data) {
        return turf.featureCollection([]);
    }
    var features = data.features;
    features = filter ? features.filter(function (f) { return filter(f.properties); }) : features;
    features = propertyMapping ? features.map(function (f) { return (__assign(__assign({}, f), { properties: propertyMapping(f.properties) })); }) : features;
    return turf.featureCollection(features);
};
var parseTopoJsonToGeoJson = function (keyName, geoType) {
    if (keyName === void 0) { keyName = 'id'; }
    if (geoType === void 0) { geoType = 'unknown'; }
    return function (topology) {
        if (topology) {
            var object = Object.values(topology.objects)[0];
            object.geometries = object.geometries.map(function (geo) { return (__assign(__assign({}, geo), { properties: __assign(__assign({}, geo.properties), { geoType: geoType }) })); });
            var featureCollection = feature(topology, object);
            var mapFeature = _.keyBy(featureCollection.features, "properties." + keyName);
            var mapGeometry = _.keyBy(object.geometries, "properties." + keyName);
            return { topology: topology, featureCollection: featureCollection, mapFeature: mapFeature, mapGeometry: mapGeometry };
        }
    };
};
export var useGeoJson = function (url, option) {
    var _a, _b;
    var _c = useCachedData(url || '', undefined, undefined, undefined, parseTopoJsonToGeoJson(option === null || option === void 0 ? void 0 : option.keyName, option === null || option === void 0 ? void 0 : option.geoType)), topoJsonData = _c[0], doRequest = _c[1], resetStateAPI = _c[2];
    var _d = useDebounceValue(function () {
        var _a, _b;
        return ({
            featureCollection: (_a = topoJsonData.data) === null || _a === void 0 ? void 0 : _a.featureCollection,
            combinedGeoJson: combineGeoJson((_b = topoJsonData.data) === null || _b === void 0 ? void 0 : _b.featureCollection, option === null || option === void 0 ? void 0 : option.filter, option === null || option === void 0 ? void 0 : option.propertyMapping, option === null || option === void 0 ? void 0 : option.forceClean),
        });
    }, [option === null || option === void 0 ? void 0 : option.filter, option === null || option === void 0 ? void 0 : option.forceClean, option === null || option === void 0 ? void 0 : option.propertyMapping, (_a = topoJsonData.data) === null || _a === void 0 ? void 0 : _a.featureCollection]), featureCollection = _d.featureCollection, combinedGeoJson = _d.combinedGeoJson;
    return [
        __assign(__assign(__assign({}, topoJsonData), topoJsonData.data), { data: combinedGeoJson, requesting: topoJsonData.requesting || featureCollection !== ((_b = topoJsonData.data) === null || _b === void 0 ? void 0 : _b.featureCollection) }),
        doRequest,
        resetStateAPI,
    ];
};
