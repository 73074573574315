/* 
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved. 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import { StoreContext } from 'redux-react-hook';

import 'bootstrap/dist/css/bootstrap.css';

import {
  AuthProvider,
  Authenticate,
  Header,
  NMQFGlobalStyle,
  HostNameIndexInfoProvider,
  reducers,
  HostNameMetaTagManager,
  FallbackStoredUIValueComponent,
} from 'nmqf-common-ui';

import { IndexDashboard } from './components/indexdashboard';

const combinedReducers = combineReducers(reducers);
const middlewares = [thunk, promiseMiddleware];
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(combinedReducers);

ReactDOM.render(
  <>
    <NMQFGlobalStyle />
    <StoreContext.Provider value={store}>
      <AuthProvider>
        <HostNameIndexInfoProvider>
          <HostNameMetaTagManager />
          <BrowserRouter>
            <Header />
            <Authenticate>
              <Switch>
                <Route path="/" exact>
                  <FallbackStoredUIValueComponent>
                    <IndexDashboard />
                  </FallbackStoredUIValueComponent>
                </Route>
                <Redirect to="/" />
              </Switch>
            </Authenticate>
          </BrowserRouter>
        </HostNameIndexInfoProvider>
      </AuthProvider>
    </StoreContext.Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
