var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useEffect } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import { CheckboxGroup, Input, SimpleCheckbox } from '../common';
import { AGE_OPTIONS } from '../lookupdata';
import { useReportsDialogValue } from './reportsdialogprovider';
var MAX_AGE_RANGE = 999;
var StyledCustomAgeInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 5rem;\n  display: inline-block;\n"], ["\n  width: 5rem;\n  display: inline-block;\n"])));
export var AgeReportsDialogTab = function (props) {
    var _a, _b;
    var _c = useReportsDialogValue('ages'), ages = _c[0], setAges = _c[1];
    var _d = useState(false), isCustomAge = _d[0], setIsCustomAge = _d[1];
    useEffect(function () {
        if (isCustomAge && !!(ages === null || ages === void 0 ? void 0 : ages[0]) && !ages[0].isCustom) {
            setIsCustomAge(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ages]);
    var handleChangeCustomAgeCheckbox = function (event) {
        setIsCustomAge(event.target.checked);
        setAges();
    };
    var handleChangeCustomAgeInput = function (value, name) {
        var _a;
        var newValue = __assign(__assign({}, ages === null || ages === void 0 ? void 0 : ages[0]), (_a = { isCustom: true }, _a[name] = value, _a));
        if (value === undefined && (ages === null || ages === void 0 ? void 0 : ages[0]) && newValue.minAge === undefined && newValue.maxAge === undefined) {
            setAges();
        }
        else {
            setAges([newValue]);
        }
    };
    var customMinAge = isCustomAge ? (_a = ages === null || ages === void 0 ? void 0 : ages[0]) === null || _a === void 0 ? void 0 : _a.minAge : undefined;
    var customMaxAge = isCustomAge ? (_b = ages === null || ages === void 0 ? void 0 : ages[0]) === null || _b === void 0 ? void 0 : _b.maxAge : undefined;
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, "Age"),
        React.createElement(CheckboxGroup, { options: AGE_OPTIONS, noSort: true, value: ages, onChange: setAges, disabled: function (i) { return isCustomAge; } }),
        React.createElement(FormGroup, { check: true },
            React.createElement(SimpleCheckbox, { checked: isCustomAge, onChange: handleChangeCustomAgeCheckbox, disabled: !isCustomAge && !!(ages === null || ages === void 0 ? void 0 : ages.length), label: ' Custom: From ' }),
            "\u00A0\u00A0",
            React.createElement(StyledCustomAgeInput, { name: "minAge", type: "number", disabled: !isCustomAge, min: 0, max: customMaxAge !== undefined ? customMaxAge : MAX_AGE_RANGE, value: customMinAge, setFieldValue: handleChangeCustomAgeInput }),
            "\u00A0\u00A0To\u00A0\u00A0",
            React.createElement(StyledCustomAgeInput, { name: "maxAge", type: "number", disabled: !isCustomAge, min: customMinAge || 0, max: MAX_AGE_RANGE, value: customMaxAge, setFieldValue: handleChangeCustomAgeInput }))));
};
AgeReportsDialogTab.defaultProps = {
    tabId: 'AgeTab',
    tabName: 'Age',
    tabField: ['ages'],
};
var templateObject_1;
