var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { StatisticTab } from '../filterdialog';
import { getStatisticOption } from '../lookupdata';
import { StyledPanel } from '../map/overlays/styled';
import { LegendByFilter } from '../map/patientchoroplethlayer/legend';
import { useFilterValue, useViewValue } from '../state';
var StyledLegendHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var StyledToggleBtn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 1rem;\n"], ["\n  padding-left: 1rem;\n"])));
var SelectedStatistic = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: fit-content;\n  line-height: initial;\n  cursor: pointer;\n  font-weight: 700;\n"], ["\n  width: fit-content;\n  line-height: initial;\n  cursor: pointer;\n  font-weight: 700;\n"])));
var StatisticCategoryLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var StatisticConfigLabel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #6b778c;\n  padding-bottom: 1rem;\n"], ["\n  color: #6b778c;\n  padding-bottom: 1rem;\n"])));
var StyledButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border-radius: 0.75rem;\n  border: solid 0.125rem #212529;\n  color: #b95951;\n  height: 1.5rem;\n  position: relative;\n  width: 2rem;\n  transition: color 0.3s;\n  &:focus {\n    outline: 0;\n  }\n  ", ";\n"], ["\n  border-radius: 0.75rem;\n  border: solid 0.125rem #212529;\n  color: #b95951;\n  height: 1.5rem;\n  position: relative;\n  width: 2rem;\n  transition: color 0.3s;\n  &:focus {\n    outline: 0;\n  }\n  ", ";\n"])), function (props) { return (props.isActive ? 'background-color: #313130;' : 'background-color: transparent;'); });
var StyledNode = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  height: 1.25rem;\n  width: 1.25rem;\n  top: 0.0125rem;\n  border: solid 0.125rem #212529;\n  border-radius: 50%;\n  transition: left 0.3s;\n  background: #ffffff;\n  ", ";\n"], ["\n  position: absolute;\n  height: 1.25rem;\n  width: 1.25rem;\n  top: 0.0125rem;\n  border: solid 0.125rem #212529;\n  border-radius: 50%;\n  transition: left 0.3s;\n  background: #ffffff;\n  ", ";\n"])), function (props) { return (props.isActive ? 'left: 0.625rem;' : 'left: -0.125rem;'); });
export var ToggleButton = function (props) {
    return (React.createElement(StyledButton, { isActive: props.show, onClick: props.toggle },
        React.createElement(StyledNode, { isActive: props.show })));
};
export var LegendSidebar = function (props) {
    var _a = useViewValue('mapView', 'showLegend'), show = _a[0], setShow = _a[1];
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticConfig = getStatisticOption(statistic);
    return (React.createElement(StyledPanel, null,
        React.createElement(StyledLegendHeader, null,
            React.createElement(SelectedStatistic, { onClick: function () { var _a; return props.toggleFilterDialog((_a = StatisticTab.defaultProps) === null || _a === void 0 ? void 0 : _a.tabId); } },
                React.createElement(StatisticCategoryLabel, null, statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.group.label),
                React.createElement(StatisticConfigLabel, null, statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.label)),
            React.createElement(StyledToggleBtn, null,
                React.createElement(ToggleButton, { show: show, toggle: function () { return setShow(!show); } }))),
        React.createElement(LegendByFilter, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
