/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useMemo } from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { useAPI } from '../state';
var LOCATOR_CLINICALTRIAL_API_URL = '/api/clinical-trials/get-clinical-trial-sites';
var clinicalTrialsSelector = function (state) { return state === null || state === void 0 ? void 0 : state.clinicalTrials; };
var clinicalTrialMapSelector = createSelector(clinicalTrialsSelector, function (clinicalTrials) {
    return _.keyBy(clinicalTrials, function (_a) {
        var nctId = _a.nctId, facilityName = _a.facilityName, zipcode = _a.zipcode;
        return nctId + "-" + facilityName + "-" + zipcode;
    });
});
var createClinicalTrialSelector = function (_a) {
    var _b = _a === void 0 ? Object.assign({}) : _a, nctId = _b.nctId, facilityName = _b.facilityName, zipcode = _b.zipcode;
    return createSelector(clinicalTrialMapSelector, function (map) { return map[nctId + "-" + facilityName + "-" + zipcode]; });
};
export var useClinicalTrialGovApi = function () {
    return useAPI(LOCATOR_CLINICALTRIAL_API_URL, undefined, 'POST');
};
export var useClinicalTrialDetail = function (id) {
    var clinicalTrialGovData = useClinicalTrialGovApi()[0];
    var clinicalTrialSelector = useMemo(function () { return createClinicalTrialSelector(id); }, [id]);
    return clinicalTrialSelector(clinicalTrialGovData.data);
};
