var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo, useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Pager } from './pager';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
export var DEFAULT_PAGE_SIZE = 20;
var StyledDataTableComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  th,\n  td {\n    padding: 0.5rem 0.25rem;\n  }\n  .table {\n    min-width: ", ";\n  }\n  .cell-container {\n    outline: none;\n  }\n  .datatable-custom-header {\n    background-color: #f2f2f2;\n    font-size: 13px;\n    color: #4a4a4a;\n    font-weight: bold;\n    border: 1px solid #dedede;\n    border-bottom: 0;\n  }\n  .datatable-custom-body {\n    font-size: 14px;\n    color: #4a4a4a;\n    border: 1px solid #dedede;\n    td:not(.reset-expansion-style),\n    .td {\n      vertical-align: middle;\n      word-break: break-word;\n      white-space: normal;\n    }\n  }\n  .dropdown-action-menu {\n    min-width: 50px;\n  }\n  .title-search-participant {\n    font-weight: 600;\n    font-size: 17px;\n  }\n  .react-bs-table-no-data {\n    height: 175px;\n    padding: 0;\n    vertical-align: middle;\n    .no-data-icon {\n      color: #cccccc;\n      font-size: 48px;\n    }\n  }\n"], ["\n  th,\n  td {\n    padding: 0.5rem 0.25rem;\n  }\n  .table {\n    min-width: ", ";\n  }\n  .cell-container {\n    outline: none;\n  }\n  .datatable-custom-header {\n    background-color: #f2f2f2;\n    font-size: 13px;\n    color: #4a4a4a;\n    font-weight: bold;\n    border: 1px solid #dedede;\n    border-bottom: 0;\n  }\n  .datatable-custom-body {\n    font-size: 14px;\n    color: #4a4a4a;\n    border: 1px solid #dedede;\n    td:not(.reset-expansion-style),\n    .td {\n      vertical-align: middle;\n      word-break: break-word;\n      white-space: normal;\n    }\n  }\n  .dropdown-action-menu {\n    min-width: 50px;\n  }\n  .title-search-participant {\n    font-weight: 600;\n    font-size: 17px;\n  }\n  .react-bs-table-no-data {\n    height: 175px;\n    padding: 0;\n    vertical-align: middle;\n    .no-data-icon {\n      color: #cccccc;\n      font-size: 48px;\n    }\n  }\n"])), function (_a) {
    var minWidth = _a.minWidth;
    return (typeof minWidth === 'number' ? minWidth + "rem" : minWidth);
});
export var enumFormatter = function (cell, row, rowIndex, enumObject) { var _a; return (_a = enumObject[cell]) === null || _a === void 0 ? void 0 : _a.label; };
export var DataTable = function (_a) {
    var className = _a.className, headerClasses = _a.headerClasses, _b = _a.bordered, bordered = _b === void 0 ? false : _b, _c = _a.nameUnitItemTable, nameUnitItemTable = _c === void 0 ? 'Record(s)' : _c, _d = _a.sizePerPage, sizePerPage = _d === void 0 ? DEFAULT_PAGE_SIZE : _d, pagination = _a.pagination, _e = _a.rowClasses, rowClassesProps = _e === void 0 ? '' : _e, _f = _a.data, data = _f === void 0 ? [] : _f, page = _a.page, onPageChange = _a.onPageChange, minWidth = _a.minWidth, restProps = __rest(_a, ["className", "headerClasses", "bordered", "nameUnitItemTable", "sizePerPage", "pagination", "rowClasses", "data", "page", "onPageChange", "minWidth"]);
    var paginationSize = Math.ceil(data.length / sizePerPage);
    var paginationOptions = useMemo(function () {
        return pagination &&
            paginationFactory({
                page: page && page > paginationSize ? 1 : page,
                onPageChange: onPageChange,
                hideSizePerPage: true,
                showTotal: true,
                sizePerPage: sizePerPage,
                paginationSize: paginationSize,
                paginationTotalRenderer: function (from, to, size) { return React.createElement(React.Fragment, null); },
                pageListRenderer: function (propsPager) { return React.createElement(Pager, __assign({ hidePageListOnlyOnePage: true }, propsPager)); },
            });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, nameUnitItemTable, sizePerPage, restProps.data, paginationSize, page]);
    var rowClasses = useCallback(function (row, rowIndex) {
        return "custom-row " + (typeof rowClassesProps === 'function' ? rowClassesProps(row, rowIndex) : rowClassesProps);
    }, [rowClassesProps]);
    return (React.createElement(StyledDataTableComponent, { className: className, minWidth: minWidth },
        React.createElement(BootstrapTable, __assign({}, restProps, { data: data, bootstrap4: true, headerClasses: "datatable-custom-header " + headerClasses, classes: "datatable-custom-body", rowClasses: rowClasses, bordered: bordered, pagination: paginationOptions, noDataIndication: "There is no data to display", sort: {
                sortCaret: function (order) { return (React.createElement(FontAwesomeIcon, { className: "ml-1", icon: !order ? 'sort' : order === 'asc' ? 'sort-up' : 'sort-down', color: !order ? '#ccc' : undefined })); },
            } }))));
};
var templateObject_1;
