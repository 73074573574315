/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo, useEffect, useState } from 'react';
import { useMount } from '../hooks';
import { useIndexName } from '../hostnameindexinfo';
import { useAPI } from '../state';
var FETCH_AGGREGATED_DATA_SOURCES_API_URL = '/api/data-file/get-data-source-names-for-an-index/{indexName}';
export var useAggregatedDataSource = function () {
    var indexName = useIndexName();
    var _a = useAPI(FETCH_AGGREGATED_DATA_SOURCES_API_URL, { indexName: indexName }), agrregatedData = _a[0], rest = _a.slice(1);
    var data = useMemo(function () { var _a; return (_a = agrregatedData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return i.dataSourceGeos.some(function (geo) { return geo.geoLevelLookup.geoName !== 'NATIONAL'; }); }); }, [
        agrregatedData.data,
    ]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], rest);
};
export var useAggregatedDataSourceName = function () {
    var _a = useAggregatedDataSource(), agrregatedData = _a[0], res = _a.slice(1);
    // transform & remove duplicated & sort
    var data = useMemo(function () { var _a; return (_a = agrregatedData.data) === null || _a === void 0 ? void 0 : _a.map(function (i) { return i.dataSourceName; }).filter(function (name, idx, arr) { return idx === arr.findIndex(function (curr) { return curr === name; }); }).sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); }); }, [agrregatedData.data]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], res);
};
// year by source name
export var useAggregatedYear = function (filterSetting) {
    var _a = useAggregatedDataSource(), agrregatedData = _a[0], res = _a.slice(1);
    // search & transform & remove duplicated & sort
    var data = useMemo(function () {
        var _a;
        return filterSetting.isAggregatedDataSource
            ? (_a = agrregatedData === null || agrregatedData === void 0 ? void 0 : agrregatedData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return i.dataSourceName === filterSetting.dataSourceName; }).map(function (i) { return i.year; }).filter(function (year, idx, arr) { return idx === arr.findIndex(function (curr) { return curr === year; }); }).sort() : undefined;
    }, [agrregatedData.data, filterSetting.isAggregatedDataSource, filterSetting.dataSourceName]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], res);
};
// statistic by source name + year
export var useAggregatedStatistic = function (filterSetting) {
    var _a = useAggregatedDataSource(), agrregatedData = _a[0], res = _a.slice(1);
    var _b = useState(), data = _b[0], setData = _b[1];
    // search & transform & remove duplicated
    useEffect(function () {
        var _a;
        if (filterSetting.isAggregatedDataSource) {
            setData((_a = agrregatedData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return i.dataSourceName === filterSetting.dataSourceName && i.year === filterSetting.year; }).map(function (i) { return i.dataTypeName; }).filter(function (i, idx, arr) { return idx === arr.findIndex(function (curr) { return curr === i; }); }));
        }
    }, [agrregatedData.data, filterSetting.isAggregatedDataSource, filterSetting.dataSourceName, filterSetting.year]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], res);
};
// demographic by source name + year
export var useAggregatedDemogaphic = function (filterSetting, demographic) {
    var _a = useAggregatedDataSource(), agrregatedData = _a[0], res = _a.slice(1);
    var _b = useState(), data = _b[0], setData = _b[1];
    // search & transform & remove duplicated
    useEffect(function () {
        var _a;
        if (filterSetting.isAggregatedDataSource) {
            setData((_a = agrregatedData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return i.dataSourceName === filterSetting.dataSourceName && i.year === filterSetting.year; }).flatMap(function (i) { return i.dataSourceColumnDefinitions.filter(function (cd) { return cd.category === demographic; }); }).filter(function (i, idx, arr) { return (idx === arr.findIndex(function (curr) { return curr.name === i.name; })); }));
        }
    }, [agrregatedData.data, filterSetting.isAggregatedDataSource, filterSetting.dataSourceName, filterSetting.year, demographic]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], res);
};
// geo level by source name + year + statistic
export var useAggregatedGeoLevel = function (filterSetting) {
    var _a;
    var _b = useAggregatedDataSource(), agrregatedData = _b[0], res = _b.slice(1);
    var _c = useState(), data = _c[0], setData = _c[1];
    // search & transform & remove duplicated
    useEffect(function () {
        var _a;
        if (filterSetting.isAggregatedDataSource) {
            setData((_a = agrregatedData.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { var _a; return i.dataSourceName === filterSetting.dataSourceName && i.year === filterSetting.year && i.dataTypeName === ((_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption); }).flatMap(function (i) { return i.dataSourceGeos; }).map(function (i) { return i.geoLevelLookup.geoName; }).filter(function (i, idx, arr) { return i !== 'NATIONAL' && idx === arr.findIndex(function (curr) { return curr === i; }); }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agrregatedData.data, filterSetting.isAggregatedDataSource, filterSetting.year, (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption]);
    return __spreadArrays([__assign(__assign({}, agrregatedData), { data: data })], res);
};
export var AggregatedDataSourceComponent = function () {
    var _a = useAggregatedDataSource(), fetchAggregatedData = _a[1];
    useMount(function () {
        fetchAggregatedData();
    });
    return null;
};
