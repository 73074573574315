var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import styled from 'styled-components';
import { FallbackComponent, Select } from '../../common';
import { useMixedYear } from '../../lookupdata';
import { useFilterSetting, useViewValue } from '../../state';
import { TrendLineContainer } from '../../trendline';
import { useTrendLineData } from '../../trendline/usetrendlinedata';
var StyledTrendLineComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  min-height: 15rem;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  min-height: 15rem;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 0.5rem;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 0.5rem;\n  align-items: center;\n"])));
var StyledDropdownToggle = styled(DropdownToggle)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #212529 !important;\n  border: none;\n  background: transparent !important;\n  &:hover {\n    background: transparent;\n    opacity: 0.8;\n  }\n  &:focus {\n    background: transparent !important;\n    box-shadow: none !important;\n  }\n  &::after {\n    font-size: 1rem;\n  }\n"], ["\n  color: #212529 !important;\n  border: none;\n  background: transparent !important;\n  &:hover {\n    background: transparent;\n    opacity: 0.8;\n  }\n  &:focus {\n    background: transparent !important;\n    box-shadow: none !important;\n  }\n  &::after {\n    font-size: 1rem;\n  }\n"])));
var Title = styled.b(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
var StyledTitle = styled.b(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 500;\n  padding-right: 0.25rem;\n"], ["\n  font-weight: 500;\n  padding-right: 0.25rem;\n"])));
var StyledDropdownMenu = styled(DropdownMenu)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  box-shadow: 0 5px 10px -5px;\n"], ["\n  box-shadow: 0 5px 10px -5px;\n"])));
var StyledSelectYearItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 18rem;\n  padding: 0.5rem 0 0.5rem 1rem;\n"], ["\n  width: 18rem;\n  padding: 0.5rem 0 0.5rem 1rem;\n"])));
export var TrendLine = function () {
    var _a = useViewValue('chartView', 'startingYear'), fromYear = _a[0], setFromYear = _a[1];
    var _b = useViewValue('chartView', 'endingYear'), toYear = _b[0], setToYear = _b[1];
    var _c = useTrendLineData(), trendlineData = _c[0], fetchTrendlineData = _c[1];
    var filterSettings = useFilterSetting();
    var lookupYearData = useMixedYear(filterSettings, true)[0];
    var selectYearItem = (React.createElement(React.Fragment, null,
        React.createElement(StyledTitle, null, "From"),
        React.createElement(Select, { inline: true, width: "6", name: "from", placeholder: "", options: lookupYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: fromYear, onChange: setFromYear, isOptionDisabled: function (option) { return !!toYear && option.value > toYear; }, isLoading: lookupYearData.requesting }),
        ' ',
        React.createElement(StyledTitle, null, "to"),
        React.createElement(Select, { inline: true, width: "6", name: "to", placeholder: "", options: lookupYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: toYear, onChange: setToYear, isOptionDisabled: function (option) { return !!fromYear && option.value < fromYear; }, isLoading: lookupYearData.requesting })));
    return (React.createElement(StyledTrendLineComponent, null,
        React.createElement(Header, null,
            React.createElement(Title, null, "National Trend"),
            React.createElement(UncontrolledDropdown, null,
                React.createElement(StyledDropdownToggle, { size: "2x", caret: true },
                    React.createElement(StyledTitle, null,
                        fromYear,
                        " to ",
                        toYear)),
                React.createElement(StyledDropdownMenu, { right: true },
                    React.createElement(StyledSelectYearItem, null, selectYearItem)))),
        React.createElement(FallbackComponent, { requesting: trendlineData.requesting || lookupYearData.requesting, error: trendlineData.error, retry: fetchTrendlineData },
            React.createElement(TrendLineContainer, { data: trendlineData.data }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
