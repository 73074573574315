var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React from 'react';
import styled from 'styled-components';
import { getDisplayText } from '../../utils';
import { extractGeneralFilterInfo, useMapData } from './usemapdata';
import { useFilterSetting, useFilterValue } from '../../state/filtersettings';
import { CloseButton, StyledPanel } from '../overlays/styled';
import { useViewValue } from '../../state';
import { getStatisticOption } from '../../lookupdata';
import { ENV_LEGEND_COLORS } from '../../env';
var StyledLegendOverlay = styled(StyledPanel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 1.5rem;\n  bottom: 2rem;\n  width: max-content;\n"], ["\n  position: absolute;\n  right: 1.5rem;\n  bottom: 2rem;\n  width: max-content;\n"])));
var StyledLegend = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.125rem 0;\n"], ["\n  padding: 0.125rem 0;\n"])));
var LegendTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 700;\n  margin-top: 0.5rem;\n  margin-bottom: 0.25rem;\n"], ["\n  font-weight: 700;\n  margin-top: 0.5rem;\n  margin-bottom: 0.25rem;\n"])));
var LegendItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.125rem;\n  line-height: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.125rem;\n  line-height: 1;\n"])));
var Color = styled.div(function (props) { return "\n  background-color: " + props.color + ";\n  width: 1rem;\n  height: 1rem;\n  margin-right: 0.25rem;\n"; });
var SelectedStatistic = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 16rem;\n  line-height: initial;\n  font-weight: 700;\n"], ["\n  max-width: 16rem;\n  line-height: initial;\n  font-weight: 700;\n"])));
var Label = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var StatisticCategoryLabel = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var StatisticConfigLabel = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: #6b778c;\n  padding-bottom: 1rem;\n"], ["\n  color: #6b778c;\n  padding-bottom: 1rem;\n"])));
// Common Legend Component use for UI & Reports
export var LegendComponent = function (props) {
    var _a;
    return (React.createElement(StyledLegend, null,
        props.title && React.createElement(LegendTitle, null, "Legend"),
        React.createElement(LegendItem, null,
            React.createElement(Color, { color: ENV_LEGEND_COLORS[0] }),
            React.createElement(Label, null, "N/A")), (_a = props.payload) === null || _a === void 0 ? void 0 :
        _a.map(function (grade, idx, arr) {
            return !(idx % 2) ? (React.createElement(LegendItem, { key: grade },
                React.createElement(Color, { color: ENV_LEGEND_COLORS[idx / 2 + 1] }),
                React.createElement(Label, null,
                    getDisplayText(grade, props.suffix, props.prefix),
                    idx !== arr.length - 1 ? " - " + getDisplayText(arr[idx + 1], props.suffix) : '+'))) : null;
        })));
};
export var LegendByFilter = function () {
    var _a;
    var filterSetting = useFilterSetting();
    var data = useMapData()[0].data;
    var _b = extractGeneralFilterInfo(data, (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption), suffix = _b.suffix, prefix = _b.prefix;
    return React.createElement(LegendComponent, { payload: data === null || data === void 0 ? void 0 : data.breakLegendInfo, suffix: suffix, prefix: prefix });
};
export var LegendOverlay = function () {
    var _a = useViewValue('mapView', 'showLegend'), show = _a[0], setShow = _a[1];
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticConfig = getStatisticOption(statistic);
    var toggle = function () { return setShow(!show); };
    if (!show)
        return null;
    return (React.createElement(StyledLegendOverlay, null,
        React.createElement(CloseButton, { onClick: toggle }),
        React.createElement(SelectedStatistic, null,
            React.createElement(StatisticCategoryLabel, null, statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.group.label),
            React.createElement(StatisticConfigLabel, null, statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.label)),
        React.createElement(LegendByFilter, null)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
