/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { METHODOLOGY_TYPE } from './constants';
export var MethodologyModal = function (props) {
    var _a = useState(METHODOLOGY_TYPE.PREVALENCE), methodology = _a[0], setMethodology = _a[1];
    var handleChangeMethodology = function (methodology) {
        setMethodology(methodology);
    };
    var handleOKBtn = function () {
        if (!methodology) {
            alert('Please select Methodology');
            return;
        }
        props.toggle();
        window.open("/api/nmqf/get-static-pdf-file/" + methodology.pdfFileType);
    };
    return (React.createElement(Modal, { isOpen: props.isOpen, toggle: props.toggle },
        React.createElement(ModalHeader, { toggle: props.toggle }, "Select Methodology"),
        React.createElement(ModalBody, null,
            React.createElement(Form, null, Object.keys(METHODOLOGY_TYPE).map(function (key) { return (React.createElement(FormGroup, { check: true, key: key },
                React.createElement(Label, { check: true },
                    React.createElement(Input, { type: "radio", name: "printOption", checked: methodology.value === METHODOLOGY_TYPE[key].value, onChange: function () { return handleChangeMethodology(METHODOLOGY_TYPE[key]); } }),
                    METHODOLOGY_TYPE[key].label))); }))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { color: "secondary", onClick: props.toggle }, "Cancel"),
            ' ',
            React.createElement(Button, { color: "primary", onClick: handleOKBtn }, "Submit"))));
};
