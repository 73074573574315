var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Authenticated } from '../authentication';
import { UserMenu } from './usermenu';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
var StyledHeader = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 0 0 4.3125rem;\n  display: flex;\n  align-items: center;\n  padding: 0.25rem 1rem;\n  position: relative;\n  box-shadow: 0 5px 10px -5px;\n"], ["\n  flex: 0 0 4.3125rem;\n  display: flex;\n  align-items: center;\n  padding: 0.25rem 1rem;\n  position: relative;\n  box-shadow: 0 5px 10px -5px;\n"])));
var IndexLogoAndNameLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  :hover {\n    text-decoration: none !important;\n  }\n  text-decoration: none;\n  background-color: transparent;\n  margin-right: auto;\n"], ["\n  display: flex;\n  :hover {\n    text-decoration: none !important;\n  }\n  text-decoration: none;\n  background-color: transparent;\n  margin-right: auto;\n"])));
var IndexLogo = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-height: 3.5rem;\n  max-width: 18.75rem;\n  vertical-align: middle;\n  border-style: none;\n  margin: auto;\n  @media only screen and (max-width: 600px) {\n    max-height: 3rem;\n  }\n"], ["\n  max-height: 3.5rem;\n  max-width: 18.75rem;\n  vertical-align: middle;\n  border-style: none;\n  margin: auto;\n  @media only screen and (max-width: 600px) {\n    max-height: 3rem;\n  }\n"])));
var IndexName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  color: #000000;\n  > * {\n    font-weight: normal;\n  }\n"], ["\n  margin-left: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  color: #000000;\n  > * {\n    font-weight: normal;\n  }\n"])));
var IndexNameH3 = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0 !important;\n  font-size: 1.75rem;\n  line-height: 1.2;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 1.25rem;\n  }\n"], ["\n  margin: 0 !important;\n  font-size: 1.75rem;\n  line-height: 1.2;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 1.25rem;\n  }\n"])));
var IndexNameH5 = styled.h5(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 !important;\n  font-size: 1.25rem;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 0.825rem;\n  }\n"], ["\n  margin: 0 !important;\n  font-size: 1.25rem;\n\n  @media only screen and (max-width: 600px) {\n    font-size: 0.825rem;\n  }\n"])));
export var Header = function (props) {
    var indexConfig = useHostnameIndexInfo();
    var location = useLocation();
    return (React.createElement(StyledHeader, null,
        React.createElement(IndexLogoAndNameLink, { to: location.search },
            React.createElement(IndexLogo, { alt: "app-logo", src: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.squareLogo }),
            React.createElement(IndexName, null,
                React.createElement(IndexNameH3, null, props.indexName || (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.indexTitle)),
                React.createElement(IndexNameH5, null, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.indexOwner))),
        props.children,
        React.createElement(Authenticated, null,
            React.createElement(UserMenu, { disabledReport: props.disabledReport, reportCondition: props.reportCondition, hasSecondaryCondition: props.hasSecondaryCondition }))));
};
export * from './usermenu';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
