/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useCallback } from 'react';
import { useMount } from '../hooks';
import { useCachedData } from '../state';
import { useLookupState } from './lookupstate';
var LOOKUP_ZIP_CODE_DISTRICT_API = '/api/general/lookup/get-data-for-drop-down-filtering-which-related-to-state';
export var useLookupZipCodeDistrict = function () {
    var _a;
    var _b = useCachedData(LOOKUP_ZIP_CODE_DISTRICT_API), data = _b[0], res = _b.slice(1);
    var lookupStateData = useLookupState()[0];
    var getStateCodeOfZipCode = useCallback(function (zipcode) {
        var _a;
        return zipcode && ((_a = data.data) === null || _a === void 0 ? void 0 : _a.data.stateByZipCode5[zipcode.padStart(5, '0')]);
    }, [data.data]);
    var getStateNameOfZipCode = useCallback(function (zipcode) {
        var stateCode = getStateCodeOfZipCode(zipcode);
        return stateCode && lookupStateData.getNameByStateCode(stateCode);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStateCodeOfZipCode, lookupStateData.getNameByStateCode]);
    var getStateAbbOfZipCode = useCallback(function (zipcode) {
        var _a, _b;
        var stateCode = getStateCodeOfZipCode(zipcode);
        return stateCode ? (_b = (_a = lookupStateData.data) === null || _a === void 0 ? void 0 : _a.find(function (i) { return i.stateCode === stateCode; })) === null || _b === void 0 ? void 0 : _b.stateAbbreviation : 'N/A';
    }, [getStateCodeOfZipCode, lookupStateData]);
    return __spreadArrays([__assign(__assign({}, data), { data: (_a = data.data) === null || _a === void 0 ? void 0 : _a.data, getStateCodeOfZipCode: getStateCodeOfZipCode, getStateNameOfZipCode: getStateNameOfZipCode, getStateAbbOfZipCode: getStateAbbOfZipCode })], res);
};
export var LookupZipCodeDistrictComponent = function () {
    var _a = useLookupZipCodeDistrict(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
