var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, } from 'recharts';
import { useTranslation } from 'react-i18next';
import { getDisplayText } from '../utils';
var BAR_COLOR = '#00008b';
var NoDataMessage = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  padding: 0.25rem;\n  background-color: transparent;\n  max-width: 11.25rem;\n"], ["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  padding: 0.25rem;\n  background-color: transparent;\n  max-width: 11.25rem;\n"])));
var ResponsiveContainerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n"], ["\n  position: relative;\n  width: ", ";\n  height: ", ";\n"])), function (props) { return (typeof props.width === 'string' ? props.width : props.width ? props.width / 16 + "rem" : '100%'); }, function (props) { return (typeof props.height === 'string' ? props.height : props.height ? props.height / 16 + "rem" : '100%'); });
export var BarComponent = function (props) {
    var _a;
    var t = useTranslation().t;
    var meetMinimumThreshold = !!((_a = props.data) === null || _a === void 0 ? void 0 : _a.length);
    var tickFormatter = useCallback(function (value) { return (meetMinimumThreshold ? getDisplayText(value) : ''); }, [meetMinimumThreshold]);
    var labelFormatter = useCallback(function () { return ''; }, []);
    var tooltipFormatter = useCallback(function (value, name, entry) { return [getDisplayText(entry.payload.value, '', entry.payload.prefix), entry.payload.name]; }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ResponsiveContainerWrapper, { width: props.width, height: props.height },
            React.createElement(ResponsiveContainer, { className: "position-absolute" },
                React.createElement(ComposedChart, { data: props.data },
                    React.createElement(CartesianGrid, { vertical: false }),
                    React.createElement(XAxis, { dataKey: "name", interval: 0, angle: -45, textAnchor: "end", height: 85 }),
                    React.createElement(YAxis, { angle: -45, width: 85, tickLine: meetMinimumThreshold, tickFormatter: tickFormatter }),
                    React.createElement(Bar, { dataKey: "value", isAnimationActive: false, fill: BAR_COLOR, maxBarSize: 20 }),
                    meetMinimumThreshold && React.createElement(Tooltip, { isAnimationActive: false, labelFormatter: labelFormatter, formatter: tooltipFormatter })))),
        !meetMinimumThreshold && React.createElement(NoDataMessage, null, t('demographicchart.nomeetminimumthreshold.msg'))));
};
var templateObject_1, templateObject_2;
