/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
// Common component use for both UI & Reports
import { GeoLevelEnum, RightPanelTypeEnum } from '../../enums';
import { useLookupState, useLookupZipCodeDistrict, useLookupMSA, useLookupCounty, getGeoLevelLabel } from '../../lookupdata';
import { separateGeoLevelId } from '../../utils';
export var useSelectedGeoIdInfo = function (viewSetting, stateName) {
    var _a, _b, _c, _d, _e, _f, _g;
    var lookupStateData = useLookupState()[0];
    var lookupZipCode = useLookupZipCodeDistrict()[0];
    var lookupMsa = useLookupMSA()[0];
    var countyData = useLookupCounty()[0];
    var geoLevel = viewSetting.geoType;
    var selectedId = ((_a = viewSetting.selectedRegion) === null || _a === void 0 ? void 0 : _a.type) === RightPanelTypeEnum.RegionDetail ? viewSetting.selectedRegion.id : undefined;
    var selectedGeoProperties = (_b = viewSetting.mapView) === null || _b === void 0 ? void 0 : _b.selectedGeoProperties;
    var geoLevelLabel = getGeoLevelLabel(geoLevel);
    var firstLevelLabel = 'All';
    var secondLevelLabel = geoLevel !== GeoLevelEnum.State && geoLevel !== GeoLevelEnum.MSA ? 'All' : undefined;
    var _h = separateGeoLevelId(selectedId), firstLevelCode = _h[0], secondLevelCode = _h[1];
    if (firstLevelCode) {
        if (geoLevel === GeoLevelEnum.Zip5 && !(selectedId === null || selectedId === void 0 ? void 0 : selectedId.endsWith('-'))) {
            secondLevelLabel = firstLevelCode.padStart(5, '0');
            firstLevelLabel = stateName || lookupZipCode.getStateNameOfZipCode(secondLevelLabel);
        }
        else if (geoLevel === GeoLevelEnum.MSA) {
            firstLevelLabel = ((_d = (_c = lookupMsa.data) === null || _c === void 0 ? void 0 : _c.find(function (lookup) { return lookup.msaNumber === firstLevelCode; })) === null || _d === void 0 ? void 0 : _d.msaName) || (selectedGeoProperties === null || selectedGeoProperties === void 0 ? void 0 : selectedGeoProperties.name);
        }
        else {
            firstLevelLabel = lookupStateData.getNameByStateCode(firstLevelCode);
        }
    }
    if (firstLevelCode && secondLevelCode) {
        if (geoLevel === GeoLevelEnum.County) {
            secondLevelLabel = (_g = (_f = (_e = countyData.data) === null || _e === void 0 ? void 0 : _e[firstLevelCode.padStart(2, '0')]) === null || _f === void 0 ? void 0 : _f.find(function (ct) { return ct.countyCode === secondLevelCode; })) === null || _g === void 0 ? void 0 : _g.countyName;
        }
        else if (geoLevel === GeoLevelEnum.Congressional) {
            secondLevelLabel = secondLevelCode.padStart(2, '0');
        }
        else {
            // zip 3, upper district, lower district
            secondLevelLabel = secondLevelCode.padStart(3, '0');
        }
    }
    return [geoLevelLabel, firstLevelLabel, secondLevelLabel].filter(function (label) { return !!label; });
};
