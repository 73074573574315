/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useCallback, useMemo } from 'react';
import { RESET_FILTER_EDITING, SET_FILTER_EDITING_VALUE } from './reducer';
export function useFilterEditingValue() {
    var keyPath = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keyPath[_i] = arguments[_i];
    }
    var dispatch = useDispatch();
    var stringifiedPath = JSON.stringify(keyPath);
    var setter = useCallback(function (value) { return dispatch({ type: SET_FILTER_EDITING_VALUE, payload: { keyPath: JSON.parse(stringifiedPath), value: value } }); }, [
        dispatch,
        stringifiedPath,
    ]);
    var getter = useCallback(function (state) { return state.filterediting.getIn(JSON.parse(stringifiedPath)); }, [stringifiedPath]);
    return [useMappedState(getter), setter];
}
export var useResetFilterEditing = function () {
    var dispatch = useDispatch();
    return useCallback(function (value) { return dispatch({ type: RESET_FILTER_EDITING, payload: value }); }, [dispatch]);
};
export var useFilterEditing = function () {
    var filterediting = useMappedState(useCallback(function (state) { return state.filterediting; }, []));
    return useMemo(function () { return filterediting.toJSON(); }, [filterediting]);
};
