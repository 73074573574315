var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { TransparencyControl } from '../map/overlays';
import { useViewValue } from '../state';
import { LegendSidebar, ToggleButton } from './legendsidebar';
var StyledWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 -0.75rem;\n"], ["\n  margin: 0 -0.75rem;\n"])));
var StyledTransparency = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.75rem;\n"], ["\n  padding: 0.75rem;\n"])));
var StyledGeography = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0.75rem;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 0.75rem;\n"])));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-bottop: 1rem;\n  font-weight: 700;\n"], ["\n  padding-bottop: 1rem;\n  font-weight: 700;\n"])));
export var LegendTransparencyTab = function (props) {
    var _a = useViewValue('mapView', 'showGeographyHoverDisplay'), show = _a[0], setShow = _a[1];
    return (React.createElement(StyledWrapper, null,
        React.createElement(LegendSidebar, { toggleFilterDialog: props.toggleFilterDialog }),
        React.createElement(StyledGeography, null,
            React.createElement(Title, null, "Geography Hover Display"),
            React.createElement(ToggleButton, { show: show, toggle: function () { return setShow(!show); } })),
        React.createElement(StyledTransparency, null,
            React.createElement(Title, null, "Transparency Settings"),
            React.createElement(TransparencyControl, null))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
