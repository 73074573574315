var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useRef, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useMount } from '../hooks';
var StyledIconTooltip = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #212529;\n  border: none;\n  background: transparent !important;\n  &:hover {\n    color: #212529;\n  }\n  &:focus,\n  &:active {\n    color: #212529;\n  }\n"], ["\n  color: #212529;\n  border: none;\n  background: transparent !important;\n  &:hover {\n    color: #212529;\n  }\n  &:focus,\n  &:active {\n    color: #212529;\n  }\n"])));
var StyledUncontrolledTooltip = styled(function (_a) {
    var maxWidth = _a.maxWidth, maxHeight = _a.maxHeight, props = __rest(_a, ["maxWidth", "maxHeight"]);
    return React.createElement(UncontrolledTooltip, __assign({}, props));
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .tooltip.show {\n    opacity: 1;\n  }\n  .tooltip-inner {\n    color: #212529;\n    background-color: #fff;\n    border: 1px solid #cccccc;\n    text-align: left;\n    max-width: ", ";\n    overflow-y: scroll;\n    max-height: ", ";\n  }\n"], ["\n  .tooltip.show {\n    opacity: 1;\n  }\n  .tooltip-inner {\n    color: #212529;\n    background-color: #fff;\n    border: 1px solid #cccccc;\n    text-align: left;\n    max-width: ", ";\n    overflow-y: scroll;\n    max-height: ", ";\n  }\n"])), function (props) { return (typeof props.maxWidth === 'number' ? props.maxWidth / 16 + "rem" : props.maxWidth || '16rem'); }, function (props) { return (typeof props.maxHeight === 'number' ? props.maxHeight / 16 + "rem" : props.maxHeight); });
export var IconTooltip = function (props) {
    var iconRef = useRef();
    var _a = useState(), ready = _a[0], setReady = _a[1];
    useMount(function () {
        setReady(true);
    });
    return (React.createElement(StyledIconTooltip, null,
        React.createElement(FontAwesomeIcon, { icon: faInfoCircle, size: props.size, fixedWidth: true, forwardedRef: iconRef }),
        props.title && ready && iconRef.current && (React.createElement(StyledUncontrolledTooltip, { hideArrow: true, placement: props.placement, target: iconRef.current, fade: false, autohide: props.autohide, maxWidth: props.maxWidth, maxHeight: props.maxHeight, modifiers: {
                preventOverflow: {
                    enabled: false,
                },
                hide: { enabled: false },
            }, flip: false }, props.title))));
};
var templateObject_1, templateObject_2;
