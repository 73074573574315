/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var RightPanelTypeEnum;
(function (RightPanelTypeEnum) {
    RightPanelTypeEnum["RegionDetail"] = "RegionDetail";
    RightPanelTypeEnum["ProviderDetail"] = "ProviderDetail";
    RightPanelTypeEnum["ClinicalTrialDetail"] = "ClinicalTrialDetail";
    RightPanelTypeEnum["CustomMapLayer"] = "CustomMapLayer";
    RightPanelTypeEnum["QuestDiagnosticsSites"] = "QuestDiagnosticsSitesLayer";
})(RightPanelTypeEnum || (RightPanelTypeEnum = {}));
