/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
import { useCallback, useMemo } from 'react';
import { ChronicConditionSource, PrimaryConditionsSortTypeEnum, SecondaryConditionsSortTypeEnum, KeyCombineConditionSource, } from '../enums';
import { useGeneralConfigByDataSource, useMount } from '../hooks';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useCachedData, useFilterEditingValue } from '../state';
import _ from 'lodash';
import { useReportsDialogValue } from '../reportsdialogextension';
var LOOKUP_CONDITION_API = '/api/general/lookup/get-lookup-chronic-condition';
export var useLookupCondition = function (values) {
    var _a = useCachedData(LOOKUP_CONDITION_API), lookupConditionData = _a[0], res = _a.slice(1);
    var labeledValues = useMemo(function () {
        var _a;
        return ((values === null || values === void 0 ? void 0 : values.length) && ((_a = lookupConditionData.data) === null || _a === void 0 ? void 0 : _a.length)
            ? values === null || values === void 0 ? void 0 : values.map(function (value) { var _a; return (_a = lookupConditionData.data) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.chronicConditionCode === value; }); }).filter(function (option) { return !!option; }) : []);
    }, [values, lookupConditionData.data]);
    return __spreadArrays([__assign(__assign({}, lookupConditionData), { labeledValues: labeledValues })], res);
};
export var LookupConditionComponent = function () {
    var _a = useLookupCondition(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
export var ConditionType;
(function (ConditionType) {
    ConditionType[ConditionType["Primary"] = 0] = "Primary";
    ConditionType[ConditionType["Key"] = 1] = "Key";
    ConditionType[ConditionType["Other"] = 2] = "Other";
    ConditionType[ConditionType["Secondary"] = 3] = "Secondary";
})(ConditionType || (ConditionType = {}));
export var getConditionsByDataSet = function (conditionByDataSet, cmsDataSource, dataYear, key) {
    if (key === void 0) { key = 'conditions'; }
    if (!conditionByDataSet)
        return undefined;
    var conditionByDataSource = cmsDataSource ? conditionByDataSet[cmsDataSource] : undefined;
    var conditionByDataSourceSetAllYear = conditionByDataSource === null || conditionByDataSource === void 0 ? void 0 : conditionByDataSource.find(function (conditions) { return conditions.dataYear === undefined; });
    var conditionByDataSourceAndDataYear = conditionByDataSource === null || conditionByDataSource === void 0 ? void 0 : conditionByDataSource.find(function (conditions) { return String(conditions.dataYear) === String(dataYear); });
    return _.get(conditionByDataSourceAndDataYear, key, undefined) || _.get(conditionByDataSourceSetAllYear, key, undefined);
};
var compareCondition = function (a, b, indexConfig, cmsDataSource, dataYear, parameters) {
    var _a = (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}, primaryConditions = _a.primaryConditions, primaryConditionsSortType = _a.primaryConditionsSortType, secondaryConditionsConfig = _a.secondaryConditions, secondaryConditionsSortType = _a.secondaryConditionsSortType, filtersConfigByDataSource = _a.filtersConfigByDataSource;
    var secondaryConditions = (parameters || {}).secondaryConditions;
    var primaryConditionByDataSourceAndYear = getConditionsByDataSet(filtersConfigByDataSource, cmsDataSource, dataYear);
    var primaryConditionWithoutClaims = getConditionsByDataSet(filtersConfigByDataSource, cmsDataSource, dataYear, 'primaryConditionWithoutClaims');
    var fullPrimaryCondition = primaryConditionWithoutClaims || primaryConditionByDataSourceAndYear || primaryConditions;
    var fullSecondaryConditions = secondaryConditions || secondaryConditionsConfig;
    return !a || !b
        ? 0
        : a.type - b.type
            ? a.type - b.type
            : b.type === ConditionType.Key
                ? parseInt(a.value, 10) - parseInt(b.value, 10)
                : b.type === ConditionType.Primary && fullPrimaryCondition && primaryConditionsSortType === PrimaryConditionsSortTypeEnum.Specifically
                    ? fullPrimaryCondition.indexOf(a.value) - fullPrimaryCondition.indexOf(b.value)
                    : b.type === ConditionType.Secondary && fullSecondaryConditions && secondaryConditionsSortType === SecondaryConditionsSortTypeEnum.Specifically
                        ? fullSecondaryConditions.indexOf(a.value) - fullSecondaryConditions.indexOf(b.value)
                        : a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase());
};
export var useSynchronizedConditionReport = function (conditionType) {
    var _a, _b;
    if (conditionType === void 0) { conditionType = ConditionType.Primary; }
    var indexConfig = useHostnameIndexInfo();
    var _c = useReportsDialogValue('secondaryCormorbidities'), selectingSecondaryCormorbidities = _c[0], setSelectingSecondaryCormorbidities = _c[1];
    var lookupData = useLookupConditionSortByType()[0];
    var primaryConditions = (_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[conditionType];
    var comorbiditiesDefaultFilter = useMemo(function () { var _a; return ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter) === null || _a === void 0 ? void 0 : _a.comorbidities) || []; }, [(_b = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter) === null || _b === void 0 ? void 0 : _b.comorbidities]);
    var doSynchronize = useCallback(function () {
        var conditionsValues = primaryConditions === null || primaryConditions === void 0 ? void 0 : primaryConditions.map(function (condition) { return condition.value; });
        if (conditionType === ConditionType.Secondary) {
            var selectingComorbiditiesFilter = selectingSecondaryCormorbidities === null || selectingSecondaryCormorbidities === void 0 ? void 0 : selectingSecondaryCormorbidities.filter(function (condition) { return (conditionsValues === null || conditionsValues === void 0 ? void 0 : conditionsValues.includes(condition)) || (comorbiditiesDefaultFilter === null || comorbiditiesDefaultFilter === void 0 ? void 0 : comorbiditiesDefaultFilter.includes(condition)); });
            setSelectingSecondaryCormorbidities(selectingComorbiditiesFilter);
        }
    }, [
        conditionType,
        comorbiditiesDefaultFilter,
        primaryConditions,
        selectingSecondaryCormorbidities,
        setSelectingSecondaryCormorbidities,
    ]);
    return [doSynchronize];
};
export var useSynchronizedPrimaryCondition = function (conditionTypes) {
    var _a;
    if (conditionTypes === void 0) { conditionTypes = [ConditionType.Primary]; }
    var _b = useFilterEditingValue('primaryConditions'), selectingPrimaryConditions = _b[0], setSelectingPrimaryConditions = _b[1];
    var _c = useFilterEditingValue('additionalConditions'), selectingAdditionalConditions = _c[0], setSelectingAdditionalConditions = _c[1];
    var _d = useFilterEditingValue('primaryCoMorbidities'), selectingPrimaryCoMorbidities = _d[0], setSelectingPrimaryCoMorbidities = _d[1];
    var _e = useFilterEditingValue('comorbidities'), selectingComorbidities = _e[0], setSelectingComorbidities = _e[1];
    var _f = useFilterEditingValue('primaryConditionWithClaimsOptionals'), selectingPrimaryConditionWithoutClaimsOptionals = _f[0], setSelectingPrimaryConditionWithoutClaimsOptionals = _f[1];
    var indexConfig = useHostnameIndexInfo();
    var useMBSFChronicCondition = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.useMBSFChronicCondition;
    var lookupData = useLookupConditionSortByType()[0];
    var comorbiditiesDefaultFilter = useMemo(function () {
        var _a, _b, _c, _d;
        var primaryConditions = (_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[ConditionType.Primary];
        var primaryConditionsValues = primaryConditions === null || primaryConditions === void 0 ? void 0 : primaryConditions.map(function (condition) { return condition.value; });
        if (!useMBSFChronicCondition)
            return ((_b = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter) === null || _b === void 0 ? void 0 : _b.comorbidities) || [];
        return (_d = (_c = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter) === null || _c === void 0 ? void 0 : _c.comorbidities) === null || _d === void 0 ? void 0 : _d.filter(function (condition) { return primaryConditionsValues === null || primaryConditionsValues === void 0 ? void 0 : primaryConditionsValues.includes(condition); });
    }, [
        (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.defaultFilter) === null || _a === void 0 ? void 0 : _a.comorbidities,
        useMBSFChronicCondition,
        lookupData.groupedCondition,
    ]);
    var doSynchronize = useCallback(function () {
        var _a, _b, _c;
        if (conditionTypes.includes(ConditionType.Primary)) {
            var primaryConditions = (_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[ConditionType.Primary];
            var primaryConditionsValues_1 = primaryConditions === null || primaryConditions === void 0 ? void 0 : primaryConditions.map(function (condition) { return condition.value; });
            var selectingPrimaryConditionsFilter = selectingPrimaryConditions === null || selectingPrimaryConditions === void 0 ? void 0 : selectingPrimaryConditions.filter(function (condition) { return (primaryConditionsValues_1 === null || primaryConditionsValues_1 === void 0 ? void 0 : primaryConditionsValues_1.includes(condition)) || comorbiditiesDefaultFilter.includes(condition); });
            var selectingComorbiditiesFilter = selectingComorbidities === null || selectingComorbidities === void 0 ? void 0 : selectingComorbidities.filter(function (condition) { return (primaryConditionsValues_1 === null || primaryConditionsValues_1 === void 0 ? void 0 : primaryConditionsValues_1.includes(condition)) || comorbiditiesDefaultFilter.includes(condition); });
            var selectingPrimaryCoMorbiditiesFilter = selectingPrimaryCoMorbidities === null || selectingPrimaryCoMorbidities === void 0 ? void 0 : selectingPrimaryCoMorbidities.filter(function (condition) { return (primaryConditionsValues_1 === null || primaryConditionsValues_1 === void 0 ? void 0 : primaryConditionsValues_1.includes(condition)) || comorbiditiesDefaultFilter.includes(condition); });
            var selectingPrimaryConditionWithoutClaimsOptionalsFilter = selectingPrimaryConditionWithoutClaimsOptionals === null || selectingPrimaryConditionWithoutClaimsOptionals === void 0 ? void 0 : selectingPrimaryConditionWithoutClaimsOptionals.filter(function (condition) { return (primaryConditionsValues_1 === null || primaryConditionsValues_1 === void 0 ? void 0 : primaryConditionsValues_1.includes(condition)) || comorbiditiesDefaultFilter.includes(condition); });
            setSelectingPrimaryConditions(selectingPrimaryConditionsFilter);
            setSelectingPrimaryCoMorbidities((selectingPrimaryCoMorbiditiesFilter === null || selectingPrimaryCoMorbiditiesFilter === void 0 ? void 0 : selectingPrimaryCoMorbiditiesFilter.length) ? selectingPrimaryCoMorbiditiesFilter : comorbiditiesDefaultFilter.slice(0, 1) || []);
            setSelectingComorbidities(selectingComorbiditiesFilter);
            setSelectingPrimaryConditionWithoutClaimsOptionals(selectingPrimaryConditionWithoutClaimsOptionalsFilter);
            return;
        }
        if (conditionTypes.includes(ConditionType.Secondary)) {
            var primaryConditions = (_b = lookupData.groupedCondition) === null || _b === void 0 ? void 0 : _b[ConditionType.Secondary];
            var primaryConditionsValues_2 = primaryConditions === null || primaryConditions === void 0 ? void 0 : primaryConditions.map(function (condition) { return condition.value; });
            var selectingComorbiditiesFilter = selectingComorbidities === null || selectingComorbidities === void 0 ? void 0 : selectingComorbidities.filter(function (condition) { return (primaryConditionsValues_2 === null || primaryConditionsValues_2 === void 0 ? void 0 : primaryConditionsValues_2.includes(condition)) || (comorbiditiesDefaultFilter === null || comorbiditiesDefaultFilter === void 0 ? void 0 : comorbiditiesDefaultFilter.includes(condition)); });
            setSelectingComorbidities(selectingComorbiditiesFilter);
        }
        if (conditionTypes.includes(ConditionType.Other)) {
            var otherConditions = (_c = lookupData.groupedCondition) === null || _c === void 0 ? void 0 : _c[ConditionType.Other];
            var otherConditionsValues_1 = otherConditions === null || otherConditions === void 0 ? void 0 : otherConditions.map(function (condition) { return condition.value; });
            var selectingAdditionalConditionsFilter = selectingAdditionalConditions === null || selectingAdditionalConditions === void 0 ? void 0 : selectingAdditionalConditions.filter(function (condition) { return (otherConditionsValues_1 === null || otherConditionsValues_1 === void 0 ? void 0 : otherConditionsValues_1.includes(condition)) || (comorbiditiesDefaultFilter === null || comorbiditiesDefaultFilter === void 0 ? void 0 : comorbiditiesDefaultFilter.includes(condition)); });
            setSelectingAdditionalConditions(selectingAdditionalConditionsFilter);
        }
    }, [
        conditionTypes,
        comorbiditiesDefaultFilter,
        lookupData.groupedCondition,
        selectingPrimaryConditions,
        selectingPrimaryCoMorbidities,
        selectingComorbidities,
        selectingPrimaryConditionWithoutClaimsOptionals,
        selectingAdditionalConditions,
        setSelectingPrimaryConditions,
        setSelectingPrimaryCoMorbidities,
        setSelectingComorbidities,
        setSelectingPrimaryConditionWithoutClaimsOptionals,
        setSelectingAdditionalConditions,
    ]);
    return [doSynchronize];
};
var ConditionCMSFilter = (_a = {},
    _a[KeyCombineConditionSource.lessThan2019] = [ChronicConditionSource.CMS_27, ChronicConditionSource.CMS_OTHER],
    _a[KeyCombineConditionSource.greaterOrEqual2019] = [ChronicConditionSource.CMS_30, ChronicConditionSource.CMS_OTHER],
    _a);
/**
 * This is a Record variable, It'll save values to combine chronic condition source
 * With Greater value, We should order by DESC
 * Otherwise, We should order by ASC
 * Ex: Greater: 2016-; 2017-; 2018-;...
 *     Smaller: -2015; -2014; -2013;...
 */
var ValueCombineCondition = {
    '2019-': KeyCombineConditionSource.greaterOrEqual2019,
    '-2018': KeyCombineConditionSource.lessThan2019,
};
var getKeyCombine = function (year) {
    return ValueCombineCondition[Object.keys(ValueCombineCondition).find(function (k) {
        var splitKey = k.split('-');
        if (!Number(splitKey[0]) && Number(splitKey[1]) >= Number(year)) {
            return true;
        }
        if (!Number(splitKey[1]) && Number(splitKey[0]) <= Number(year)) {
            return true;
        }
        return false;
    }) || ''];
};
export var useLookupConditionSortByType = function (indexConfig, dataYear, cmsDataSource, dataYearSeasonParam) {
    var _a = useLookupCondition(), lookupData = _a[0], rest = _a.slice(1);
    var hostednameIndexConfig = useHostnameIndexInfo();
    var dataYearFilterEditing = useFilterEditingValue('dataYear')[0];
    var dataYearSeasonEditing = useFilterEditingValue('dataYearSeason')[0];
    indexConfig = indexConfig || hostednameIndexConfig;
    var useMBSFChronicCondition = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.useMBSFChronicCondition;
    var dataYearSeason = useMemo(function () { return dataYearSeasonParam || dataYearSeasonEditing; }, [dataYearSeasonParam, dataYearSeasonEditing]);
    var primaryConditionByDataSourceAndYear = useGeneralConfigByDataSource('conditions');
    var primaryConditionWithoutClaims = useGeneralConfigByDataSource('primaryConditionWithoutClaims', cmsDataSource, dataYearSeason, dataYear);
    var secondaryConditionsConfig = useGeneralConfigByDataSource('secondaryConditions', cmsDataSource, dataYearSeason, dataYear);
    var primaryConditionsByChronicConditionSourceConfig = useGeneralConfigByDataSource('primaryConditionsByChronicConditionSource', cmsDataSource, dataYearSeason, dataYear);
    var allConditionDefault = useMemo(function () {
        var _a, _b;
        var ccBySource = (_b = (_a = lookupData.data) === null || _a === void 0 ? void 0 : _a.filter(function (condition) { return primaryConditionsByChronicConditionSourceConfig === null || primaryConditionsByChronicConditionSourceConfig === void 0 ? void 0 : primaryConditionsByChronicConditionSourceConfig.includes(condition.chronicConditionSource); })) === null || _b === void 0 ? void 0 : _b.map(function (condition) { return condition.chronicConditionCode; });
        return _.union(indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.secondaryConditions, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.primaryConditions, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.prioritizedConditions, primaryConditionByDataSourceAndYear, primaryConditionWithoutClaims, secondaryConditionsConfig, ccBySource);
    }, [
        indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.secondaryConditions,
        indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.primaryConditions,
        indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.prioritizedConditions,
        primaryConditionByDataSourceAndYear,
        primaryConditionWithoutClaims,
        secondaryConditionsConfig,
        primaryConditionsByChronicConditionSourceConfig,
        lookupData.data,
    ]);
    var combinedData = useMemo(function () {
        var _a;
        return (_a = lookupData.data) === null || _a === void 0 ? void 0 : _a.map(function (i) {
            var _a, _b, _c;
            var isPrimaryByCCSource = primaryConditionsByChronicConditionSourceConfig === null || primaryConditionsByChronicConditionSourceConfig === void 0 ? void 0 : primaryConditionsByChronicConditionSourceConfig.some(function (template) { return i.chronicConditionSource === template; });
            var isPrimaryByPrimaryConditionWithoutClaims = primaryConditionWithoutClaims === null || primaryConditionWithoutClaims === void 0 ? void 0 : primaryConditionWithoutClaims.some(function (v) { return v === i.chronicConditionCode; });
            var isPrimaryByPrimaryConditionByDataSourceAndYear = primaryConditionByDataSourceAndYear === null || primaryConditionByDataSourceAndYear === void 0 ? void 0 : primaryConditionByDataSourceAndYear.some(function (v) { return v === i.chronicConditionCode; });
            var isPrimaryByPrimaryCondition = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.primaryConditions) === null || _a === void 0 ? void 0 : _a.some(function (v) { return v === i.chronicConditionCode; });
            var isPrimary = isPrimaryByCCSource || isPrimaryByPrimaryConditionWithoutClaims || isPrimaryByPrimaryConditionByDataSourceAndYear || isPrimaryByPrimaryCondition;
            var isSecondaryBySecondaryConditionsConfig = secondaryConditionsConfig === null || secondaryConditionsConfig === void 0 ? void 0 : secondaryConditionsConfig.some(function (v) { return v === i.chronicConditionCode; });
            var isSecondaryBySecondaryConditions = (_b = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.secondaryConditions) === null || _b === void 0 ? void 0 : _b.some(function (v) { return v === i.chronicConditionCode; });
            var isSecondary = isSecondaryBySecondaryConditionsConfig || isSecondaryBySecondaryConditions;
            var isKey = (_c = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.prioritizedConditions) === null || _c === void 0 ? void 0 : _c.some(function (v) {
                var _a = v.split('-'), from = _a[0], to = _a[1];
                return (v === i.chronicConditionCode ||
                    (parseInt(i.chronicConditionCode, 10) >= parseInt(from, 10) && parseInt(i.chronicConditionCode, 10) <= parseInt(to || from, 10)));
            });
            return {
                value: i.chronicConditionCode,
                label: i.chronicConditionName,
                type: isPrimary
                    ? ConditionType.Primary
                    : isKey
                        ? ConditionType.Key
                        : isSecondary
                            ? ConditionType.Secondary
                            : ConditionType.Other,
                source: i.chronicConditionSource,
            };
        }).filter(function (i) {
            var _a, _b, _c;
            var isIgnoreConditions = ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditions) === null || _a === void 0 ? void 0 : _a.some(function (v) {
                var _a = v.split('-'), from = _a[0], to = _a[1];
                return (v === i.value ||
                    (parseInt(i.value, 10) >= parseInt(from, 10) && parseInt(i.value, 10) <= parseInt(to || from, 10)));
            })) || ((_c = (_b = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _b === void 0 ? void 0 : _b["" + dataYear]) === null || _c === void 0 ? void 0 : _c.includes(i.value));
            return i.type !== ConditionType.Other || !isIgnoreConditions;
        }).sort(function (a, b) { return compareCondition(a, b, indexConfig, cmsDataSource, dataYear, { secondaryConditions: secondaryConditionsConfig }); });
    }, [
        lookupData.data,
        indexConfig,
        dataYear,
        cmsDataSource,
        primaryConditionByDataSourceAndYear,
        primaryConditionWithoutClaims,
        secondaryConditionsConfig,
        primaryConditionsByChronicConditionSourceConfig,
    ]);
    var combinedDataWithinReferenceYear = useMemo(function () {
        var groupedDataByReferenceYear = Object.keys(ConditionCMSFilter).reduce(function (acc, cur) {
            acc[cur] = (combinedData === null || combinedData === void 0 ? void 0 : combinedData.filter(function (c) {
                return ConditionCMSFilter[cur].includes(c.source) ||
                    (c.type === ConditionType.Primary && c.source === ChronicConditionSource.NMQF_CUSTOM) ||
                    (!useMBSFChronicCondition && allConditionDefault.includes(c.value));
            })) || [];
            return acc;
        }, {});
        var dataYearToFilter = dataYear || 0;
        if (dataYearSeason === null || dataYearSeason === void 0 ? void 0 : dataYearSeason.length)
            dataYearToFilter = dataYearSeason[0];
        if (dataYearFilterEditing)
            dataYearToFilter = dataYearFilterEditing;
        return groupedDataByReferenceYear[getKeyCombine(dataYearToFilter)];
    }, [
        dataYear,
        dataYearSeason,
        dataYearFilterEditing,
        combinedData,
        useMBSFChronicCondition,
        allConditionDefault,
    ]);
    var _b = useMemo(function () {
        return (combinedDataWithinReferenceYear || []).reduce(function (acc, curr) {
            acc.mapByValue[curr.value] = curr;
            if (!acc.groupedCondition[curr.type]) {
                acc.groupedCondition[curr.type] = Object.assign([]);
            }
            acc.groupedCondition[curr.type].push(curr);
            return acc;
        }, Object.assign({ mapByValue: {}, groupedCondition: {} }));
    }, [combinedDataWithinReferenceYear]), mapByValue = _b.mapByValue, groupedCondition = _b.groupedCondition;
    var sortValue = useCallback(function (a, b) { return compareCondition(mapByValue[a], mapByValue[b], indexConfig); }, [indexConfig, mapByValue]);
    return __spreadArrays([__assign(__assign({}, lookupData), { groupedCondition: groupedCondition, sortValue: sortValue })], rest);
};
