var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Label, FormGroup, Input, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { useAPI } from '../state';
import { isEmpty, getDirectionUrl } from '../utils';
import { useForm, useMount } from '../hooks';
import { MessageBox, Button, PASSWORD_PATTERN, PASSWORD_RULE_MSG, PASSWORD_DESCRIPTION } from '../common';
var LinkWrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: inherit;\n  :hover {\n    color: inherit;\n    text-decoration: none;\n  }\n"], ["\n  color: inherit;\n  :hover {\n    color: inherit;\n    text-decoration: none;\n  }\n"])));
var H5Style = styled.h5(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center !important;\n"], ["\n  text-align: center !important;\n"])));
var ConfirmText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 1.5rem !important;\n"], ["\n  margin-bottom: 1.5rem !important;\n"])));
var Description = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: -0.5rem 0 0.75rem;\n  font-size: 80%;\n"], ["\n  margin: -0.5rem 0 0.75rem;\n  font-size: 80%;\n"])));
var ButtonGroup = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: right !important;\n"], ["\n  text-align: right !important;\n"])));
var ConfirmForgotPasswordContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 450px;\n  border: 1px solid #dee2e6;\n  border-radius: 4px;\n  padding: 30px;\n  margin: auto;\n"], ["\n  width: 450px;\n  border: 1px solid #dee2e6;\n  border-radius: 4px;\n  padding: 30px;\n  margin: auto;\n"])));
var validationSchema = Yup.lazy(function (values) {
    return Yup.object().shape({
        verificationCode: Yup.string().required('Required'),
        password: Yup.string().matches(PASSWORD_PATTERN, PASSWORD_RULE_MSG).required('Required'),
        passwordConfirmation: Yup.lazy(function (value) {
            return isEmpty(value) ? Yup.string().required('Required') : Yup.string().oneOf([values.password], 'Passwords do not match');
        }),
    });
});
export var ConfirmForgotPassword = function (props) {
    var _a = useState(false), redirectToHomeURL = _a[0], setRedirectToHomeURL = _a[1];
    var _b = useAPI('/api/auth/confirm-forgot-password', undefined, 'POST'), confirmForgotPasswordData = _b[0], confirmForgotPassword = _b[1], resetStore = _b[2];
    var _c = useForm({ validationSchema: validationSchema }), setInitialValues = _c.setInitialValues, values = _c.values, handleChange = _c.handleChange, handleBlur = _c.handleBlur, handleSubmit = _c.handleSubmit, isSubmitting = _c.isSubmitting, getError = _c.getError;
    useMount(function () {
        setInitialValues({
            password: '',
            passwordConfirmation: '',
            verificationCode: '',
        });
        return resetStore;
    });
    useEffect(function () {
        if (confirmForgotPasswordData.requesting === false && !confirmForgotPasswordData.error) {
            setRedirectToHomeURL(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmForgotPasswordData.requesting]);
    var onSubmit = handleSubmit(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, confirmForgotPassword(__assign(__assign({}, payload), { username: props.username }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    return (React.createElement(ConfirmForgotPasswordContent, { hidden: !props.username },
        React.createElement(H5Style, null, "Forgot Password"),
        React.createElement(ConfirmText, null, "A confirmation code was sent to your email account"),
        React.createElement(MessageBox, { error: confirmForgotPasswordData.error }),
        React.createElement(Form, { onSubmit: onSubmit },
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Enter new password"),
                React.createElement(Description, null, PASSWORD_DESCRIPTION),
                React.createElement(Input, { type: "password", name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('password') }),
                React.createElement(FormFeedback, null, getError('password'))),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Re-type new password"),
                React.createElement(Input, { type: "password", name: "passwordConfirmation", value: values.passwordConfirmation, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('passwordConfirmation') }),
                React.createElement(FormFeedback, null, getError('passwordConfirmation'))),
            React.createElement(FormGroup, null,
                React.createElement(Label, null, "Enter confirmation code"),
                React.createElement(Input, { type: "text", name: "verificationCode", value: values.verificationCode, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('verificationCode') }),
                React.createElement(FormFeedback, null, getError('verificationCode'))),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { type: "button", disabled: isSubmitting },
                    React.createElement(LinkWrapper, { to: getDirectionUrl('/') }, "Cancel")),
                ' ',
                React.createElement(Button, { color: "primary", type: "submit", loading: isSubmitting }, "Change Password"),
                redirectToHomeURL && React.createElement(Redirect, { to: getDirectionUrl('/') })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
