/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo, useCallback } from 'react';
import { useMount } from '../hooks';
import { useCachedData } from '../state';
var LOOKUP_STATE_API = '/api/general/lookup/get-list-state';
export var useLookupState = function (valueIsAbb) {
    var _a;
    var _b = useCachedData(LOOKUP_STATE_API), lookupStateData = _b[0], res = _b.slice(1);
    var data = useMemo(function () { var _a; return (_a = lookupStateData.data) === null || _a === void 0 ? void 0 : _a.data.sort(function (a, b) { return a.stateName.toLowerCase().localeCompare(b.stateName.toLowerCase()); }).map(function (i) { return (__assign(__assign({}, i), { value: valueIsAbb ? i.stateAbbreviation : i.stateCode, label: i.stateName })); }); }, [(_a = lookupStateData.data) === null || _a === void 0 ? void 0 : _a.data, valueIsAbb]);
    var getStateByStateCode = useCallback(function (stateCode) { return data === null || data === void 0 ? void 0 : data.find(function (i) { return parseInt(i.stateCode, 10) === parseInt(stateCode, 10); }); }, [data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var getNameByStateCode = useCallback(function (stateCode) { var _a; return ((_a = getStateByStateCode(stateCode)) === null || _a === void 0 ? void 0 : _a.stateName) || stateCode; }, [data]);
    var getNameByStateAbb = useCallback(function (stateAbb) { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.find(function (i) { return i.stateAbbreviation === stateAbb; })) === null || _a === void 0 ? void 0 : _a.stateName) || stateAbb; }, [data]);
    return __spreadArrays([__assign(__assign({}, lookupStateData), { data: data, getStateByStateCode: getStateByStateCode, getNameByStateCode: getNameByStateCode, getNameByStateAbb: getNameByStateAbb })], res);
};
export var LookupStateComponent = function () {
    var _a = useLookupState(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
