/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo, useCallback } from 'react';
import { useFilterSetting, useFilterValue, useViewValue, exportFilterSettingPayload } from '../state';
import { ViewModeEnum, ChartViewEnum, StatisticTypeEnum, GeoLevelEnum } from '../enums';
import { useAPI } from '../state';
import { getStatisticOption, processStatisticValue } from '../lookupdata';
import { extractHostname, useHostnameIndexInfo } from '../hostnameindexinfo';
import { getYearsBySeason } from '../utils';
import { DELIMITER_SEASON_YEAR } from '../common';
var SAFE_SEASON_YEAR = 100;
var FETCH_AGGREGATED_DATA_API_URL = '/api/data-file/get-aggregated-data-for-yearly-linechart';
var FETCH_YEARLY_DATA_API_URL = '/api/stats/{hostname}/calculate-statistic-for-yearly-linechart';
var FETCH_YEARLY_DATA_API_URL_PROXY = '/api/{hostname}/calculate-statistic-for-yearly-linechart';
var getLinear = function (arr, xKey, yKey) {
    if (!arr.length)
        return [];
    var xStart = arr[0][xKey];
    var xEnd = arr[arr.length - 1][xKey];
    var n = xEnd - xStart + 1;
    var sumMult = 0;
    var xSum = 0;
    var ySum = 0;
    var sumXSq = 0;
    arr.forEach(function (i) {
        var x = i[xKey];
        var y = i[yKey];
        sumMult += y * x;
        xSum += x;
        ySum += y;
        sumXSq += x * x;
    });
    var x1 = n * sumMult;
    var x2 = xSum * ySum;
    var y1 = n * sumXSq;
    var y2 = xSum * xSum;
    // slope = m = (a - b) / (c - d)
    var m = (x1 - x2) / (y1 - y2);
    var e = ySum;
    var f = m * xSum;
    // y-intercept = b = (e - f) / n
    var b = (e - f) / n;
    // y = mx + b
    return [m * xStart + b, m * xEnd + b];
};
var useStatisticYearlyData = function () {
    var _a;
    var indexConfig = useHostnameIndexInfo();
    var _b = useAPI((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.calculateStatisticAttachHostname) ? FETCH_YEARLY_DATA_API_URL_PROXY
        : FETCH_YEARLY_DATA_API_URL, { hostname: extractHostname() }, 'POST'), yearlyData = _b[0], res = _b.slice(1);
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticOption = getStatisticOption(statistic);
    var getDataByYear = useCallback(function (year) {
        var _a;
        var _b = ((_a = yearlyData.data) === null || _a === void 0 ? void 0 : _a.mapStatisticResponsingData[year]) || {}, numerator = _b.numerator, denominator = _b.denominator;
        var value = numerator || 0;
        var isNA = !numerator;
        if (!isNA && statistic !== StatisticTypeEnum.TotalCount) {
            isNA = !denominator;
            value = isNA ? 0 : value / (denominator || 1);
        }
        return {
            value: value,
            isNA: isNA,
            percentage: statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.percentage,
        };
    }, [statistic, statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.percentage, (_a = yearlyData.data) === null || _a === void 0 ? void 0 : _a.mapStatisticResponsingData]);
    return __spreadArrays([__assign(__assign({}, yearlyData), { getDataByYear: getDataByYear })], res);
};
var useAggregatedYearlyData = function () {
    var _a = useAPI(FETCH_AGGREGATED_DATA_API_URL, undefined, 'POST'), yearlyData = _a[0], res = _a.slice(1);
    var getDataByYear = useCallback(function (year) {
        var _a;
        var _b = ((_a = yearlyData.data) === null || _a === void 0 ? void 0 : _a.mapStatisticResponsingData[year]) || {}, percentage = _b.percentage, overall = _b.overall;
        var isNA = overall === undefined || overall === null || overall < 0;
        var value = (isNA ? 0 : overall);
        return {
            value: value,
            isNA: isNA,
            percentage: percentage,
        };
    }, [yearlyData.data]);
    return __spreadArrays([__assign(__assign({}, yearlyData), { getDataByYear: getDataByYear })], res);
};
export var useTrendLineData = function () {
    var _a;
    var statisticYearlyData = useStatisticYearlyData();
    var aggregatedYearlyData = useAggregatedYearlyData();
    var filterSettings = useFilterSetting();
    var _b = filterSettings.isAggregatedDataSource ? aggregatedYearlyData : statisticYearlyData, data = _b[0], fetchData = _b[1], res = _b.slice(2);
    var statistic = (_a = filterSettings.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var statisticOption = getStatisticOption(statistic);
    var from = useViewValue('chartView', 'startingYear')[0];
    var to = useViewValue('chartView', 'endingYear')[0];
    var fetchDataWrapper = useCallback(function () {
        if (filterSettings.forceUpdate) {
            var payload = exportFilterSettingPayload(filterSettings);
            fetchData(__assign(__assign({}, payload), { displayOption: {
                    displayOptionType: ViewModeEnum.Chart,
                    mapView: { type: GeoLevelEnum.Zip5 },
                    chartView: {
                        type: ChartViewEnum.Trends,
                    },
                } }));
        }
    }, [fetchData, filterSettings]);
    var combinedData = useMemo(function () {
        var result = [];
        if (from && to) {
            for (var year = from; year <= to; year += 1) {
                var yearData = data.getDataByYear(year);
                var value = processStatisticValue(yearData.value, yearData.percentage, statistic) || 0;
                result.push(__assign(__assign({}, yearData), { year: year,
                    value: value, suffix: yearData.percentage ? '%' : '', prefix: statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit }));
            }
            var linear = getLinear(result, 'year', 'value');
            result[0].linear = linear[0];
            result[result.length - 1].linear = linear[1];
        }
        return result;
    }, [from, to, data, statistic, statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit]);
    return __spreadArrays([__assign(__assign({}, data), { data: combinedData }), fetchDataWrapper], res);
};
export var useTrendLineDataForSeason = function () {
    var _a;
    var statisticYearlyData = useStatisticYearlyData();
    var filterSettings = useFilterSetting();
    var statistic = (_a = filterSettings.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var statisticOption = getStatisticOption(statistic);
    var aggregatedYearlyData = useAggregatedYearlyData();
    var _b = filterSettings.isAggregatedDataSource ? aggregatedYearlyData : statisticYearlyData, data = _b[0], fetchData = _b[1], res = _b.slice(2);
    var fromSeasonYear = useViewValue('chartView', 'startingSeasonYear')[0];
    var toSeasonYear = useViewValue('chartView', 'endingSeasonYear')[0];
    var fetchDataWrapper = useCallback(function () {
        if (filterSettings.forceUpdate) {
            var payload = exportFilterSettingPayload(filterSettings);
            fetchData(__assign(__assign({}, payload), { displayOption: {
                    displayOptionType: ViewModeEnum.Chart,
                    mapView: { type: GeoLevelEnum.Zip5 },
                    chartView: {
                        type: ChartViewEnum.Trends,
                    },
                } }));
        }
    }, [fetchData, filterSettings]);
    var combinedData = useMemo(function () {
        var result = [];
        if (fromSeasonYear && toSeasonYear) {
            var year = fromSeasonYear;
            var safeNumber = 0;
            while (!isEqualSeasonYear(year, putToSeasonYear(toSeasonYear, 1)) && safeNumber < SAFE_SEASON_YEAR) {
                var yearData = data.getDataByYear(year);
                var value = processStatisticValue(yearData.value, yearData.percentage, statistic) || 0;
                result.push(__assign(__assign({}, yearData), { year: 0, seasonYear: year, value: value, suffix: yearData.percentage ? '%' : '', prefix: statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit }));
                year = putToSeasonYear(year, 1);
                safeNumber++;
            }
            var linear = getLinear(result, 'seasonYear', 'value');
            result[0].linear = linear[0];
            result[result.length - 1].linear = linear[1];
        }
        return result;
    }, [fromSeasonYear, toSeasonYear, data, statistic, statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.preUnit]);
    return __spreadArrays([__assign(__assign({}, data), { data: combinedData }), fetchDataWrapper], res);
};
var isEqualSeasonYear = function (arg1, arg2) {
    var arg1Split = getYearsBySeason(arg1);
    var arg2Split = getYearsBySeason(arg2);
    return arg1Split[0] === arg2Split[0] && arg1Split[1] === arg2Split[1];
};
var putToSeasonYear = function (seasonYear, year) {
    var arg1Split = getYearsBySeason(seasonYear);
    return arg1Split.map(function (arg) { return Number(arg) + year; }).join(DELIMITER_SEASON_YEAR);
};
