var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useViewValue } from '../../state';
import { IconButton } from '../../common';
import { SexChart, RaceChart, AgeChart } from './demographicchart';
import { StyledMapSidebar } from '../sidebar';
import { RightPanelTypeEnum } from '../../enums';
import { useProviderDetail } from '../../selectors';
var StyledProviderDetail = styled(StyledMapSidebar)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PanelHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.25rem;\n"], ["\n  font-weight: 500;\n  font-size: 1.25rem;\n"])));
var ProviderName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.125rem;\n"], ["\n  font-weight: 500;\n  font-size: 1.125rem;\n"])));
var ProviderInfo = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var ProviderAddress = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #6c757d;\n"], ["\n  color: #6c757d;\n"])));
var PatientDemographics = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var ProviderDetail = function () {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var id = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.ProviderDetail ? selectedRegion.id : undefined;
    var selectedProviderInfo = useProviderDetail(id);
    var clearSelectedProviderInfo = function () {
        setSelectedRegion();
    };
    if (!selectedProviderInfo)
        return null;
    return (React.createElement(StyledProviderDetail, null,
        React.createElement(PanelHeader, null,
            React.createElement(Title, null, "Provider Information"),
            React.createElement(IconButton, { className: "ml-auto", onClick: clearSelectedProviderInfo, icon: faTimesCircle, color: "warning", size: "2x" })),
        React.createElement(ProviderInfo, null,
            React.createElement("div", { className: "font-weight-bold" },
                selectedProviderInfo.providerFirstName,
                " ",
                selectedProviderInfo.providerLastName,
                " ",
                selectedProviderInfo.providerCredentialText),
            React.createElement(ProviderName, null, selectedProviderInfo.name),
            React.createElement(ProviderAddress, null,
                selectedProviderInfo.address1,
                selectedProviderInfo.address2 && (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    selectedProviderInfo.address2)),
                React.createElement("br", null),
                selectedProviderInfo.city,
                ", ",
                selectedProviderInfo.state,
                " ",
                selectedProviderInfo.zip)),
        React.createElement(PatientDemographics, null,
            React.createElement("div", { className: "font-weight-bold" }, "Patient Demographics"),
            selectedProviderInfo.sex && React.createElement(SexChart, { data: selectedProviderInfo.sex }),
            selectedProviderInfo.age && React.createElement(AgeChart, { data: selectedProviderInfo.age }),
            selectedProviderInfo.race && React.createElement(RaceChart, { data: selectedProviderInfo.race }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
