var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { DataSourceEnum } from '../enums';
import { useFilterSetting, useViewValue } from '../state';
export var useProviderSetting = function (edittingFilterSetting) {
    var _a, _b;
    var _c = useViewValue('provider'), providerSetting = _c[0], setProviderSetting = _c[1];
    var filterSetting = useFilterSetting();
    var checkingSetting = edittingFilterSetting || filterSetting;
    var disabled = !checkingSetting.available || checkingSetting.isAggregatedDataSource || ((_a = checkingSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource) === DataSourceEnum.Medicaid;
    var available = !disabled && ((providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetPrimaryCareProvider) || (providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.doGetHospitalProvider) || !!((_b = providerSetting === null || providerSetting === void 0 ? void 0 : providerSetting.specialistProviders) === null || _b === void 0 ? void 0 : _b.length));
    return [__assign(__assign({}, providerSetting), { disabled: disabled, available: available }), setProviderSetting];
};
