/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { Map } from 'immutable';
export var RESET_FILTER_EDITING = 'RESET_FILTER_EDITING';
export var SET_FILTER_EDITING_VALUE = 'SET_FILTER_EDITING_VALUE';
// Init state
var initialState = Map();
// Init action handlers
var actionHandlers = {};
actionHandlers[RESET_FILTER_EDITING] = function (s, a) { return Map(a.payload); };
actionHandlers[SET_FILTER_EDITING_VALUE] = function (s, a) { return (a.payload.value ? s.setIn(a.payload.keyPath, a.payload.value) : s.removeIn(a.payload.keyPath)); };
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    // Get appropriate action handler by type
    var fn = actionHandlers[action.type];
    // Check if handler is existed
    // Then call the handler
    // Otherwise return state
    return fn ? fn(state, action) : state;
}
;
