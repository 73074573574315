/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { TabPane, FormGroup, Label } from 'reactstrap';
import { CheckboxGroup } from '../common';
import { GROUPED_STATISTIC_OPTIONS } from '../lookupdata';
import { useReportSetting, useReportsDialogValue } from './reportsdialogprovider';
import { useGeneralConfigByDataSource, useMount } from '../hooks';
import { DATA_SOURCES } from '../configs';
export var StatisticReportsDialogTab = function (props) {
    var filterReports = useReportSetting()[0];
    var _a = useReportsDialogValue('statistics'), statistics = _a[0], setStatistics = _a[1];
    var cmsDataSource = filterReports.dataSourceType;
    var availableStatisticsConfig = useGeneralConfigByDataSource('availableStatistic');
    var availableStatistics = availableStatisticsConfig || DATA_SOURCES[cmsDataSource].availableStatistic;
    var isOptionDisabled = function (option) {
        return !!availableStatistics && !(availableStatistics === null || availableStatistics === void 0 ? void 0 : availableStatistics.some(function (i) { return i === option.value; }));
    };
    // Filter statistics which not available
    useMount(function () {
        if (Array.isArray(availableStatistics) && availableStatistics.length) {
            var statisticFilterAvalable = statistics.filter(function (s) { return availableStatistics.includes(s); });
            setStatistics(statisticFilterAvalable);
        }
    });
    return (React.createElement(TabPane, { tabId: props.tabId }, GROUPED_STATISTIC_OPTIONS.map(function (group) { return (React.createElement(FormGroup, { tag: "fieldset", key: group.value },
        React.createElement(Label, { className: "font-weight-bold" }, group.label),
        React.createElement(CheckboxGroup, { options: group.options, noSort: true, noAll: true, value: statistics, onChange: setStatistics, disabled: isOptionDisabled }))); })));
};
StatisticReportsDialogTab.defaultProps = {
    tabId: 'StatisticTab',
    tabName: 'Statistics',
    tabField: ['statistics'],
};
