var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import { ClinicalTrialOptionEnum, ClinicalTrialStatusEnum } from '../enums';
import { useFilterSetting, useViewValue } from '../state';
import { DropdownItemCheck, DropdownItemCheckList, DropdownToggleIcon } from '../common';
import { useMount } from '../hooks';
var CLINICALTRIALS_GOV_OPTIONS = [
    {
        value: ClinicalTrialOptionEnum.TrialsRelated,
        label: 'Show trials related to currently selected conditions',
    },
    {
        value: ClinicalTrialOptionEnum.AllTrials,
        label: 'Show all trials',
    },
];
var CLINICAL_TRIAL_STATUS_DATA = [
    {
        value: ClinicalTrialStatusEnum.NotYetRecruiting,
        label: 'Not yet recruiting',
    },
    {
        value: ClinicalTrialStatusEnum.Recruiting,
        label: 'Recruiting',
    },
    {
        value: ClinicalTrialStatusEnum.EnrollingByInvitation,
        label: 'Enrolling by invitation',
    },
    {
        value: ClinicalTrialStatusEnum.ActiveNotRecruiting,
        label: 'Active not recruiting',
    },
];
var ALL_CLINICAL_TRIAL_STATUS = CLINICAL_TRIAL_STATUS_DATA === null || CLINICAL_TRIAL_STATUS_DATA === void 0 ? void 0 : CLINICAL_TRIAL_STATUS_DATA.map(function (i) { return i.value; });
var StyledSubMenuClinicalTrialsItem = styled(UncontrolledDropdown)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  display: flex;\n  padding-right: 0;\n  align-items: center;\n"])));
var StyledSubMenuClinicalTrialsStatusItem = styled(UncontrolledDropdown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"], ["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"])));
var StyledClinicalTrialOption = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-height: 15.75rem;\n  overflow: auto;\n"], ["\n  max-height: 15.75rem;\n  overflow: auto;\n"])));
export var ClinicalTrialMenu = function () {
    var _a = useViewValue('clinicalTrial', 'clinicalTrialOption'), clinicalTrialOption = _a[0], setClinicalTrialOption = _a[1];
    var _b = useViewValue('clinicalTrialStatus'), clinicalTrialStatus = _b[0], setClinicalTrialStatus = _b[1];
    var _c = useState(), filterRecruitmentStatuses = _c[0], setFilterRecruitmentStatuses = _c[1];
    var _d = useViewValue('showMapLayerMenu'), setShowMapLayerMenu = _d[1];
    var filterSetting = useFilterSetting();
    var disabled = !filterSetting.available;
    var clinicalTrialStatusRef = useRef();
    useEffect(function () {
        clinicalTrialStatusRef.current = {
            filterRecruitmentStatuses: filterRecruitmentStatuses,
        };
    }, [filterRecruitmentStatuses]);
    useMount(function () { return function () {
        setClinicalTrialStatus(__assign(__assign({}, clinicalTrialStatus), clinicalTrialStatusRef.current));
    }; });
    useEffect(function () {
        if (!clinicalTrialOption) {
            setFilterRecruitmentStatuses(ALL_CLINICAL_TRIAL_STATUS);
        }
        else {
            setFilterRecruitmentStatuses(clinicalTrialStatus === null || clinicalTrialStatus === void 0 ? void 0 : clinicalTrialStatus.filterRecruitmentStatuses);
        }
    }, [clinicalTrialOption, clinicalTrialStatus === null || clinicalTrialStatus === void 0 ? void 0 : clinicalTrialStatus.filterRecruitmentStatuses]);
    var handleClickClinicalTrialItem = function () {
        setClinicalTrialOption(!clinicalTrialOption ? ClinicalTrialOptionEnum.TrialsRelated : undefined);
    };
    var handleClickClinicalTrialStatus = function () {
        setFilterRecruitmentStatuses((filterRecruitmentStatuses === null || filterRecruitmentStatuses === void 0 ? void 0 : filterRecruitmentStatuses.length) ? [] : ALL_CLINICAL_TRIAL_STATUS);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItemCheck, { disabled: disabled, icon: "notes-medical", tag: StyledSubMenuClinicalTrialsItem, direction: "right", checked: !!clinicalTrialOption, onChange: handleClickClinicalTrialItem },
            React.createElement("span", { className: "flex-grow-1" }, "ClinicalTrials.gov"),
            React.createElement(DropdownToggleIcon, { disabled: disabled, onClick: function (event) { return event.stopPropagation(); }, caret: true, color: "warning", className: "py-0" }),
            React.createElement(DropdownMenu, { onClick: function (event) { return event.stopPropagation(); } },
                React.createElement("div", null,
                    React.createElement(DropdownItemCheck, { tag: StyledSubMenuClinicalTrialsItem, direction: "right", checked: !!(filterRecruitmentStatuses === null || filterRecruitmentStatuses === void 0 ? void 0 : filterRecruitmentStatuses.length), onChange: handleClickClinicalTrialStatus, partial: (filterRecruitmentStatuses === null || filterRecruitmentStatuses === void 0 ? void 0 : filterRecruitmentStatuses.length) !== (CLINICAL_TRIAL_STATUS_DATA === null || CLINICAL_TRIAL_STATUS_DATA === void 0 ? void 0 : CLINICAL_TRIAL_STATUS_DATA.length) },
                        React.createElement("span", { className: "flex-grow-1" }, "Clinical Trial Status"),
                        React.createElement(DropdownToggleIcon, { onClick: function (event) { return event.stopPropagation(); }, caret: true, color: "warning", className: "py-0" }),
                        React.createElement(DropdownMenu, { onClick: function (event) { return event.stopPropagation(); } },
                            React.createElement(StyledClinicalTrialOption, null, CLINICAL_TRIAL_STATUS_DATA.map(function (item) { return (React.createElement(DropdownItemCheckList, { values: filterRecruitmentStatuses, onChange: setFilterRecruitmentStatuses, value: item.value, key: item.value, tag: StyledSubMenuClinicalTrialsStatusItem, direction: "right", toggle: false, label: item.label })); })))),
                    CLINICALTRIALS_GOV_OPTIONS.map(function (item) {
                        var checked = item.value === clinicalTrialOption;
                        var handleChange = function (value) {
                            setClinicalTrialOption(checked ? undefined : value);
                            setShowMapLayerMenu();
                        };
                        return React.createElement(DropdownItemCheck, __assign({}, item, { key: item.value, checked: checked, onChange: handleChange }));
                    }))))));
};
var templateObject_1, templateObject_2, templateObject_3;
