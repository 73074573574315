export var stringifyAgeValue = function (_a) {
    var minAge = _a.minAge, maxAge = _a.maxAge;
    return (minAge !== null && minAge !== void 0 ? minAge : '') + "-" + (maxAge !== null && maxAge !== void 0 ? maxAge : '');
};
export var getAgeLabel = function (_a) {
    var _b = _a === void 0 ? {} : _a, minAge = _b.minAge, maxAge = _b.maxAge;
    return minAge !== undefined && maxAge !== undefined
        ? minAge + " to " + maxAge
        : maxAge !== undefined
            ? "Under " + (maxAge + 1)
            : minAge !== undefined
                ? minAge ? "Over " + (minAge - 1) : minAge + "+"
                : 'N/A';
};
var transformAgeOption = function (value) { return ({
    value: value,
    stringified: stringifyAgeValue(value),
    label: getAgeLabel(value),
}); };
export var AGE_OPTIONS = [18, 49, 64, 74, 80, 81].map(function (age, idx, arr) {
    var minAge = !idx ? undefined : idx === arr.length - 1 ? age : arr[idx - 1] + 1;
    var maxAge = idx === arr.length - 1 ? undefined : age;
    var value = { maxAge: maxAge, minAge: minAge };
    return transformAgeOption(value);
});
export var ALL_AGE_VALUES = AGE_OPTIONS.map(function (i) { return i.value; });
export var mapAgeValues = function (values) {
    return (values === null || values === void 0 ? void 0 : values.length) ? values[0].isCustom
        ? [transformAgeOption(values[0])]
        : AGE_OPTIONS.filter(function (i) { return values.some(function (v) { return stringifyAgeValue(v) === i.stringified; }); })
        : undefined;
};
