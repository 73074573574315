var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import bbox from '@turf/bbox';
import intersect from '@turf/intersect';
import { useViewSetting, useFilterSetting, useFilterValue } from '../state';
import { ViewModeEnum, ChartViewEnum, DemographicTypeEnum } from '../enums';
import { DATA_SOURCES } from '../configs';
import { useLookupCondition, getStatisticShortName, useLookupSexCode, useLookupRaceCode, useLookupZipCodeDistrict, useCMSDataYear, useLookupState, AGE_OPTIONS, getAgeLabel, stringifyAgeValue, } from '../lookupdata';
import { useSelectedGeoIdInfo } from '../map/geoselectedinfo';
import { useMapData } from '../map/patientchoroplethlayer/usemapdata';
import { useMapRef, useMapValue, useSelectedGeoJson } from '../map';
import { useChartData } from '../demographicchart/usechartdata';
import { useTrendLineData } from '../trendline/usetrendlinedata';
import { getDisplayText } from '../utils';
import { useExtractTableData } from '../tableview';
export var exportCSV = function (data, fileName) {
    var processCell = function (cell) { return (cell === null ? '' : "\"" + cell.toString().replace(/"/g, '""') + "\""); };
    var processRow = function (row) { return row.map(processCell).join(','); };
    var csvFile = data.map(processRow).join('\n');
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    var encodedUri = URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = encodedUri;
    link.download = fileName + ".csv";
    link.click();
};
export var useFileName = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var filterSettings = useFilterSetting();
    var viewSettings = useViewSetting();
    var lookupCondition = useLookupCondition(filterSettings.comorbidities)[0];
    var csvNameArr = [];
    var selectedGeoInfo = useSelectedGeoIdInfo(viewSettings);
    if (viewSettings.viewMode !== ViewModeEnum.Chart || ((_a = viewSettings.chartView) === null || _a === void 0 ? void 0 : _a.type) !== ChartViewEnum.Trends) {
        csvNameArr.push(filterSettings.dataYear);
    }
    var dataSource = (_b = filterSettings.statistic) === null || _b === void 0 ? void 0 : _b.cmsDataSource;
    csvNameArr.push(filterSettings.isAggregatedDataSource ? filterSettings.dataSourceName : dataSource && DATA_SOURCES[dataSource].abbreviation);
    if (!filterSettings.isAggregatedDataSource) {
        csvNameArr.push((_c = lookupCondition.labeledValues) === null || _c === void 0 ? void 0 : _c.slice(0, 2).map(function (option) { return option.chronicConditionName; }).join(' '));
    }
    csvNameArr.push(getStatisticShortName((_d = filterSettings.statistic) === null || _d === void 0 ? void 0 : _d.statisticOption));
    if (viewSettings.viewMode === ViewModeEnum.Map || viewSettings.viewMode === ViewModeEnum.Table) {
        csvNameArr.push(selectedGeoInfo.join('-'));
    }
    else if (((_e = viewSettings.chartView) === null || _e === void 0 ? void 0 : _e.type) === ChartViewEnum.Demographic) {
        csvNameArr.push('Demographics');
        csvNameArr.push((_f = viewSettings.chartView.demographics) === null || _f === void 0 ? void 0 : _f.join(''));
    }
    else {
        csvNameArr.push('Trends');
        csvNameArr.push(((_g = viewSettings.chartView) === null || _g === void 0 ? void 0 : _g.startingYear) + "-" + ((_h = viewSettings.chartView) === null || _h === void 0 ? void 0 : _h.endingYear));
    }
    csvNameArr.push(new Date().toISOString().slice(0, 10).replace(/-/g, ''));
    return csvNameArr.map(function (partName) { return ("" + partName).toLowerCase().replace(/[ /]/g, ''); }).join('_');
};
var useExportGeoData = function (viewMode) {
    var _a;
    var mapData = useMapData()[0];
    var geoJsonData = useSelectedGeoJson()[0];
    var lookupState = useLookupState()[0];
    var lookupGeo = useLookupZipCodeDistrict()[0];
    var extractTableData = useExtractTableData();
    var mapRef = useMapRef();
    var editorRef = useMapValue('editorRef')[0];
    var disabled = mapData.requesting || !!mapData.error || !((_a = geoJsonData.data) === null || _a === void 0 ? void 0 : _a.features.length) || !lookupState.data || !lookupGeo.data;
    var getExportData = function () {
        var _a, _b;
        var intersectionIds;
        var poly = (_a = editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) === null || _a === void 0 ? void 0 : _a.getFeatures()[0];
        if (viewMode === ViewModeEnum.Map && poly) {
            try {
                var _c = bbox(poly), minLng = _c[0], minLat = _c[1], maxLng = _c[2], maxLat = _c[3];
                var map = (_b = mapRef.current) === null || _b === void 0 ? void 0 : _b.getMap();
                var selectedFeatures = map === null || map === void 0 ? void 0 : map.queryRenderedFeatures([map.project([minLng, minLat]), map.project([maxLng, maxLat])], {
                    layers: ['patientchoroplethlayer'],
                });
                intersectionIds = selectedFeatures === null || selectedFeatures === void 0 ? void 0 : selectedFeatures.reduce(function (acc, _a) {
                    var _b;
                    var properties = _a.properties;
                    var id = properties.id;
                    var feature = (_b = geoJsonData.mapFeature) === null || _b === void 0 ? void 0 : _b[id];
                    if (feature && intersect(poly, feature)) {
                        acc[id] = id;
                    }
                    return acc;
                }, Object.assign({}));
            }
            catch (e) {
                console.error(e);
            }
        }
        var _d = extractTableData(intersectionIds), columns = _d[0], data = _d[1];
        var header = columns.map(function (col) { return col.text; });
        var rows = data.map(function (row) { return columns.map(function (col) { return row[col.dataField].displayText; }); });
        return __spreadArrays([header], rows);
    };
    return [disabled, getExportData];
};
var useExportDemographicChartData = function () {
    var _a, _b, _c;
    var demographicChartData = useChartData()[0];
    var lookupSex = useLookupSexCode()[0];
    var filterSettings = useFilterSetting();
    var lookupRace = useLookupRaceCode(filterSettings.raceMethodology)[0];
    var viewSettings = useViewSetting();
    var customAge = ((_c = (_b = (_a = filterSettings.demographics) === null || _a === void 0 ? void 0 : _a.ages) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.isCustom) ? filterSettings.demographics.ages[0] : undefined;
    var disabled = demographicChartData.requesting || !!demographicChartData.error || !lookupSex.data || !lookupRace.data;
    var getExportData = function () {
        var _a, _b;
        var header = [];
        var row = [];
        (_b = (_a = viewSettings.chartView) === null || _a === void 0 ? void 0 : _a.demographics) === null || _b === void 0 ? void 0 : _b.forEach(function (type) {
            var _a;
            var data = (_a = demographicChartData.data) === null || _a === void 0 ? void 0 : _a[type][0].data;
            if (filterSettings.isAggregatedDataSource) {
                data === null || data === void 0 ? void 0 : data.forEach(function (item) {
                    header.push(item.name);
                    row.push(getDisplayText(item.value, item.suffix, item.prefix));
                });
            }
            else {
                var targetLookup = type === DemographicTypeEnum.Sex
                    ? lookupSex.data
                    : type === DemographicTypeEnum.Race
                        ? lookupRace.data
                        : __spreadArrays(AGE_OPTIONS, [{ value: customAge && stringifyAgeValue(customAge), label: "Custom " + getAgeLabel(customAge) }]);
                targetLookup === null || targetLookup === void 0 ? void 0 : targetLookup.forEach(function (option) {
                    header.push(option.label);
                    var item = data === null || data === void 0 ? void 0 : data.find(function (_a) {
                        var id = _a.id;
                        return id === (option.stringified || option.value);
                    });
                    row.push(getDisplayText(item === null || item === void 0 ? void 0 : item.value, item === null || item === void 0 ? void 0 : item.suffix, item === null || item === void 0 ? void 0 : item.prefix));
                });
            }
        });
        return [header, row];
    };
    return [disabled, getExportData];
};
export var useExportTrendYearlyData = function () {
    var dataSource = useFilterValue('statistic', 'cmsDataSource')[0];
    var yearData = useCMSDataYear(dataSource)[0];
    var trendData = useTrendLineData()[0];
    var disabled = trendData.requesting || !!trendData.error || !yearData.data;
    var getExportData = function () {
        var _a;
        var header = [];
        var row = [];
        (_a = trendData.data) === null || _a === void 0 ? void 0 : _a.forEach(function (i) {
            header.push("" + i.year);
            row.push(getDisplayText(i.value, i.suffix, i.prefix));
        });
        return [header, row];
    };
    return [disabled, getExportData];
};
export var useExport = function () {
    var _a;
    var viewSettings = useViewSetting();
    var exportGeoData = useExportGeoData(viewSettings.viewMode);
    var exportDemographicChartData = useExportDemographicChartData();
    var exportTrendYearlyData = useExportTrendYearlyData();
    var fileName = useFileName();
    var _b = viewSettings.viewMode === ViewModeEnum.Map || viewSettings.viewMode === ViewModeEnum.Table
        ? exportGeoData
        : ((_a = viewSettings.chartView) === null || _a === void 0 ? void 0 : _a.type) === ChartViewEnum.Demographic
            ? exportDemographicChartData
            : exportTrendYearlyData, disabled = _b[0], getExportData = _b[1];
    var handleExport = function () {
        var data = getExportData();
        var processCell = function (cell) { return (cell === null ? '' : "\"" + cell.toString().replace(/"/g, '""') + "\""); };
        var processRow = function (row) { return row.map(processCell).join(','); };
        var csvFile = data.map(processRow).join('\n');
        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        var encodedUri = URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = encodedUri;
        link.download = fileName + ".csv";
        link.click();
    };
    return [disabled, handleExport];
};
