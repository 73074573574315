var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { StatisticGroupEnum, StatisticTypeEnum } from '../enums';
import { safeDivide } from '../utils';
var STATISTIC_GROUPS = (_a = {},
    _a[StatisticGroupEnum.Population] = {
        value: StatisticGroupEnum.Population,
        label: 'Population',
    },
    _a[StatisticGroupEnum.Rate] = {
        value: StatisticGroupEnum.Rate,
        label: 'Rate of Acute Events',
    },
    _a[StatisticGroupEnum.Cost] = {
        value: StatisticGroupEnum.Cost,
        label: 'Cost of Acute Events',
    },
    _a);
var DEFAULT_STATISTIC_PRECISION = 2;
var STATISTIC_OPTION_BY_TYPE = (_b = {},
    _b[StatisticTypeEnum.Prevalence] = {
        value: StatisticTypeEnum.Prevalence,
        label: 'Prevalence',
        shortTerm: 'Prevalence',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Population],
        percentage: true,
    },
    _b[StatisticTypeEnum.TotalCount] = {
        value: StatisticTypeEnum.TotalCount,
        label: 'Total Count of Patients',
        shortTerm: 'Total Count',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Population],
        precision: 0,
    },
    _b[StatisticTypeEnum.HospitalizationRate] = {
        value: StatisticTypeEnum.HospitalizationRate,
        label: 'Hospitalization Rate',
        shortTerm: 'Hospitalization Rate',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Rate],
    },
    _b[StatisticTypeEnum.ERVisitsRate] = {
        value: StatisticTypeEnum.ERVisitsRate,
        label: 'ER Visits Rate',
        shortTerm: 'ER Visits Rate',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Rate],
    },
    _b[StatisticTypeEnum.HospitalEncounterRate] = {
        value: StatisticTypeEnum.HospitalEncounterRate,
        label: 'Hospital Encounters Rate',
        shortTerm: 'Hospital Encounters Rate',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Rate],
    },
    _b[StatisticTypeEnum.DeathRate] = {
        value: StatisticTypeEnum.DeathRate,
        label: 'Death Rate',
        shortTerm: 'Death Rate',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Rate],
        precision: 4,
    },
    _b[StatisticTypeEnum.ReadmissionRate] = {
        value: StatisticTypeEnum.ReadmissionRate,
        label: 'Readmission Rate',
        shortTerm: 'Readmission Rate',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Rate],
        precision: 3,
    },
    _b[StatisticTypeEnum.HospitalizationCost] = {
        value: StatisticTypeEnum.HospitalizationCost,
        label: 'Total Annual Hospitalization Cost Per Patient',
        shortTerm: 'Hospitalization Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.ERCost] = {
        value: StatisticTypeEnum.ERCost,
        label: 'Total Annual ER Cost Per Patient',
        shortTerm: 'ER Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.OOPPartBDrugCost] = {
        value: StatisticTypeEnum.OOPPartBDrugCost,
        label: 'Total Annual Patient Out-of-pocket Part b Drug Cost Per Patient',
        shortTerm: 'Patient Out-of-pocket Part b Drug Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.OOPPartDDrugCost] = {
        value: StatisticTypeEnum.OOPPartDDrugCost,
        label: 'Total Annual Patient Out-of-pocket Part d Drug Cost Per Patient',
        shortTerm: 'Patient Out-of-pocket Part d Drug Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.MCRPartBDrugCost] = {
        value: StatisticTypeEnum.MCRPartBDrugCost,
        label: 'Total Annual MCR Part b Drug Payments Per Patient',
        shortTerm: 'Medicare Part b Drug Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.MCRPartDDrugCost] = {
        value: StatisticTypeEnum.MCRPartDDrugCost,
        label: 'Total Annual MCR Part d Drug Payments Per Patient',
        shortTerm: 'Medicare Part d Drug Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b[StatisticTypeEnum.TotalCost] = {
        value: StatisticTypeEnum.TotalCost,
        label: 'Total Annual Cost Per Patient',
        shortTerm: 'Total Cost',
        group: STATISTIC_GROUPS[StatisticGroupEnum.Cost],
        preUnit: '$',
    },
    _b);
export var GROUPED_STATISTIC_OPTIONS = Object.values(STATISTIC_GROUPS).map(function (group) { return (__assign(__assign({}, group), { options: Object.values(STATISTIC_OPTION_BY_TYPE).filter(function (i) { return i.group.value === group.value; }) })); });
export var getStatisticOptions = function (statistics) {
    return !statistics
        ? Object.values(STATISTIC_OPTION_BY_TYPE)
        : Object.values(STATISTIC_OPTION_BY_TYPE).filter(function (option) { return statistics.some(function (statistic) { return statistic === option.value; }); });
};
export var getStatisticOption = function (statistic) { return statistic && STATISTIC_OPTION_BY_TYPE[statistic]; };
export var getStatisticShortName = function (statistic) { var _a; return (_a = getStatisticOption(statistic)) === null || _a === void 0 ? void 0 : _a.shortTerm; };
export var getStatisticPrecision = function (statistic) {
    var statisticOption = getStatisticOption(statistic);
    return (statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.precision) === undefined ? DEFAULT_STATISTIC_PRECISION : statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.precision;
};
export function processStatisticValue(value, percentage, statistic) {
    return safeDivide(value, 1, percentage, getStatisticPrecision(statistic));
}
