/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo } from 'react';
import { useMount } from '../hooks';
import { useCachedData } from '../state';
var LOOKUP_COUNTY_API = '/api/general/lookup/get-lookup-county';
export var useLookupCounty = function () {
    var _a = useCachedData(LOOKUP_COUNTY_API), data = _a[0], res = _a.slice(1);
    var countyByStateCode = useMemo(function () { var _a; return (_a = data.data) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, curr) {
        if (!acc[curr.stateCode]) {
            acc[curr.stateCode] = [];
        }
        acc[curr.stateCode].push(curr);
        return acc;
    }, Object.assign({})); }, [data.data]);
    return __spreadArrays([__assign(__assign({}, data), { data: countyByStateCode })], res);
};
export var LookupCountyComponent = function () {
    var _a = useLookupCounty(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
