var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ListGroup, ListGroupItem, TabContent, TabPane } from 'reactstrap';
import { IconButton } from '../../common';
import { useFilterValue, useViewSetting, useViewValue } from '../../state';
import { StyledMapSidebar } from '../sidebar';
import { getStatisticShortName } from '../../lookupdata';
import { GeoLevelEnum, RightPanelTypeEnum } from '../../enums';
import { useSelectedGeoIdInfo } from '../geoselectedinfo';
import { StatisticDetail } from './statisticdetail';
import { LegislatorsComponent, StateDelegatesComponent } from './representatives';
import { useMount } from '../../hooks';
import { deviceType } from '../../utils';
var PanelHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 0 -1rem;\n  padding: 0.5rem 1rem;\n  padding-right: 0;\n  background-color: #cacaca;\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 0 -1rem;\n  padding: 0.5rem 1rem;\n  padding-right: 0;\n  background-color: #cacaca;\n"])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.25rem;\n"], ["\n  font-weight: 500;\n  font-size: 1.25rem;\n"])));
var StyledTabsContainer = styled(ListGroup)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 -1rem;\n  border-radius: 0;\n  > li {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  margin: 0 -1rem;\n  border-radius: 0;\n  > li {\n    display: flex;\n    align-items: center;\n  }\n"])));
var StyleTabSelector = styled(ListGroupItem)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  cursor: pointer;\n  border: 0;\n  border-radius: 0 !important;\n  :not(.active):not(:hover) {\n    background-color: #ededed;\n  }\n"], ["\n  flex: 1;\n  cursor: pointer;\n  border: 0;\n  border-radius: 0 !important;\n  :not(.active):not(:hover) {\n    background-color: #ededed;\n  }\n"])));
var TabSelector = React.memo(function (props) {
    var setActiveTab = props.setActiveTab;
    var handleClick = useCallback(function () {
        setActiveTab(props.tabId);
    }, [props.tabId, setActiveTab]);
    return (React.createElement(StyleTabSelector, { action: true, disabled: props.disabled, active: props.activeTab === props.tabId, onClick: handleClick }, props.children));
});
export var RegionDetail = React.memo(function () {
    var _a;
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var statisticName = getStatisticShortName(statistic);
    var _b = useViewValue('selectedRegion'), selectedRegion = _b[0], setSelectedRegion = _b[1];
    var _c = useState('1'), activeTab = _c[0], setActiveTab = _c[1];
    var viewSetting = useViewSetting();
    var _d = useSelectedGeoIdInfo(viewSetting), firstLevelLabel = _d[1], secondLevelLabel = _d[2];
    var makeSelection = !((_a = viewSetting.mapView) === null || _a === void 0 ? void 0 : _a.selectedGeoProperties);
    var isDisabledRepresentativesTab = makeSelection || viewSetting.geoType === GeoLevelEnum.MSA;
    useMount(function () {
        if (!(selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) && deviceType() !== 'mobile') {
            setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail });
        }
        else if (deviceType() === 'mobile' && (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail && !selectedRegion.id) {
            setSelectedRegion();
        }
    });
    useEffect(function () {
        if (isDisabledRepresentativesTab) {
            setActiveTab('1');
        }
    }, [isDisabledRepresentativesTab]);
    var closePanel = useCallback(function () {
        setSelectedRegion();
    }, [setSelectedRegion]);
    if ((selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) !== RightPanelTypeEnum.RegionDetail)
        return null;
    return (React.createElement(StyledMapSidebar, null,
        React.createElement(PanelHeader, null,
            React.createElement(Title, null, makeSelection ? ('United States') : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: secondLevelLabel ? 'font-weight-bold' : '' }, firstLevelLabel),
                " ",
                secondLevelLabel))),
            React.createElement(IconButton, { className: "ml-auto", onClick: closePanel, icon: faTimesCircle, color: "warning", size: "2x" })),
        React.createElement(StyledTabsContainer, { horizontal: true },
            React.createElement(TabSelector, { tabId: "1", activeTab: activeTab, setActiveTab: setActiveTab }, statisticName),
            React.createElement(TabSelector, { disabled: isDisabledRepresentativesTab, tabId: "2", activeTab: activeTab, setActiveTab: setActiveTab }, "Representatives")),
        React.createElement(TabContent, { activeTab: activeTab },
            React.createElement(TabPane, { tabId: "1" },
                React.createElement(StatisticDetail, null)),
            React.createElement(TabPane, { tabId: "2" },
                React.createElement(LegislatorsComponent, { type: viewSetting.geoType }),
                React.createElement(StateDelegatesComponent, { type: viewSetting.geoType })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
