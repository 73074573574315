var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useExtractGeoData } from './usemapdata';
import { useFilterValue } from '../../state/filtersettings';
import { useViewValue } from '../../state/viewsetting';
import { useMapValue } from '../mapprovider';
import { useLookupState, useLookupZipCodeDistrict, getStatisticOption } from '../../lookupdata';
import { getDataFieldsByMapViewLevel } from './dataforgeo';
import { useDebounce } from '../../hooks';
import { MemoMarker } from '../memo';
import { StatisticTypeEnum } from '../../enums';
import { getDisplayText } from '../../utils';
var DELAY_SHOW_POPUP_TIME = 500;
var useStyles = makeStyles({
    arrow: {
        color: '#fff',
    },
    tooltip: {
        color: '#000',
        backgroundColor: '#fff',
        padding: 0,
        minWidth: '20rem',
        maxWidth: '25rem',
        textAlign: 'left',
        fontSize: '1rem',
        boxShadow: '0 1px 2px rgb(0 0 0 / 10%)',
    },
});
var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.75rem;\n  background-color: rgba(0, 0, 0, 0.06);\n  font-weight: bolder;\n  border-top-right-radius: 0.25rem;\n  max-width: 18rem;\n  min-width: 100%;\n"], ["\n  padding: 0.75rem;\n  background-color: rgba(0, 0, 0, 0.06);\n  font-weight: bolder;\n  border-top-right-radius: 0.25rem;\n  max-width: 18rem;\n  min-width: 100%;\n"])));
var Body = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 1.5rem;\n"], ["\n  margin: 0 1.5rem;\n"])));
var StyledCard = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.75rem 0;\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(0, 0, 0, 0.125);\n  }\n  display: flex;\n  align-items: center;\n"], ["\n  padding: 0.75rem 0;\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(0, 0, 0, 0.125);\n  }\n  display: flex;\n  align-items: center;\n"])));
var CardTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var CardText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bolder;\n  padding-left: 0.5rem;\n"], ["\n  font-weight: bolder;\n  padding-left: 0.5rem;\n"])));
var formatMetricValue = function (value, suffix, prefix) {
    var roundedValue = prefix === '$' && value ? Math.max(Math.round(value), 1) : value;
    return getDisplayText(roundedValue, suffix, prefix);
};
var StatisticValue = function (props) {
    return React.createElement(React.Fragment, null, formatMetricValue(props.valueKey ? props[props.valueKey] : props.value, props.suffix, props.prefix));
};
var Card = function (props) {
    var _a;
    return (React.createElement(StyledCard, null,
        React.createElement(CardTitle, null,
            React.createElement("div", null, props.metricValue.title), (_a = props.secondaryValues) === null || _a === void 0 ? void 0 :
            _a.map(function (payload, idx) { return (React.createElement("div", { key: idx, className: "text-secondary" },
                React.createElement("small", null,
                    payload.title,
                    ": ",
                    React.createElement(StatisticValue, __assign({}, payload, { valueKey: props.valueKey }))))); })),
        React.createElement(CardText, null,
            React.createElement(StatisticValue, __assign({}, props.metricValue, { valueKey: undefined })))));
};
var GeoName = function (props) {
    var _a = getDataFieldsByMapViewLevel(props.geoLevel, props.geoProps, props.lookupStateData, props.lookupZipCode), secondLevel = _a[0], firstLevel = _a[1];
    return (React.createElement(React.Fragment, null, firstLevel ? (React.createElement(React.Fragment, null,
        React.createElement("strong", null, firstLevel.value),
        " ",
        secondLevel.value)) : (React.createElement("strong", null, secondLevel.value))));
};
var getMetricValues = function (geoData, geoName, region, regionData) {
    var metricValues = [];
    var isShowCohortValue = geoData.value !== geoData.geographic || geoData.numerator !== geoData.geographicNumerator || geoData.denominator !== geoData.geographicDenominator;
    if (isShowCohortValue) {
        metricValues.push({
            title: React.createElement(React.Fragment, null,
                geoName,
                " (selected cohort)"),
            valueKey: 'cohort',
            value: geoData.value,
            suffix: geoData.suffix,
            prefix: geoData.prefix,
        });
    }
    metricValues.push({
        title: React.createElement(React.Fragment, null, geoName),
        valueKey: 'geo',
        value: geoData.geographic,
        suffix: geoData.suffix,
        prefix: geoData.prefix,
    });
    if (region) {
        metricValues.push({
            title: React.createElement("strong", null, region),
            valueKey: 'region',
            value: regionData === null || regionData === void 0 ? void 0 : regionData.value,
            suffix: geoData.suffix,
            prefix: geoData.prefix,
        });
        metricValues.push({
            title: React.createElement("strong", null, "Illinois"),
            valueKey: 'state',
            value: regionData === null || regionData === void 0 ? void 0 : regionData.stateValue,
            suffix: geoData.suffix,
            prefix: geoData.prefix,
        });
    }
    metricValues.push({
        title: React.createElement("strong", null, "National"),
        valueKey: 'national',
        value: geoData.national,
        suffix: geoData.suffix,
        prefix: geoData.prefix,
    });
    return metricValues;
};
var getFirstSecondaryValueTitle = function (statistic) {
    switch (statistic) {
        case StatisticTypeEnum.Prevalence:
            return 'Patient Count';
        case StatisticTypeEnum.HospitalizationRate:
        case StatisticTypeEnum.HospitalizationCost:
            return 'Total Visits';
        case StatisticTypeEnum.ERVisitsRate:
        case StatisticTypeEnum.ERCost:
            return 'Total ER Visits';
        case StatisticTypeEnum.HospitalEncounterRate:
            return 'Total Encounters';
        case StatisticTypeEnum.DeathRate:
            return 'Total Deaths';
        case StatisticTypeEnum.ReadmissionRate:
            return 'Total Readmissions';
        case StatisticTypeEnum.OOPPartBDrugCost:
        case StatisticTypeEnum.OOPPartDDrugCost:
        case StatisticTypeEnum.MCRPartBDrugCost:
        case StatisticTypeEnum.MCRPartDDrugCost:
        case StatisticTypeEnum.TotalCost:
            return 'Total';
    }
};
var getSecondaryValues = function (geoData, statistic, regionData) {
    var firstSecondaryValueTitle = getFirstSecondaryValueTitle(statistic);
    if (statistic === StatisticTypeEnum.TotalCount) {
        return [
            {
                title: 'Patient Population',
                cohort: geoData.patientPopulation,
                geo: geoData.patientPopulation,
                national: geoData.nationalPatientPopulation,
                region: regionData === null || regionData === void 0 ? void 0 : regionData.patientPopulation,
                state: regionData === null || regionData === void 0 ? void 0 : regionData.statePatientPopulation,
            },
        ];
    }
    var secondaryValues = [
        {
            title: firstSecondaryValueTitle,
            cohort: geoData.numerator,
            geo: geoData.geographicNumerator,
            national: geoData.nationalNumerator,
            region: regionData === null || regionData === void 0 ? void 0 : regionData.numerator,
            state: regionData === null || regionData === void 0 ? void 0 : regionData.stateNumerator,
            prefix: geoData.prefix,
        },
        {
            title: statistic === StatisticTypeEnum.Prevalence ? 'Patient Population' : 'Patient Count',
            cohort: geoData.denominator,
            geo: geoData.geographicDenominator,
            national: geoData.nationalDenominator,
            region: regionData === null || regionData === void 0 ? void 0 : regionData.denominator,
            state: regionData === null || regionData === void 0 ? void 0 : regionData.stateDenominator,
        },
    ];
    if (statistic === StatisticTypeEnum.HospitalizationCost || statistic === StatisticTypeEnum.ERCost) {
        secondaryValues[0] = {
            title: firstSecondaryValueTitle,
            cohort: geoData.eventCount,
            geo: geoData.geographicEventCount,
            national: geoData.nationalEventCount,
            region: regionData === null || regionData === void 0 ? void 0 : regionData.eventCount,
            state: regionData === null || regionData === void 0 ? void 0 : regionData.stateEventCount,
        };
    }
    return secondaryValues;
};
var PopperProps = {
    modifiers: {
        preventOverflow: {
            boundariesElement: 'viewport',
        },
    },
};
export var InfoPopup = function (props) {
    var lookupStateData = useLookupState()[0];
    var lookupZipCode = useLookupZipCodeDistrict()[0];
    var hoveringInfo = useMapValue('hoveringInfo')[0];
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var geoLevel = useViewValue('geoType')[0];
    var showGeographyHoverDisplay = useViewValue('mapView', 'showGeographyHoverDisplay')[0];
    var extractGeoData = useExtractGeoData();
    var _a = useState(), show = _a[0], setShow = _a[1];
    var classes = useStyles();
    useEffect(function () {
        setShow(false);
    }, [hoveringInfo]);
    useDebounce(function () {
        setShow(true);
    }, [hoveringInfo], DELAY_SHOW_POPUP_TIME);
    if (!show || !showGeographyHoverDisplay || !(hoveringInfo === null || hoveringInfo === void 0 ? void 0 : hoveringInfo.length) || !hoveringInfo[0] || !statistic || !geoLevel)
        return null;
    var statisticOption = getStatisticOption(statistic);
    var geoName = React.createElement(GeoName, { geoLevel: geoLevel, geoProps: hoveringInfo[0], lookupStateData: lookupStateData, lookupZipCode: lookupZipCode });
    var geoData = extractGeoData(hoveringInfo[0].id);
    var metricValues = getMetricValues(geoData, geoName, props.region, props.regionData);
    var secondaryValues = getSecondaryValues(geoData, statistic, props.regionData);
    return (React.createElement(MemoMarker, { longitude: hoveringInfo[1], latitude: hoveringInfo[2], offsetTop: 0, offsetLeft: 0 },
        React.createElement(Tooltip, { open: true, placement: "right", classes: classes, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, PopperProps: PopperProps, title: React.createElement(React.Fragment, null,
                React.createElement(Header, null, statisticOption === null || statisticOption === void 0 ? void 0 : statisticOption.label),
                React.createElement(Body, null, metricValues.map(function (card) { return (React.createElement(Card, { key: card.valueKey, valueKey: card.valueKey, metricValue: card, secondaryValues: secondaryValues })); }))) },
            React.createElement("div", null))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
