/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var _a;
import { ReportTypeEnum } from '../enums/reporttypeenum';
export var REPORT_TYPE_OPTIONS = (_a = {},
    _a[ReportTypeEnum.CongressionalDetailAnalysis] = {
        value: ReportTypeEnum.CongressionalDetailAnalysis,
        label: 'Congressional Detail Analysis',
    },
    _a[ReportTypeEnum.ZipCodeDetailAnalysis] = {
        value: ReportTypeEnum.ZipCodeDetailAnalysis,
        label: 'Zip Code Detail Analysis',
    },
    _a);
