/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useContext, useCallback, useMemo } from 'react';
import { Map } from 'immutable';
import { DataSourceEnum, RaceMethodologyEnum, StatisticTypeEnum } from '../enums';
import { useDefaultFilter, useHostnameIndexInfo } from '../hostnameindexinfo';
import { ReportTypeEnum } from '../enums/reporttypeenum';
export var REPORT_DATA_SOURCES = [DataSourceEnum.MedicareFFS];
var ReportsDialogContext = React.createContext([]);
export var useReportsDialogValue = function (key) {
    var _a = useContext(ReportsDialogContext), state = _a[0], updateState = _a[1];
    var getter = function () { return state === null || state === void 0 ? void 0 : state.get(key); };
    var setter = useCallback(function (value) { return updateState === null || updateState === void 0 ? void 0 : updateState(function (curr) { return curr.set(key, value); }); }, [key, updateState]);
    return [useMemo(getter, [key, state]), setter];
};
export var useReportSetting = function () {
    var _a = useContext(ReportsDialogContext), data = _a[0], updateData = _a[1];
    var getter = function () { return data === null || data === void 0 ? void 0 : data.toJSON(); };
    var setter = useCallback(function (value) { return updateData === null || updateData === void 0 ? void 0 : updateData(Map(value)); }, [updateData]);
    return [useMemo(getter, [data]), setter];
};
export var ReportsDialogProvider = function (props) {
    var _a, _b, _c, _d, _e;
    var defaultFilter = useDefaultFilter();
    var indexConfig = useHostnameIndexInfo();
    var _f = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).isSeasonStatistic, isSeasonStatistic = _f === void 0 ? false : _f;
    var INITIAL_VALUE = {
        isSeasonStatistic: isSeasonStatistic,
        conditions: defaultFilter.primaryCoMorbidities || [],
        secondaryCormorbidities: [],
        dataSourceType: ((_b = (_a = defaultFilter.report) === null || _a === void 0 ? void 0 : _a.cmsDataSource) === null || _b === void 0 ? void 0 : _b[0]) || REPORT_DATA_SOURCES[0],
        dataYear: 0,
        dataYearSeason: defaultFilter.dataYearSeason || [],
        firstLevel: undefined,
        reportType: defaultFilter.defaultReportType || ReportTypeEnum.CongressionalDetailAnalysis,
        secondLevel: undefined,
        zipCodes: undefined,
        raceMethodology: RaceMethodologyEnum.Standard,
        statistics: [
            StatisticTypeEnum.Prevalence,
            StatisticTypeEnum.TotalCount,
            StatisticTypeEnum.HospitalizationCost,
            StatisticTypeEnum.ERCost,
            StatisticTypeEnum.TotalCost,
        ],
        sexCodes: (_c = defaultFilter.demographics) === null || _c === void 0 ? void 0 : _c.sexCodes,
        raceCodes: (_d = defaultFilter.demographics) === null || _d === void 0 ? void 0 : _d.raceCodes,
        ages: (_e = defaultFilter.demographics) === null || _e === void 0 ? void 0 : _e.ages,
        isRememberSettings: false,
        fluVaccineCptCodes: [],
    };
    var _g = useState(function () { return Map(INITIAL_VALUE); }), state = _g[0], setState = _g[1];
    var contextValue = [state, setState];
    return (React.createElement(ReportsDialogContext.Provider, { value: contextValue }, props.children));
};
