var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { PieComponent } from '../../demographicchart/pie';
import { useFilterSetting } from '../../state';
import { useLookupSexCode, useLookupRaceCode, mapAgeValues, AGE_OPTIONS } from '../../lookupdata';
import { getDisplayText } from '../../utils';
var ChartContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ChartContainerTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 500;\n  margin: 0.25rem 0;\n"], ["\n  font-weight: 500;\n  margin: 0.25rem 0;\n"])));
var StyledChartComponent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // height: 8.5rem;\n"], ["\n  // height: 8.5rem;\n"])));
var StyledLegendFormatter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var legendFormatter = function (value, entry) {
    return (React.createElement(StyledLegendFormatter, null,
        React.createElement("div", null, value),
        React.createElement("div", { className: "pl-1 ml-auto" }, (entry === null || entry === void 0 ? void 0 : entry.dataValue) && getDisplayText(entry.dataValue))));
};
var ProviderPieComponent = function (props) {
    return (React.createElement(StyledChartComponent, null,
        React.createElement(PieComponent, { type: props.type, data: props.data, className: "flex-row", width: 160, height: "8.5rem", legendFormatter: legendFormatter })));
};
export var SexChart = function (props) {
    var _a, _b;
    var filterSetting = useFilterSetting();
    var lookupSexData = useLookupSexCode((_a = filterSetting.demographics) === null || _a === void 0 ? void 0 : _a.sexCodes)[0];
    var data = (_b = (lookupSexData.mappedValues || lookupSexData.data)) === null || _b === void 0 ? void 0 : _b.map(function (i) { return ({
        id: i.value,
        name: i.label,
        value: props.data[i.value],
    }); }).filter(function (i) { return !!i.value; });
    return (React.createElement(ChartContainer, null,
        React.createElement(ChartContainerTitle, null, "Sex"),
        React.createElement(ProviderPieComponent, { type: "sex", data: data })));
};
export var RaceChart = function (props) {
    var _a, _b;
    var filterSetting = useFilterSetting();
    var lookupRaceData = useLookupRaceCode(filterSetting.raceMethodology, (_a = filterSetting.demographics) === null || _a === void 0 ? void 0 : _a.raceCodes)[0];
    var data = (_b = (lookupRaceData.mappedValues || lookupRaceData.data)) === null || _b === void 0 ? void 0 : _b.map(function (i) { return ({
        id: i.value,
        name: i.shortName,
        value: props.data[i.value],
    }); }).filter(function (i) { return !!i.value; });
    return (React.createElement(ChartContainer, null,
        React.createElement(ChartContainerTitle, null, "Race/Ethnicity"),
        React.createElement(ProviderPieComponent, { type: "race", data: data })));
};
export var AgeChart = function (props) {
    var _a;
    var filterSetting = useFilterSetting();
    var ageOptions = mapAgeValues((_a = filterSetting.demographics) === null || _a === void 0 ? void 0 : _a.ages) || AGE_OPTIONS;
    var data = ageOptions
        .map(function (i) { return ({
        id: i.stringified,
        name: i.label,
        value: props.data[i.stringified],
    }); })
        .filter(function (i) { return !!i.value; });
    return (React.createElement(ChartContainer, null,
        React.createElement(ChartContainerTitle, null, "Age"),
        React.createElement(ProviderPieComponent, { type: "age", data: data })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
