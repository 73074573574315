var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useEffect } from 'react';
import { TabPane, TabContent } from 'reactstrap';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import styled from 'styled-components';
import { FallbackComponent } from '../common';
import { useMapData } from './patientchoroplethlayer/usemapdata';
import { useViewValue } from '../state/viewsetting';
import { useViewport, useMapValue, useMapRef, useMapFallback } from './mapprovider';
import { LegendOverlay } from './patientchoroplethlayer/legend';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { RightPanelTypeEnum, ViewModeEnum } from '../enums';
import { CountNotification } from './countnotification';
import { MapSidebar } from './sidebar';
import { ImportingLocationDetail } from './importinglocationlayer';
import { ProviderDetail } from './providerdetail';
import { RegionDetail } from './regiondetail';
import { useDrawPolygon } from './drawpolygon';
import { isTouchEnabled } from '../utils';
var StyledMapContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n"], ["\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n"])));
var StyledNavigationControl = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 2.5rem;\n  left: 0.5rem;\n  .disabled-zoom-out .mapboxgl-ctrl-zoom-out {\n    &:focus,\n    &:active {\n      box-shadow: none;\n    }\n    cursor: default !important;\n    span {\n      background-color: #f4f4f4;\n      background-image: url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23bbb'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E\") !important;\n    }\n  }\n"], ["\n  position: absolute;\n  bottom: 2.5rem;\n  left: 0.5rem;\n  .disabled-zoom-out .mapboxgl-ctrl-zoom-out {\n    &:focus,\n    &:active {\n      box-shadow: none;\n    }\n    cursor: default !important;\n    span {\n      background-color: #f4f4f4;\n      background-image: url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23bbb'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E\") !important;\n    }\n  }\n"])));
var StyledRightOverlay = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: flex-end;\n  right: 0;\n  top: 3.875rem;\n  bottom: 0;\n"], ["\n  position: absolute;\n  display: flex;\n  flex-direction: row-reverse;\n  align-items: flex-end;\n  right: 0;\n  top: 3.875rem;\n  bottom: 0;\n"])));
export var MapComponent = function (props) {
    var _a = useMapData(), mapData = _a[0], fetchMapData = _a[1], resetMapData = _a[2];
    var _b = useViewport(), viewport = _b[0], setViewport = _b[1];
    var _c = useMapValue('hoveringInfo'), setHoveringInfo = _c[1];
    var indexConfig = useHostnameIndexInfo();
    var mapRef = useMapRef();
    var viewMode = useViewValue('viewMode')[0];
    var show = viewMode === ViewModeEnum.Map;
    var _d = useMapFallback(), fallbackData = _d[0], setFallbackData = _d[1];
    var _e = useViewValue('selectedRegion'), setSelectedRegion = _e[1];
    var _f = useViewValue('mapView', 'zoomId'), setZoomId = _f[1];
    var _g = useDrawPolygon(), editor = _g.editor, drawControl = _g.drawControl;
    useEffect(function () {
        resetMapData();
        fetchMapData();
    }, [fetchMapData, resetMapData]);
    useEffect(function () {
        setFallbackData({ requesting: mapData.requesting, error: mapData.error, retry: fetchMapData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapData.requesting, mapData.error]);
    var updateViewport = useCallback(function (newViewport) {
        var zoom = newViewport.zoom;
        var minZoom = viewport.minZoom, maxZoom = viewport.maxZoom;
        if (minZoom && zoom < minZoom) {
            zoom = minZoom;
        }
        if (maxZoom && zoom > maxZoom) {
            zoom = maxZoom;
        }
        setViewport(__assign(__assign({}, newViewport), { minZoom: minZoom, maxZoom: maxZoom, zoom: zoom }));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewport.minZoom, viewport.maxZoom]);
    var handleHover = useCallback(function (event) {
        var properties = null;
        var lngLat = [0, 0];
        var feature = (event.features || [])[0];
        if (!isTouchEnabled() && feature && event.target.className === 'overlays') {
            (lngLat = event.lngLat);
            (properties = feature.properties);
            setHoveringInfo(__spreadArrays([properties], lngLat));
        }
        else {
            setHoveringInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var handleMouseOut = useCallback(function () {
        setHoveringInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var hanldeClick = useCallback(function (event) {
        var feature = (event.features || [])[0];
        if (feature && event.target.className === 'overlays') {
            setZoomId(feature.properties.id);
            setHoveringInfo();
            setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail, id: feature.properties.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var mapEvents = {};
    if (show) {
        mapEvents = {
            onHover: handleHover,
            onMouseOut: handleMouseOut,
            onClick: hanldeClick,
        };
    }
    return (React.createElement(TabContent, { activeTab: viewMode },
        React.createElement(TabPane, { tabId: ViewModeEnum.Map },
            React.createElement(FallbackComponent, { requesting: fallbackData.requesting, error: fallbackData.error, retry: fallbackData.retry, overlay: true },
                React.createElement(StyledMapContent, null,
                    React.createElement(ReactMapGL, __assign({ ref: mapRef, mapboxApiAccessToken: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.mapboxToken, mapStyle: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.mapboxStyle }, viewport, { width: "100%", height: "100%", onViewportChange: updateViewport, interactiveLayerIds: ['patientchoroplethlayer'] }, mapEvents, { doubleClickZoom: false }),
                        React.createElement(StyledNavigationControl, null,
                            React.createElement(NavigationControl, { className: "" + (viewport.minZoom >= viewport.zoom ? 'disabled-zoom-out' : ''), showCompass: false })),
                        React.Children.map(props.children, function (child) { return ((child === null || child === void 0 ? void 0 : child.type) !== MapSidebar ? child : null); }),
                        editor,
                        React.createElement(CountNotification, null)),
                    React.createElement(StyledRightOverlay, null,
                        React.createElement(MapSidebar, null,
                            React.createElement(RegionDetail, null),
                            React.createElement(ProviderDetail, null),
                            React.createElement(ImportingLocationDetail, null),
                            React.Children.map(props.children, function (child) { return ((child === null || child === void 0 ? void 0 : child.type) === MapSidebar ? child : null); })),
                        React.createElement("div", { className: "position-relative h-100" },
                            drawControl,
                            React.createElement(LegendOverlay, null))))))));
};
var templateObject_1, templateObject_2, templateObject_3;
