var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import { useToggle } from '../hooks';
import { useViewSetting } from '../state';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 4.875rem;\n  font-weight: 500;\n  left: 2rem;\n  width: max-content;\n  padding: 0.2rem;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 2;\n"], ["\n  position: absolute;\n  top: 4.875rem;\n  font-weight: 500;\n  left: 2rem;\n  width: max-content;\n  padding: 0.2rem;\n  background-color: rgba(255, 255, 255, 0.8);\n  z-index: 2;\n"])));
var ToggleButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  color: #007bff;\n  text-decoration: none;\n  background-color: transparent;\n  font-size: 0.75rem;\n  padding: 0;\n  outline: none !important;\n"], ["\n  border: none;\n  color: #007bff;\n  text-decoration: none;\n  background-color: transparent;\n  font-size: 0.75rem;\n  padding: 0;\n  outline: none !important;\n"])));
var StyledAndText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #6c757d;\n  margin-left: 1rem;\n"], ["\n  color: #6c757d;\n  margin-left: 1rem;\n"])));
var getCountDisplay = function (layerItem) {
    var _a, _b, _c;
    if (!layerItem.totalCount) {
        return 'no';
    }
    if (layerItem.displayingCount === layerItem.totalCount) {
        return "all " + ((_a = layerItem.totalCount) === null || _a === void 0 ? void 0 : _a.toLocaleString());
    }
    return ((_b = layerItem.displayingCount) === null || _b === void 0 ? void 0 : _b.toLocaleString()) + " of " + ((_c = layerItem.totalCount) === null || _c === void 0 ? void 0 : _c.toLocaleString());
};
export var CountNotification = function () {
    var _a = useToggle(), open = _a[0], toggle = _a[1];
    var mapLayerCountNotifications = useViewSetting().mapLayerCountNotifications;
    var _b = useMemo(function () { return Object.entries(mapLayerCountNotifications || {}).filter(function (_a) {
        var item = _a[1];
        return item;
    }); }, [mapLayerCountNotifications]), _c = _b[0], _d = _c === void 0 ? [] : _c, firstItem = _d[1], otherItems = _b.slice(1);
    if (!firstItem)
        return null;
    return (React.createElement(Wrapper, null,
        React.createElement("div", null,
            "Displaying",
            React.createElement("b", null,
                " ",
                getCountDisplay(firstItem)),
            " ",
            firstItem.name,
            otherItems.length ? React.createElement(ToggleButton, { onClick: toggle },
                "\u00A0(",
                open ? 'less' : 'more',
                ")") : null),
        React.createElement(Collapse, { isOpen: open }, otherItems.map(function (_a) {
            var key = _a[0], item = _a[1];
            return item && (React.createElement("div", { key: key },
                React.createElement(StyledAndText, null, "and"),
                React.createElement("b", null,
                    " ",
                    getCountDisplay(item),
                    " "),
                item.name));
        }))));
};
var templateObject_1, templateObject_2, templateObject_3;
