var _a;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { DataSourceEnum, StatisticTypeEnum } from '../enums';
export var DATA_SOURCES = (_a = {},
    _a[DataSourceEnum.Medicaid] = {
        value: DataSourceEnum.Medicaid,
        label: 'Medicaid',
        availableStatistic: [
            StatisticTypeEnum.Prevalence,
            StatisticTypeEnum.TotalCount
        ],
        abbreviation: 'mcd',
    },
    _a[DataSourceEnum.MedicareFFS] = {
        value: DataSourceEnum.MedicareFFS,
        label: 'Medicare FFS',
        supportConsumptionGroup: true,
        abbreviation: 'mcr',
    },
    _a[DataSourceEnum.MedicareAdvantage] = {
        value: DataSourceEnum.MedicareAdvantage,
        label: 'Medicare Advantage',
        abbreviation: 'mcradv',
        availableStatistic: [
            StatisticTypeEnum.Prevalence,
            StatisticTypeEnum.TotalCount,
            StatisticTypeEnum.HospitalizationRate,
            StatisticTypeEnum.ERVisitsRate,
            StatisticTypeEnum.HospitalEncounterRate,
            StatisticTypeEnum.DeathRate,
            StatisticTypeEnum.ReadmissionRate,
        ],
    },
    _a[DataSourceEnum.MedicareAll] = {
        value: DataSourceEnum.MedicareAll,
        label: 'Medicare All',
        abbreviation: 'mcrall',
        availableStatistic: [
            StatisticTypeEnum.Prevalence,
            StatisticTypeEnum.TotalCount,
            StatisticTypeEnum.HospitalizationRate,
            StatisticTypeEnum.ERVisitsRate,
            StatisticTypeEnum.HospitalEncounterRate,
            StatisticTypeEnum.DeathRate,
            StatisticTypeEnum.ReadmissionRate,
        ],
    },
    _a[DataSourceEnum.MedicareFfsFluSeason] = {
        value: DataSourceEnum.MedicareFfsFluSeason,
        label: 'Medicare FFS Flu Season',
        abbreviation: 'mcr',
    },
    _a);
