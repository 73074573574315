var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useEffect } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { CheckboxGroup, Input, SimpleCheckbox, ThreeDotLoading } from '../common';
import { useFilterEditingValue } from '../state/filterediting';
import { CMSSourceTabSelector } from './tabselector';
import { AGE_OPTIONS } from '../lookupdata';
import { useEffectFilterEditingCount } from '../filterbar';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { getDisplayText } from '../utils';
import { useFilterCount } from './usefiltercount';
var MAX_AGE_RANGE = 999;
var StyledCustomAgeInput = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 5rem;\n  display: inline-block;\n\n  @media only screen and (max-width: 420px) {\n    width: 3.5rem;\n  }\n"], ["\n  width: 5rem;\n  display: inline-block;\n\n  @media only screen and (max-width: 420px) {\n    width: 3.5rem;\n  }\n"])));
export var useAgeGroupCount = function (indexConfig) {
    return useFilterCount('AGE_GROUP_CODE', indexConfig);
};
var AGE_GROUP_CODE = {
    " - 18": 0,
    "19 - 49": 1,
    "50 - 64": 2,
    "65 - 74": 3,
    "75 - 80": 4,
    "81 - ": 5,
};
var AgeCountComponent = function (_a) {
    var loading = _a.loading, value = _a.value;
    return (React.createElement(React.Fragment, null,
        '(',
        React.createElement(ThreeDotLoading, { loading: loading }, getDisplayText(value)),
        ')'));
};
export var AgeTab = function (props) {
    var _a, _b, _c;
    var _d = useFilterEditingValue('demographics', 'ages'), ages = _d[0], setAges = _d[1];
    var indexConfig = useHostnameIndexInfo();
    var _e = useState(!!((_a = ages === null || ages === void 0 ? void 0 : ages[0]) === null || _a === void 0 ? void 0 : _a.isCustom)), isCustomAge = _e[0], setIsCustomAge = _e[1];
    var _f = useAgeGroupCount(indexConfig), ageGroupCount = _f[0], fetchAgeGroupCount = _f[1];
    var filterCountEnabled = (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && props.filterCountEnabled;
    var handleFetchAgeGroupCount = function (payload) {
        filterCountEnabled && fetchAgeGroupCount(payload);
    };
    useEffect(function () {
        if (isCustomAge && !!(ages === null || ages === void 0 ? void 0 : ages[0]) && !ages[0].isCustom) {
            setIsCustomAge(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ages]);
    useEffectFilterEditingCount(function (body) {
        if (filterCountEnabled) {
            handleFetchAgeGroupCount(body);
        }
    }, __spreadArrays((props.filterCountEffectDeps || []), [isCustomAge]));
    /**
     * Refetch when custom age range has changed
     */
    useEffectFilterEditingCount(function (body) {
        if (isCustomAge) {
            handleFetchAgeGroupCount(body);
        }
    }, __spreadArrays((props.filterCountEffectDeps || []), [ages]));
    var handleChangeCheckboxGroup = function (values) {
        var agesUniq = _.uniqWith(values, function (arr, other) { return arr.minAge === other.minAge && arr.maxAge === other.maxAge; });
        setAges(agesUniq);
    };
    var handleChangeCustomAgeCheckbox = function (event) {
        setIsCustomAge(event.target.checked);
        setAges(event.target.checked ? [{ isCustom: true }] : undefined);
    };
    var handleChangeCustomAgeInput = function (value, name) {
        var _a;
        var newValue = __assign(__assign({}, ages === null || ages === void 0 ? void 0 : ages[0]), (_a = { isCustom: true }, _a[name] = value, _a));
        if (value === undefined && (ages === null || ages === void 0 ? void 0 : ages[0]) && newValue.minAge === undefined && newValue.maxAge === undefined) {
            setAges();
        }
        else {
            setAges([newValue]);
        }
    };
    var getAgeGroupCode = function (_a) {
        var minAge = _a.minAge, maxAge = _a.maxAge;
        var ageGroup = (minAge || '') + " - " + (maxAge || '');
        return AGE_GROUP_CODE[ageGroup];
    };
    var renderAgeCount = function (value) {
        var _a;
        return filterCountEnabled && (React.createElement(AgeCountComponent, { loading: ageGroupCount.requesting, value: (_a = ageGroupCount.data) === null || _a === void 0 ? void 0 : _a[getAgeGroupCode(value)] }));
    };
    var renderCustomAgeGroupCount = function () { return filterCountEnabled && isCustomAge && (React.createElement(AgeCountComponent, { loading: ageGroupCount.requesting, value: (ageGroupCount === null || ageGroupCount === void 0 ? void 0 : ageGroupCount.data) ? Object.values(ageGroupCount === null || ageGroupCount === void 0 ? void 0 : ageGroupCount.data).reduce(function (acc, val) { return acc + val; }, 0) : null })); };
    var customMinAge = isCustomAge ? (_b = ages === null || ages === void 0 ? void 0 : ages[0]) === null || _b === void 0 ? void 0 : _b.minAge : undefined;
    var customMaxAge = isCustomAge ? (_c = ages === null || ages === void 0 ? void 0 : ages[0]) === null || _c === void 0 ? void 0 : _c.maxAge : undefined;
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, "Age"),
        React.createElement(CheckboxGroup, { options: AGE_OPTIONS, noSort: true, value: ages, formatOptionLabel: function (i) { return (React.createElement(React.Fragment, null,
                i.label,
                "\u00A0",
                renderAgeCount(i.value))); }, onChange: handleChangeCheckboxGroup, disabled: function (i) { return isCustomAge; } }),
        React.createElement(FormGroup, { check: true },
            React.createElement(SimpleCheckbox, { checked: isCustomAge, onChange: handleChangeCustomAgeCheckbox, disabled: !isCustomAge && !!(ages === null || ages === void 0 ? void 0 : ages.length), label: React.createElement(React.Fragment, null,
                    ' ',
                    "Custom",
                    isCustomAge && React.createElement("span", { className: "text-danger" }, "*"),
                    ": From") }),
            "\u00A0\u00A0",
            React.createElement(StyledCustomAgeInput, { name: "minAge", type: "number", disabled: !isCustomAge, min: 0, max: customMaxAge !== undefined ? customMaxAge : MAX_AGE_RANGE, value: customMinAge, setFieldValue: handleChangeCustomAgeInput }),
            "\u00A0\u00A0To\u00A0\u00A0",
            React.createElement(StyledCustomAgeInput, { name: "maxAge", type: "number", disabled: !isCustomAge, min: customMinAge || 0, max: MAX_AGE_RANGE, value: customMaxAge, setFieldValue: handleChangeCustomAgeInput }),
            "\u00A0\u00A0",
            renderCustomAgeGroupCount())));
};
AgeTab.defaultProps = {
    tabId: 'AgeTab',
    tabName: 'Age',
    tabSelector: CMSSourceTabSelector,
    filterCountEnabled: false
};
var templateObject_1;
