var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { components } from 'react-select';
import { TabPane, FormGroup, ListGroupItem } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { Select } from '../common';
import { ReportTypeEnum } from '../enums/reporttypeenum';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useLookupState, useLookupZipCodeDistrict } from '../lookupdata';
import { useReportsDialogValue } from './reportsdialogprovider';
var HighlightExisted = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-color: #007bff;\n  }\n  50% {\n    background-color: #fff;\n  }\n  100% {\n    background-color: #007bff;\n  }\n"], ["\n  0% {\n    background-color: #007bff;\n  }\n  50% {\n    background-color: #fff;\n  }\n  100% {\n    background-color: #007bff;\n  }\n"])));
var StyledLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: calc(0.375rem + 1px);\n  padding-bottom: calc(0.375rem + 1px);\n  margin-bottom: 0;\n  font-size: 14px;\n  line-height: 1.5;\n"], ["\n  padding-top: calc(0.375rem + 1px);\n  padding-bottom: calc(0.375rem + 1px);\n  margin-bottom: 0;\n  font-size: 14px;\n  line-height: 1.5;\n"])));
var StyledBadgeZipCodes = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #007bff;\n  color: #ffffff;\n  display: inline-block;\n  border-radius: 50px;\n  padding: 0.5rem 0.625rem;\n  white-space: nowrap;\n  margin-right: 0.3rem;\n  &.badge-existed {\n    animation: ", " 500ms 1;\n  }\n"], ["\n  background-color: #007bff;\n  color: #ffffff;\n  display: inline-block;\n  border-radius: 50px;\n  padding: 0.5rem 0.625rem;\n  white-space: nowrap;\n  margin-right: 0.3rem;\n  &.badge-existed {\n    animation: ", " 500ms 1;\n  }\n"])), HighlightExisted);
var StyledSelect = styled(Select)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 1;\n"], ["\n  font-size: 14px;\n  line-height: 1;\n"])));
var TabPaneContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  gap: 2rem;\n  font-size: 14px;\n"], ["\n  flex: 1;\n  gap: 2rem;\n  font-size: 14px;\n"])));
var TabPaneContentLeft = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 3;\n  min-width: 200px;\n"], ["\n  flex: 3;\n  min-width: 200px;\n"])));
var TabPaneContentRight = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 4;\n  min-width: 200px;\n"], ["\n  flex: 4;\n  min-width: 200px;\n"])));
var TabPaneContentRightContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  min-height: 2rem;\n  max-height: 30rem;\n  gap: 0.375rem;\n"], ["\n  min-height: 2rem;\n  max-height: 30rem;\n  gap: 0.375rem;\n"])));
var StyledResetButton = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #808080;\n  cursor: pointer;\n"], ["\n  color: #808080;\n  cursor: pointer;\n"])));
var FormGroupWrapper = styled(FormGroup)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  justify-content: space-between;\n"], ["\n  justify-content: space-between;\n"])));
var StyledParentOption = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
var StyledCloseButton = styled(IconButton)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  :focus {\n    outline: none\n  }\n"], ["\n  :focus {\n    outline: none\n  }\n"])));
var StyledChildOption = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding-left: 1rem;\n"], ["\n  padding-left: 1rem;\n"])));
var StyledStartWithOption = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject([""], [""])));
var useOptionsZipCode = function (inputBoxValue) {
    var dataByStateCode = useLookupZipCodeDistrict()[0];
    var lookupState = useLookupState()[0];
    var indexConfig = useHostnameIndexInfo();
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    var _a = (dataByStateCode.data || {}).zipCode5ByState, zipCode5ByState = _a === void 0 ? {} : _a;
    var zipCode5GroupByState = React.useMemo(function () { return _.reduce(zipCode5ByState, function (acc, zipCodes, state) {
        acc[state] = zipCodes.map(function (zipCode) { return ({ label: zipCode, value: zipCode, type: "zipCode" /* ZIP_CODE */ }); });
        return acc;
    }, {}); }, [zipCode5ByState]);
    var states = React.useMemo(function () {
        return _.chain((lookupState.data || []))
            .filter(function (st) { return !singleState || parseInt(st.stateCode, 10) === singleState.stateCode; })
            .map(function (st) {
            var _a;
            return (__assign(__assign({}, st), { children: (_a = zipCode5GroupByState[st.stateCode]) === null || _a === void 0 ? void 0 : _a.map(function (zipCode) { return (__assign(__assign({}, zipCode), { parent: st })); }), type: "allInState" /* ALL_IN_STATE */ }));
        })
            .value() || [];
    }, [lookupState.data, singleState, zipCode5GroupByState]);
    var optionsOriginal = React.useMemo(function () {
        var stateWithZipCode = [];
        states.forEach(function (state) {
            var stateAndZipCode = __spreadArrays([state], (state.children || []));
            stateWithZipCode = stateWithZipCode.concat(stateAndZipCode);
        });
        return stateWithZipCode;
    }, [states]);
    var options = React.useMemo(function () {
        var regex = new RegExp(/^\d+$/);
        return regex.test(inputBoxValue) ? __spreadArrays([{ label: "Starting with \"" + inputBoxValue + "\"", value: inputBoxValue, type: "startWith" /* START_WITH */, noCheckFilter: true }], optionsOriginal) :
            optionsOriginal;
    }, [optionsOriginal, inputBoxValue]);
    return [options, !!(lookupState.requesting || dataByStateCode.requesting)];
};
var ShowByZipCodeReportsTabSelector = function (props) {
    var resprops = __rest(props, []);
    var reportType = useReportsDialogValue('reportType')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: reportType !== ReportTypeEnum.ZipCodeDetailAnalysis })));
};
var Option = function (props) {
    var _a;
    var StyledOption = props.data.noCheckFilter ?
        StyledStartWithOption :
        ((_a = props.data.children) === null || _a === void 0 ? void 0 : _a.length) ?
            StyledParentOption :
            StyledChildOption;
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement(StyledOption, null, props.children)));
};
var ZipCodeComponent = function (_a) {
    var handleAdd = _a.handleAdd;
    var _b = React.useState(''), inputBoxValue = _b[0], setInputBoxValue = _b[1];
    var value = React.useState('')[0];
    var _c = useOptionsZipCode(inputBoxValue), options = _c[0], isLoading = _c[1];
    return (React.createElement(StyledSelect, { components: { Option: Option }, isLoading: isLoading, options: options, placeholder: "Search State or Zip Code", isClearable: true, name: "", indicatorIcon: "search", noSort: true, closeMenuOnSelect: false, value: value, inputBoxValue: inputBoxValue, onInputBoxValueChange: function (value, actionMeta) {
            if (actionMeta.action === 'input-change') {
                setInputBoxValue(value);
            }
            if (actionMeta.action === 'menu-close') {
                setInputBoxValue('');
            }
        }, onChange: function (value, name, values) { return handleAdd(values); }, menuPosition: "fixed", isFilterStartWith: true, onSelectToRenderOptions: true }));
};
var ZipCodeMemoComponent = React.memo(ZipCodeComponent, function () { return true; });
var LABEL_BADGE_ZIP_CODE = (_a = {},
    _a["zipCode" /* ZIP_CODE */] = function (selected) { return selected.value; },
    _a["allInState" /* ALL_IN_STATE */] = function (selected) { return "All Zip Codes in " + selected.label; },
    _a["startWith" /* START_WITH */] = function (selected) { return "Starting with \"" + selected.value + "\""; },
    _a);
var BadgeZipCodes = React.memo(function (_a) {
    var selected = _a.selected, isZipCodeExisted = _a.isZipCodeExisted, handleRemove = _a.handleRemove;
    return (React.createElement(StyledBadgeZipCodes, { className: isZipCodeExisted ? 'badge-existed' : '' },
        React.createElement("span", { className: 'd-flex' },
            LABEL_BADGE_ZIP_CODE[selected.type](selected),
            "\u00A0",
            React.createElement(StyledCloseButton, { className: 'p-0', onClick: handleRemove },
                React.createElement(FontAwesomeIcon, { className: 'w-1 h-1', style: { fontSize: 14 }, icon: faTimes, color: "#ffffff" })))));
}, function (preProps, nextProps) {
    return preProps.selected.value === nextProps.selected.value &&
        preProps.isZipCodeExisted === nextProps.isZipCodeExisted;
});
export var ShowByZipCodeReportsDialogTab = function (props) {
    var _a = React.useState([]), zipCodesSelected = _a[0], setZipCodesSelected = _a[1];
    var zipCodesSelectedRef = React.useRef([]);
    var _b = React.useState(), existedZipCodesSelected = _b[0], setExistedZipCodesSelected = _b[1];
    var _c = useReportsDialogValue('zipCodes'), setZipCodeReports = _c[1];
    React.useEffect(function () {
        if (existedZipCodesSelected) {
            var timeout_1 = setTimeout(function () {
                setExistedZipCodesSelected(undefined);
            }, 500);
            return function () {
                clearTimeout(timeout_1);
            };
        }
    }, [existedZipCodesSelected]);
    React.useEffect(function () {
        var zipCode = [];
        var startWith = [];
        var allInState = [];
        zipCodesSelected.forEach((function (zipCodeItem) {
            if (zipCodeItem.type === "zipCode" /* ZIP_CODE */) {
                zipCode.push(zipCodeItem.value);
                return;
            }
            if (zipCodeItem.type === "allInState" /* ALL_IN_STATE */) {
                allInState.push(zipCodeItem.value);
                return;
            }
            startWith.push(zipCodeItem.value);
        }));
        setZipCodeReports({
            zipCode: zipCode,
            startWith: startWith,
            allInState: allInState,
        });
    }, [setZipCodeReports, zipCodesSelected]);
    var addZipCodeSelected = function (values) {
        if (!values)
            return;
        var zipCodeExisted;
        var zipCodesSelectedRefCurrent = zipCodesSelectedRef.current;
        var newZipCodes = __spreadArrays(zipCodesSelectedRefCurrent);
        if (values.type === "allInState" /* ALL_IN_STATE */ && Array.isArray(values.children) && values.children.length) {
            newZipCodes = zipCodesSelectedRefCurrent.filter(function (zipCode) { return !_.some(values.children, ['value', zipCode.value]); });
            zipCodeExisted = zipCodesSelectedRefCurrent.find(function (zipCode) { return zipCode.value === values.value; });
            if (!zipCodeExisted) {
                newZipCodes.push({ label: values.stateName || '', type: values.type, value: values.value });
            }
        }
        if (values.type === "zipCode" /* ZIP_CODE */) {
            zipCodeExisted = zipCodesSelectedRefCurrent.find(function (zipCode) {
                var _a;
                return (values.type === zipCode.type && values.value === zipCode.value) ||
                    (((_a = values.parent) === null || _a === void 0 ? void 0 : _a.value) === zipCode.value) ||
                    (values.value.startsWith(zipCode.value));
            });
            if (!zipCodeExisted) {
                zipCodesSelectedRefCurrent.push({ label: '', type: values.type, value: values.value });
                newZipCodes = __spreadArrays(zipCodesSelectedRefCurrent);
            }
        }
        if (values.type === "startWith" /* START_WITH */) {
            zipCodeExisted = zipCodesSelectedRefCurrent.find(function (zipCode) {
                var _a;
                return (values.type === zipCode.type && values.value === zipCode.value) ||
                    (((_a = values.parent) === null || _a === void 0 ? void 0 : _a.value) === zipCode.value);
            });
            newZipCodes = zipCodesSelectedRefCurrent.filter(function (zipCode) { return zipCode.type !== "zipCode" /* ZIP_CODE */ || !zipCode.value.startsWith(values.value); });
            if (!zipCodeExisted) {
                newZipCodes.push({ label: '', type: values.type, value: values.value });
            }
        }
        setExistedZipCodesSelected(zipCodeExisted);
        zipCodesSelectedRef.current = newZipCodes;
        setZipCodesSelected(newZipCodes);
    };
    var removeZipCodeSelected = function (index) {
        zipCodesSelectedRef.current.splice(index, 1);
        setZipCodesSelected(__spreadArrays(zipCodesSelectedRef.current));
    };
    var resetZipCodeSelected = function () {
        zipCodesSelectedRef.current = [];
        setZipCodesSelected(__spreadArrays(zipCodesSelectedRef.current));
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroupWrapper, { row: true },
            React.createElement(TabPaneContentWrapper, { className: 'd-flex py-0 px-3 flex-wrap' },
                React.createElement(TabPaneContentLeft, null,
                    React.createElement(StyledLabel, { className: "font-weight-bold" }, "Add Zip Codes to Report"),
                    React.createElement(ZipCodeMemoComponent, { handleAdd: addZipCodeSelected })),
                React.createElement(TabPaneContentRight, null,
                    React.createElement("div", { className: 'd-flex justify-content-between align-items-center' },
                        React.createElement(StyledLabel, { className: "font-weight-bold" }, "Selected Zip Codes"),
                        React.createElement(StyledResetButton, { className: 'p-0', onClick: resetZipCodeSelected },
                            React.createElement(FontAwesomeIcon, { className: 'w-1 h-1', icon: faUndo }),
                            "\u00A0 Reset")),
                    React.createElement(TabPaneContentRightContent, { className: 'flex-wrap d-flex align-items-center overflow-auto' },
                        zipCodesSelected.length === 0 && 'Search and select zip codes on the left',
                        zipCodesSelected.map(function (selected, index) { return (React.createElement(BadgeZipCodes, { key: index, handleRemove: function () { return removeZipCodeSelected(index); }, selected: selected, isZipCodeExisted: (existedZipCodesSelected === null || existedZipCodesSelected === void 0 ? void 0 : existedZipCodesSelected.value) === selected.value })); })))))));
};
ShowByZipCodeReportsDialogTab.defaultProps = {
    tabId: 'showByZipCodeTab',
    tabName: 'Show by',
    tabField: ['zipCodes'],
    tabSelector: ShowByZipCodeReportsTabSelector,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
