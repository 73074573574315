var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useState, useRef, useEffect } from 'react';
import { formatPhoneNumber, cleanFormattingPhoneNumber } from '../utils';
import { Input } from 'reactstrap';
export var PhoneInput = function (props) {
    var _a = useState(), value = _a[0], setValue = _a[1];
    var ref = useRef();
    var setFieldValue = props.setFieldValue, setFieldTouched = props.setFieldTouched, resprops = __rest(props, ["setFieldValue", "setFieldTouched"]);
    useEffect(function () {
        setValue(formatPhoneNumber(props.value));
    }, [props.value]);
    var handleChange = function (event) {
        var _a;
        var _b = event.currentTarget, name = _b.name, selectionEnd = _b.selectionEnd, value = _b.value;
        setValue(value);
        var cleaned = cleanFormattingPhoneNumber(value);
        if (props.value !== cleaned) {
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, name, cleaned);
            setFieldValue === null || setFieldValue === void 0 ? void 0 : setFieldValue(cleaned, name);
            var newSelection_1 = Math.max(formatPhoneNumber(value).length - value.length, 0) + (selectionEnd || 0);
            setTimeout(function () { return ref.current.setSelectionRange(newSelection_1, newSelection_1); });
        }
    };
    var handleBlur = function (event) {
        var onBlur = props.onBlur;
        var formatedValue = formatPhoneNumber(value);
        if (value !== formatedValue) {
            setValue(formatedValue);
        }
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        setFieldTouched === null || setFieldTouched === void 0 ? void 0 : setFieldTouched(true, props.name);
    };
    var handleKeyPress = function (event) {
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!/^\d*$/.test(key)) {
            event.preventDefault();
            return false;
        }
        return true;
    };
    return (React.createElement(Input, __assign({}, resprops, { innerRef: ref, type: "text", maxLength: 12, value: value || '', onChange: handleChange, onBlur: handleBlur, onKeyPress: handleKeyPress })));
};
