var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { Popup as MapboxPopup } from 'react-map-gl';
export var Popup = function (props) {
    var show = props.show, onClose = props.onClose, resprops = __rest(props, ["show", "onClose"]);
    useEffect(function () {
        if (show && onClose) {
            var handleKeyDown_1 = function (event) {
                var key = event.key || event.keyCode;
                if (key === 'Escape' || key === 'Esc' || key === 27) {
                    onClose();
                }
            };
            document.addEventListener('keydown', handleKeyDown_1);
            return function () {
                document.removeEventListener('keydown', handleKeyDown_1);
            };
        }
    }, [onClose, show]);
    if (!show)
        return null;
    return React.createElement(MapboxPopup, __assign({}, resprops));
};
