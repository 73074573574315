var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FederalRepresentativeDetail, StateRepresentativeDetail } from './representativedetail';
import { GeoLevelEnum, RightPanelTypeEnum } from '../../../enums';
import { getDisplayUserName, separateGeoLevelId } from '../../../utils';
import { useLookupState, useLookupZipCodeDistrict } from '../../../lookupdata';
import { useCachedData, useViewValue } from '../../../state';
import { FallbackComponent } from '../../../common';
import { useToggle } from '../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDemocrat, faRepublican, faFlagUsa } from '@fortawesome/free-solid-svg-icons';
var GET_FEDERAL_REPRESENTATIVES_API = '/api/general/representative/get-federal-representatives';
var GET_STATE_REPRESENTATIVES_UPPER_API = '/api/general/representative/get-state-representatives/UPPER';
var GET_STATE_REPRESENTATIVES_LOWER_API = '/api/general/representative/get-state-representatives/LOWER';
var StyledGroup = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.25rem 0;\n"], ["\n  padding: 0.25rem 0;\n"])));
var GroupTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 1.25rem;\n"], ["\n  font-weight: bold;\n  font-size: 1.25rem;\n"])));
var GroupContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var StyledRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  padding: 0.5rem 1rem;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n"], ["\n  ", ";\n  padding: 0.5rem 1rem;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 0.5rem;\n"])), function (props) { return (props.expanded ? 'background-color: #007bff; color: white;' : 'background-color: #ededed'); });
var StyledRowExpanded = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0.5rem 0.5rem 0.5rem 1rem;\n  background-color: white;\n"], ["\n  padding: 0.5rem 0.5rem 0.5rem 1rem;\n  background-color: white;\n"])));
var StyledFullName = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var StyledIcon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (props) { return (props.expanded ? 'white' : props.isRepublican ? 'red' : 'blue'); });
var FederalRepresentativeItem = function (props) {
    var _a;
    var _b = useToggle(), expandedFederal = _b[0], toggleExpandFederal = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledRow, { onClick: toggleExpandFederal, expanded: expandedFederal },
            React.createElement("div", null,
                React.createElement(StyledFullName, null,
                    getDisplayUserName(props.data.frFirstName, props.data.frLastName, props.data.frMiddleName),
                    "\u00A0 (",
                    props.data.frParty.charAt(0).toUpperCase(),
                    ")"),
                React.createElement("div", null,
                    props.data.frDistrict ? 'Representative' : 'Senator',
                    "\u00A0",
                    props.data.frDistrict ? "(" + props.data.frStateAbbreviation + " " + ((_a = props.data.frDistrict) === null || _a === void 0 ? void 0 : _a.padStart(2, '0')) + ")" : "(" + props.data.frStateAbbreviation + ")")),
            React.createElement(StyledIcon, { expanded: expandedFederal, isRepublican: props.data.frParty.charAt(0).toUpperCase() === 'R' },
                React.createElement(FontAwesomeIcon, { size: "3x", icon: props.data.frParty.charAt(0).toUpperCase() === 'D' ? faDemocrat : props.data.frParty.charAt(0).toUpperCase() === 'R' ? faRepublican : faFlagUsa }))),
        React.createElement(StyledRowExpanded, { hidden: !expandedFederal },
            React.createElement(FederalRepresentativeDetail, { data: props.data }))));
};
var StateRepresentativeItem = function (props) {
    var _a;
    var _b = useToggle(), expandedState = _b[0], toggleExpandState = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledRow, { onClick: toggleExpandState, expanded: expandedState },
            React.createElement("div", null,
                React.createElement(StyledFullName, null,
                    getDisplayUserName(props.data.srFirstName, props.data.srLastName, props.data.srMiddleName),
                    "\u00A0 (",
                    props.data.srParty.charAt(0).toUpperCase(),
                    ")"),
                React.createElement("div", null,
                    props.data.srChamber === 'upper' ? 'State Senator' : 'State Representative',
                    "\u00A0",
                    React.createElement("span", null, props.data.srDistrict ? "(" + props.data.stState + " " + ((_a = props.data.srDistrict) === null || _a === void 0 ? void 0 : _a.padStart(2, '0')) + ")" : "(" + props.data.stState + ")"))),
            React.createElement(StyledIcon, { expanded: expandedState, isRepublican: props.data.srParty.charAt(0).toUpperCase() === 'R' },
                React.createElement(FontAwesomeIcon, { size: "3x", icon: props.data.srParty.charAt(0).toUpperCase() === 'D' ? faDemocrat : props.data.srParty.charAt(0).toUpperCase() === 'R' ? faRepublican : faFlagUsa }))),
        React.createElement(StyledRowExpanded, { hidden: !expandedState },
            React.createElement(StateRepresentativeDetail, { data: props.data }))));
};
var DataGroup = function (props) {
    var _a;
    return (React.createElement(StyledGroup, null,
        React.createElement(GroupTitle, null, props.title),
        React.createElement(GroupContent, null, (_a = props.data) === null || _a === void 0 ? void 0 : _a.map(function (rep) { return (React.createElement(props.itemComponent, { key: rep.id, data: rep })); }))));
};
var useRepresentativePanel = function (apiUrl) {
    var lookupState = useLookupState()[0].data;
    var selectedRegion = useViewValue('selectedRegion')[0];
    var selectedId = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail ? selectedRegion.id : undefined;
    var geoLevel = useViewValue('geoType')[0];
    var lookupZipCode = useLookupZipCodeDistrict()[0];
    var _a = useCachedData(apiUrl), _b = _a[0], requesting = _b.requesting, apiRes = _b.data, error = _b.error, fetchData = _a[1];
    useEffect(function () {
        fetchData();
    }, [fetchData]);
    var _c = useMemo(function () {
        var _a;
        var _b = separateGeoLevelId(selectedId), stateCode = _b[0], district = _b[1];
        if (geoLevel !== GeoLevelEnum.Zip5 || (selectedId === null || selectedId === void 0 ? void 0 : selectedId.endsWith('-'))) {
            return [(stateCode && ((_a = lookupState === null || lookupState === void 0 ? void 0 : lookupState.find(function (lookup) { return lookup.stateCode === stateCode.padStart(2, '0'); })) === null || _a === void 0 ? void 0 : _a.stateAbbreviation)) || '', district];
        }
        else {
            return [lookupZipCode.getStateAbbOfZipCode(selectedId) || '', district];
        }
    }, [selectedId, geoLevel, lookupZipCode, lookupState]), stateAbb = _c[0], district = _c[1];
    return {
        stateAbb: stateAbb,
        district: district,
        requesting: requesting,
        apiData: apiRes === null || apiRes === void 0 ? void 0 : apiRes.data,
        error: error,
        fetchData: fetchData,
    };
};
export var LegislatorsComponent = function (props) {
    var _a, _b;
    var data = useRepresentativePanel(GET_FEDERAL_REPRESENTATIVES_API);
    return (React.createElement(FallbackComponent, { requesting: data.requesting, error: data.error, retry: data.fetchData },
        React.createElement(DataGroup, { title: "U.S. Senators", data: (_a = data.apiData) === null || _a === void 0 ? void 0 : _a.senators[data.stateAbb], itemComponent: FederalRepresentativeItem }),
        props.type === GeoLevelEnum.Congressional && (React.createElement(DataGroup, { title: "U.S. Representative", data: (_b = data.apiData) === null || _b === void 0 ? void 0 : _b.representatives[data.stateAbb + "-" + data.district], itemComponent: FederalRepresentativeItem }))));
};
export var StateDelegatesComponent = function (props) {
    var _a, _b, _c, _d;
    var apiUrl = '';
    if (props.type === GeoLevelEnum.StateUpper) {
        apiUrl = GET_STATE_REPRESENTATIVES_UPPER_API;
    }
    else if (props.type === GeoLevelEnum.StateLower) {
        apiUrl = GET_STATE_REPRESENTATIVES_LOWER_API;
    }
    var data = useRepresentativePanel(apiUrl);
    if (props.type !== GeoLevelEnum.StateUpper && props.type !== GeoLevelEnum.StateLower)
        return null;
    return (React.createElement(FallbackComponent, { requesting: data.requesting, error: data.error, retry: data.fetchData },
        props.type === GeoLevelEnum.StateUpper && ((_a = data.apiData) === null || _a === void 0 ? void 0 : _a[data.stateAbb + "-" + data.district]) && (React.createElement(DataGroup, { title: "State Senator", data: (_b = data.apiData) === null || _b === void 0 ? void 0 : _b[data.stateAbb + "-" + data.district], itemComponent: StateRepresentativeItem })),
        props.type === GeoLevelEnum.StateLower && ((_c = data.apiData) === null || _c === void 0 ? void 0 : _c[data.stateAbb + "-" + data.district]) && (React.createElement(DataGroup, { title: "State Representative", data: (_d = data.apiData) === null || _d === void 0 ? void 0 : _d[data.stateAbb + "-" + data.district], itemComponent: StateRepresentativeItem }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
