var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { useViewValue, useFilterSetting } from '../state';
import { Select } from '../common';
import { GeoLevelEnum, RightPanelTypeEnum } from '../enums';
import { getDisplayText } from '../utils';
import { useLookupState, useLookupZipCodeDistrict, LookupMSAComponent, useLookupMSA, LookupCountyComponent, useLookupCounty, } from '../lookupdata';
import { useEffectFilterSettingCount } from './useeffectfiltercount';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useViewport } from '../map';
import { useFilterCount } from '../filterdialog';
var EXCLUDE_STATE_COUNT_INDEXES = ['lupus', 'fluvaccine'];
var StyledParentOption = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 500;\n"], ["\n  font-weight: 500;\n"])));
var StyledChildOption = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 1rem;\n"], ["\n  padding-left: 1rem;\n"])));
var usePatientCountForState = function () {
    var indexConfig = useHostnameIndexInfo();
    var _a = useFilterCount('STATE_CODE', indexConfig), countData = _a[0], res = _a.slice(1);
    var filterSetting = useFilterSetting();
    var formatOptionLabel = useCallback(function (i) {
        var _a;
        var showLabelCount = (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && !filterSetting.isAggregatedDataSource
            // NMQF-2098: Skip filter count for STATE_CODE in Lupus index
            && !EXCLUDE_STATE_COUNT_INDEXES.includes(indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.hostname);
        return "" + i.stateName + (showLabelCount ? " (" + getDisplayText((_a = countData.data) === null || _a === void 0 ? void 0 : _a[i.stateCode]) + ")" : '');
    }, [countData.data, filterSetting.isAggregatedDataSource, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.hostname]);
    return __spreadArrays([__assign(__assign({}, countData), { formatOptionLabel: formatOptionLabel })], res);
};
var transformLookupStateForGeoId = function (i, suffix) {
    if (suffix === void 0) { suffix = ''; }
    return (__assign(__assign({}, i), { value: "" + parseInt(i.stateCode, 10) + suffix, label: i.stateName }));
};
var useCombineTwoGeoLevel = function (dataByStateCode, sortFn, transformFn, isCombineKey, key) {
    if (isCombineKey === void 0) { isCombineKey = true; }
    var lookupState = useLookupState()[0];
    var countData = usePatientCountForState()[0];
    var _a = useState([]), options = _a[0], setOptions = _a[1];
    var indexConfig = useHostnameIndexInfo();
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    useEffect(function () {
        var _a;
        setOptions((_a = lookupState.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return !singleState || parseInt(i.stateCode, 10) === singleState.stateCode; }).flatMap(function (i) {
            var _a, _b;
            var parent = transformLookupStateForGeoId(i, '-');
            var data = key ? (_a = dataByStateCode.data) === null || _a === void 0 ? void 0 : _a[key] : dataByStateCode.data;
            var children = (_b = data === null || data === void 0 ? void 0 : data[i.stateCode]) === null || _b === void 0 ? void 0 : _b.sort(sortFn).map(function (child) {
                var transformedChild = transformFn(child);
                var childValue = ("" + transformedChild.value).replace(/^0+(\d+)$/, '$1');
                return __assign(__assign({}, transformedChild), { value: isCombineKey ? "" + parent.value + childValue : childValue, parent: parent });
            });
            return (children === null || children === void 0 ? void 0 : children.length) ? __spreadArrays([__assign(__assign({}, parent), { children: children })], children) : [];
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupState.data, dataByStateCode.data]);
    var formatOptionLabel = function (i) { var _a; return (((_a = i.children) === null || _a === void 0 ? void 0 : _a.length) ? countData.formatOptionLabel(i) : i.label); };
    return {
        options: options,
        noSort: true,
        isLoading: lookupState.requesting || dataByStateCode.requesting,
        components: { Option: Option },
        formatOptionLabel: formatOptionLabel,
    };
};
var useZipCodeDistrictSelectProps = function (key, isCombineKey) {
    var dataByStateCode = useLookupZipCodeDistrict()[0];
    var sortFn = function (a, b) { return ("" + a).padStart(5, '0').localeCompare(("" + b).padStart(5, '0')); };
    var transformFn = function (value) { return ({ value: value, label: value }); };
    return useCombineTwoGeoLevel(dataByStateCode, sortFn, transformFn, isCombineKey, key);
};
var Option = function (props) {
    var _a;
    var StyledOption = ((_a = props.data.children) === null || _a === void 0 ? void 0 : _a.length) ? StyledParentOption : StyledChildOption;
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement(StyledOption, null, props.children)));
};
var SingleValue = function (props) {
    return (React.createElement(components.SingleValue, __assign({}, props),
        props.data.parent && React.createElement("b", null,
            props.data.parent.label,
            "\u00A0"),
        props.data.label));
};
var GeoSelectComponent = function (props) {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var _b = useViewValue('mapView', 'zoomId'), setZoomId = _b[1];
    var _c = useViewport(), resetViewport = _c[2];
    var selectedId = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail ? selectedRegion.id : null;
    var handleChange = function (id) {
        setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail, id: id });
        setZoomId(id);
        if (!id) {
            resetViewport();
        }
    };
    return (React.createElement(Select, __assign({}, props, { components: __assign(__assign({}, props.components), { SingleValue: SingleValue }), isClearable: true, width: "17", name: "selectId", indicatorIcon: "search", value: selectedId, onChange: handleChange })));
};
var ZipCodeComponent = function () {
    var selectProps = useZipCodeDistrictSelectProps('zipCode5ByState', false);
    return React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or Zip Code" }, selectProps));
};
var Zip3Component = function () {
    var selectProps = useZipCodeDistrictSelectProps('zipCode3ByState');
    return React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or Zip Code" }, selectProps));
};
var CongrestionalComponent = function () {
    var selectProps = useZipCodeDistrictSelectProps('congressionalDistrictByState');
    return React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or District" }, selectProps));
};
var StateUpperComponent = function () {
    var selectProps = useZipCodeDistrictSelectProps('upperHouseDistrictByState');
    return React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or District" }, selectProps));
};
var StateLowerComponent = function () {
    var selectProps = useZipCodeDistrictSelectProps('lowerHouseDistrictByState');
    return React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or District" }, selectProps));
};
var CountyComponent = function () {
    var countyData = useLookupCounty()[0];
    var sortFn = function (a, b) { return a.countyName.toLowerCase().localeCompare(b.countyName.toLowerCase()); };
    var transformFn = function (i) { return ({ value: i.countyCode, label: i.countyName }); };
    var selectProps = useCombineTwoGeoLevel(countyData, sortFn, transformFn);
    return (React.createElement(React.Fragment, null,
        React.createElement(GeoSelectComponent, __assign({ placeholder: "Search State or County" }, selectProps)),
        React.createElement(LookupCountyComponent, null)));
};
var StateComponent = function () {
    var lookupState = useLookupState()[0];
    var countData = usePatientCountForState()[0];
    return (React.createElement(GeoSelectComponent, { placeholder: "Search State", isLoading: lookupState.requesting, options: lookupState.data, optionsTransform: function (i) { return transformLookupStateForGeoId(i); }, formatOptionLabel: countData.formatOptionLabel }));
};
var MSAComponent = function () {
    var _a;
    var lookupMsa = useLookupMSA()[0];
    var indexConfig = useHostnameIndexInfo();
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    var re = new RegExp("^[^,]*,.*(?: |-)(" + (singleState === null || singleState === void 0 ? void 0 : singleState.stateAbb) + ")(?:$| |-)");
    var options = singleState ? (_a = lookupMsa.data) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return re.test(i.msaName); }) : lookupMsa.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(GeoSelectComponent, { placeholder: "Search MSA", isLoading: lookupMsa.requesting, options: options, optionsTransform: function (i) { return ({ value: i.msaNumber, label: i.msaName }); } }),
        React.createElement(LookupMSAComponent, null)));
};
var SEARCH_COMPONENT_BY_GEO = (_a = {},
    _a[GeoLevelEnum.Zip5] = ZipCodeComponent,
    _a[GeoLevelEnum.Zip3] = Zip3Component,
    _a[GeoLevelEnum.State] = StateComponent,
    _a[GeoLevelEnum.County] = CountyComponent,
    _a[GeoLevelEnum.MSA] = MSAComponent,
    _a[GeoLevelEnum.Congressional] = CongrestionalComponent,
    _a[GeoLevelEnum.StateUpper] = StateUpperComponent,
    _a[GeoLevelEnum.StateLower] = StateLowerComponent,
    _a);
export var GeoSearchComponent = function () {
    var _a = usePatientCountForState(), fetchPatientCountForState = _a[1];
    var geoLevel = useViewValue('geoType')[0];
    var SearchCoponent = geoLevel && SEARCH_COMPONENT_BY_GEO[geoLevel];
    useEffectFilterSettingCount(function (body, indexConfig) {
        // NMQF-2098: Skip filter count for STATE_CODE in Lupus index
        if (!EXCLUDE_STATE_COUNT_INDEXES.includes(indexConfig.hostname)) {
            fetchPatientCountForState(body);
        }
    });
    return React.createElement(React.Fragment, null, SearchCoponent && React.createElement(SearchCoponent, null));
};
var templateObject_1, templateObject_2;
