/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var FilterSidebarConst = {
    InfluenzaVaccine: {
        tabId: 'influenzavaccinestab',
        title: 'Vaccine',
    },
    Vaccine: {
        tabId: 'vaccinestab',
        title: 'Vaccine',
    },
    PrimaryCondition: {
        tabId: 'primaryConditionTab',
        title: 'Primary Conditions',
    },
    SecondaryCondition: {
        tabId: 'secondaryConditionTab',
        title: 'Secondary Conditions',
    },
    AdditionalCondition: {
        tabId: 'additionalConditionTab',
        title: 'Additional Conditions',
    }
};
