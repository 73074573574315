var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
var ICON = "M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3\n  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9\n  C20.1,15.8,20.2,15.8,20.2,15.7z";
var ImagePin = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 20px;\n"], ["\n  width: 20px;\n"])));
var SIZE = 35;
var StyledPin = styled.svg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: ", ";\n  fill: ", ";\n  stroke: ", ";\n  color: ", ";\n"], ["\n  cursor: ", ";\n  fill: ", ";\n  stroke: ", ";\n  color: ", ";\n"])), function (props) { return (props.pointer ? 'pointer' : 'default'); }, function (props) { return (props.selected ? '#000' : props.color); }, function (props) { return (props.selected ? '#000' : props.stroke || props.color); }, function (props) { return (props.selected ? '#000' : props.stroke || props.color); });
export var Pin = React.memo(function (props) {
    var _a = props.size, size = _a === void 0 ? SIZE : _a;
    var onClick = function () {
        var _a;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.payload);
    };
    return (React.createElement(StyledPin, { className: props.className, pointer: !!props.onClick, color: props.color, stroke: props.stroke, selected: props.selected, height: size, width: size, viewBox: "0 0 24 24", onClick: onClick },
        React.createElement("path", { d: ICON }),
        React.createElement("circle", { cx: 12, cy: 10, r: 8, style: { fill: 'white', stroke: props.stroke } }),
        props.icon && React.createElement(FontAwesomeIcon, { height: 11, y: 4, icon: props.icon }),
        props.imgPin && React.createElement("image", { height: 10, y: 5, x: 7, xlinkHref: "/assets/images/icons/" + props.imgPin + ".png" }),
        props.text && (React.createElement("text", { x: "50%", y: "50%", dy: "-0.15rem", style: { fill: '#000', stroke: 'none', fontSize: '8px', textAnchor: 'middle', alignmentBaseline: 'central' } }, props.text))));
});
export var ClusterPin = React.memo(function (props) {
    var _a = props.size, size = _a === void 0 ? SIZE : _a;
    return (React.createElement(StyledPin, { height: size, width: size, viewBox: "0 0 24 24", pointer: true, onClick: props.onClick },
        React.createElement("circle", { cx: 12, cy: 12, r: 11.5, style: { fill: props.color, stroke: props.stroke || props.color, strokeWidth: '0.5px' } }),
        React.createElement("text", { x: "50%", y: "50%", style: { fill: '#fff', stroke: 'none', fontSize: '8px', textAnchor: 'middle', alignmentBaseline: 'central' } }, props.text)));
});
var templateObject_1, templateObject_2;
