var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Source, Layer } from 'react-map-gl';
import { useExtractGeoData, useMapData } from './patientchoroplethlayer/usemapdata';
import { GeoLevelEnum, RightPanelTypeEnum } from '../enums';
import { ENV_STATE_BORDER_COLOR } from '../env';
import { useGeoJson, useMount } from '../hooks';
import { useViewValue } from '../state/viewsetting';
import { MapFallback, useMapValue, useZoomToFeature } from './mapprovider';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useLookupZipCodeDistrict } from '../lookupdata';
import { isFeatureInAState, isEmpty } from '../utils';
export var API_URL_STATE_GEO = '/assets/topojsons/cb_2021_us_state_500k_polyline_topo.json';
export var GEO_JSON_URL_BY_LEVEL = (_a = {},
    _a[GeoLevelEnum.Zip5] = '/assets/topojsons/tl_2021_us_zcta520_topo.json',
    _a[GeoLevelEnum.Zip3] = '/assets/topojsons/zip3_digit_topo.json',
    _a[GeoLevelEnum.State] = '/assets/topojsons/cb_2021_us_state_500k_topo.json',
    _a[GeoLevelEnum.MSA] = '/assets/topojsons/cb_2021_us_cbsa_500k_topo.json',
    _a[GeoLevelEnum.County] = '/assets/topojsons/cb_2021_us_county_500k_topo.json',
    _a[GeoLevelEnum.Congressional] = '/assets/topojsons/cb_2022_us_cd118_500k_topo.json',
    _a[GeoLevelEnum.StateUpper] = '/assets/topojsons/cb_2022_us_sldu_500k_topo.json',
    _a[GeoLevelEnum.StateLower] = '/assets/topojsons/cb_2022_us_sldl_500k_topo.json',
    _a);
var stateLayer = {
    id: 'statelayer',
    type: 'line',
    paint: {
        'line-color': ENV_STATE_BORDER_COLOR,
    },
};
var highlightLayer = {
    id: 'highlightlayer',
    type: 'line',
    paint: {
        'line-color': '#666',
        'line-width': 3,
    },
};
var selectedLayer = {
    id: 'selectedlayer',
    type: 'line',
    paint: {
        'line-color': '#027DFF',
        'line-width': 4,
    },
};
export var useSelectedGeoJson = function (option) {
    var _a = useViewValue('geoType')[0], geoLevel = _a === void 0 ? GeoLevelEnum.State : _a;
    return useGeoJson(GEO_JSON_URL_BY_LEVEL[geoLevel], option);
};
export var SelectedGeographySource = function (props) {
    var _a;
    var _b = useMapData()[0], data = _b.data, mapDataRequesting = _b.requesting;
    var hoveringInfo = useMapValue('hoveringInfo')[0];
    var zoomToFeature = useZoomToFeature();
    var _c = useViewValue('selectedRegion'), selectedRegion = _c[0], setSelectedRegion = _c[1];
    var selectedId = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail ? selectedRegion.id : undefined;
    var _d = useViewValue('mapView', 'selectedGeoProperties'), setSelectedGeoProperties = _d[1];
    var _e = useViewValue('geoType')[0], geoLevel = _e === void 0 ? GeoLevelEnum.State : _e;
    var zipcodeData = useLookupZipCodeDistrict()[0];
    var indexConfig = useHostnameIndexInfo();
    var singleState = ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}).singleState;
    var zoomId = useViewValue('mapView', 'zoomId')[0];
    var filterForState = useMemo(function () { return (!singleState ? undefined : function (properties) { return isFeatureInAState(singleState, properties, GeoLevelEnum.State); }); }, [singleState]);
    var _f = useGeoJson(API_URL_STATE_GEO, {
        filter: filterForState,
    }), stateGeoJsonData = _f[0], fetchStateGeoJson = _f[1];
    var filterForGeo = useMemo(function () {
        return !singleState
            ? undefined
            : function (properties) { var _a; return isFeatureInAState(singleState, properties, geoLevel, (_a = zipcodeData.data) === null || _a === void 0 ? void 0 : _a.stateByZipCode5); };
    }, [singleState, geoLevel, zipcodeData.data]);
    var extractGeoData = useExtractGeoData();
    var propertyMapping = useCallback(function (properties) {
        var breakIdx = extractGeoData(properties === null || properties === void 0 ? void 0 : properties.id).breakIdx;
        return __assign(__assign({}, properties), { breakIdx: breakIdx });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);
    var _g = useSelectedGeoJson({ filter: filterForGeo, propertyMapping: propertyMapping, forceClean: !data }), geoJsonData = _g[0], fetchGeoJson = _g[1];
    var _h = useState(true), isCookingGeoJson = _h[0], setIsCookingGeoJson = _h[1];
    useMount(function () {
        fetchStateGeoJson();
    });
    useEffect(function () {
        fetchGeoJson();
    }, [fetchGeoJson]);
    useEffect(function () {
        var _a;
        setIsCookingGeoJson(true);
        var delay = Math.round((((_a = geoJsonData.data) === null || _a === void 0 ? void 0 : _a.features.length) || 0) / 20);
        var handler = setTimeout(function () {
            setIsCookingGeoJson(!!geoJsonData.requesting || !!mapDataRequesting);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [geoJsonData.data, geoJsonData.requesting, mapDataRequesting]);
    var selectedFeature = useMemo(function () { var _a, _b; return !selectedId ? undefined : selectedId.endsWith('-') ? (_a = stateGeoJsonData.mapFeature) === null || _a === void 0 ? void 0 : _a[selectedId.replace('-', '')] : (_b = geoJsonData.mapFeature) === null || _b === void 0 ? void 0 : _b[selectedId]; }, [selectedId, stateGeoJsonData.mapFeature, geoJsonData.mapFeature]);
    useEffect(function () {
        if (selectedFeature && zoomId === selectedId) {
            zoomToFeature(selectedFeature);
        }
        if (selectedFeature && selectedId && !selectedId.endsWith('-')) {
            setSelectedGeoProperties(selectedFeature.properties);
        }
        else {
            setSelectedGeoProperties();
        }
        if (selectedId &&
            !selectedFeature &&
            !isEmpty(stateGeoJsonData.mapFeature) &&
            !isEmpty(geoJsonData.mapFeature) &&
            (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.RegionDetail) {
            setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoJsonData.mapFeature, selectedFeature, selectedId, selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type, stateGeoJsonData.mapFeature, zoomId]);
    var hoveringId = (_a = hoveringInfo === null || hoveringInfo === void 0 ? void 0 : hoveringInfo[0]) === null || _a === void 0 ? void 0 : _a.id;
    var highlightGeoJson = useMemo(function () {
        var _a;
        return ({
            type: 'FeatureCollection',
            features: (hoveringId && [(_a = geoJsonData.mapFeature) === null || _a === void 0 ? void 0 : _a[hoveringId]]) || [],
        });
    }, [geoJsonData.mapFeature, hoveringId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Source, { type: "geojson", data: geoJsonData.data, id: "selectedgeographysource" },
            props.children || React.createElement(React.Fragment, null),
            React.createElement(Layer, __assign({}, selectedLayer, { filter: ['==', 'id', selectedId || ''] }))),
        React.createElement(Source, { type: "geojson", data: highlightGeoJson },
            React.createElement(Layer, __assign({}, highlightLayer))),
        React.createElement(Source, { type: "geojson", data: stateGeoJsonData.data },
            React.createElement(Layer, __assign({}, stateLayer))),
        React.createElement(MapFallback, { requesting: isCookingGeoJson, error: geoJsonData.error, retry: fetchGeoJson })));
};
