var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, Label, FormGroup, ListGroupItem } from 'reactstrap';
import { Select, FallbackComponent } from '../common';
import { LookupYearComponent, useCMSDataYear } from '../lookupdata';
import { DATA_SOURCES } from '../configs';
import { REPORT_DATA_SOURCES, useReportsDialogValue } from './reportsdialogprovider';
import { useDefaultFilter, useHostnameIndexInfo } from '../hostnameindexinfo';
import _ from 'lodash';
var DataYearReportsTabSelector = function (props) {
    var resprops = __rest(props, []);
    var isSeasonStatistic = useReportsDialogValue('isSeasonStatistic')[0];
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: isSeasonStatistic })));
};
export var DataYearReportsDialogTab = function (props) {
    var _a;
    var _b = useReportsDialogValue('dataSourceType'), dataSourceType = _b[0], setDataSourceType = _b[1];
    var _c = useReportsDialogValue('dataYear'), dataYear = _c[0], setDataYear = _c[1];
    var listYearData = useCMSDataYear(dataSourceType)[0];
    var _d = useReportsDialogValue('conditions'), conditions = _d[0], setConditions = _d[1];
    var indexConfig = useHostnameIndexInfo();
    var defaultFilter = useDefaultFilter();
    useEffect(function () {
        var _a;
        if (((_a = listYearData.data) === null || _a === void 0 ? void 0 : _a.length) && !listYearData.data.includes(dataYear)) {
            var yearDataLatest = listYearData.data[listYearData.data.length - 1];
            if (!_.isNaN(Number(yearDataLatest)))
                setDataYear(yearDataLatest);
        }
    }, [dataYear, listYearData.data, setDataYear]);
    var handleChangeDataSource = function (value, name, option) {
        setDataSourceType(value);
    };
    var handleChangeYear = function (value) {
        var _a;
        setDataYear(value);
        if ((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) {
            setConditions(conditions === null || conditions === void 0 ? void 0 : conditions.filter(function (cc) { var _a, _b; return !((_b = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.ignoreConditionsByYear) === null || _a === void 0 ? void 0 : _a["" + value]) === null || _b === void 0 ? void 0 : _b.includes(cc)); }));
        }
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Source"),
            React.createElement(Select, { name: "dataSource", options: ((_a = defaultFilter.report) === null || _a === void 0 ? void 0 : _a.cmsDataSource) || REPORT_DATA_SOURCES, optionsTransform: function (v) { return DATA_SOURCES[v]; }, noSort: true, value: dataSourceType, onChange: handleChangeDataSource })),
        React.createElement(FormGroup, null,
            React.createElement(Label, { className: "font-weight-bold" }, "Data Year"),
            React.createElement(FallbackComponent, { spinnerSize: 50, requesting: listYearData.requesting, error: listYearData.error },
                React.createElement(Select, { name: "dataYear", options: listYearData.data, noSort: true, value: dataYear, onChange: handleChangeYear, optionsTransform: function (year) { return ({ value: year, label: "" + year }); } }))),
        React.createElement(LookupYearComponent, { dataSource: dataSourceType })));
};
DataYearReportsDialogTab.defaultProps = {
    tabId: 'DataYearTab',
    tabName: 'Data Set',
    tabField: ['dataSourceType', 'dataYear'],
    tabSelector: DataYearReportsTabSelector,
};
