var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import styled, { css } from 'styled-components';
var indeterminateStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0;\n  :not(:disabled) {\n    background-color: #0374fe;\n    :hover {\n      background-color: #065cc8;\n    }\n  }\n  :disabled {\n    background-color: #d1d1d1;\n  }\n"], ["\n  border: 0;\n  :not(:disabled) {\n    background-color: #0374fe;\n    :hover {\n      background-color: #065cc8;\n    }\n  }\n  :disabled {\n    background-color: #d1d1d1;\n  }\n"])));
export var StyledCheckbox = styled(function (_a) {
    var indeterminate = _a.indeterminate, props = __rest(_a, ["indeterminate"]);
    return React.createElement(Label, __assign({}, props));
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  input {\n    appearance: none;\n    width: 0.875rem;\n    height: 0.875rem;\n    border-radius: 0.125rem;\n    border: 1px solid #757575;\n    :hover:not(:disabled) {\n      border-color: #212529;\n    }\n    :disabled {\n      background-color: #f8f8f8;\n      border-color: #d1d1d1;\n    }\n    ", "\n    :checked {\n      ", "\n    }\n  }\n  input:checked + span::before {\n    content: '';\n    width: 0.25rem;\n    height: 0.625rem;\n    border: 1px solid #fff;\n    border-width: 0 0.125rem 0.125rem 0;\n    transform: rotate(45deg);\n    position: absolute;\n    left: -0.9rem;\n    top: 0.4rem;\n  }\n"], ["\n  position: relative;\n  input {\n    appearance: none;\n    width: 0.875rem;\n    height: 0.875rem;\n    border-radius: 0.125rem;\n    border: 1px solid #757575;\n    :hover:not(:disabled) {\n      border-color: #212529;\n    }\n    :disabled {\n      background-color: #f8f8f8;\n      border-color: #d1d1d1;\n    }\n    ", "\n    :checked {\n      ", "\n    }\n  }\n  input:checked + span::before {\n    content: '';\n    width: 0.25rem;\n    height: 0.625rem;\n    border: 1px solid #fff;\n    border-width: 0 0.125rem 0.125rem 0;\n    transform: rotate(45deg);\n    position: absolute;\n    left: -0.9rem;\n    top: 0.4rem;\n  }\n"])), function (props) { return (props.indeterminate ? indeterminateStyle : ''); }, indeterminateStyle);
export var SimpleCheckbox = function (props) {
    return (React.createElement(StyledCheckbox, { indeterminate: props.indeterminate, check: true, className: props.disabled ? 'text-muted' : '' },
        React.createElement(Input, { type: "checkbox", value: props.value, disabled: props.disabled, checked: props.checked, onChange: props.onChange }),
        React.createElement("span", null,
            props.label,
            props.children)));
};
export var Checkbox = function (props) {
    var value = useMemo(function () { return (Array.isArray(props.value) ? props.value.map(function (i) { return i.value; }) : [props.value]); }, [props.value]);
    var handleChange = function (event) {
        var _a;
        var newValue;
        if (event.target.checked) {
            newValue = __spreadArrays((props.groupValue || []), value);
        }
        else {
            newValue = (_a = props.groupValue) === null || _a === void 0 ? void 0 : _a.filter(function (gv) { return !value.some(function (v) { return JSON.stringify(v) === JSON.stringify(gv); }); });
        }
        props.sortValue && (newValue === null || newValue === void 0 ? void 0 : newValue.sort(props.sortValue));
        props.onChange(newValue, props.payload);
    };
    var checked = useMemo(function () { return value.every(function (v) { var _a; return (_a = props.groupValue) === null || _a === void 0 ? void 0 : _a.some(function (gv) { return JSON.stringify(gv) === JSON.stringify(v); }); }); }, [value, props.groupValue]);
    return (React.createElement(FormGroup, { check: true, inline: props.inline },
        React.createElement(SimpleCheckbox, { label: props.label, value: value === null || value === void 0 ? void 0 : value[0], disabled: props.disabled, checked: checked, onChange: handleChange })));
};
export var CheckboxGroup = function (props) {
    var _a;
    var options = props.optionsTransform ? (_a = props.options) === null || _a === void 0 ? void 0 : _a.map(props.optionsTransform) : props.options;
    !props.noSort && (options === null || options === void 0 ? void 0 : options.sort(function (a, b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0); }));
    options = props.input ? options === null || options === void 0 ? void 0 : options.filter(function (i) { var _a; return i.label.toLowerCase().includes((_a = props.input) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }) : options;
    var handleChange = function (value, payload) {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, value, payload);
        (_b = props.setFieldValue) === null || _b === void 0 ? void 0 : _b.call(props, value, props.name || '');
    };
    if (!(options === null || options === void 0 ? void 0 : options.length))
        return null;
    return (React.createElement("div", { className: props.className },
        !props.noAll && (React.createElement(Checkbox, { disabled: props.disabled && props.disabled('__ALL__'), inline: props.inline, value: options, groupValue: props.value, label: props.allText || 'Select All', onChange: handleChange, sortValue: props.sortValue })), options === null || options === void 0 ? void 0 :
        options.map(function (item) { return (React.createElement(Checkbox, { key: JSON.stringify(item.value), disabled: props.disabled && props.disabled(item), inline: props.inline, value: item.value, payload: item, groupValue: props.value, label: props.formatOptionLabel ? props.formatOptionLabel(item) : item.label, onChange: handleChange, sortValue: props.sortValue })); })));
};
var templateObject_1, templateObject_2;
