var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useAuth } from '../authentication';
import { useViewValue } from '../state';
import { IconButton } from '../common';
import { StyledMapSidebar } from './sidebar';
import { RightPanelTypeEnum } from '../enums';
import { useClinicalTrialDetail } from '../selectors';
var EXPORT_CSV_API = '/api/clinical-trials/export-to-excel-file';
var StyledClinicalTrialDetail = styled(StyledMapSidebar)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PanelHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.25rem;\n"], ["\n  font-weight: 500;\n  font-size: 1.25rem;\n"])));
var ClinicalTrialLink = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"], ["\n  font-weight: 500;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"])));
var ClinicalTrialText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: normal;\n  padding-top: 1rem;\n  padding-right: 0.5rem;\n  text-decoration: underline;\n"], ["\n  font-weight: normal;\n  padding-top: 1rem;\n  padding-right: 0.5rem;\n  text-decoration: underline;\n"])));
var ClinicalTrialInfo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var ClinicalTrialLocation = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding-top: 1rem;\n"], ["\n  padding-top: 1rem;\n"])));
var ClinicalTrialDate = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-top: 1rem;\n"], ["\n  padding-top: 1rem;\n"])));
var ClinicalTrialDateText = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding-left: 0.25rem;\n"], ["\n  padding-left: 0.25rem;\n"])));
var ClinicalTrialContactInfo = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding-top: 2rem;\n"], ["\n  padding-top: 2rem;\n"])));
var ClinicalTrialInvestigatorInfo = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding-top: 2rem;\n"], ["\n  padding-top: 2rem;\n"])));
var StyledDiv = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
var Location = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject([""], [""])));
var StyledEmail = styled.a(templateObject_14 || (templateObject_14 = __makeTemplateObject([""], [""])));
export var ClinicalTrialDetail = function () {
    var _a, _b, _c;
    var auth = useAuth();
    var _d = useViewValue('selectedRegion'), selectedRegion = _d[0], setSelectedRegion = _d[1];
    var id = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === RightPanelTypeEnum.ClinicalTrialDetail ? selectedRegion.id : undefined;
    var selectedClinicalTrialSite = useClinicalTrialDetail(id);
    var clearSelectedClinicalTrialInfo = function () {
        setSelectedRegion();
    };
    var onDownloadClinicalTrial = function () {
        var form = document.createElement('form');
        form.action = EXPORT_CSV_API;
        form.method = 'post';
        var params = {
            token: auth.jwt,
            filtering: JSON.stringify({
                clinicalTrialSites: [selectedClinicalTrialSite],
                dateTimeStringForExportingCsv: moment().format('YYYYMMDD HHmmss'),
            }),
        };
        Object.keys(params).forEach(function (key) {
            var input = document.createElement('input');
            input.name = key;
            input.value = params[key] || '';
            form.appendChild(input);
        });
        window.document.body.appendChild(form);
        form.submit();
        window.document.body.removeChild(form);
    };
    if (!selectedClinicalTrialSite)
        return null;
    return (React.createElement(StyledClinicalTrialDetail, null,
        React.createElement(PanelHeader, null,
            React.createElement(Title, null, "Clinical Trial Information"),
            React.createElement(IconButton, { className: "ml-auto", onClick: onDownloadClinicalTrial, icon: faCloudDownloadAlt, color: "warning", size: "2x" }),
            React.createElement(IconButton, { onClick: clearSelectedClinicalTrialInfo, icon: faTimesCircle, color: "warning", size: "2x" })),
        React.createElement(ClinicalTrialInfo, null,
            React.createElement("div", { className: "font-weight-bold" }, selectedClinicalTrialSite.briefTitle),
            React.createElement(ClinicalTrialLink, { href: "https://clinicaltrials.gov/ct2/show/" + selectedClinicalTrialSite.nctId, target: "_blank" },
                React.createElement(ClinicalTrialText, null, "View in ClinicalTrials.gov"),
                React.createElement(FontAwesomeIcon, { icon: faExternalLinkAlt })),
            React.createElement(ClinicalTrialLocation, null,
                React.createElement("div", { className: "font-weight-bold" }, selectedClinicalTrialSite.facilityName),
                React.createElement(Location, null,
                    selectedClinicalTrialSite.city,
                    ", ",
                    selectedClinicalTrialSite.stateAbv,
                    " ",
                    selectedClinicalTrialSite.zipcode)),
            React.createElement(ClinicalTrialDate, null,
                React.createElement("span", { className: "font-weight-bold" }, "Recruitment Status:"),
                React.createElement(ClinicalTrialDateText, null, (_a = selectedClinicalTrialSite.recruimentStatuses) === null || _a === void 0 ? void 0 : _a.join(', ')),
                React.createElement("br", null),
                React.createElement("span", { className: "font-weight-bold" }, "Start Date:"),
                React.createElement(ClinicalTrialDateText, null, moment(selectedClinicalTrialSite.startDate).format('LL')),
                React.createElement("br", null),
                React.createElement("span", { className: "font-weight-bold" }, "Estimated Completion Date:"),
                React.createElement(ClinicalTrialDateText, null, moment(selectedClinicalTrialSite.completionDate).format('LL')),
                React.createElement("br", null)),
            React.createElement(ClinicalTrialContactInfo, null,
                React.createElement("div", { className: "font-weight-bold" }, "Contact Info"), (_b = selectedClinicalTrialSite.contacts) === null || _b === void 0 ? void 0 :
                _b.map(function (item, idx) { return (React.createElement("div", { key: idx, className: "mb-2" },
                    React.createElement(StyledDiv, null, item.contactName),
                    React.createElement(StyledDiv, null, item.contactPhone),
                    React.createElement(StyledEmail, { href: "mailto:" + item.contactEmail }, item.contactEmail))); })),
            React.createElement(ClinicalTrialInvestigatorInfo, null,
                React.createElement("div", { className: "font-weight-bold" }, "Investigator Info"), (_c = selectedClinicalTrialSite.investigators) === null || _c === void 0 ? void 0 :
                _c.map(function (item, idx) { return (React.createElement("div", { key: idx, className: "mb-2" },
                    React.createElement(StyledDiv, null, item.investigatorName),
                    React.createElement(StyledDiv, null, item.investigatorAffiliation))); })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
