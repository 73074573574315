var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useRef } from "react";
import moment from 'moment';
import { exportFilterSettingPayload, useFilterSetting, useViewValue } from "../../state";
import { useMapRef, useViewport } from "../mapprovider";
import { useDebounce, useDebounceValue } from "../../hooks";
import { LocationLayer } from "../locationlayer";
import { useQuestDiagnosticsApi } from "../../selectors";
var VIEW_PORT_DEBOUNCE_TIME = 500;
export var QuestDiagnosticsSitesLayer = function () {
    var _a, _b, _c;
    var filterSetting = useFilterSetting();
    var _d = useQuestDiagnosticsApi(), questDiagnosticsData = _d[0], fetchQuestDiagnosticsData = _d[1], clearData = _d[2];
    var checkedQuestDiagnosticsStatus = useViewValue('checkedQuestDiagnosticsStatus')[0];
    var viewport = useViewport()[0];
    var mapRef = useMapRef();
    var map = (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap();
    var selectedRegion = useViewValue('selectedRegion')[0];
    var loadedUserActionsHistoryRef = useRef(false);
    var lngLatBounds = useDebounceValue(function () { return map === null || map === void 0 ? void 0 : map.getBounds().toArray(); }, [viewport.zoom, viewport.longitude, viewport.latitude], VIEW_PORT_DEBOUNCE_TIME);
    var show = !!checkedQuestDiagnosticsStatus;
    var fetchDataWrapped = function () {
        var payload = exportFilterSettingPayload(filterSetting);
        var userActionsHistory = !loadedUserActionsHistoryRef.current && (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type) === 'QuestDiagnosticsSitesLayer'
            ? {
                site: selectedRegion.id,
            }
            : undefined;
        fetchQuestDiagnosticsData(__assign(__assign({}, payload), { currentDate: {
                date: moment().format('D'),
                month: moment().format('M'),
                year: moment().format('YYYY'),
            }, bottomLeftAndTopRightLngLatBound: lngLatBounds, userActionsHistory: userActionsHistory }));
        loadedUserActionsHistoryRef.current = true;
    };
    useDebounce(function () {
        if (filterSetting.forceUpdate && show) {
            fetchDataWrapped();
        }
        return clearData;
    }, [filterSetting.forceUpdate, show, lngLatBounds]);
    return (React.createElement(LocationLayer, { id: 'QuestDiagnosticsSitesLayer', locations: ((_b = questDiagnosticsData.data) === null || _b === void 0 ? void 0 : _b.questDiagnosticsSites) || [], show: show, color: "#135B23", imgPin: 'quest-diagnostics-sites-pin', requesting: questDiagnosticsData.requesting, error: questDiagnosticsData.error, retry: fetchDataWrapped, totalCount: (_c = questDiagnosticsData.data) === null || _c === void 0 ? void 0 : _c.totalCount, compareFn: function (a, b) { var _a, _b; return ((_a = a.site_name) === null || _a === void 0 ? void 0 : _a.localeCompare(b.site_name || '')) || ((_b = a.site_name) === null || _b === void 0 ? void 0 : _b.localeCompare(b.site_name || '')) || 0; }, 
        // menuItemComponent={MenuItem}
        name: "quest diagnostics sites", idFields: ['id', 'site_code', 'zip_code'] }));
};
