/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { TabPane, Label } from 'reactstrap';
import { FallbackComponent, CheckboxGroup } from '../common';
import { useLookupSexCode, LookupSexCodeComponent } from '../lookupdata';
import { useReportsDialogValue } from './reportsdialogprovider';
export var SexReportsDialogTab = function (props) {
    var _a = useLookupSexCode(), lookupSexCodeData = _a[0], fetchLookupSexCode = _a[1];
    var _b = useReportsDialogValue('sexCodes'), sexCodes = _b[0], setSexCodes = _b[1];
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FallbackComponent, { requesting: lookupSexCodeData.requesting, error: lookupSexCodeData.error, retry: fetchLookupSexCode },
            React.createElement(Label, { className: "font-weight-bold" }, "Sex"),
            React.createElement(CheckboxGroup, { options: lookupSexCodeData.data, noSort: true, value: sexCodes, onChange: setSexCodes })),
        React.createElement(LookupSexCodeComponent, null)));
};
SexReportsDialogTab.defaultProps = {
    tabId: 'SexTab',
    tabName: 'Sex',
    tabField: ['sexCodes'],
};
