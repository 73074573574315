/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var ViewModeEnum;
(function (ViewModeEnum) {
    ViewModeEnum["Map"] = "MAP_VIEW";
    ViewModeEnum["Table"] = "TABULAR_VIEW";
    ViewModeEnum["Chart"] = "CHART_VIEW";
})(ViewModeEnum || (ViewModeEnum = {}));
export var ChartViewEnum;
(function (ChartViewEnum) {
    ChartViewEnum["Demographic"] = "DEMOGRAPHIC";
    ChartViewEnum["Trends"] = "TRENDS";
})(ChartViewEnum || (ChartViewEnum = {}));
