/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var PrimaryConditionsSortTypeEnum;
(function (PrimaryConditionsSortTypeEnum) {
    PrimaryConditionsSortTypeEnum["Specifically"] = "SPECIFICALLY";
})(PrimaryConditionsSortTypeEnum || (PrimaryConditionsSortTypeEnum = {}));
export var SecondaryConditionsSortTypeEnum;
(function (SecondaryConditionsSortTypeEnum) {
    SecondaryConditionsSortTypeEnum["Specifically"] = "SPECIFICALLY";
})(SecondaryConditionsSortTypeEnum || (SecondaryConditionsSortTypeEnum = {}));
