var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { Button } from '../common';
import { useFilterValue } from '../state/filtersettings';
import { LookupRaceCodeComponent, LookupSexCodeComponent, LookupConditionComponent, LookupStateComponent, LookupZipCodeDistrictComponent } from '../lookupdata';
import { ConditionTab } from '../filterdialog';
var StyledFilterResultDashboard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  overflow: auto;\n"], ["\n  flex: 1;\n  display: flex;\n  overflow: auto;\n"])));
var StyledGetStartedButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  transform: translateY(-50%);\n  top: 50%;\n  align-self: center;\n"], ["\n  position: absolute;\n  transform: translateY(-50%);\n  top: 50%;\n  align-self: center;\n"])));
var NoDefaultFilterComponent = function (props) {
    return (React.createElement(StyledGetStartedButton, null,
        React.createElement(Button, { outline: true, color: "primary", size: "lg", className: "p-5", message: "Click here to get started", onClick: function () { return props.getStartedFilter && props.getStartedFilter(props.tabId); } })));
};
export var FilterResultDashboard = function (props) {
    var _a;
    var available = useFilterValue('available')[0];
    return (React.createElement(React.Fragment, null,
        available ? (React.createElement(StyledFilterResultDashboard, null, props.children)) : (React.createElement(NoDefaultFilterComponent, { tabId: props.tagId || ((_a = ConditionTab.defaultProps) === null || _a === void 0 ? void 0 : _a.tabId), getStartedFilter: props.toggleFilterDialog })),
        React.createElement(LookupConditionComponent, null),
        React.createElement(LookupSexCodeComponent, null),
        React.createElement(LookupRaceCodeComponent, null),
        React.createElement(LookupStateComponent, null),
        React.createElement(LookupZipCodeDistrictComponent, null)));
};
var templateObject_1, templateObject_2;
