var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useFilterSetting, exportFilterSettingPayload } from '../state/filtersettings';
import { useFilterEditing } from '../state/filterediting';
import { useDebounce } from '../hooks';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
var RECOUNT_FILTER_DELAY_TIME = 1500;
var useEffectFilterCount = function (filterSetting, effect, addDeps, ignore) {
    var _a, _b, _c;
    var indexConfig = useHostnameIndexInfo();
    var year = filterSetting.dataYearSeason || filterSetting.dataYear;
    var cmsDataSource = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource;
    var isAggregatedDataSource = filterSetting.isAggregatedDataSource;
    var primaryCoditions = filterSetting.primaryCoMorbidities;
    var selectingConditions = filterSetting.comorbidities;
    var sexCodes = (_b = filterSetting.demographics) === null || _b === void 0 ? void 0 : _b.sexCodes;
    var raceCodes = (_c = filterSetting.demographics) === null || _c === void 0 ? void 0 : _c.raceCodes;
    var raceMethodology = filterSetting.raceMethodology;
    var deps = __spreadArrays([year, cmsDataSource, isAggregatedDataSource, filterSetting.claimNumber, filterSetting.claimDay], (addDeps || []));
    if (ignore !== 'PRIMARY_CONDITION') {
        deps.push(primaryCoditions);
    }
    if (ignore !== 'PRIMARY_CONDITION' && ignore !== 'CONDITION') {
        deps.push(selectingConditions);
    }
    if (ignore !== 'SEX') {
        deps.push(sexCodes);
    }
    if (ignore !== 'RACE') {
        deps.push(raceCodes);
        deps.push(raceMethodology);
    }
    useDebounce(function () {
        if ((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && year && cmsDataSource && !isAggregatedDataSource) {
            var payload = exportFilterSettingPayload(filterSetting);
            effect(payload, indexConfig);
        }
    }, [JSON.stringify(deps)], RECOUNT_FILTER_DELAY_TIME);
};
export var useEffectFilterEditingCount = function (effect, addDeps, ignore) {
    return useEffectFilterCount(useFilterEditing(), effect, addDeps, ignore);
};
export var useEffectFilterSettingCount = function (effect, addDeps, ignore) {
    return useEffectFilterCount(useFilterSetting(), effect, addDeps, ignore);
};
