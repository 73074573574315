var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useRef, useState } from 'react';
import { Editor, DrawPolygonMode, EditingMode, RENDER_STATE } from 'react-map-gl-draw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTrash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { useToggle } from '../hooks';
import { useMapValue } from './mapprovider';
var getEditHandleStyle = function (_a) {
    var feature = _a.feature, state = _a.state;
    switch (state) {
        case RENDER_STATE.SELECTED:
        case RENDER_STATE.HOVERED:
        case RENDER_STATE.UNCOMMITTED:
            return {
                fill: 'rgb(60, 71, 208)',
                fillOpacity: 1,
                stroke: 'rgb(255, 255, 255)',
                strokeWidth: 2,
                r: 7,
            };
        default:
            return {
                fill: 'rgb(60, 71, 208)',
                fillOpacity: 1,
                stroke: 'rgb(255, 255, 255)',
                strokeWidth: 2,
                r: 5,
            };
    }
};
var getFeatureStyle = function (_a) {
    var feature = _a.feature, index = _a.index, state = _a.state;
    switch (state) {
        case RENDER_STATE.SELECTED:
        case RENDER_STATE.HOVERED:
        case RENDER_STATE.UNCOMMITTED:
        case RENDER_STATE.CLOSING:
            return {
                stroke: 'rgb(60, 71, 208)',
                strokeWidth: 2,
                fill: 'rgb(60, 71, 208)',
                fillOpacity: 0.3,
                strokeDasharray: '4,2',
            };
        default:
            return {
                stroke: 'rgb(0, 123, 255)',
                strokeWidth: 2,
                fill: 'rgb(0, 123, 255)',
                fillOpacity: 0.1,
            };
    }
};
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) { return (props.isActive ? 'background-color: #007bff !important; color: #ffffff;' : ''); });
var StyledWrapperTool = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var DrawControl = function (props) {
    var _a = useToggle(), isOpenHelpDialog = _a[0], toggleHelpDialog = _a[1];
    return (React.createElement(StyledWrapperTool, null,
        React.createElement("div", { className: "mapboxgl-ctrl-top-right" },
            React.createElement("div", { className: "mapboxgl-ctrl-group mapboxgl-ctrl" },
                React.createElement(StyledButton, { className: "mapbox-gl-draw_ctrl-draw-btn", title: "Polygon tool", isActive: props.isOnDrawMode, onClick: props.toggleDrawMode },
                    React.createElement(FontAwesomeIcon, { icon: faVectorSquare })),
                React.createElement(StyledButton, { className: "mapbox-gl-draw_ctrl-draw-btn", title: "Delete", onClick: props.onDelete },
                    React.createElement(FontAwesomeIcon, { icon: faTrash })),
                React.createElement(StyledButton, { className: "mapbox-gl-draw_ctrl-draw-btn", title: "Help", onClick: toggleHelpDialog },
                    React.createElement(FontAwesomeIcon, { icon: faQuestion })))),
        React.createElement(HelpDialog, { isOpen: isOpenHelpDialog, toggle: toggleHelpDialog })));
};
var HelpDialog = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { size: "lg", isOpen: props.isOpen, toggle: props.toggle, scrollable: true },
            React.createElement(ModalHeader, { toggle: props.toggle }, "Using the Polygon Selection Tool"),
            React.createElement(ModalBody, null,
                React.createElement("video", { width: "100%", height: "auto", controls: true },
                    React.createElement("source", { src: "/assets/videos/polygon_selection_video.mp4", type: "video/mp4" })),
                React.createElement("br", null),
                React.createElement("p", null, "The polygon selection tool is used to highlight an area and select, for example, a group of providers or clinicals for export."),
                React.createElement("ol", null,
                    React.createElement("li", null, "Turn on the drawing tool by clicking the square icons"),
                    React.createElement("li", null, "Click on the map where you want to start the polygon"),
                    React.createElement("li", null, "Draw the polygon by clicking on each point of the shape"),
                    React.createElement("li", null, "When you reach the last point, double-click to complete the shape"),
                    React.createElement("li", null, "From the export menu choose the data you woold like to save"),
                    React.createElement("li", null, "Click the trash can icon the remove the selection"))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { color: "primary", onClick: props.toggle }, "Close")))));
};
export var useDrawPolygon = function () {
    var _a = useState(null), mode = _a[0], setMode = _a[1];
    var _b = useToggle(), isOnDrawMode = _b[0], toggleDrawMode = _b[1], setDrawMode = _b[2];
    var editorRef = useRef(null);
    var _c = useMapValue('editorRef'), setEditorRef = _c[1];
    useEffect(function () {
        var _a;
        if (isOnDrawMode && !((_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getFeatures().length)) {
            setMode(new DrawPolygonMode());
        }
    }, [isOnDrawMode]);
    useEffect(function () {
        setEditorRef(isOnDrawMode ? editorRef : undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnDrawMode]);
    var onDelete = function () {
        var _a, _b;
        var features = (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getFeatures();
        if (features.length) {
            (_b = editorRef.current) === null || _b === void 0 ? void 0 : _b.deleteFeatures(0);
            setDrawMode(false);
        }
    };
    var onUpdate = function (_a) {
        var editType = _a.editType;
        if (editType === 'addFeature') {
            setMode(new EditingMode());
        }
    };
    return {
        editor: (React.createElement("div", { hidden: !isOnDrawMode, className: "w-100 h-100" },
            React.createElement(Editor, { ref: editorRef, style: { width: '100%', height: '100%' }, clickRadius: 12, mode: mode, onUpdate: onUpdate, editHandleShape: 'circle', featureStyle: getFeatureStyle, editHandleStyle: getEditHandleStyle }))),
        drawControl: React.createElement(DrawControl, { isOnDrawMode: isOnDrawMode, toggleDrawMode: toggleDrawMode, onDelete: onDelete }),
    };
};
var templateObject_1, templateObject_2;
