var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { StatisticCard } from './statisticcard';
import { TrendLine } from './trendline';
import { TrendLineSeason } from './trendlineseason';
import { DemographicChart } from './demographicchart';
import { useHostnameIndexInfo } from '../../hostnameindexinfo';
var Space = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 0.75rem;\n"], ["\n  padding-bottom: 0.75rem;\n"])));
var TrendLineStatisticDetail = function () {
    var indexConfiguration = useHostnameIndexInfo();
    var isSeasonStatistic = ((indexConfiguration === null || indexConfiguration === void 0 ? void 0 : indexConfiguration.generalInformation) || {}).isSeasonStatistic;
    return isSeasonStatistic ? React.createElement(TrendLineSeason, null) : React.createElement(TrendLine, null);
};
export var StatisticDetail = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(StatisticCard, null),
        React.createElement(Space, null),
        React.createElement(TrendLineStatisticDetail, null),
        React.createElement(Space, null),
        React.createElement(DemographicChart, null)));
};
var templateObject_1;
