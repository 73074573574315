/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from "react";
import { DropdownItemCheck } from "../common";
import { useFilterSetting, useViewValue } from "../state";
export var QuestDiagnosticsSites = function () {
    var _a = useViewValue('checkedQuestDiagnosticsStatus'), questDiagnosticsStatus = _a[0], setQuestDiagnosticsChecked = _a[1];
    var filterSetting = useFilterSetting();
    var _b = useViewValue('showMapLayerMenu'), setShowMapLayerMenu = _b[1];
    var disabled = !filterSetting.available;
    var handleClickQuestDiagnostics = function (checked) {
        setQuestDiagnosticsChecked(!checked);
        setShowMapLayerMenu();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownItemCheck, { value: !!questDiagnosticsStatus, disabled: disabled, checked: !!questDiagnosticsStatus, onChange: handleClickQuestDiagnostics, img: "quest-diagnostics-sites-pin" },
            React.createElement("span", { className: "flex-grow-1" }, "Quest Diagnostics Sites"))));
};
