/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useMemo } from 'react';
import { DELIMITER_SEASON_YEAR } from '../common';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useFilterEditingValue } from '../state';
import _ from 'lodash';
import { DataSourceEnum } from '../enums';
export var useGeneralConfigByDataSource = function (key, cmsDataSourceParam, dataYearSeasonParam, dataYearParam) {
    var indexConfig = useHostnameIndexInfo();
    var dataYearFilter = useFilterEditingValue('dataYear')[0];
    var dataYearSeasonFilter = useFilterEditingValue('dataYearSeason')[0];
    var _a = useFilterEditingValue('statistic', 'cmsDataSource')[0], cmsDataSourceFilter = _a === void 0 ? DataSourceEnum.MedicareFFS : _a;
    var filtersConfig = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filtersConfigByDataSource;
    var cmsDataSource = useMemo(function () { return cmsDataSourceParam || cmsDataSourceFilter; }, [cmsDataSourceParam, cmsDataSourceFilter]);
    var dataYearSeason = useMemo(function () { return dataYearSeasonParam || dataYearSeasonFilter; }, [dataYearSeasonParam, dataYearSeasonFilter]);
    var dataYear = useMemo(function () { return dataYearParam || dataYearFilter; }, [dataYearParam, dataYearFilter]);
    var result = useMemo(function () {
        if (cmsDataSource && filtersConfig && key) {
            var configByDataSource = filtersConfig === null || filtersConfig === void 0 ? void 0 : filtersConfig[cmsDataSource];
            var configByDataYear = configByDataSource === null || configByDataSource === void 0 ? void 0 : configByDataSource.find(function (config) { return String(config.dataYear) === String(dataYear); });
            // Format of data year season '<YEAR> - <YEAR>'
            var configByDataYearSeason = dataYearSeason ? configByDataSource === null || configByDataSource === void 0 ? void 0 : configByDataSource.find(function (config) { var _a; return ((_a = config.dataYearSeason) === null || _a === void 0 ? void 0 : _a.join(DELIMITER_SEASON_YEAR)) === dataYearSeason.join(DELIMITER_SEASON_YEAR); }) : undefined;
            var valueByKey = _.get(configByDataYearSeason, key, undefined);
            if (valueByKey)
                return valueByKey;
            valueByKey = _.get(configByDataYear, key, undefined);
            if (valueByKey)
                return valueByKey;
            var configByAllYear = configByDataSource === null || configByDataSource === void 0 ? void 0 : configByDataSource.find(function (config) { return !config.dataYear && !config.dataYearSeason; });
            return _.get(configByAllYear, key, undefined);
        }
        return undefined;
    }, [cmsDataSource, filtersConfig, dataYearSeason, key, dataYear]);
    return result;
};
