var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useEffect, useRef, useState } from 'react';
export var useDebounce = function (callback, depends, delay) {
    if (delay === void 0) { delay = 0; }
    var mountedRef = useRef(false);
    useEffect(function () {
        var unmountHandeler;
        var handler = setTimeout(function () {
            unmountHandeler = callback();
        }, !mountedRef.current ? 0 : delay);
        mountedRef.current = true;
        return function () {
            clearTimeout(handler);
            unmountHandeler && unmountHandeler();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArrays([delay], depends));
};
export var useDebounceValue = function (callback, depends, delay) {
    if (delay === void 0) { delay = 0; }
    var _a = useState(callback), value = _a[0], setValue = _a[1];
    var mountedRef = useRef(false);
    useEffect(function () {
        if (mountedRef.current) {
            var handler_1 = setTimeout(function () {
                setValue(callback());
            }, delay);
            return function () {
                clearTimeout(handler_1);
            };
        }
        mountedRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArrays([delay], depends));
    return value;
};
