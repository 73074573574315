var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import Recaptcha from 'react-recaptcha';
import { Form, Row, FormGroup, Label, FormFeedback, Col } from 'reactstrap';
import { useForm } from '../hooks';
import { PhoneInput, Input, Select, MessageBox, Button, ZIP_CODE_PATTERN, PHONE_NUMBER_PATTERN, MessageId } from '../common';
import { LicenseAgreementModal } from '../aboutmodal/licenseagreementmodal';
import styled from 'styled-components';
import { useAPI, useMessage } from '../state';
import { Redirect } from 'react-router-dom';
import { useHostnameIndexInfo, useIndexName } from '../hostnameindexinfo';
import { getDirectionUrl } from '../utils';
export var NOTIFICATION_PREFERENCES = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 62.5rem;\n  margin: 0 auto;\n  padding: 1.25rem;\n"], ["\n  max-width: 62.5rem;\n  margin: 0 auto;\n  padding: 1.25rem;\n"])));
var Margin = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 3.125rem;\n"], ["\n  margin: 0 3.125rem;\n"])));
var validationSchema = Yup.object().shape({
    recaptchaResponse: Yup.string().required('Please verify that you are not a robot.'),
    firstName: Yup.string().trim().required('Required'),
    lastName: Yup.string().trim().required('Required'),
    username: Yup.string().email().required('Required'),
    organization: Yup.string().trim().required('Required'),
    address1: Yup.string().trim().required('Required'),
    city: Yup.string().trim().required('Required'),
    stateAbb: Yup.string().trim().required('Required'),
    zipcode: Yup.string().trim().required('Required').matches(ZIP_CODE_PATTERN, {
        excludeEmptyString: true,
        message: 'Invalid',
    }),
    notificationPreference: Yup.string().trim().required('Required'),
    phoneNumber: Yup.string().trim().required('Required').matches(PHONE_NUMBER_PATTERN, {
        excludeEmptyString: true,
        message: 'Invalid',
    }),
});
export var SignUpComponent = function () {
    var _a;
    var indexName = useIndexName();
    var _b = useAPI('/api/general/get-recaptcha-site-key'), recaptchaSiteKey = _b[0].data, fetchRecaptchaSiteKey = _b[1];
    var _c = useState(false), isOpenLicenseModal = _c[0], setIsOpenLicenseModal = _c[1];
    var _d = useState(false), redirectToHomeURL = _d[0], setRedirectToHomeURL = _d[1];
    var recaptchaRef = useRef();
    var _e = useAPI('/api/user/register', undefined, 'POST'), signUpData = _e[0], signUp = _e[1], resetData = _e[2];
    var _f = useAPI('/api/general/lookup/get-list-state'), stateData = _f[0], fetchStateData = _f[1];
    var _g = useForm({
        validationSchema: validationSchema,
    }), setInitialValues = _g.setInitialValues, values = _g.values, handleChange = _g.handleChange, handleBlur = _g.handleBlur, setFieldValue = _g.setFieldValue, setFieldTouched = _g.setFieldTouched, handleSubmit = _g.handleSubmit, isSubmitting = _g.isSubmitting, getError = _g.getError;
    var indexConfig = useHostnameIndexInfo();
    var _h = useMessage(MessageId.LoginPage), setMessage = _h[1];
    useEffect(function () {
        setInitialValues({
            firstName: '',
            middleName: '',
            lastName: '',
            username: '',
            organization: '',
            address1: '',
            address2: '',
            city: '',
            stateAbb: '',
            zipcode: '',
            phoneNumber: '',
            notificationPreference: NOTIFICATION_PREFERENCES.EMAIL,
            recaptchaResponse: '',
        });
        return resetData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        fetchStateData();
        fetchRecaptchaSiteKey();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (!recaptchaRef.current) {
            recaptchaRef.current = true;
        }
        else {
            recaptchaRef.current.reset();
            setFieldTouched(false, 'recaptchaResponse');
            setFieldValue('', 'recaptchaResponse');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (signUpData.data) {
            setRedirectToHomeURL(true);
            setMessage({
                value: "Your request for access to the " + (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.indexTitle) + " was successfully submitted. Once approved, you will receive an email or SMS message with a temporary password to enable your account",
                type: 'success'
            });
        }
    }, [indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.indexTitle, setMessage, signUpData.data]);
    var toggleLicenseAgreementModal = function () {
        setIsOpenLicenseModal(!isOpenLicenseModal);
    };
    var handleRecaptchVerify = function (recaptchaResponse) {
        setFieldValue(recaptchaResponse, 'recaptchaResponse');
    };
    var handleRecaptchExpired = function () {
        recaptchaRef.current.reset();
        setFieldValue('', 'recaptchaResponse');
    };
    var handleCancelBtn = function () {
        setRedirectToHomeURL(true);
    };
    var onSubmit = handleSubmit(useCallback(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        firstName: payload.firstName,
                        middleName: payload.middleName,
                        lastName: payload.lastName,
                        username: payload.username,
                        organization: payload.organization,
                        address1: payload.address1,
                        address2: payload.address2,
                        city: payload.city,
                        stateAbb: payload.stateAbb,
                        zipcode: payload.zipcode,
                        phoneNumber: payload.phoneNumber,
                        notificationPreference: payload.notificationPreference,
                        recaptchaResponse: payload.recaptchaResponse,
                        indexName: indexName,
                    };
                    return [4 /*yield*/, signUp(data)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, []));
    return (React.createElement(Wrapper, null,
        React.createElement(MessageBox, { error: signUpData.error }),
        React.createElement("h4", null, "Register New Account"),
        React.createElement("hr", null),
        React.createElement(Form, { className: "mt-4", onSubmit: onSubmit },
            React.createElement(Margin, null,
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-4" },
                        React.createElement(Label, null,
                            "First Name",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "firstName", value: values.firstName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('firstName') }),
                        React.createElement(FormFeedback, null, getError('firstName'))),
                    React.createElement(FormGroup, { className: "col-sm-4" },
                        React.createElement(Label, null, "Middle Name"),
                        React.createElement(Input, { type: "text", name: "middleName", value: values.middleName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('middleName') }),
                        React.createElement(FormFeedback, null, getError('middleName'))),
                    React.createElement(FormGroup, { className: "col-sm-4" },
                        React.createElement(Label, null,
                            "Last Name",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "lastName", value: values.lastName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('lastName') }),
                        React.createElement(FormFeedback, null, getError('lastName')))),
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-12" },
                        React.createElement(Label, null,
                            "Email Address",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Row, null,
                            React.createElement(Col, { sm: 4 },
                                React.createElement(Input, { type: "email", name: "username", value: values.username, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('username') })),
                            React.createElement(Col, { className: "align-self-center font-italic" },
                                "This will be your login name. ",
                                React.createElement("span", { className: "text-danger" }, "Please note the username is case sensitive."))),
                        React.createElement(FormFeedback, { className: "d-block" }, getError('username')))),
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-4" },
                        React.createElement(Label, null,
                            "Mobile phone number",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(PhoneInput, { name: "phoneNumber", value: values.phoneNumber, setFieldValue: setFieldValue, setFieldTouched: setFieldTouched, invalid: !!getError('phoneNumber') }),
                        React.createElement(FormFeedback, null, getError('phoneNumber'))),
                    React.createElement(FormGroup, { className: "col-sm-8" },
                        React.createElement(Label, null, "\u00A0"),
                        React.createElement("div", { className: "pt-2" },
                            React.createElement(Label, { className: "mr-2" },
                                "Preference for notifications:",
                                React.createElement("span", { className: "text-danger" }, "*")),
                            React.createElement(FormGroup, { check: true, className: "d-inline-block mr-2" },
                                React.createElement(Label, { check: true },
                                    React.createElement(Input, { type: "radio", name: "notificationPreference", checked: values.notificationPreference === NOTIFICATION_PREFERENCES.EMAIL, onChange: handleChange, onBlur: handleBlur, value: NOTIFICATION_PREFERENCES.EMAIL }),
                                    "Email")),
                            React.createElement(FormGroup, { check: true, className: "d-inline-block mr-2" },
                                React.createElement(Label, { check: true },
                                    React.createElement(Input, { type: "radio", name: "notificationPreference", checked: values.notificationPreference === NOTIFICATION_PREFERENCES.SMS, onChange: handleChange, onBlur: handleBlur, value: NOTIFICATION_PREFERENCES.SMS }),
                                    "SMS Text"))),
                        React.createElement(FormFeedback, { className: "d-lock" }, getError('notificationPreference')))),
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-6" },
                        React.createElement(Label, null,
                            "Organization Name",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "organization", value: values.organization, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('organization') }),
                        React.createElement(FormFeedback, null, getError('organization')))),
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-6" },
                        React.createElement(Label, null,
                            "Address 1",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "address1", value: values.address1, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('address1') }),
                        React.createElement(FormFeedback, null, getError('address1'))),
                    React.createElement(FormGroup, { className: "col-sm-6" },
                        React.createElement(Label, null, "Address 2"),
                        React.createElement(Input, { type: "text", name: "address2", value: values.address2, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('address2') }),
                        React.createElement(FormFeedback, null, getError('address2')))),
                React.createElement(Row, null,
                    React.createElement(FormGroup, { className: "col-sm-6" },
                        React.createElement(Label, null,
                            "City",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "city", value: values.city, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('city') }),
                        React.createElement(FormFeedback, null, getError('city'))),
                    React.createElement(FormGroup, { className: "col-sm-3" },
                        React.createElement(Label, null,
                            "State",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Select, { name: "stateAbb", options: (_a = stateData.data) === null || _a === void 0 ? void 0 : _a.data, value: values.stateAbb, onChange: setFieldValue, setFieldTouched: setFieldTouched, optionsTransform: function (st) { return (__assign(__assign({}, st), { value: st.stateAbbreviation, label: st.stateName })); }, menuPlacement: "top" }),
                        React.createElement(FormFeedback, { className: "d-block" }, getError('stateAbb'))),
                    React.createElement(FormGroup, { className: "col-sm-3" },
                        React.createElement(Label, null,
                            "Zip Code",
                            React.createElement("span", { className: "text-danger" }, "*")),
                        React.createElement(Input, { type: "text", name: "zipcode", value: values.zipcode, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('zipcode') }),
                        React.createElement(FormFeedback, null, getError('zipcode'))))),
            React.createElement(Row, { className: "justify-content-center" },
                React.createElement(FormGroup, null,
                    recaptchaSiteKey && (React.createElement(Recaptcha, { ref: recaptchaRef, sitekey: recaptchaSiteKey, verifyCallback: handleRecaptchVerify, expiredCallback: handleRecaptchExpired })),
                    React.createElement(FormFeedback, { className: "d-block" }, getError('recaptchaResponse')))),
            React.createElement(Row, null,
                React.createElement(Col, { sm: 4, className: "align-self-center mr-auto" },
                    React.createElement("small", null,
                        "In submitting this form, you agree to the National Minority Quality Forum, Inc",
                        ' ',
                        React.createElement("a", { href: "#", onClick: toggleLicenseAgreementModal }, "License Agreement"),
                        ".")),
                React.createElement(Col, { sm: "auto", className: "text-right align-self-end" },
                    React.createElement(Button, { type: "button", disabled: isSubmitting, onClick: handleCancelBtn }, "Cancel"),
                    ' ',
                    React.createElement(Button, { color: "primary", type: "submit", loading: isSubmitting }, "Submit"),
                    redirectToHomeURL && React.createElement(Redirect, { to: getDirectionUrl('/') })))),
        React.createElement(LicenseAgreementModal, { isOpen: isOpenLicenseModal, toggle: toggleLicenseAgreementModal })));
};
var templateObject_1, templateObject_2;
