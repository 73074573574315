/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { TabPane, Label, FormGroup, Col } from 'reactstrap';
import { useReportsDialogValue } from './reportsdialogprovider';
import { Select } from '../common';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { ReportTypeEnum } from '../enums/reporttypeenum';
import { REPORT_TYPE_OPTIONS } from '../configs/reportoptions';
export var ReportsTab = function (props) {
    var _a;
    var indexConfig = useHostnameIndexInfo();
    var _b = useReportsDialogValue('reportType'), reportType = _b[0], setReportType = _b[1];
    var allReportTypeOptions = React.useMemo(function () {
        var _a, _b, _c;
        if (!((_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) === null || _a === void 0 ? void 0 : _a.reportTypes))
            return [REPORT_TYPE_OPTIONS[ReportTypeEnum.CongressionalDetailAnalysis]];
        return (_c = (_b = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) === null || _b === void 0 ? void 0 : _b.reportTypes) === null || _c === void 0 ? void 0 : _c.map(function (reportType) { return REPORT_TYPE_OPTIONS[reportType]; });
    }, [(_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) === null || _a === void 0 ? void 0 : _a.reportTypes]);
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, "Select Report Type"),
        React.createElement(FormGroup, { row: true },
            React.createElement(Col, { sm: 10 },
                React.createElement(Select, { placeholder: "Select report type", options: allReportTypeOptions, name: "reportType", value: reportType, onChange: setReportType, isClearable: false })))));
};
ReportsTab.defaultProps = {
    tabId: 'ReportsTab',
    tabName: 'Reports',
    tabField: ['reportType'],
};
