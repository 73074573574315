var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { useViewValue } from '../../state';
import { IconButton } from '../../common';
import { StyledMapSidebar } from '../sidebar';
import { RightPanelTypeEnum } from '../../enums';
import { useCustomMapLayerDetail } from '../../selectors';
var StyledLocationDetailPanel = styled(StyledMapSidebar)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PanelHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-right: -1rem;\n  margin-bottom: 0.5rem;\n"])));
var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.25rem;\n  margin-right: auto;\n"], ["\n  font-weight: 500;\n  font-size: 1.25rem;\n  margin-right: auto;\n"])));
export var ImportingLocationDetail = function () {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var id = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.type.startsWith(RightPanelTypeEnum.CustomMapLayer)) ? selectedRegion.id : undefined;
    var maplayerLocationDetail = useCustomMapLayerDetail(id);
    var clearSelectedClinicalTrialInfo = function () {
        setSelectedRegion();
    };
    if (!maplayerLocationDetail)
        return null;
    return (React.createElement(StyledLocationDetailPanel, null,
        React.createElement(PanelHeader, null,
            React.createElement(Title, null, "Location Information"),
            React.createElement(IconButton, { onClick: clearSelectedClinicalTrialInfo, icon: faTimesCircle, color: "warning", size: "2x" })),
        React.createElement("div", null,
            React.createElement("div", { className: "mb-3 font-weight-bold" }, maplayerLocationDetail.trialName),
            React.createElement("div", { className: "mb-3" },
                React.createElement("div", { className: "font-weight-bold" }, maplayerLocationDetail.locationName),
                React.createElement("address", null,
                    maplayerLocationDetail.address1 && (React.createElement(React.Fragment, null,
                        maplayerLocationDetail.address1,
                        React.createElement("br", null))),
                    maplayerLocationDetail.address2 && (React.createElement(React.Fragment, null,
                        maplayerLocationDetail.address2,
                        React.createElement("br", null))),
                    maplayerLocationDetail.city && React.createElement(React.Fragment, null,
                        maplayerLocationDetail.city,
                        ", "),
                    maplayerLocationDetail.stateAbv,
                    " ",
                    maplayerLocationDetail.zip)),
            React.createElement("div", { className: "mb-3" },
                maplayerLocationDetail.recruitmentStatus && (React.createElement("div", null,
                    React.createElement("strong", null, "Recruitment Status:"),
                    " ",
                    maplayerLocationDetail.recruitmentStatus)),
                maplayerLocationDetail.startDate && (React.createElement("div", null,
                    React.createElement("strong", null, "Start Date:"),
                    " ",
                    moment(maplayerLocationDetail.startDate).format('LL'))),
                maplayerLocationDetail.estimatedCompletionDate && (React.createElement("div", null,
                    React.createElement("strong", null, "Estimated Completion Date:"),
                    " ",
                    moment(maplayerLocationDetail.estimatedCompletionDate).format('LL')))),
            React.createElement("div", { className: "mb-3" },
                React.createElement("div", { className: "font-weight-bold" }, "Contact Info"),
                React.createElement("div", { className: "mb-2" },
                    React.createElement("div", null, maplayerLocationDetail.contactName1),
                    React.createElement("div", null, maplayerLocationDetail.contactPhone1),
                    React.createElement("a", { href: "mailto:" + maplayerLocationDetail.contactEmail1 }, maplayerLocationDetail.contactEmail1))),
            React.createElement("div", { className: "mb-3" },
                React.createElement("div", { className: "font-weight-bold" }, "Investigator Info"),
                React.createElement("div", { className: "mb-2" },
                    React.createElement("div", null,
                        maplayerLocationDetail.investigatorName1,
                        " ",
                        maplayerLocationDetail.investigatorCredentials1),
                    React.createElement("div", null, maplayerLocationDetail.investigatorOrganization1))))));
};
var templateObject_1, templateObject_2, templateObject_3;
