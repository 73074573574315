var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useContext, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { Map } from 'immutable';
import { AuthContext } from 'cinchy';
import { FETCH_URL_START, FETCH_URL_SUCCESS, FETCH_URL_FAILED, FETCH_URL_RESET } from './reducer';
import { substituteParams } from '../../utils';
var DEFAULT_TIMEOUT = 60000; // One Minute Timeout
var startFetch = function (key, method, timeout, abortController) { return ({
    type: FETCH_URL_START,
    payload: { key: key, method: method, timeout: timeout, abortController: abortController },
}); };
var successFetch = function (key, method, data, statusCode) { return ({
    type: FETCH_URL_SUCCESS,
    payload: { key: key, method: method, data: data, statusCode: statusCode },
}); };
var failFetch = function (key, method, error, statusCode) { return ({
    type: FETCH_URL_FAILED,
    payload: {
        key: key,
        method: method,
        error: __assign(__assign({}, (typeof error === 'object' ? error : { error: error })), { statusCode: statusCode, message: statusCode === 502 || statusCode === 504
                ? 'The system is currently busy, please try again in a couple of minutes'
                : typeof error === 'object'
                    ? error.message || error.errorMessage
                    : 'Server Error' }),
        statusCode: statusCode,
    },
}); };
var resetState = function (key, method) { return ({ type: FETCH_URL_RESET, payload: { key: key, method: method } }); };
var APIClient = /** @class */ (function () {
    function APIClient(url, key, method, authStrategy) {
        this.authStrategy = authStrategy;
        this.url = url;
        this.key = key;
        this.method = method;
    }
    APIClient.prototype.getKey = function () {
        return this.key;
    };
    APIClient.prototype.getMethod = function () {
        return this.method;
    };
    APIClient.prototype.getUrl = function () {
        return this.url;
    };
    APIClient.prototype.abort = function (state) {
        var _a, _b;
        (_b = (_a = state.api.get(this.key + ":" + this.method)) === null || _a === void 0 ? void 0 : _a.get('abortController')) === null || _b === void 0 ? void 0 : _b.abort();
    };
    APIClient.prototype.resetState = function () {
        var _this = this;
        return function (dispatch, getState) {
            _this.abort(getState());
            dispatch(resetState(_this.getKey(), _this.getMethod()));
        };
    };
    APIClient.prototype.request = function (timeout, body, parseData) {
        var _this = this;
        if (timeout === void 0) { timeout = DEFAULT_TIMEOUT; }
        return function (dispatch, getState) { return __awaiter(_this, void 0, void 0, function () {
            var abortController, retry, response, requestOptions, request, contentType, _a, contentType, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.url)
                            return [2 /*return*/];
                        this.abort(getState());
                        abortController = new AbortController();
                        dispatch(startFetch(this.key, this.method, Date.now() + timeout, abortController));
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 19, , 22]);
                        retry = true;
                        response = {};
                        _c.label = 2;
                    case 2:
                        if (!retry) return [3 /*break*/, 18];
                        retry = false;
                        requestOptions = this.authStrategy.getRequestOptions(this.method, body);
                        return [4 /*yield*/, fetch(this.url, __assign({ signal: abortController.signal }, requestOptions))];
                    case 3:
                        request = _c.sent();
                        if (!(request.status > 299)) return [3 /*break*/, 12];
                        if (!(request.status === 401)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.authStrategy.onUnauthorized(dispatch, getState, request)];
                    case 4:
                        retry = _c.sent();
                        return [3 /*break*/, 11];
                    case 5:
                        if (!(request.status === 403)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.authStrategy.onForbidden(dispatch, getState, request)];
                    case 6:
                        retry = _c.sent();
                        return [3 /*break*/, 11];
                    case 7:
                        contentType = request.headers.get('content-type');
                        if (!(contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) return [3 /*break*/, 9];
                        return [4 /*yield*/, request.json()];
                    case 8:
                        _a = _c.sent();
                        return [3 /*break*/, 10];
                    case 9:
                        _a = request.statusText;
                        _c.label = 10;
                    case 10:
                        response = _a;
                        dispatch(failFetch(this.key, this.method, response, request.status));
                        _c.label = 11;
                    case 11: return [3 /*break*/, 17];
                    case 12:
                        contentType = request.headers.get('content-type');
                        if (!(contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) return [3 /*break*/, 14];
                        return [4 /*yield*/, request.json()];
                    case 13:
                        _b = _c.sent();
                        return [3 /*break*/, 16];
                    case 14: return [4 /*yield*/, request.text()];
                    case 15:
                        _b = _c.sent();
                        _c.label = 16;
                    case 16:
                        response = _b;
                        response = parseData ? parseData(response) : response;
                        dispatch(successFetch(this.key, this.method, response, request.status));
                        _c.label = 17;
                    case 17: return [3 /*break*/, 2];
                    case 18: return [2 /*return*/, response];
                    case 19:
                        e_1 = _c.sent();
                        if (!(e_1.name !== 'AbortError')) return [3 /*break*/, 21];
                        return [4 /*yield*/, this.authStrategy.onUnauthorized(dispatch, getState, e_1)];
                    case 20:
                        _c.sent();
                        dispatch(failFetch(this.key, this.method, e_1));
                        _c.label = 21;
                    case 21: return [3 /*break*/, 22];
                    case 22: return [2 /*return*/];
                }
            });
        }); };
    };
    return APIClient;
}());
export { APIClient };
export var useAPI = function (url, substitutions, method, key, parseData) {
    if (substitutions === void 0) { substitutions = {}; }
    if (method === void 0) { method = 'GET'; }
    if (key === void 0) { key = url; }
    var parseDataRef = useRef();
    parseDataRef.current = parseData;
    var auth = useContext(AuthContext);
    var dispatch = useDispatch();
    var substitutedUrl = substituteParams(url, substitutions);
    var substitutedKey = substituteParams(key, substitutions);
    var client = useMemo(function () { return new APIClient(substitutedUrl, substitutedKey, method, auth); }, [substitutedUrl, substitutedKey, method, auth]);
    var doRequest = useCallback(function (body, force, timeout) {
        if (timeout === void 0) { timeout = DEFAULT_TIMEOUT; }
        return dispatch(client.request(timeout, body, parseDataRef.current));
    }, [client, dispatch]);
    var data = useMappedState(useCallback(function (state) { return state.api.get(client.getKey() + ":" + client.getMethod(), Map({})).toObject(); }, [client]));
    var resetStateAPI = useCallback(function () { return dispatch(client.resetState()); }, [client, dispatch]);
    return [data, doRequest, resetStateAPI];
};
export var useCachedData = function (url, substitutions, method, key, parseData) {
    if (method === void 0) { method = 'GET'; }
    if (key === void 0) { key = url; }
    var _a = useAPI(url, substitutions, method, key, parseData), data = _a[0], doRequest = _a[1], resetState = _a[2];
    var ignoreRequest = useRef(false);
    ignoreRequest.current = !!data.data || !!data.requesting;
    var fetchData = useCallback(function (body, force, timeout) {
        if (force || !ignoreRequest.current) {
            doRequest(body, force, timeout);
        }
    }, [doRequest]);
    return [data, fetchData, resetState];
};
