var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { useChartData } from '../../demographicchart/usechartdata';
import { FallbackComponent } from '../../common';
import { useFilterValue } from '../../state';
import { PieComponent } from '../../demographicchart/pie';
import { DemographicTypeEnum, StatisticTypeEnum } from '../../enums';
var StyledDemographicChart = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 700;\n"], ["\n  font-weight: 700;\n"])));
var StyledNational = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: normal;\n"], ["\n  font-weight: normal;\n"])));
var Body = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var StyledChartContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var ChartContainerTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 500;\n  margin: 0.25rem 0;\n"], ["\n  font-weight: 500;\n  margin: 0.25rem 0;\n"])));
var StyledChartComponent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  // height: 8.5rem;\n"], ["\n  // height: 8.5rem;\n"])));
var ChartContainer = function (props) {
    var _a;
    var statistic = useFilterValue('statistic', 'statisticOption')[0];
    var _b = props.data || [], data = _b[0], retry = _b[1];
    if (!((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return (React.createElement(StyledChartContainer, null,
        React.createElement(ChartContainerTitle, null, props.title),
        React.createElement(StyledChartComponent, null,
            React.createElement(FallbackComponent, { requesting: data === null || data === void 0 ? void 0 : data.requesting, error: data === null || data === void 0 ? void 0 : data.error, retry: retry },
                React.createElement(PieComponent, { type: props.type, data: data === null || data === void 0 ? void 0 : data.data, percentage: statistic === StatisticTypeEnum.Prevalence, className: "flex-row", width: "45%", height: "8.5rem" })))));
};
export var DemographicChart = function () {
    var _a, _b, _c;
    var _d = useChartData(), chartData = _d[0], fetchChartData = _d[1];
    return (React.createElement(StyledDemographicChart, null,
        React.createElement(Title, null,
            "Demographics - ",
            React.createElement(StyledNational, null, "National")),
        React.createElement(Body, null,
            React.createElement(FallbackComponent, { requesting: chartData.requesting !== false, error: chartData.error, retry: fetchChartData },
                React.createElement(ChartContainer, { type: DemographicTypeEnum.Sex, title: "Sex", data: (_a = chartData.data) === null || _a === void 0 ? void 0 : _a.sex }),
                React.createElement(ChartContainer, { type: DemographicTypeEnum.Age, title: "Age", data: (_b = chartData.data) === null || _b === void 0 ? void 0 : _b.age }),
                React.createElement(ChartContainer, { type: DemographicTypeEnum.Race, title: "Race/Ethnicity", data: (_c = chartData.data) === null || _c === void 0 ? void 0 : _c.race })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
