/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var ROUTE_URL = {
    USER: '/admin/user',
    DATA_FILE: '/admin/data-file',
    MAP_LAYER: '/admin/map-layer',
};
export var PHONE_NUMBER_PATTERN = /^(\d{3})(\d{3})(\d{4})$/;
export var ZIP_CODE_PATTERN = /^[0-9]{5}(?:-[0-9]{4})?$/;
export var PASSWORD_PATTERN = /^(?=^\S*$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*()])(?=.{8,})/;
export var PASSWORD_DESCRIPTION = 'Password may not include spaces and must be at least 8 characters with at least 1 upper case, lower case, number and special character included.';
export var PASSWORD_RULE_MSG = 'Password does not meet criteria above';
export var DELIMITER_SEASON_YEAR = ' - ';
