var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import styled from 'styled-components';
import { useToggle } from '../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AboutModal } from '../aboutmodal';
import { MethodologyModal } from '../methodologymodal';
import { useAuth, useUser, Authenticated } from '../authentication';
import { getUserInitials } from '../utils';
import { UserProfile } from '../userprofile';
import { UserChangePassword } from '../userchangepassword';
import { RightPanelTypeEnum, RoleEnum } from '../enums';
import { ROUTE_URL } from '../common';
import { useFilterSetting, useViewSetting } from '../state';
import { useLookupZipCodeDistrict } from '../lookupdata';
import { AgeReportsDialogTab, ConditionReportsDialogTab, DataYearReportsDialogTab, RaceReportsDialogTab, ReportsDialogExtension, ReportsDialogProvider, ReportsTab, SexReportsDialogTab, ShowByReportsDialogTab, StatisticReportsDialogTab, } from '../reportsdialogextension';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { ShowByZipCodeReportsDialogTab } from '../reportsdialogextension/showbyzipcodetab';
import { DataYearSeasonReportsDialogTab } from '../reportsdialogextension/datayearseasontab';
import { FluVaccineReportsDialogTab } from '../reportsdialogextension/fluvaccinetab';
var UserInfo = styled(DropdownToggle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: #000000;\n  text-decoration: none;\n  :hover {\n    text-decoration: none !important;\n    color: #000000;\n  }\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  color: #000000;\n  text-decoration: none;\n  :hover {\n    text-decoration: none !important;\n    color: #000000;\n  }\n  cursor: pointer;\n"])));
var AvatarCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 2.25rem;\n  height: 2.25rem;\n  background-color: #007bff;\n  text-align: center;\n  border-radius: 50%;\n  color: #ffffff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 0.25rem;\n"], ["\n  width: 2.25rem;\n  height: 2.25rem;\n  background-color: #007bff;\n  text-align: center;\n  border-radius: 50%;\n  color: #ffffff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 0.25rem;\n"])));
var Initials = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-decoration: none;\n"], ["\n  text-decoration: none;\n"])));
var PrincipalUser = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: none;\n\n  @media only screen and (max-width: 600px) {\n    display: none;\n  }\n"], ["\n  text-decoration: none;\n\n  @media only screen and (max-width: 600px) {\n    display: none;\n  }\n"])));
var UserMenuStyle = styled(DropdownMenu)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-bottom: 0;\n  > .dropdown-item:not(.level-1) {\n    font-weight: 500;\n  }\n"], ["\n  padding-bottom: 0;\n  > .dropdown-item:not(.level-1) {\n    font-weight: 500;\n  }\n"])));
var LogOutItem = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
var StyledSpace = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
export var UserMenuToggle = function () {
    var _a;
    var user = useUser();
    return (React.createElement(UserInfo, { tag: "div" },
        React.createElement(AvatarCircle, null,
            React.createElement(Initials, null, (user === null || user === void 0 ? void 0 : user.firstName) ? getUserInitials(user === null || user === void 0 ? void 0 : user.firstName, user === null || user === void 0 ? void 0 : user.lastName) : (_a = user === null || user === void 0 ? void 0 : user.username) === null || _a === void 0 ? void 0 : _a.charAt(0).toUpperCase())),
        React.createElement(PrincipalUser, null, (user === null || user === void 0 ? void 0 : user.firstName) ? (user === null || user === void 0 ? void 0 : user.firstName) + " " + (user === null || user === void 0 ? void 0 : user.lastName) : user === null || user === void 0 ? void 0 : user.username)));
};
var DialogManager = function (props) {
    return (React.createElement(ReportsDialogProvider, null,
        React.createElement(ReportsDialogExtension, { isOpen: props.showReportDialog, toggle: props.toggleReportDialog },
            React.createElement(ReportsTab, null),
            React.createElement(DataYearReportsDialogTab, null),
            React.createElement(DataYearSeasonReportsDialogTab, null),
            React.createElement(FluVaccineReportsDialogTab, null),
            React.createElement(ConditionReportsDialogTab, { condition: props.reportCondition, hasSecondaryCondition: props.hasSecondaryCondition }),
            React.createElement(StatisticReportsDialogTab, null),
            React.createElement(ShowByReportsDialogTab, null),
            React.createElement(ShowByZipCodeReportsDialogTab, null),
            React.createElement(SexReportsDialogTab, null),
            React.createElement(RaceReportsDialogTab, null),
            React.createElement(AgeReportsDialogTab, null))));
};
export var UserMenu = function (props) {
    var _a;
    var auth = useAuth();
    var indexConfig = useHostnameIndexInfo();
    var _b = (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) || {}, publicIndex = _b.publicIndex, methodologyDocument = _b.methodologyDocument;
    var _c = useToggle(), isOpenMethodologyModal = _c[0], toggleMethodologyModal = _c[1];
    var _d = useToggle(), isOpenUserProfileModal = _d[0], toggleUserProfileModal = _d[1];
    var _e = useToggle(), isOpenChangePasswordModal = _e[0], toggleChangePasswordModal = _e[1];
    var _f = useToggle(), isOpenAboutModal = _f[0], toggleAboutModal = _f[1];
    var _g = useToggle(), showReportDialog = _g[0], toggleReportDialog = _g[1];
    var filterSettings = useFilterSetting();
    var viewSettings = useViewSetting();
    var lookupZipCode = useLookupZipCodeDistrict()[0];
    var disabled = !filterSettings.available;
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var form, params;
        var _a;
        return __generator(this, function (_b) {
            form = document.createElement('form');
            form.action = "/api/pdf/private/printmap";
            form.method = 'post';
            form.target = '_blank';
            params = {
                token: auth.jwt,
                data: JSON.stringify({
                    filterSettings: filterSettings,
                    viewSettings: viewSettings,
                    stateName: lookupZipCode.getStateNameOfZipCode(((_a = viewSettings.selectedRegion) === null || _a === void 0 ? void 0 : _a.type) === RightPanelTypeEnum.RegionDetail ? viewSettings.selectedRegion.id : undefined),
                    hostname: window.location.host.split('.')[0],
                }),
                landscape: true,
                isPrintMap: true,
            };
            Object.keys(params).forEach(function (key) {
                var input = document.createElement('input');
                input.name = key;
                input.value = params[key] || '';
                form.appendChild(input);
            });
            window.document.body.appendChild(form);
            form.submit();
            window.document.body.removeChild(form);
            return [2 /*return*/];
        });
    }); };
    var handleLogout = function () {
        auth.logout();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(UncontrolledDropdown, null,
            React.createElement(UserMenuToggle, null),
            React.createElement(UserMenuStyle, { right: true },
                React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.GeneralUser, RoleEnum.IndexManager, RoleEnum.SingleIndexUserManager, RoleEnum.SingleIndexDataManager] },
                    React.createElement(DropdownItem, { header: true }, "Index"),
                    React.createElement(DropdownItem, { disabled: props.disabledReport, onClick: toggleReportDialog }, "View Reports"),
                    React.createElement(DropdownItem, { disabled: disabled, onClick: handleSubmit }, "Print Map"),
                    React.createElement(StyledSpace, null)),
                !publicIndex && (React.createElement(React.Fragment, null,
                    React.createElement(DropdownItem, { header: true }, "User Account"),
                    ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.firstName) && React.createElement(DropdownItem, { onClick: toggleUserProfileModal }, "View Profile"),
                    React.createElement(DropdownItem, { onClick: toggleChangePasswordModal }, "Change Password"),
                    React.createElement(StyledSpace, null))),
                React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.IndexManager, RoleEnum.SingleIndexUserManager, RoleEnum.SingleIndexDataManager] },
                    React.createElement(DropdownItem, { header: true }, "Administration"),
                    React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.IndexManager, RoleEnum.SingleIndexUserManager] },
                        React.createElement(DropdownItem, { tag: "a", target: "_blank", rel: "opener", href: ROUTE_URL.USER }, "Manage Users")),
                    React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.IndexManager, RoleEnum.SingleIndexDataManager] },
                        React.createElement(DropdownItem, { tag: "a", target: "_blank", rel: "opener", href: ROUTE_URL.DATA_FILE }, "Manage Data Files")),
                    React.createElement(Authenticated, { roles: [RoleEnum.SystemAdmin, RoleEnum.IndexManager] },
                        React.createElement(DropdownItem, { tag: "a", target: "_blank", rel: "opener", href: ROUTE_URL.MAP_LAYER }, "Manage Map Layers")),
                    React.createElement(StyledSpace, null)),
                React.createElement(DropdownItem, { header: true }, "Help"),
                !methodologyDocument ? (React.createElement(DropdownItem, { onClick: toggleMethodologyModal }, "Methodologies")) : (React.createElement(DropdownItem, { tag: "a", target: "_blank", rel: "opener", href: methodologyDocument }, "Methodologies")),
                React.createElement(DropdownItem, { onClick: toggleAboutModal }, "About"),
                React.createElement(StyledSpace, null),
                !publicIndex && (React.createElement(DropdownItem, { onClick: handleLogout, disabled: false, className: "level-1 bg-primary text-white" },
                    React.createElement(LogOutItem, null, "Log Out"),
                    " ",
                    React.createElement(FontAwesomeIcon, { icon: "sign-out-alt", size: "lg" }))))),
        React.createElement(UserProfile, { isOpen: isOpenUserProfileModal, toggle: toggleUserProfileModal, principalUser: auth.user }),
        React.createElement(UserChangePassword, { isOpen: isOpenChangePasswordModal, toggle: toggleChangePasswordModal }),
        React.createElement(MethodologyModal, { isOpen: isOpenMethodologyModal, toggle: toggleMethodologyModal }),
        React.createElement(AboutModal, { isOpen: isOpenAboutModal, toggle: toggleAboutModal, title: '' }),
        React.createElement(DialogManager, { reportCondition: props.reportCondition, hasSecondaryCondition: props.hasSecondaryCondition, showReportDialog: showReportDialog, toggleReportDialog: toggleReportDialog })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
