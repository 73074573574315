/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useMemo } from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { useAPI } from '../state';
var LOCATOR_PROVIDER_API_URL = '/api/provider/get-demographic-patient-count-related-to-provider';
var providersSelector = function (state) { return state === null || state === void 0 ? void 0 : state.providers; };
var providerMapSelector = createSelector(providersSelector, function (providers) { return _.keyBy(providers, 'providerNpiNumber'); });
var createProviderDetailSelector = function (_a) {
    var providerNpiNumber = (_a === void 0 ? Object.assign({}) : _a).providerNpiNumber;
    return createSelector(providerMapSelector, function (map) { return map["" + providerNpiNumber]; });
};
export var useProviderData = function () {
    return useAPI(LOCATOR_PROVIDER_API_URL, undefined, 'POST');
};
export var useProviderDetail = function (id) {
    var providerData = useProviderData()[0];
    var providerDetailSelector = useMemo(function () { return createProviderDetailSelector(id); }, [id]);
    return providerDetailSelector(providerData.data);
};
