/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
export var STATIC_PDF_FILE_TYPE = {
    METHODOLOGY_PREVALENCE: 'METHODOLOGY_PREVALENCE',
    METHODOLOGY_TOTAL_COUNTS: 'METHODOLOGY_TOTAL_COUNTS',
    METHODOLOGY_HOSPITALIZATIONS: 'METHODOLOGY_HOSPITALIZATIONS',
    METHODOLOGY_COSTS: 'METHODOLOGY_COSTS',
    METHODOLOGY_DATA_SOURCES: 'METHODOLOGY_DATA_SOURCES',
};
export var METHODOLOGY_TYPE = {
    PREVALENCE: {
        value: 'PREVALENCE',
        label: 'Prevalence',
        pdfFileType: STATIC_PDF_FILE_TYPE.METHODOLOGY_PREVALENCE,
    },
    TOTAL_COUNTS: {
        value: 'TOTAL_COUNTS',
        label: 'Total Counts',
        pdfFileType: STATIC_PDF_FILE_TYPE.METHODOLOGY_TOTAL_COUNTS,
    },
    HOSPITALIZATIONS: {
        value: 'HOSPITALIZATIONS',
        label: 'Hospitalizations',
        pdfFileType: STATIC_PDF_FILE_TYPE.METHODOLOGY_HOSPITALIZATIONS,
    },
    COSTS: {
        value: 'COSTS',
        label: 'Costs',
        pdfFileType: STATIC_PDF_FILE_TYPE.METHODOLOGY_COSTS,
    },
    DATA_SOURCES: {
        value: 'DATA_SOURCES',
        label: 'Data Sources',
        pdfFileType: STATIC_PDF_FILE_TYPE.METHODOLOGY_DATA_SOURCES,
    },
};
