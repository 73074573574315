var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { Map } from 'immutable';
import { DEFAULT_VIEW_SETTING } from '../../configs';
import { setUIValueSessionStorage } from '../../storeduivalue';
export var RESET_VIEW_SETTING = 'RESET_VIEW_SETTING';
export var SET_VIEW_VALUE = 'SET_VIEW_VALUE';
// Init state
var initialState = Map(DEFAULT_VIEW_SETTING);
// Init action handlers
var actionHandlers = {};
actionHandlers[RESET_VIEW_SETTING] = function (s, a) { return Map(__assign(__assign({}, DEFAULT_VIEW_SETTING), a.payload)); };
actionHandlers[SET_VIEW_VALUE] = function (s, a) { return (a.payload.value !== undefined ? s.setIn(a.payload.keyPath, a.payload.value) : s.removeIn(a.payload.keyPath)); };
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var fn = actionHandlers[action.type];
    if (!fn)
        return state;
    var updatedState = fn(state, action);
    setUIValueSessionStorage('viewSetting', updatedState.toObject(), undefined, [
        'mapView',
        'showMapLayerMenu',
        'selectedMultiplePin',
        'mapLayerCountNotifications',
    ]);
    return updatedState;
}
