var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAPI } from '../state';
import { ConfirmForgotPassword } from './confirmforgotpassword';
import { useForm, useMount } from '../hooks';
import { MessageBox, Button } from '../common';
import { getDirectionUrl } from '../utils';
import { useIndexName } from '../hostnameindexinfo';
var ForgotPasswordWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  overflow: auto;\n  display: flex;\n  padding: 10px;\n"], ["\n  flex-grow: 1;\n  overflow: auto;\n  display: flex;\n  padding: 10px;\n"])));
var ForgotPasswordContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 450px;\n  border: 1px solid #dee2e6;\n  border-radius: 4px;\n  padding: 30px;\n  margin: auto;\n"], ["\n  width: 450px;\n  border: 1px solid #dee2e6;\n  border-radius: 4px;\n  padding: 30px;\n  margin: auto;\n"])));
var H5Style = styled.h5(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center !important;\n  margin-bottom: 1.5rem !important;\n"], ["\n  text-align: center !important;\n  margin-bottom: 1.5rem !important;\n"])));
var ButtonGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: right !important;\n"], ["\n  text-align: right !important;\n"])));
var LinkWrapper = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: inherit;\n  :hover {\n    color: inherit;\n    text-decoration: none;\n  }\n"], ["\n  color: inherit;\n  :hover {\n    color: inherit;\n    text-decoration: none;\n  }\n"])));
var validationSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
});
export var ForgotPasswordComponent = function () {
    var _a = useState(), usernameSubmitted = _a[0], setUsernameSubmitted = _a[1];
    var _b = useAPI('/api/auth/forgot-password', undefined, 'POST'), forgotPasswordData = _b[0], forgotPassword = _b[1], resetData = _b[2];
    var _c = useForm({ validationSchema: validationSchema }), setInitialValues = _c.setInitialValues, values = _c.values, handleChange = _c.handleChange, handleBlur = _c.handleBlur, handleSubmit = _c.handleSubmit, isSubmitting = _c.isSubmitting, getError = _c.getError;
    var indexName = useIndexName();
    useMount(function () {
        setInitialValues({
            username: '',
        });
        return resetData;
    });
    useEffect(function () {
        if (forgotPasswordData.requesting === false && !forgotPasswordData.error) {
            setUsernameSubmitted(values.username);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forgotPasswordData.requesting]);
    var onSubmit = handleSubmit(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, forgotPassword(__assign(__assign({}, payload), { indexName: indexName }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    return (React.createElement(ForgotPasswordWrapper, null,
        React.createElement(ForgotPasswordContent, { hidden: !!usernameSubmitted },
            React.createElement(H5Style, null, "Forgot Password"),
            React.createElement(MessageBox, { error: forgotPasswordData.error }),
            React.createElement(Form, { onSubmit: onSubmit },
                React.createElement(FormGroup, null,
                    React.createElement(Label, null, "Enter your email address"),
                    React.createElement(Input, { type: "email", name: "username", value: values.username, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('username') }),
                    React.createElement(FormFeedback, null, getError('username'))),
                React.createElement(ButtonGroup, null,
                    React.createElement(Button, { type: "button", disabled: isSubmitting },
                        React.createElement(LinkWrapper, { to: getDirectionUrl('/') }, "Cancel")),
                    ' ',
                    React.createElement(Button, { color: "primary", type: "submit", loading: isSubmitting }, "Continue")))),
        React.createElement(ConfirmForgotPassword, { username: usernameSubmitted })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
