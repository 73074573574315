/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var DataSourceEnum;
(function (DataSourceEnum) {
    DataSourceEnum["MedicareAll"] = "MEDICARE_ALL";
    DataSourceEnum["MedicareFFS"] = "MEDICARE_FFS";
    DataSourceEnum["MedicareAdvantage"] = "MEDICARE_ADVANTAGE";
    DataSourceEnum["Medicaid"] = "MEDICAID";
    DataSourceEnum["MedicareFfsFluSeason"] = "MEDICARE_FFS_FLU_SEASON";
})(DataSourceEnum || (DataSourceEnum = {}));
