/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, FormGroup, Label } from 'reactstrap';
import { DATA_SOURCES } from '../configs';
import { RadioGroup } from '../common';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { useAggregatedStatistic } from './aggregateddatasource';
import { getStatisticOption, GROUPED_STATISTIC_OPTIONS, getStatisticOptions } from '../lookupdata';
import { useGeneralConfigByDataSource } from '../hooks/useconfig';
var useStatisticEditing = function () {
    var _a = useFilterEditingValue('statistic', 'statisticOption'), statisticType = _a[0], setStatisticType = _a[1];
    var _b = useFilterEditingValue('statistic', 'statisticType'), setStatisticGroup = _b[1];
    var _c = useFilterEditingValue('statisticOptionType'), setStatisticTypeAggregated = _c[1];
    var _d = useFilterEditingValue('statisticType'), setStatisticGroupAggregated = _d[1];
    useEffect(function () {
        var _a;
        var staticsticGroup = (_a = getStatisticOption(statisticType)) === null || _a === void 0 ? void 0 : _a.group.value;
        setStatisticTypeAggregated(statisticType);
        setStatisticGroup(staticsticGroup);
        setStatisticGroupAggregated(staticsticGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statisticType]);
    return [statisticType, setStatisticType];
};
export var StatisticTab = function (props) {
    var _a;
    var _b = useStatisticEditing(), statisticType = _b[0], setStatisticType = _b[1];
    var filterEditing = useFilterEditing();
    var aggregatedData = useAggregatedStatistic(filterEditing)[0];
    var availableStatisticsConfig = useGeneralConfigByDataSource('availableStatistic');
    var cmsDataSource = (_a = filterEditing.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource;
    var availableStatistics = filterEditing.isAggregatedDataSource
        ? aggregatedData.data
        : cmsDataSource && (availableStatisticsConfig || DATA_SOURCES[cmsDataSource].availableStatistic);
    useEffect(function () {
        if ((availableStatistics === null || availableStatistics === void 0 ? void 0 : availableStatistics.length) && !availableStatistics.some(function (v) { return v === statisticType; })) {
            var statisticOption = getStatisticOptions(availableStatistics)[0];
            setStatisticType(statisticOption.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableStatistics]);
    var isOptionDisabled = function (option) {
        return !!availableStatistics && !(availableStatistics === null || availableStatistics === void 0 ? void 0 : availableStatistics.some(function (i) { return i === option.value; }));
    };
    return (React.createElement(TabPane, { tabId: props.tabId }, GROUPED_STATISTIC_OPTIONS.map(function (group) { return (React.createElement(FormGroup, { tag: "fieldset", key: group.value },
        React.createElement(Label, { className: "font-weight-bold" }, group.label),
        React.createElement(RadioGroup, { options: group.options, noSort: true, value: statisticType, onChange: setStatisticType, isOptionDisabled: isOptionDisabled }))); })));
};
StatisticTab.defaultProps = {
    tabId: 'StatisticTab',
    tabName: 'Statistics',
};
