/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { GeoLevelEnum, StatisticTypeEnum } from '../../enums';
import { getDisplayText } from '../../utils';
import { getStatisticShortName } from '../../lookupdata';
var createDataFieldObject = function (fieldName, fieldValue, suffix, prefix, loading) {
    if (suffix === void 0) { suffix = ''; }
    if (prefix === void 0) { prefix = ''; }
    return {
        key: fieldName,
        name: fieldName,
        value: getDisplayText(fieldValue, suffix, prefix),
        originalValue: fieldValue,
        loading: loading,
    };
};
function getDataFieldsForZip5Digit(props, lookupState, lookupZipCode) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Zip Code', "\t" + props.zipCode));
    dataFields.push(createDataFieldObject('State', lookupZipCode.getStateNameOfZipCode(props.zipCode), undefined, undefined, lookupState.requesting || lookupZipCode.requesting));
    return dataFields;
}
var getDataFieldsForZip3Digit = function (props, lookupState) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Zip Code', "\t" + props.zip3));
    dataFields.push(createDataFieldObject('State', lookupState.getNameByStateAbb(props.stateAbb), undefined, undefined, lookupState.requesting));
    return dataFields;
};
var getDataFieldsForStateLevel = function (props) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('State', props === null || props === void 0 ? void 0 : props.name));
    return dataFields;
};
var getDataFieldsForCountyLevel = function (props, lookupState) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('County', props.name));
    dataFields.push(createDataFieldObject('State', lookupState.getNameByStateCode(props.stateCode), undefined, undefined, lookupState.requesting));
    return dataFields;
};
var getDataFieldsForMSALevel = function (props) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('MSA', props.name));
    return dataFields;
};
var getDataFieldsForCongressionalLevel = function (props, lookupState) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('US Congress District', props.cd));
    dataFields.push(createDataFieldObject('State', lookupState.getNameByStateCode(props.stateCode), undefined, undefined, lookupState.requesting));
    return dataFields;
};
var getDataFieldsForStateUpperLevel = function (props, lookupState) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('State Upper House District', props.sldu));
    dataFields.push(createDataFieldObject('State', lookupState.getNameByStateCode(props.stateCode), undefined, undefined, lookupState.requesting));
    return dataFields;
};
var getDataFieldsForStateLowerLevel = function (props, lookupState) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('State Lower House District', props.sldl));
    dataFields.push(createDataFieldObject('State', lookupState.getNameByStateCode(props.stateCode), undefined, undefined, lookupState.requesting));
    return dataFields;
};
var getDataFieldForAggregatedDataSource = function (data, statistic) {
    var dataFields = [];
    dataFields.push(createDataFieldObject(getStatisticShortName(statistic), data.value, data.suffix, data.prefix));
    return dataFields;
};
var getDataFieldsForPrevalence = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Patient Population', data.denominator));
    dataFields.push(createDataFieldObject('Patient Count', data.numerator));
    dataFields.push(createDataFieldObject('Prevalence', data.value, '%'));
    dataFields.push(createDataFieldObject('National Prevalence', data.national, '%'));
    return dataFields;
};
var getDataFieldsForTotalCountStatistic = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Patient Count', data.value));
    return dataFields;
};
var getDataFieldForHospitalRate = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total hospital visits', data.numerator));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort hospital rate', data.value));
    dataFields.push(createDataFieldObject('Geographic hospital rate', data.geographic));
    dataFields.push(createDataFieldObject('National hospital rate', data.national));
    return dataFields;
};
var getDataFieldForERVisitRate = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total ER visits', data.numerator));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort ER visit rate', data.value));
    dataFields.push(createDataFieldObject('Geographic ER visit rate', data.geographic));
    dataFields.push(createDataFieldObject('National ER visit rate', data.national));
    return dataFields;
};
var getDataFieldForHospitalEncounterRate = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total # hosp encounters', data.numerator));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort hosp encounter rate', data.value));
    dataFields.push(createDataFieldObject('Geographic hosp encounter rate', data.geographic));
    dataFields.push(createDataFieldObject('National hosp encounter rate', data.national));
    return dataFields;
};
var getDataFieldForDeathRate = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total # deaths', data.numerator));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort death rate', data.value));
    dataFields.push(createDataFieldObject('Geographic death rate', data.geographic));
    dataFields.push(createDataFieldObject('National death rate', data.national));
    return dataFields;
};
var getDataFieldForReadmissionRate = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total # readmissions', data.numerator));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort readmission rate', data.value));
    dataFields.push(createDataFieldObject('Geographic readmission rate', data.geographic));
    dataFields.push(createDataFieldObject('National readmission rate', data.national));
    return dataFields;
};
var getDataFieldForHospitalCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total hospital visits', data.eventCount));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort hosp cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic hosp cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National hosp cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForERCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total ER visits', data.eventCount));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort ER cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic ER cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National ER cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForPatientOutOfPocketPartBDrugCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total Patient Part b Drug Payments', data.numerator, '', '$'));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForPatientOutOfPocketPartDDrugCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total Patient Part d Drug Payments', data.numerator, '', '$'));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForMedicarePartBDrugCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total Part b Drug Payments', data.numerator, '', '$'));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForMedicarePartDDrugCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total Part d Drug Payments', data.numerator, '', '$'));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National cost/patient', data.national, '', '$'));
    return dataFields;
};
var getDataFieldForTotalCost = function (data) {
    var dataFields = [];
    dataFields.push(createDataFieldObject('Total annual costs', data.numerator, '', '$'));
    dataFields.push(createDataFieldObject('Total # patients', data.denominator));
    dataFields.push(createDataFieldObject('Cohort cost/patient', data.value, '', '$'));
    dataFields.push(createDataFieldObject('Geographic cost/patient', data.geographic, '', '$'));
    dataFields.push(createDataFieldObject('National cost/patient', data.national, '', '$'));
    return dataFields;
};
export var getDataFieldsByMapViewLevel = function (mapViewType, properties, lookupState, lookupZipCode) {
    var _a;
    var targetFunctionMapping = (_a = {},
        _a[GeoLevelEnum.Zip5] = getDataFieldsForZip5Digit,
        _a[GeoLevelEnum.Zip3] = getDataFieldsForZip3Digit,
        _a[GeoLevelEnum.State] = getDataFieldsForStateLevel,
        _a[GeoLevelEnum.County] = getDataFieldsForCountyLevel,
        _a[GeoLevelEnum.MSA] = getDataFieldsForMSALevel,
        _a[GeoLevelEnum.Congressional] = getDataFieldsForCongressionalLevel,
        _a[GeoLevelEnum.StateUpper] = getDataFieldsForStateUpperLevel,
        _a[GeoLevelEnum.StateLower] = getDataFieldsForStateLowerLevel,
        _a);
    if (mapViewType && targetFunctionMapping[mapViewType]) {
        return targetFunctionMapping[mapViewType](properties, lookupState, lookupZipCode);
    }
    return [];
};
export var getDataFieldByStatisticType = function (data, statistic) {
    var _a;
    var isAggregated = data.isAggregated;
    if (isAggregated) {
        return getDataFieldForAggregatedDataSource(data, statistic);
    }
    var targetFunctionMapping = (_a = {},
        _a[StatisticTypeEnum.Prevalence] = getDataFieldsForPrevalence,
        _a[StatisticTypeEnum.TotalCount] = getDataFieldsForTotalCountStatistic,
        _a[StatisticTypeEnum.HospitalizationRate] = getDataFieldForHospitalRate,
        _a[StatisticTypeEnum.ERVisitsRate] = getDataFieldForERVisitRate,
        _a[StatisticTypeEnum.HospitalEncounterRate] = getDataFieldForHospitalEncounterRate,
        _a[StatisticTypeEnum.DeathRate] = getDataFieldForDeathRate,
        _a[StatisticTypeEnum.ReadmissionRate] = getDataFieldForReadmissionRate,
        _a[StatisticTypeEnum.HospitalizationCost] = getDataFieldForHospitalCost,
        _a[StatisticTypeEnum.ERCost] = getDataFieldForERCost,
        _a[StatisticTypeEnum.OOPPartBDrugCost] = getDataFieldForPatientOutOfPocketPartBDrugCost,
        _a[StatisticTypeEnum.OOPPartDDrugCost] = getDataFieldForPatientOutOfPocketPartDDrugCost,
        _a[StatisticTypeEnum.MCRPartBDrugCost] = getDataFieldForMedicarePartBDrugCost,
        _a[StatisticTypeEnum.MCRPartDDrugCost] = getDataFieldForMedicarePartDDrugCost,
        _a[StatisticTypeEnum.TotalCost] = getDataFieldForTotalCost,
        _a);
    if (statistic && targetFunctionMapping[statistic]) {
        return targetFunctionMapping[statistic](data);
    }
    return [];
};
