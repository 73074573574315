var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { DropdownMenu, Dropdown } from 'reactstrap';
import styled from 'styled-components';
import { DropdownToggleIcon } from '../common';
import { ViewModeEnum } from '../enums';
import { useViewValue } from '../state/viewsetting';
import { LocationMenu, useMapLayerListAPI } from './locationmenu';
var DropdownMenuStyle = styled(DropdownMenu)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 80vh;\n  overflow-y: auto;\n"], ["\n  max-height: 80vh;\n  overflow-y: auto;\n"])));
export var MapLayerMenu = function (props) {
    var _a;
    var viewMode = useViewValue('viewMode')[0];
    var _b = useViewValue('showMapLayerMenu'), showMapLayerMenu = _b[0], setShowMapLayerMenu = _b[1];
    var _c = useMapLayerListAPI(), mapLayerData = _c[0], fetchMapLayerData = _c[1];
    var hasFixedChildren = React.Children.toArray(props.children).some(function (c) { var _a; return (c === null || c === void 0 ? void 0 : c.type) !== LocationMenu || ((_a = c === null || c === void 0 ? void 0 : c.props) === null || _a === void 0 ? void 0 : _a.children); });
    useEffect(function () {
        if (mapLayerData.requesting === undefined || (showMapLayerMenu && !mapLayerData.requesting)) {
            fetchMapLayerData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMapLayerMenu]);
    if (viewMode !== ViewModeEnum.Map || (!hasFixedChildren && !((_a = mapLayerData.data) === null || _a === void 0 ? void 0 : _a.length)))
        return null;
    return (React.createElement(Dropdown, { isOpen: showMapLayerMenu, toggle: function () { return setShowMapLayerMenu(!showMapLayerMenu); } },
        React.createElement(DropdownToggleIcon, { icon: "layer-group", size: "2x", title: "Choose layers to display", placement: "top" }),
        React.createElement(DropdownMenuStyle, { flip: false }, props.children)));
};
export * from './providermenu';
export * from './locationmenu';
export * from './clinicaltrialmenu';
export * from './questdiagnosticsmenu';
var templateObject_1;
