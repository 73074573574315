var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelectCreatable from 'react-select/creatable';
import { isEmpty } from '../utils';
var MAX_OPTION_SIZE = 1000;
var InputGroupWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-shrink: 0;\n  ", "\n  // max-width: 25rem;\n  ", "\n"], ["\n  flex-shrink: 0;\n  ", "\n  // max-width: 25rem;\n  ",
    "\n"])), function (props) { return (props.inline ? 'display: inline-block;' : ''); }, function (props) {
    return props.width
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          width: ", "rem;\n        "], ["\n          width: ", "rem;\n        "])), props.width) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          min-width: 10rem;\n        "], ["\n          min-width: 10rem;\n        "])));
});
var DropdownIndicator = function (_a) {
    var icon = _a.icon, props = __rest(_a, ["icon"]);
    return React.createElement(components.DropdownIndicator, __assign({}, props), icon ? React.createElement(FontAwesomeIcon, { icon: icon }) : props.children);
};
var MenuList = function (_a) {
    var searchedOptionsSize = _a.searchedOptionsSize, props = __rest(_a, ["searchedOptionsSize"]);
    return React.createElement(components.MenuList, __assign({}, props), props.children);
};
var MenuListMemo = React.memo(MenuList, function (prev, next) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var menuOptionsPrev = (((_d = (_c = (_b = (_a = prev.selectRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.select) === null || _d === void 0 ? void 0 : _d.state) || {}).menuOptions;
    var menuOptionsNext = (((_h = (_g = (_f = (_e = next.selectRef) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.select) === null || _g === void 0 ? void 0 : _g.select) === null || _h === void 0 ? void 0 : _h.state) || {}).menuOptions;
    return ((_k = (_j = menuOptionsPrev === null || menuOptionsPrev === void 0 ? void 0 : menuOptionsPrev.render) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.value) === ((_m = (_l = menuOptionsNext === null || menuOptionsNext === void 0 ? void 0 : menuOptionsNext.render) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.value) &&
        prev.searchedOptionsSize === next.searchedOptionsSize &&
        prev.isLoading === next.isLoading;
});
var IconOption = function (props) { return (React.createElement(components.Option, __assign({}, props),
    props.data.icon && React.createElement(FontAwesomeIcon, { icon: props.data.icon, className: "mr-1" }),
    props.children)); };
var SingleValue = function (props) { return (React.createElement(components.SingleValue, __assign({}, props),
    props.data.icon && React.createElement(FontAwesomeIcon, { icon: props.data.icon, className: "mr-1" }),
    props.children)); };
export var Select = function (props) {
    var _a = useState(props.inputBoxValue || ''), inputValue = _a[0], setInputValue = _a[1];
    var _b = useState(), searchedOptionsSize = _b[0], setSearchedOptionsSize = _b[1];
    var selectRef = useRef();
    useEffect(function () {
        var _a;
        setSearchedOptionsSize((_a = props.options) === null || _a === void 0 ? void 0 : _a.length);
    }, [props.options]);
    useEffect(function () {
        setInputValue(props.inputBoxValue || '');
    }, [props.inputBoxValue]);
    var handleInputChange = function (newValue) {
        var _a;
        setInputValue(newValue);
        if (!newValue) {
            setSearchedOptionsSize((_a = props.options) === null || _a === void 0 ? void 0 : _a.length);
        }
    };
    var handleChange = function (values) {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.isMulti ? values === null || values === void 0 ? void 0 : values.map(function (i) { return i.value; }) : values === null || values === void 0 ? void 0 : values.value, props.name, values);
        (_b = props.setFieldValue) === null || _b === void 0 ? void 0 : _b.call(props, props.isMulti ? values === null || values === void 0 ? void 0 : values.map(function (i) { return i.value; }) : values === null || values === void 0 ? void 0 : values.value, props.name, values);
    };
    var options = props.options ? (props.optionsTransform ? props.options.map(props.optionsTransform) : props.options) : [];
    if (props.noSort === undefined || props.noSort === false) {
        options.sort(function (a, b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0); });
    }
    options = props.isOptionDisabled ? options === null || options === void 0 ? void 0 : options.map(function (option) { var _a; return (__assign(__assign({}, option), { __disabled: (_a = props.isOptionDisabled) === null || _a === void 0 ? void 0 : _a.call(props, option) })); }) : options;
    var isOptionDisabled = function (option) { return !!option.__disabled; };
    var loadOptions = function (input) { return __awaiter(void 0, void 0, void 0, function () {
        var filterIncludes, filterStartWith;
        return __generator(this, function (_a) {
            filterIncludes = function (i) {
                var _a, _b;
                return (i.label.toLowerCase().includes(input.toLowerCase()) || ((_a = i.parent) === null || _a === void 0 ? void 0 : _a.label.toLowerCase().includes(input.toLowerCase())) || ((_b = i.children) === null || _b === void 0 ? void 0 : _b.some(function (child) { return child.label.toLowerCase().includes(input.toLowerCase()); })));
            };
            filterStartWith = function (i) {
                var _a, _b;
                return (i.noCheckFilter ||
                    i.label.toLowerCase().startsWith(input.toLowerCase()) || ((_a = i.parent) === null || _a === void 0 ? void 0 : _a.label.toLowerCase().startsWith(input.toLowerCase())) || ((_b = i.children) === null || _b === void 0 ? void 0 : _b.some(function (child) { return child.label.toLowerCase().startsWith(input.toLowerCase()); })));
            };
            return [2 /*return*/, new Promise(function (resolve) {
                    setTimeout(function () {
                        var filteredOptions = options.filter(props.isFilterStartWith ? filterStartWith : filterIncludes);
                        setSearchedOptionsSize(filteredOptions.length);
                        filteredOptions = filteredOptions.slice(0, MAX_OPTION_SIZE);
                        resolve(filteredOptions);
                        var focusedOption = selectRef.current.select.select.state.focusedOption;
                        if (filteredOptions.indexOf(focusedOption) === -1 || !(focusedOption === null || focusedOption === void 0 ? void 0 : focusedOption.label.toLowerCase().includes(input.toLowerCase()))) {
                            selectRef.current.select.select.setState({
                                focusedOption: filteredOptions.find(function (option) { return option.label.toLowerCase().includes(inputValue.toLowerCase()); }),
                            });
                        }
                    });
                })];
        });
    }); };
    var handleBlur = function () {
        var _a;
        (_a = props.setFieldTouched) === null || _a === void 0 ? void 0 : _a.call(props, true, props.name);
    };
    var flatedOptions = options.flatMap(function (i) { return i.options || [i]; });
    var value = props.isMulti ? flatedOptions.filter(function (i) { var _a; return (_a = props.value) === null || _a === void 0 ? void 0 : _a.some(function (v) { return v === i.value; }); }) : flatedOptions.find(function (i) { return i.value === props.value; });
    return (React.createElement(InputGroupWrapper, { inline: props.inline, width: props.width },
        React.createElement(AsyncSelect, { ref: selectRef, className: props.className, isLoading: props.isLoading, isDisabled: props.disabled, value: value === undefined ? null : value, isClearable: props.isClearable, placeholder: props.placeholder, defaultOptions: options.slice(0, MAX_OPTION_SIZE), loadOptions: loadOptions, onChange: handleChange, onBlur: handleBlur, name: props.name, menuPlacement: props.menuPlacement, formatOptionLabel: props.formatOptionLabel, components: __assign({ DropdownIndicator: function (indicatorProps) {
                    return inputValue && props.indicatorIcon ? null : React.createElement(DropdownIndicator, __assign({}, indicatorProps, { icon: props.indicatorIcon }));
                }, IndicatorSeparator: inputValue && props.indicatorIcon ? null : components.IndicatorSeparator, MenuList: function (menuListProps) {
                    if (props.onSelectToRenderOptions)
                        return React.createElement(MenuListMemo, __assign({}, menuListProps, { selectRef: selectRef, searchedOptionsSize: searchedOptionsSize }));
                    return React.createElement(MenuList, __assign({}, menuListProps, { searchedOptionsSize: searchedOptionsSize }));
                }, Option: IconOption, SingleValue: SingleValue }, props.components), inputValue: inputValue, menuPosition: props.menuPosition, onInputChange: props.onInputBoxValueChange || handleInputChange, isOptionDisabled: isOptionDisabled, isMulti: props.isMulti, closeMenuOnSelect: props.closeMenuOnSelect, controlShouldRenderValue: props.controlShouldRenderValue, styles: {
                menu: function (provided) { return (__assign(__assign({}, provided), { zIndex: 9999 })); },
            }, minMenuHeight: props.minMenuHeight, maxMenuHeight: props.maxMenuHeight })));
};
export var CreatableSelect = function (props) {
    var _a = useState(''), inputValue = _a[0], setInputValue = _a[1];
    var handleInputChange = function (inputValue) {
        setInputValue(inputValue.trimStart());
    };
    var handleChange = function (newValue) {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, newValue === null || newValue === void 0 ? void 0 : newValue.value, props.name, newValue);
        (_b = props.setFieldValue) === null || _b === void 0 ? void 0 : _b.call(props, newValue === null || newValue === void 0 ? void 0 : newValue.value, props.name, newValue);
    };
    var handleBlur = function () {
        var _a;
        (_a = props.setFieldTouched) === null || _a === void 0 ? void 0 : _a.call(props, true, props.name);
    };
    var isValidNewOption = function (inputValue, selectValue, selectOptions) {
        var candidate = inputValue.trim().toLowerCase();
        return (!!candidate &&
            selectValue.every(function (value) { return candidate !== ("" + value).trim().toLowerCase(); }) &&
            selectOptions.every(function (option) { return candidate !== option.label.trim().toLowerCase(); }));
    };
    var options = props.options ? (props.optionsTransform ? props.options.map(props.optionsTransform) : props.options) : [];
    if (props.noSort === undefined || props.noSort === false) {
        options.sort(function (a, b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0); });
    }
    var value = !isEmpty(props.value) ? options.find(function (i) { return i.value === props.value; }) || { value: props.value, label: props.value } : null;
    return (React.createElement(ReactSelectCreatable, __assign({}, props, { value: value, options: options, inputValue: inputValue, onInputChange: handleInputChange, onChange: handleChange, onBlur: handleBlur, isValidNewOption: isValidNewOption })));
};
var templateObject_1, templateObject_2, templateObject_3;
