/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, Label } from 'reactstrap';
import { RadioGroup } from '../common';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { GEO_LEVELS } from '../lookupdata';
import { useAggregatedGeoLevel } from './aggregateddatasource';
import { useGeneralConfigByDataSource } from '../hooks';
export var GeoLevelTab = function (props) {
    var _a = useFilterEditingValue('geoLevel'), geoLevel = _a[0], setGeoLevel = _a[1];
    var filterEdditing = useFilterEditing();
    var aggregatedGeoLevelData = useAggregatedGeoLevel(filterEdditing)[0];
    var availableGeography = useGeneralConfigByDataSource('availableGeography');
    useEffect(function () {
        var _a;
        if (filterEdditing.isAggregatedDataSource && ((_a = aggregatedGeoLevelData.data) === null || _a === void 0 ? void 0 : _a.length) && !aggregatedGeoLevelData.data.some(function (i) { return i === geoLevel; })) {
            setGeoLevel(aggregatedGeoLevelData.data[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterEdditing.isAggregatedDataSource, aggregatedGeoLevelData.data]);
    var isOptionDisabled = function (option) { var _a; return !!filterEdditing.isAggregatedDataSource && !((_a = aggregatedGeoLevelData.data) === null || _a === void 0 ? void 0 : _a.some(function (i) { return i === option.value; })); };
    var isOptionDisabledByGeneralConfig = function (option) { return Array.isArray(availableGeography) && !availableGeography.includes(option.value); };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, "Select a geographic level"),
        React.createElement(RadioGroup, { options: GEO_LEVELS, noSort: true, value: geoLevel, onChange: setGeoLevel, isOptionDisabled: isOptionDisabledByGeneralConfig || isOptionDisabled })));
};
GeoLevelTab.defaultProps = {
    tabId: 'geoLevelTab',
    tabName: 'Geography',
};
