/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
import { useMount } from '../hooks';
import { useCachedData } from '../state';
var LOOKUP_MSA_API = '/api/general/lookup/get-lookup-msa';
export var useLookupMSA = function () {
    return useCachedData(LOOKUP_MSA_API);
};
export var LookupMSAComponent = function () {
    var _a = useLookupMSA(), fetchLookup = _a[1];
    useMount(function () {
        fetchLookup();
    });
    return null;
};
