var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useMemo, useRef } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDisplayText } from '../utils';
var COLORS_BY_DEMOGRAPHIC = {
    sex: ['#00008b', '#ffa500'],
    race: ['#800080', '#a8135f', '#b6443a', '#a77217', '#ac8d02', '#cd9d00', '#ffa500'],
    age: ['#ffeabd', '#ffd59b', '#ffa474', '#f47461', '#db4551', '#b81b34', '#8b0000'],
};
var StyledPieComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"], ["\n  height: 100%;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"])));
var ResponsiveContainerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n"], ["\n  position: relative;\n  width: ", ";\n  height: ", ";\n"])), function (props) { return (typeof props.width === 'string' ? props.width : props.width ? props.width / 16 + "rem" : '100%'); }, function (props) { return (typeof props.height === 'string' ? props.height : props.height ? props.height / 16 + "rem" : '100%'); });
var StyledLegend = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-height: 2.25rem;\n  min-width: 8.5rem;\n  flex: 1;\n  .recharts-legend-item {\n    display: ", "flex !important;\n    align-items: center;\n    text-align: left;\n    .recharts-legend-item-text {\n      flex: 1;\n    }\n  }\n"], ["\n  min-height: 2.25rem;\n  min-width: 8.5rem;\n  flex: 1;\n  .recharts-legend-item {\n    display: ", "flex !important;\n    align-items: center;\n    text-align: left;\n    .recharts-legend-item-text {\n      flex: 1;\n    }\n  }\n"])), function (props) { return (props.inline ? 'inline-' : ''); });
var NoDataMessage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  padding: 0.25rem;\n  background-color: transparent;\n  max-width: 11.25rem;\n  text-align: center;\n  font-style: italic;\n"], ["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  padding: 0.25rem;\n  background-color: transparent;\n  max-width: 11.25rem;\n  text-align: center;\n  font-style: italic;\n"])));
var calculatePieSlicePercent = function (value, totalCount) {
    var percent = (value / totalCount) * 100;
    var integerPortion = Math.floor(percent);
    var numberOfDecimalPlaces = 3;
    if (integerPortion > 1 && integerPortion < 10) {
        numberOfDecimalPlaces = 2;
    }
    else if (integerPortion < 100) {
        numberOfDecimalPlaces = 1;
    }
    return parseFloat(percent.toFixed(numberOfDecimalPlaces));
};
export var PieComponent = function (props) {
    var _a, _b, _c;
    var t = useTranslation().t;
    var myRef = useRef(null);
    var meetMinimumThreshold = !!((_a = props.data) === null || _a === void 0 ? void 0 : _a.length);
    var data = useMemo(function () { return (meetMinimumThreshold ? props.data : [{ name: 'NA', value: 1 }]); }, [props.data, meetMinimumThreshold]);
    var colors = useMemo(function () { return COLORS_BY_DEMOGRAPHIC[props.type]; }, [props.type]);
    var total = useMemo(function () { var _a; return ((_a = props.data) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, curr) { return acc + curr.value; }, 0)) || 0; }, [props.data]);
    var tooltipFomatter = useCallback(function (value, name, entry) {
        return props.percentage
            ? getDisplayText(calculatePieSlicePercent(entry.payload.value, total), '%')
            : getDisplayText(entry.payload.value, entry.payload.suffix, entry.payload.prefix);
    }, [props.percentage, total]);
    var onPieEnter = function () {
        var _a;
        var tooltipWrapper = (_a = myRef.current) === null || _a === void 0 ? void 0 : _a.getElementsByClassName('recharts-tooltip-wrapper')[0];
        if (tooltipWrapper) {
            tooltipWrapper.style.right = 'auto';
            if (tooltipWrapper.getBoundingClientRect().right > window.innerWidth) {
                tooltipWrapper.style.right = '0';
            }
        }
    };
    var onPieLeave = function () {
        var _a;
        var tooltipWrapper = (_a = myRef.current) === null || _a === void 0 ? void 0 : _a.getElementsByClassName('recharts-tooltip-wrapper')[0];
        if (tooltipWrapper) {
            tooltipWrapper.style.right = 'auto';
        }
    };
    return (React.createElement(StyledPieComponent, { className: props.className, ref: myRef },
        React.createElement(ResponsiveContainerWrapper, { width: props.width, height: props.height },
            React.createElement(ResponsiveContainer, { className: "position-absolute" },
                React.createElement(PieChart, { onMouseMove: onPieEnter, onMouseLeave: onPieLeave },
                    React.createElement(Pie, { dataKey: "value", isAnimationActive: false, data: data, fill: "#ccc", strokeWidth: meetMinimumThreshold ? 2 : 0, labelLine: false, startAngle: 90, endAngle: -270, minAngle: 2, outerRadius: "100%" }, (_b = props.data) === null || _b === void 0 ? void 0 : _b.map(function (entry, idx) { return (React.createElement(Cell, { key: "cell-" + entry.name, fill: colors[idx] })); })),
                    meetMinimumThreshold && React.createElement(Tooltip, { wrapperStyle: { zIndex: 99 }, isAnimationActive: false, formatter: tooltipFomatter }))),
            !meetMinimumThreshold && React.createElement(NoDataMessage, null, t('demographicchart.nomeetminimumthreshold.msg'))),
        meetMinimumThreshold && (React.createElement(StyledLegend, { inline: props.inline },
            React.createElement(Legend, { wrapperStyle: { position: 'relative' }, payload: meetMinimumThreshold
                    ? (_c = props.data) === null || _c === void 0 ? void 0 : _c.map(function (entry, idx) { return ({
                        id: idx,
                        value: entry.name,
                        color: colors[idx],
                        type: 'rect',
                        dataValue: entry.value,
                    }); }) : [{ id: 0, value: 'N/A', color: '#ccc', type: 'rect' }], formatter: props.legendFormatter })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
