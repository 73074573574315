/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var GeoLevelEnum;
(function (GeoLevelEnum) {
    GeoLevelEnum["Zip5"] = "ZIP_CODE_5";
    GeoLevelEnum["Zip3"] = "ZIP_CODE_3";
    GeoLevelEnum["State"] = "STATE";
    GeoLevelEnum["County"] = "COUNTY";
    GeoLevelEnum["MSA"] = "MSA";
    GeoLevelEnum["Congressional"] = "CONGRESSIONAL_DISTRICT";
    GeoLevelEnum["StateUpper"] = "STATE_UPPER_HOUSE_DISTRICT";
    GeoLevelEnum["StateLower"] = "STATE_LOWER_HOUSE_DISTRICT";
})(GeoLevelEnum || (GeoLevelEnum = {}));
