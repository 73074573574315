var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { useSelectedGeoIdInfo } from '../../geoselectedinfo';
import { getStatisticShortName } from '../../../lookupdata';
import { extractGeoData } from '../..';
import { StatisticTypeEnum } from '../../../enums';
import { PrevalenceDashBoard } from './prevalencedashboard';
import { AggregatedDataBannerDashBoard } from './aggregateddatabannerdashboard';
import { TotalCountDashBoard } from './totalcountdashboard';
import { HospitalizationRateDashBoard } from './hospitalizationratedashboard';
import { ERVisitRateDashBoard } from './ervisitratedashboard';
import { HospEncounterRateDashBoard } from './hospencounterratedashboard';
import { DeathRateDashBoard } from './deathratedashboard';
import { ReadmissionRateDashBoard } from './readmissionratedashboard';
import { HospitalCostDashBoard } from './hospitalcostdashboard';
import { ERVisitCostDashBoard } from './ervisitcostdashboard';
import { PatientOutOfPocketPartBDrugCostDashBoard } from './patientoutofpocketpartbdrugcostdashboard';
import { PatientOutOfPocketPartDDrugCostDashBoard } from './patientoutofpocketpartddrugcostdashboard';
import { MedicarePartBDrugCostDashBoard } from './medicarepartbdrugcostdashboard';
import { MedicarePartDDrugCostDashBoard } from './medicarepartddrugcostdashboard';
import { TotalCostDashBoard } from './totalcostdashboard';
import { ENV_LEGEND_COLORS } from '../../../env';
import { useFilterSetting, useViewSetting } from '../../../state';
import { useMapData } from '../../patientchoroplethlayer/usemapdata';
var CardTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.5rem 0;\n  font-weight: 500;\n"], ["\n  padding: 0.5rem 0;\n  font-weight: 500;\n"])));
var CardContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0.75rem;\n  vertical-align: middle;\n  position: relative;\n"], ["\n  width: 100%;\n  padding: 0.75rem;\n  vertical-align: middle;\n  position: relative;\n"])));
var CardColor = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  opacity: 0.7;\n"], ["\n  background-color: ", ";\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  opacity: 0.7;\n"])), function (props) { return props.backgroundColor; });
var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Card = function (props) {
    var _a;
    var _b = useSelectedGeoIdInfo(props.viewSetting), firstLevel = _b[1], secondLevel = _b[2];
    var makeSelection = !((_a = props.viewSetting.mapView) === null || _a === void 0 ? void 0 : _a.selectedGeoProperties);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, null, makeSelection ? ('Make Selection') : (React.createElement(React.Fragment, null,
            firstLevel,
            " ",
            secondLevel))),
        React.createElement(CardContent, null,
            React.createElement(CardColor, { backgroundColor: props.backgroundColor }),
            React.createElement(Text, null, props.children))));
};
export var NationalCard = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(CardTitle, null, "National"),
        React.createElement(CardContent, null,
            React.createElement(CardColor, { backgroundColor: "#ccc" }),
            React.createElement(Text, null, props.children))));
};
export var StatisticCard = function () {
    var _a, _b, _c;
    var filterSetting = useFilterSetting();
    var viewSetting = useViewSetting();
    var mapData = useMapData()[0].data;
    var geoLevel = viewSetting.geoType;
    var selectedId = (_b = (_a = viewSetting.mapView) === null || _a === void 0 ? void 0 : _a.selectedGeoProperties) === null || _b === void 0 ? void 0 : _b.id;
    var statistic = (_c = filterSetting.statistic) === null || _c === void 0 ? void 0 : _c.statisticOption;
    var statisticName = getStatisticShortName(statistic);
    var extractedGeoData = extractGeoData(selectedId, filterSetting, geoLevel, mapData);
    var getStatisticComponent = function () {
        if (filterSetting.isAggregatedDataSource) {
            return AggregatedDataBannerDashBoard;
        }
        switch (statistic) {
            case StatisticTypeEnum.Prevalence:
                return PrevalenceDashBoard;
            case StatisticTypeEnum.TotalCount:
                return TotalCountDashBoard;
            case StatisticTypeEnum.HospitalizationRate:
                return HospitalizationRateDashBoard;
            case StatisticTypeEnum.ERVisitsRate:
                return ERVisitRateDashBoard;
            case StatisticTypeEnum.HospitalEncounterRate:
                return HospEncounterRateDashBoard;
            case StatisticTypeEnum.DeathRate:
                return DeathRateDashBoard;
            case StatisticTypeEnum.ReadmissionRate:
                return ReadmissionRateDashBoard;
            case StatisticTypeEnum.HospitalizationCost:
                return HospitalCostDashBoard;
            case StatisticTypeEnum.ERCost:
                return ERVisitCostDashBoard;
            case StatisticTypeEnum.OOPPartBDrugCost:
                return PatientOutOfPocketPartBDrugCostDashBoard;
            case StatisticTypeEnum.OOPPartDDrugCost:
                return PatientOutOfPocketPartDDrugCostDashBoard;
            case StatisticTypeEnum.MCRPartBDrugCost:
                return MedicarePartBDrugCostDashBoard;
            case StatisticTypeEnum.MCRPartDDrugCost:
                return MedicarePartDDrugCostDashBoard;
            case StatisticTypeEnum.TotalCost:
                return TotalCostDashBoard;
            default:
                return null;
        }
    };
    var StatisticComponent = getStatisticComponent();
    return (React.createElement(React.Fragment, null, StatisticComponent && (React.createElement(StatisticComponent, { backgroundColor: ENV_LEGEND_COLORS[extractedGeoData.breakIdx], viewSetting: viewSetting, data: extractedGeoData, geoId: selectedId, statisticName: statisticName }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
