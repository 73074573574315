var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo, useEffect } from 'react';
import { TabPane, Label, FormGroup, ListGroupItem } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { CheckboxGroup } from '../common';
import { DATA_SOURCES } from '../configs';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { CONSUMPTION_GROUP_OPTIONS } from '../lookupdata';
import { useDefaultFilter, useHostnameIndexInfo } from '../hostnameindexinfo';
import { IconTooltip } from '../common/icontooltip';
import { useGeneralConfigByDataSource } from '../hooks/useconfig';
import { useFilterCount } from './usefiltercount';
import { useEffectFilterEditingCount } from '../filterbar';
import { FilterCountLoading } from './filtercountloading';
var StyledFormGroup = styled(FormGroup)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var StyledTabPane = styled(TabPane)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.active {\n    display: flex;\n  }\n"], ["\n  &.active {\n    display: flex;\n  }\n"])));
var StyledHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var StyledDiv = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var useSupportConsumptionGroup = function (filterSetting) {
    var _a;
    var isAggregatedDataSource = filterSetting.isAggregatedDataSource;
    var cmsDataSource = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource;
    return useMemo(function () {
        var _a;
        return isAggregatedDataSource || !cmsDataSource ? false : !!((_a = DATA_SOURCES[cmsDataSource]) === null || _a === void 0 ? void 0 : _a.supportConsumptionGroup);
    }, [isAggregatedDataSource, cmsDataSource]);
};
var ConsumptionGroupTabSelector = function (props) {
    var resprops = __rest(props, []);
    var isSupportConsumptionGroup = useSupportConsumptionGroup(useFilterEditing());
    return (React.createElement(ListGroupItem, __assign({}, resprops, { hidden: !isSupportConsumptionGroup })));
};
export var ConsumptionGroupTab = function (props) {
    var _a = useFilterEditingValue('consumptionGroupClasses'), consumptionGroups = _a[0], setConsumptionGroups = _a[1];
    var isSupportConsumptionGroup = useSupportConsumptionGroup(useFilterEditing());
    var defaultFilter = useDefaultFilter();
    var availableConsumption = useGeneralConfigByDataSource('availableConsumption');
    var indexConfig = useHostnameIndexInfo();
    var _b = useFilterCount('CONSUMPTION_GROUP', indexConfig), consumptionGroupCount = _b[0], fetchConsumptionGroupCount = _b[1];
    var filterCountEnabled = (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && props.filterCountEnabled;
    useEffectFilterEditingCount(function (body) {
        if (filterCountEnabled) {
            fetchConsumptionGroupCount(body);
        }
    }, props.filterCountEffectDeps);
    useEffect(function () {
        if (isSupportConsumptionGroup) {
            var consumptionDefault = defaultFilter.consumptionGroupClasses;
            if (availableConsumption) {
                consumptionDefault = _.intersection(defaultFilter.consumptionGroupClasses, availableConsumption);
            }
            setConsumptionGroups(consumptionDefault);
        }
        else {
            setConsumptionGroups([]);
        }
    }, [availableConsumption, defaultFilter.consumptionGroupClasses, isSupportConsumptionGroup, setConsumptionGroups]);
    var HelperText = function () {
        return (React.createElement(React.Fragment, null, "Consumption groups are based on total Medicare costs per beneficiary. Each group represents a segment of Medicare FFS beneficiaries whose expenditures for the selected year places them in the specified percentile range. This filter option is intended to reflect a distribution of Medicare utilization."));
    };
    var isDisabled = React.useCallback(function (_a) {
        var value = _a.value;
        if (!availableConsumption)
            return false;
        return !availableConsumption.includes(value);
    }, [availableConsumption]);
    if (!isSupportConsumptionGroup)
        return null;
    return (React.createElement(StyledTabPane, { tabId: props.tabId },
        React.createElement(StyledFormGroup, null,
            React.createElement(StyledHeader, null,
                React.createElement(StyledDiv, null,
                    React.createElement(Label, { className: "font-weight-bold" }, "Consumption Group Class")),
                React.createElement(StyledDiv, null,
                    React.createElement(IconTooltip, { title: React.createElement(HelperText, null), placement: "bottom-end", maxWidth: "min(80vw, 28rem)", maxHeight: "calc(90vh - 10rem)", autohide: false }))),
            React.createElement(CheckboxGroup, { options: CONSUMPTION_GROUP_OPTIONS, value: consumptionGroups, formatOptionLabel: function (i) {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        i.label,
                        "\u00A0",
                        filterCountEnabled && (React.createElement(FilterCountLoading, { loading: consumptionGroupCount.requesting, value: (_a = consumptionGroupCount.data) === null || _a === void 0 ? void 0 : _a[i.value] }))));
                }, disabled: isDisabled, onChange: setConsumptionGroups, noSort: true, allText: "All consumption groups" }))));
};
ConsumptionGroupTab.defaultProps = {
    tabId: 'ConsumptionGroupTab',
    tabName: 'Consumption Group',
    tabSelector: ConsumptionGroupTabSelector,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
