var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useRef, useEffect } from 'react';
import { Button as RSButton, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';
var iconButtonRule = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  background: transparent !important;\n  &:hover {\n    background: transparent;\n    opacity: 0.8;\n  }\n  &:focus {\n    background: transparent !important;\n    box-shadow: none !important;\n  }\n  &::after {\n    font-size: 1rem;\n  }\n"], ["\n  border: none;\n  background: transparent !important;\n  &:hover {\n    background: transparent;\n    opacity: 0.8;\n  }\n  &:focus {\n    background: transparent !important;\n    box-shadow: none !important;\n  }\n  &::after {\n    font-size: 1rem;\n  }\n"])));
var StyledIconButton = styled(RSButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), iconButtonRule);
var StyledDropdownToggleIcon = styled(DropdownToggle)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), iconButtonRule);
var StyledButton = styled(RSButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  outline: none !important;\n  box-shadow: none !important;\n"], ["\n  outline: none !important;\n  box-shadow: none !important;\n"])));
export var IconButton = function (_a) {
    var icon = _a.icon, size = _a.size, title = _a.title, placement = _a.placement, loading = _a.loading, resprops = __rest(_a, ["icon", "size", "title", "placement", "loading"]);
    var myRef = useRef();
    var buttonRef = resprops.innerRef || myRef;
    var _b = useState(false), ready = _b[0], setReady = _b[1];
    useEffect(function () {
        setReady(true);
    }, []);
    return (React.createElement(StyledIconButton, __assign({ size: "sm" }, resprops, { disabled: loading || resprops.disabled, innerRef: buttonRef }),
        loading ? React.createElement(FontAwesomeIcon, { icon: faSpinner, spin: true, size: size, fixedWidth: true }) : icon && React.createElement(FontAwesomeIcon, { icon: icon, size: size, fixedWidth: true }),
        title && ready && React.createElement(UncontrolledTooltip, { placement: placement, target: buttonRef.current, fade: false, delay: 1000 }, title)));
};
export var DropdownToggleIcon = function (_a) {
    var icon = _a.icon, size = _a.size, title = _a.title, placement = _a.placement, resprops = __rest(_a, ["icon", "size", "title", "placement"]);
    var tooltipRef = useRef();
    var _b = useState(false), ready = _b[0], setReady = _b[1];
    useEffect(function () {
        setReady(true);
    }, []);
    return (React.createElement(StyledDropdownToggleIcon, __assign({ size: "sm" }, resprops, { innerRef: tooltipRef }),
        icon && React.createElement(FontAwesomeIcon, { icon: icon, size: size, fixedWidth: true }),
        title && ready && React.createElement(UncontrolledTooltip, { placement: placement, target: tooltipRef.current, fade: false, delay: 1000 }, title)));
};
export var Button = function (_a) {
    var message = _a.message, icon = _a.icon, loading = _a.loading, resprops = __rest(_a, ["message", "icon", "loading"]);
    return (React.createElement(StyledButton, __assign({}, resprops, { disabled: loading || resprops.disabled }),
        loading ? React.createElement(FontAwesomeIcon, { icon: faSpinner, spin: true }) : icon && React.createElement(FontAwesomeIcon, { icon: icon }),
        " ",
        message,
        resprops.children));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
