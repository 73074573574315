var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo, useCallback } from 'react';
import { TabPane, FormGroup, Label, Input } from 'reactstrap';
import styled from 'styled-components';
import { Select, FallbackComponent, CheckboxGroup, ThreeDotLoading, IconTooltip } from '../common';
import { getDisplayText } from '../utils';
import { useFilterEditingValue } from '../state/filterediting';
import { CMSSourceTabSelector } from './tabselector';
import { useEffectFilterEditingCount } from '../filterbar/useeffectfiltercount';
import { useLookupCondition, CLAIM_NUMBERS, CLAIM_DAYS, ConditionType, useLookupConditionSortByType, useSynchronizedPrimaryCondition } from '../lookupdata';
import { useHostnameIndexInfo, useDefaultFilter } from '../hostnameindexinfo';
import { useGeneralConfigByDataSource, useMount } from '../hooks';
import { useFilterCount } from './usefiltercount';
import { FilterCountLoading } from './filtercountloading';
var StyledPrimaryConditionSelect = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 20rem;\n"], ["\n  max-width: 20rem;\n"])));
export var StyledPrimaryConditionSection = styled(FormGroup)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 1rem;\n"], ["\n  margin-left: 1rem;\n"])));
var StyledConditions = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var StyledPrimaryCondition = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 700;\n  margin-bottom: 0.5rem;\n"], ["\n  font-weight: 700;\n  margin-bottom: 0.5rem;\n"])));
var HelperText = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null, "Why can\u2019t I select \u201Cnumber of claims\u201D for the primary conditions?"),
        "Medicaid data does not support the ability to search by # of claims."));
};
export var NotSupportClaimForMedicaidDialog = function () {
    return React.createElement(IconTooltip, { title: React.createElement(HelperText, null), placement: "bottom-end" });
};
var formatConditionLabel = function (params) {
    var label = params.label, count = params.count, loading = params.loading, showCount = params.showCount, showIndicator = params.showIndicator;
    return (React.createElement(React.Fragment, null,
        label,
        "\u00A0",
        showCount && (React.createElement(React.Fragment, null,
            '(',
            React.createElement(ThreeDotLoading, { loading: loading }, getDisplayText(count)),
            ')')),
        showIndicator ? ' *' : ''));
};
export var useConditionCount = function (params) {
    var indexConfig = useHostnameIndexInfo();
    var filterType = (params === null || params === void 0 ? void 0 : params.isPrimary) ? 'PRIMARY_CHRONIC_CONDITION' : 'CHRONIC_CONDITION';
    var _a = useFilterCount(filterType, indexConfig), countData = _a[0], rest = _a.slice(1);
    var isLoading = useMemo(function () { return countData.requesting === undefined ? true : countData.requesting; }, [countData.requesting]);
    var getLabelWithCount = useCallback(function (i) {
        var _a;
        return formatConditionLabel({
            label: i.label,
            count: (_a = countData.data) === null || _a === void 0 ? void 0 : _a[i.value],
            loading: isLoading,
            showCount: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount,
            showIndicator: (params === null || params === void 0 ? void 0 : params.showCmsSourceIndicator) && Number(i.value) <= 64
        });
    }, [countData.data, isLoading, indexConfig, params]);
    return __spreadArrays([__assign(__assign({}, countData), { getLabelWithCount: getLabelWithCount })], rest);
};
export var PrioritizedConditionSection = function (_a) {
    var _b, _c;
    var settings = _a.settings;
    var textSearch = useFilterEditingValue('textSearch')[0];
    var lookupData = useLookupConditionSortByType()[0];
    var countData = useConditionCount({ showCmsSourceIndicator: settings === null || settings === void 0 ? void 0 : settings.showCmsSourceIndicator })[0];
    var primaryConditions = (_b = lookupData.groupedCondition) === null || _b === void 0 ? void 0 : _b[ConditionType.Primary];
    var keyConditions = (_c = lookupData.groupedCondition) === null || _c === void 0 ? void 0 : _c[ConditionType.Key];
    var _d = useFilterEditingValue('primaryCoMorbidities')[0], selectingPrimaryCondition = (_d === void 0 ? [] : _d)[0];
    var _e = useFilterEditingValue('comorbidities'), selectingConditions = _e[0], setSelectingConditions = _e[1];
    var indexConfig = useHostnameIndexInfo();
    var combinedKeyConditions = useMemo(function () {
        return Object.assign([]).concat((indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.useCheckboxesForPrimaryConditions) ? [] : (primaryConditions === null || primaryConditions === void 0 ? void 0 : primaryConditions.filter(function (i) { return i.value !== selectingPrimaryCondition; })) || [], keyConditions || []);
    }, [primaryConditions, keyConditions, selectingPrimaryCondition, indexConfig]);
    if (!combinedKeyConditions.length)
        return null;
    return (React.createElement(StyledPrimaryConditionSection, { tag: "fieldset" },
        React.createElement(CheckboxGroup, { options: combinedKeyConditions, formatOptionLabel: countData.getLabelWithCount, input: textSearch, noAll: true, noSort: true, value: selectingConditions, onChange: setSelectingConditions, sortValue: lookupData.sortValue })));
};
export var AdditionalConditionSection = function (_a) {
    var _b;
    var settings = _a.settings, isSingleSection = _a.isSingleSection, _c = _a.label, label = _c === void 0 ? 'Additional Condition Filters' : _c;
    var textSearch = useFilterEditingValue('textSearch')[0];
    var lookupData = useLookupConditionSortByType()[0];
    var countData = useConditionCount({ showCmsSourceIndicator: settings === null || settings === void 0 ? void 0 : settings.showCmsSourceIndicator })[0];
    var additionalConditions = (_b = lookupData.groupedCondition) === null || _b === void 0 ? void 0 : _b[ConditionType.Other];
    var _d = useFilterEditingValue('comorbidities'), selectingConditions = _d[0], setSelectingConditions = _d[1];
    var _e = useFilterEditingValue('additionalConditions'), setSelectingAdditionalConditions = _e[1];
    var cmsDataSource = useFilterEditingValue('statistic', 'cmsDataSource')[0];
    var overrideCondition = useGeneralConfigByDataSource('overrideAdditionalConditionType');
    var onChange = function (value) {
        setSelectingAdditionalConditions(value);
        setSelectingConditions(value);
    };
    var optionConditions = useMemo(function () {
        var _a;
        var options = additionalConditions;
        if (typeof overrideCondition === 'number') {
            var extendConditions = ((_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[overrideCondition]) || additionalConditions;
            options = extendConditions;
        }
        return options;
    }, [additionalConditions, lookupData.groupedCondition, overrideCondition]);
    return (React.createElement(React.Fragment, null,
        isSingleSection || React.createElement("hr", null),
        React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(Label, { className: "font-weight-bold" }, label),
            React.createElement("div", { className: isSingleSection ? 'ml-3' : '' },
                React.createElement(CheckboxGroup, { options: optionConditions, formatOptionLabel: countData.getLabelWithCount, input: textSearch, noAll: true, noSort: true, value: selectingConditions, onChange: onChange, sortValue: lookupData.sortValue })))));
};
export var SecondaryConditionSection = function (_a) {
    var _b;
    var filterCountEffectDeps = _a.filterCountEffectDeps;
    var textSearch = useFilterEditingValue('textSearch')[0];
    var lookupData = useLookupConditionSortByType()[0];
    var secondaryConditions = (_b = lookupData.groupedCondition) === null || _b === void 0 ? void 0 : _b[ConditionType.Secondary];
    var _c = useFilterEditingValue('comorbidities'), selectingConditions = _c[0], setSelectingConditions = _c[1];
    var dataYearSeason = useFilterEditingValue('dataYearSeason')[0];
    var doSyncSecondary = useSynchronizedPrimaryCondition([ConditionType.Secondary])[0];
    var indexConfig = useHostnameIndexInfo();
    var _d = useFilterCount('CHRONIC_CONDITION', indexConfig), secondaryConditionCount = _d[0], fetchSecondaryConditionCount = _d[1];
    var filterCountLoading = secondaryConditionCount.requesting === undefined || secondaryConditionCount.requesting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(doSyncSecondary, [dataYearSeason]);
    useEffectFilterEditingCount(function (body) {
        fetchSecondaryConditionCount(__assign(__assign({}, body), { comorbidities: selectingConditions }));
    }, __spreadArrays([selectingConditions], (filterCountEffectDeps || [])), 'CONDITION');
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", null),
        React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(Label, { className: "font-weight-bold" }, "Secondary Conditions"),
            React.createElement(CheckboxGroup, { options: secondaryConditions, formatOptionLabel: function (i) {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        i.label,
                        "\u00A0",
                        (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && (React.createElement(FilterCountLoading, { loading: filterCountLoading, value: getDisplayText((_a = secondaryConditionCount.data) === null || _a === void 0 ? void 0 : _a[i.value]) }))));
                }, input: textSearch, noSort: true, value: selectingConditions, onChange: setSelectingConditions, sortValue: lookupData.sortValue }))));
};
export var PrimaryConditionSection = function (_a) {
    var _b;
    var settings = _a.settings, children = _a.children;
    var dataYear = useFilterEditingValue('dataYear')[0];
    var lookupData = useLookupConditionSortByType(undefined, dataYear)[0];
    var primaryConditionOptions = (_b = lookupData.groupedCondition) === null || _b === void 0 ? void 0 : _b[ConditionType.Primary];
    var countData = useConditionCount({ showCmsSourceIndicator: settings === null || settings === void 0 ? void 0 : settings.showCmsSourceIndicator })[0];
    var textSearch = useFilterEditingValue('textSearch')[0];
    var indexConfig = useHostnameIndexInfo();
    var _c = useFilterCount('PRIMARY_CHRONIC_CONDITION', indexConfig), primaryConditionCount = _c[0], fetchPrimaryConditionCount = _c[1];
    var _d = useFilterEditingValue('primaryCoMorbidities'), selectingPrimaryCoMorbidities = _d[0], setSelectingPrimaryCoMorbidities = _d[1];
    var _e = useFilterEditingValue('comorbidities'), selectingConditions = _e[0], setSelectingConditions = _e[1];
    var _f = useFilterEditingValue('primaryConditions'), selectingPrimaryCondition = _f[0], setSelectingPrimaryCondition = _f[1];
    useMount(function () {
        setSelectingPrimaryCoMorbidities(selectingPrimaryCondition || selectingPrimaryCoMorbidities);
    });
    useEffectFilterEditingCount(function (body) {
        if (primaryConditionOptions && primaryConditionOptions.length > 1) {
            fetchPrimaryConditionCount(__assign(__assign({}, body), { comorbidities: [], primaryCoMorbidities: primaryConditionOptions === null || primaryConditionOptions === void 0 ? void 0 : primaryConditionOptions.map(function (i) { return i.value; }) }));
        }
    }, [primaryConditionOptions], 'PRIMARY_CONDITION');
    var formatOptionLabel = useCallback(function (i) {
        var _a;
        return formatConditionLabel({
            label: i.label,
            count: (_a = primaryConditionCount.data) === null || _a === void 0 ? void 0 : _a[i.value],
            loading: primaryConditionCount.requesting,
            showCount: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount,
            showIndicator: (settings === null || settings === void 0 ? void 0 : settings.showCmsSourceIndicator) && Number(i.value) <= 64
        });
    }, [indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount, settings === null || settings === void 0 ? void 0 : settings.showCmsSourceIndicator, primaryConditionCount.data, primaryConditionCount.requesting]);
    var handleChange = function (value) {
        setSelectingPrimaryCoMorbidities([value]);
        setSelectingConditions(__spreadArrays([value], ((selectingConditions === null || selectingConditions === void 0 ? void 0 : selectingConditions.filter(function (cond, idx) { return idx && cond !== value; })) || [])));
    };
    var handleChangeCheckbox = function (value) {
        setSelectingPrimaryCondition(value);
        setSelectingPrimaryCoMorbidities(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(StyledConditions, null,
                React.createElement(StyledPrimaryCondition, null,
                    React.createElement(Label, { className: "font-weight-bold" }, "Primary Condition")),
                children),
            React.createElement("div", { className: "ml-3" }, !(primaryConditionOptions === null || primaryConditionOptions === void 0 ? void 0 : primaryConditionOptions.length) ? ('There is no primary condition associated with this Index.') : primaryConditionOptions.length === 1 ? (React.createElement("div", { className: "font-weight-bold" }, countData.getLabelWithCount(primaryConditionOptions[0]))) : (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.useCheckboxesForPrimaryConditions) ? (React.createElement(CheckboxGroup, { options: primaryConditionOptions, formatOptionLabel: formatOptionLabel, input: textSearch, noAll: true, noSort: true, value: selectingPrimaryCoMorbidities, onChange: handleChangeCheckbox, sortValue: lookupData.sortValue })) : (React.createElement(StyledPrimaryConditionSelect, { options: primaryConditionOptions, noSort: true, formatOptionLabel: formatOptionLabel, value: selectingPrimaryCoMorbidities === null || selectingPrimaryCoMorbidities === void 0 ? void 0 : selectingPrimaryCoMorbidities[0], name: "primaryCondition", onChange: handleChange }))))));
};
export var ConditionTab = function (props) {
    var _a = useFilterEditingValue('textSearch'), textSearch = _a[0], setTextSearch = _a[1];
    var _b = useLookupCondition(), lookupConditionData = _b[0], fetchLookupCondition = _b[1];
    var _c = useConditionCount(), fetchConditionCountData = _c[1];
    var selectingPrimaryConditions = useFilterEditingValue('primaryCoMorbidities')[0];
    useMount(function () {
        fetchLookupCondition();
    });
    useEffectFilterEditingCount(function (body) {
        if (props.shouldCountOnConditionChange) {
            fetchConditionCountData(__assign(__assign({}, body), { comorbidities: selectingPrimaryConditions }));
        }
    }, __spreadArrays([selectingPrimaryConditions], (props.filterCountEffectDeps || [])), 'CONDITION');
    var handleSearchInputChange = function (event) {
        var value = event.target.value;
        setTextSearch(value);
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FallbackComponent, { requesting: lookupConditionData.requesting, error: lookupConditionData.error, retry: fetchLookupCondition },
            React.createElement(Input, { className: "flex-shrink-0 mb-2", placeholder: "Search...", value: textSearch || '', onChange: handleSearchInputChange }),
            props.children)));
};
ConditionTab.defaultProps = {
    tabId: 'ConditionTab',
    tabName: 'Conditions',
    tabSelector: function (props) { return React.createElement(CMSSourceTabSelector, __assign({}, props, { type: "disabled" })); },
    filterCountEffectDeps: [],
    shouldCountOnConditionChange: true,
};
export var ClaimCondition = function () {
    var _a = useFilterEditingValue('claimNumber'), claimNumber = _a[0], setClaimNumber = _a[1];
    var _b = useFilterEditingValue('claimDay'), claimDay = _b[0], setClaimDay = _b[1];
    var defaultFilter = useDefaultFilter();
    var handleChangeClaimNumber = function (value) {
        setClaimNumber(value);
        if (value < 2) {
            setClaimDay();
        }
        else if (claimNumber && claimNumber < 2) {
            setClaimDay(defaultFilter.claimDay);
        }
    };
    return (React.createElement(StyledPrimaryConditionSection, null,
        "Beneficiaries must have at least",
        ' ',
        React.createElement(Select, { inline: true, width: "4.5", name: "claimNumber", options: CLAIM_NUMBERS, optionsTransform: function (cn) { return ({ value: cn, label: cn }); }, value: claimNumber, onChange: handleChangeClaimNumber }),
        ' ',
        "claim(s)",
        claimDay && (React.createElement(React.Fragment, null,
            ' ',
            React.createElement(Select, { inline: true, width: "8.5", name: "claimDay", options: CLAIM_DAYS, noSort: true, value: claimDay, onChange: setClaimDay }),
            " days apart")),
        React.createElement(React.Fragment, null, " to be included in the index")));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
