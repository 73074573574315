var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { prependingHttpToUrlIfMissing } from '../../../utils';
var DataBody = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  word-break: break-word;\n"], ["\n  display: flex;\n  flex-direction: row;\n  word-break: break-word;\n"])));
var RepresentativePhoto = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 6.5rem;\n  height: 8rem;\n  object-fit: cover;\n  margin-right: 0.5rem;\n"], ["\n  width: 6.5rem;\n  height: 8rem;\n  object-fit: cover;\n  margin-right: 0.5rem;\n"])));
var RepresentativeInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // width: calc(100% - 7rem);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"], ["\n  // width: calc(100% - 7rem);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
var DataRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var DataRowAddress = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.875rem;\n"], ["\n  font-size: 0.875rem;\n"])));
var DataRowPhone = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 550;\n"], ["\n  font-weight: 550;\n"])));
export var FederalRepresentativeDetail = function (props) {
    var onErrorFunc = function (e) {
        e.target.onError = null;
        e.target.style.display = 'none';
    };
    if (!props.data)
        return null;
    return (React.createElement(DataBody, null,
        props.data.frPhotoLink && React.createElement(RepresentativePhoto, { src: prependingHttpToUrlIfMissing(props.data.frPhotoLink), onError: function (e) { return onErrorFunc(e); }, alt: "" }),
        React.createElement(RepresentativeInfo, null,
            props.data.frAddress && React.createElement(DataRowAddress, { className: "mb-3" }, props.data.frAddress.replace('Building', 'Bldg')),
            props.data.frPhoneNumber && React.createElement(DataRowPhone, { className: "mb-3" }, props.data.frPhoneNumber),
            React.createElement(DataRow, null,
                React.createElement("a", { href: props.data.frWebSite, target: "_blank", rel: "noopener noreferrer" },
                    "Website\u00A0",
                    React.createElement(FontAwesomeIcon, { icon: faExternalLinkAlt }))))));
};
export var StateRepresentativeDetail = function (props) {
    var onErrorFunc = function (e) {
        e.target.onError = null;
        e.target.style.display = 'none';
    };
    if (!props.data)
        return null;
    return (React.createElement(DataBody, null,
        props.data.srPhotoLink && React.createElement(RepresentativePhoto, { src: prependingHttpToUrlIfMissing(props.data.srPhotoLink), onError: function (e) { return onErrorFunc(e); }, alt: "" }),
        React.createElement(RepresentativeInfo, null,
            props.data.srAddress && React.createElement(DataRowAddress, { className: "mb-3" }, props.data.srAddress.replace('Building', 'Bldg').replaceAll(';', ' ')),
            props.data.srPhoneNumber && React.createElement(DataRowPhone, { className: "mb-3" }, props.data.srPhoneNumber),
            React.createElement(DataRow, null,
                React.createElement("a", { href: props.data.srWebSite, target: "_blank", rel: "noopener noreferrer" },
                    "Website\u00A0",
                    React.createElement(FontAwesomeIcon, { icon: faExternalLinkAlt }))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
