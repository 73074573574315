var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { DropdownItem as RSDropdownItem } from 'reactstrap';
var StyledDropdownItemIcon = styled(FontAwesomeIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 0.375rem;\n"], ["\n  margin-right: 0.375rem;\n"])));
var StyledImageDropdownItem = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 15px;\n  margin-right: 4px;\n"], ["\n  width: 15px;\n  margin-right: 4px;\n"])));
var StyledDropdownItem = styled(function (_a) {
    var checkMark = _a.checkMark, rest = __rest(_a, ["checkMark"]);
    return React.createElement(RSDropdownItem, __assign({}, rest));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ",
    "\n"])), function (props) {
    return props.checkMark
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          padding-left: 3rem;\n          position: relative;\n        "], ["\n          padding-left: 3rem;\n          position: relative;\n        "]))) : '';
});
var CheckIcon = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  left: 1.5rem;\n"], ["\n  position: absolute;\n  left: 1.5rem;\n"])));
export var DropdownItem = function (props) {
    var checked = props.checked, icon = props.icon, img = props.img, label = props.label, partial = props.partial, resprops = __rest(props, ["checked", "icon", "img", "label", "partial"]);
    var handleClick = function () {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.value);
    };
    return (React.createElement(StyledDropdownItem, __assign({}, resprops, { onClick: handleClick }),
        checked && (React.createElement(CheckIcon, null,
            React.createElement(FontAwesomeIcon, { icon: "check", color: partial ? '#ccc' : undefined }))),
        icon && React.createElement(StyledDropdownItemIcon, { icon: icon }),
        img && React.createElement(StyledImageDropdownItem, { src: "/assets/images/icons/" + props.img + ".png", alt: '' }),
        label,
        props.children));
};
export var DropdownItemCheck = function (props) {
    return React.createElement(DropdownItem, __assign({ checkMark: true }, props));
};
export var DropdownItemCheckList = function (props) {
    var values = props.values, sort = props.sort, resprops = __rest(props, ["values", "sort"]);
    var checked = useMemo(function () { return values === null || values === void 0 ? void 0 : values.some(function (v) { return v === props.value; }); }, [props.value, values]);
    var handleChange = function () {
        var _a, _b;
        if (checked) {
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, values === null || values === void 0 ? void 0 : values.filter(function (v) { return v !== props.value; }));
        }
        else {
            (_b = props.onChange) === null || _b === void 0 ? void 0 : _b.call(props, __spreadArrays((values || []), [props.value]).sort(sort || (function () { return 0; })));
        }
    };
    return React.createElement(DropdownItemCheck, __assign({}, resprops, { checked: checked, onChange: handleChange }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
