/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var StatisticGroupEnum;
(function (StatisticGroupEnum) {
    StatisticGroupEnum["Population"] = "POPULATION";
    StatisticGroupEnum["Rate"] = "RATE_OF_ACUTE_EVENTS";
    StatisticGroupEnum["Cost"] = "COST_OF_ACUTE_EVENTS";
})(StatisticGroupEnum || (StatisticGroupEnum = {}));
export var StatisticTypeEnum;
(function (StatisticTypeEnum) {
    // Population
    StatisticTypeEnum["Prevalence"] = "PREVALENCE";
    StatisticTypeEnum["TotalCount"] = "TOTAL_COUNT_OF_PATIENTS";
    // RateOfAcuteEvents
    StatisticTypeEnum["HospitalizationRate"] = "HOSPITALIZATION_RATE";
    StatisticTypeEnum["ERVisitsRate"] = "ER_VISITS_RATE";
    StatisticTypeEnum["HospitalEncounterRate"] = "HOSPITAL_ENCOUNTERS_RATE";
    StatisticTypeEnum["DeathRate"] = "DEATH_RATE";
    StatisticTypeEnum["ReadmissionRate"] = "READMISSION_RATE";
    // CostOfAcuteEvents
    StatisticTypeEnum["HospitalizationCost"] = "TOTAL_ANNUAL_HOSPITALIZATION_COST_PER_PATIENT";
    StatisticTypeEnum["ERCost"] = "TOTAL_ANNUAL_ER_COST_PER_PATIENT";
    StatisticTypeEnum["OOPPartBDrugCost"] = "TOTAL_ANNUAL_PATIENT_OUT_OF_POCKET_PART_B_DRUG_COST_PER_PATIENT";
    StatisticTypeEnum["OOPPartDDrugCost"] = "TOTAL_ANNUAL_PATIENT_OUT_OF_POCKET_PART_D_DRUG_COST_PER_PATIENT";
    StatisticTypeEnum["MCRPartBDrugCost"] = "TOTAL_ANNUAL_MCR_PART_B_DRUG_PAYMENTS_PER_PATIENT";
    StatisticTypeEnum["MCRPartDDrugCost"] = "TOTAL_ANNUAL_MCR_PART_D_DRUG_PAYMENTS_PER_PATIENT";
    StatisticTypeEnum["TotalCost"] = "TOTAL_ANNUAL_COST_PER_PATIENT";
})(StatisticTypeEnum || (StatisticTypeEnum = {}));
