/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
var Radio = function (props) {
    var handleChange = function (event) {
        props.onChange(props.value, props.payload);
    };
    return (React.createElement(FormGroup, { check: true, inline: props.inline },
        React.createElement(Label, { check: true, className: props.disabled ? 'text-muted' : '' },
            React.createElement(Input, { type: "radio", disabled: props.disabled, checked: props.value === props.groupValue, onChange: handleChange }),
            props.label)));
};
export var RadioGroup = function (props) {
    var _a;
    var options = props.optionsTransform ? (_a = props.options) === null || _a === void 0 ? void 0 : _a.map(props.optionsTransform) : props.options;
    !props.noSort && (options === null || options === void 0 ? void 0 : options.sort(function (a, b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0) * (props.reverse ? -1 : 1); }));
    options = props.input ? options === null || options === void 0 ? void 0 : options.filter(function (i) { var _a; return i.label.toLowerCase().includes((_a = props.input) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }) : options;
    var handleChange = function (value, payload) {
        var _a, _b;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, value, payload);
        (_b = props.setFieldValue) === null || _b === void 0 ? void 0 : _b.call(props, value, props.name || '');
    };
    if (!(options === null || options === void 0 ? void 0 : options.length))
        return null;
    return (React.createElement(React.Fragment, null, options === null || options === void 0 ? void 0 : options.map(function (item) {
        var _a;
        return (React.createElement(Radio, { key: item.value, inline: props.inline, payload: item, value: item.value, label: props.formatOptionLabel ? props.formatOptionLabel(item) : item.label, groupValue: props.value, onChange: handleChange, disabled: (_a = props.isOptionDisabled) === null || _a === void 0 ? void 0 : _a.call(props, item) }));
    })));
};
