var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
var StyledMainView = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1.25rem;\n  max-width: ", ";\n  margin: 0 auto;\n  width: 100%;\n"], ["\n  padding: 1.25rem;\n  max-width: ", ";\n  margin: 0 auto;\n  width: 100%;\n"])), function (props) { return (typeof props.maxWidth === 'string' ? props.maxWidth : (props.maxWidth || 75) + "rem"); });
export var MainView = function (props) {
    return (React.createElement(StyledMainView, { maxWidth: props.maxWidth },
        React.createElement("h4", null, props.title),
        props.children));
};
var templateObject_1;
