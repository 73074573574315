var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
export var StyledMapSidebar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 25rem;\n  box-shadow: 0 0 2rem rgba(16, 14, 23, 0.33);\n  padding: 1rem;\n  padding-top: 0;\n  z-index: 3;\n  background-color: #fff;\n  min-height: calc(100%);\n  max-height: calc(100%);\n  overflow: auto;\n  max-width: 100vw;\n"], ["\n  width: 25rem;\n  box-shadow: 0 0 2rem rgba(16, 14, 23, 0.33);\n  padding: 1rem;\n  padding-top: 0;\n  z-index: 3;\n  background-color: #fff;\n  min-height: calc(100%);\n  max-height: calc(100%);\n  overflow: auto;\n  max-width: 100vw;\n"])));
export var MapSidebar = function (props) {
    return React.createElement(React.Fragment, null, props.children);
};
var templateObject_1;
