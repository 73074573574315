var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ListGroup, ListGroupItem, TabContent, TabPane } from 'reactstrap';
import { useToggle } from '../hooks';
import { IconButton } from '../common';
import { FilterTab } from './filtertab';
import { LegendTransparencyTab } from './legendtransparencytab';
var StyledFilterSidebar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 99;\n  height: 100%;\n  width: 0;\n  position: relative;\n"], ["\n  z-index: 99;\n  height: 100%;\n  width: 0;\n  position: relative;\n"])));
var Sidebar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1rem;\n  padding-top: 0;\n  position: absolute;\n  left: 0;\n  transform: translateX(-20.5rem);\n  width: 20rem;\n  background-color: white;\n  box-shadow: 0 0 2rem rgba(16, 14, 23, 0.33); \n  min-height: 100%;\n  max-height: 100%;\n  overflow: auto;\n  ", "\n"], ["\n  padding: 1rem;\n  padding-top: 0;\n  position: absolute;\n  left: 0;\n  transform: translateX(-20.5rem);\n  width: 20rem;\n  background-color: white;\n  box-shadow: 0 0 2rem rgba(16, 14, 23, 0.33); \n  min-height: 100%;\n  max-height: 100%;\n  overflow: auto;\n  ", "\n"])), function (props) { return (props.collapsed ? "transform: translateX(0)" : ''); });
var StyledButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  -ms-transform: translateY(-50%);\n  transform: translateY(-50%);\n  top: 50%;\n  background-color: hsla(0, 0%, 0%, 0.3);\n  z-index: 99;\n  ", "\n  :focus, :active {\n    background-color: hsla(0, 0%, 0%, 0.3);\n  }\n"], ["\n  position: absolute;\n  -ms-transform: translateY(-50%);\n  transform: translateY(-50%);\n  top: 50%;\n  background-color: hsla(0, 0%, 0%, 0.3);\n  z-index: 99;\n  ", "\n  :focus, :active {\n    background-color: hsla(0, 0%, 0%, 0.3);\n  }\n"])), function (props) { return (props.collapsed ? "transform: translate(20rem, -50%);" : ''); });
var PanelHeader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 0 -1rem;\n  padding: 0.75rem 1rem;\n  padding-right: 0;\n  background-color: #cacaca;\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 0 -1rem;\n  padding: 0.75rem 1rem;\n  padding-right: 0;\n  background-color: #cacaca;\n"])));
var Title = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 500;\n  font-size: 1.25rem;\n"], ["\n  font-weight: 500;\n  font-size: 1.25rem;\n"])));
var StyledTabsContainer = styled(ListGroup)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 -1rem;\n  border-radius: 0;\n  > li {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  margin: 0 -1rem;\n  border-radius: 0;\n  > li {\n    display: flex;\n    align-items: center;\n  }\n"])));
var StyleTabSelector = styled(ListGroupItem)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  cursor: pointer;\n  border: 0;\n  border-radius: 0 !important;\n  :not(.active):not(:hover) {\n    background-color: #ededed;\n  }\n"], ["\n  flex: 1;\n  cursor: pointer;\n  border: 0;\n  border-radius: 0 !important;\n  :not(.active):not(:hover) {\n    background-color: #ededed;\n  }\n"])));
var TabSelector = React.memo(function (props) {
    var setActiveTab = props.setActiveTab;
    var handleClick = useCallback(function () {
        setActiveTab(props.tabId);
    }, [props.tabId, setActiveTab]);
    return (React.createElement(StyleTabSelector, { action: true, disabled: props.disabled, active: props.activeTab === props.tabId, onClick: handleClick }, props.children));
});
export var FilterSidebar = function (props) {
    var _a = useToggle(), collapsed = _a[0], toggleCollapsed = _a[1];
    var _b = useState('1'), activeTab = _b[0], setActiveTab = _b[1];
    return (React.createElement(StyledFilterSidebar, null,
        React.createElement(Sidebar, { collapsed: collapsed },
            React.createElement(PanelHeader, null,
                React.createElement(Title, null, "Display Settings")),
            React.createElement(StyledTabsContainer, { horizontal: true },
                React.createElement(TabSelector, { tabId: "1", activeTab: activeTab, setActiveTab: setActiveTab }, "Filters"),
                React.createElement(TabSelector, { tabId: "2", activeTab: activeTab, setActiveTab: setActiveTab }, "Other Settings")),
            React.createElement(TabContent, { activeTab: activeTab },
                React.createElement(TabPane, { tabId: "1" },
                    React.createElement(FilterTab, { showVaccine: props.showVaccine, showFluVaccine: props.showFluVaccine, toggleFilterDialog: props.toggleFilterDialog })),
                React.createElement(TabPane, { tabId: "2" },
                    React.createElement(LegendTransparencyTab, { toggleFilterDialog: props.toggleFilterDialog })))),
        React.createElement(StyledButton, { collapsed: collapsed },
            React.createElement(IconButton, { icon: collapsed ? 'caret-left' : 'caret-right', size: "2x", style: { padding: 0 }, onClick: toggleCollapsed, title: collapsed ? 'Hide Display Settings' : 'Show Display Settings', placement: "right" }))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
