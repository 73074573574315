var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine } from 'recharts';
import styled from 'styled-components';
import { useViewSetting, useFilterSetting, useViewValue } from '../state';
import { getDisplayText, getYearsBySeason } from '../utils';
import { ViewModeEnum, ChartViewEnum } from '../enums';
import { useTrendLineData, useTrendLineDataForSeason } from './usetrendlinedata';
import { FallbackComponent, Select } from '../common';
import { LookupYearComponent, useMixedYear, getStatisticShortName, useCMSDataSeasonYear } from '../lookupdata';
var FROM_YEAR_DISTANCE = 3;
var LINE_COLOR = '#00008b';
var StyledTrendLineComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  position: relatived;\n  min-height: 15rem;\n  height: 100%;\n"], ["\n  padding: 1rem;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  position: relatived;\n  min-height: 15rem;\n  height: 100%;\n"])));
var TrendLineTitle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n"], ["\n  margin-bottom: 1rem;\n"])));
var TrendLineTitleText = styled.h4(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n"], ["\n  display: inline-block;\n"])));
var StyledTrendLineContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  min-height: 6.25rem;\n  max-height: 25rem;\n  max-width: 75rem;\n  min-width: 10rem;\n  position: relative;\n"], ["\n  flex: 1;\n  min-height: 6.25rem;\n  max-height: 25rem;\n  max-width: 75rem;\n  min-width: 10rem;\n  position: relative;\n"])));
export var TrendLineContainer = function (_a) {
    var _b = _a.keyValue, keyValue = _b === void 0 ? 'year' : _b, data = _a.data;
    var tooltipFomatter = function (value, name, entry) {
        if (name === 'linear')
            return [];
        if (entry.payload.isNA)
            return ['NA', entry.payload[keyValue]];
        return [getDisplayText(value, entry.payload.suffix, entry.payload.prefix), entry.payload[keyValue]];
    };
    return (React.createElement(StyledTrendLineContainer, null,
        React.createElement(ResponsiveContainer, { className: "position-absolute" },
            React.createElement(LineChart, { data: data },
                React.createElement(CartesianGrid, { vertical: false }),
                React.createElement(XAxis, { dataKey: keyValue, padding: { left: 30, right: 30 } }),
                React.createElement(YAxis, { allowDataOverflow: true, padding: { top: 10, bottom: 0 } }),
                React.createElement(Tooltip, { isAnimationActive: false, labelFormatter: function () { return ''; }, formatter: tooltipFomatter }),
                React.createElement(ReferenceLine, { y: 0, stroke: "#000" }),
                React.createElement(Line, { dataKey: "value", isAnimationActive: false, stroke: LINE_COLOR, dot: { strokeWidth: 12 }, activeDot: { r: 12 }, strokeWidth: 5 }),
                React.createElement(Line, { dataKey: "linear", isAnimationActive: false, connectNulls: true, dot: false, activeDot: false, stroke: LINE_COLOR, strokeDasharray: "5 5", strokeWidth: 5 })))));
};
export var TrendLineComponent = function () {
    var _a, _b, _c;
    var _d = useViewValue('chartView', 'startingYear'), fromYear = _d[0], setFromYear = _d[1];
    var _e = useViewValue('chartView', 'endingYear'), toYear = _e[0], setToYear = _e[1];
    var _f = useTrendLineData(), trendLineData = _f[0], fetchTrendLineData = _f[1];
    var filterSettings = useFilterSetting();
    var lookupYearData = useMixedYear(filterSettings, true)[0];
    var statisticName = getStatisticShortName((_a = filterSettings.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption);
    var viewSetting = useViewSetting();
    var isShow = viewSetting.viewMode === ViewModeEnum.Chart && ((_b = viewSetting.chartView) === null || _b === void 0 ? void 0 : _b.type) === ChartViewEnum.Trends;
    useEffect(function () {
        fetchTrendLineData();
    }, [fetchTrendLineData]);
    useEffect(function () {
        var _a;
        if ((_a = lookupYearData.data) === null || _a === void 0 ? void 0 : _a.length) {
            var newToYear = toYear;
            if (!newToYear || !lookupYearData.data.includes(newToYear)) {
                newToYear = lookupYearData.data[lookupYearData.data.length - 1];
            }
            var newFromYear = fromYear;
            if (!newFromYear || newFromYear > newToYear || !lookupYearData.data.includes(newFromYear)) {
                var fromYearIdx = lookupYearData.data.length - FROM_YEAR_DISTANCE;
                fromYearIdx = fromYearIdx < 0 ? 0 : fromYearIdx;
                newFromYear = lookupYearData.data[fromYearIdx];
            }
            setFromYear(newFromYear);
            setToYear(newToYear);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupYearData.data, setFromYear, setToYear]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LookupYearComponent, { dataSource: (_c = filterSettings.statistic) === null || _c === void 0 ? void 0 : _c.cmsDataSource }),
        isShow && (React.createElement(StyledTrendLineComponent, null,
            React.createElement(TrendLineTitle, null,
                React.createElement(TrendLineTitleText, null,
                    "Yearly ",
                    statisticName,
                    " from"),
                ' ',
                React.createElement(Select, { inline: true, width: "6", name: "from", placeholder: "", options: lookupYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: fromYear, onChange: setFromYear, isOptionDisabled: function (option) { return !!toYear && option.value > toYear; }, isLoading: lookupYearData.requesting }),
                ' ',
                React.createElement(TrendLineTitleText, null, "to"),
                ' ',
                React.createElement(Select, { inline: true, width: "6", name: "to", placeholder: "", options: lookupYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: toYear, onChange: setToYear, isOptionDisabled: function (option) { return !!fromYear && option.value < fromYear; }, isLoading: lookupYearData.requesting })),
            React.createElement(FallbackComponent, { requesting: trendLineData.requesting || lookupYearData.requesting, error: trendLineData.error, retry: fetchTrendLineData },
                React.createElement(TrendLineContainer, { data: trendLineData.data }))))));
};
export var TrendLineSeasonComponent = function () {
    var _a, _b, _c;
    var filterSettings = useFilterSetting();
    var lookupSeasonYearData = useCMSDataSeasonYear(filterSettings)[0];
    var _d = useTrendLineDataForSeason(), trendLineData = _d[0], fetchTrendLineData = _d[1];
    var _e = useViewValue('chartView', 'startingSeasonYear'), fromSeasonYear = _e[0], setFromSeasonYear = _e[1];
    var _f = useViewValue('chartView', 'endingSeasonYear'), toSeasonYear = _f[0], setToSeasonYear = _f[1];
    var viewSetting = useViewSetting();
    var statisticName = getStatisticShortName((_a = filterSettings.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption);
    var isShow = viewSetting.viewMode === ViewModeEnum.Chart && ((_b = viewSetting.chartView) === null || _b === void 0 ? void 0 : _b.type) === ChartViewEnum.Trends;
    useEffect(function () {
        fetchTrendLineData();
    }, [fetchTrendLineData]);
    useEffect(function () {
        var lookupSeasonYearDataByCMS = lookupSeasonYearData.data || [];
        if (lookupSeasonYearDataByCMS.length) {
            var newFromSeasonYear = fromSeasonYear;
            var newToSeasonYear = toSeasonYear;
            if (!newToSeasonYear || !lookupSeasonYearDataByCMS.includes(newToSeasonYear)) {
                newToSeasonYear = lookupSeasonYearDataByCMS[lookupSeasonYearDataByCMS.length - 1];
            }
            if (!newFromSeasonYear || !lookupSeasonYearDataByCMS.includes(newFromSeasonYear)) {
                newFromSeasonYear = lookupSeasonYearDataByCMS[0];
            }
            setFromSeasonYear(newFromSeasonYear);
            setToSeasonYear(newToSeasonYear);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupSeasonYearData.data, setFromSeasonYear, setToSeasonYear]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LookupYearComponent, { dataSource: (_c = filterSettings.statistic) === null || _c === void 0 ? void 0 : _c.cmsDataSource }),
        isShow && (React.createElement(StyledTrendLineComponent, null,
            React.createElement(TrendLineTitle, null,
                React.createElement(TrendLineTitleText, null,
                    "Yearly ",
                    statisticName,
                    " from"),
                ' ',
                React.createElement(Select, { inline: true, width: "12", name: "from", placeholder: "", options: lookupSeasonYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: fromSeasonYear, isOptionDisabled: function (item) {
                        var firstFrom = getYearsBySeason(item.value)[0];
                        var firstTo = getYearsBySeason(toSeasonYear)[0];
                        return !!toSeasonYear && firstFrom > firstTo;
                    }, onChange: setFromSeasonYear, isLoading: lookupSeasonYearData.requesting }),
                ' ',
                React.createElement(TrendLineTitleText, null, "to"),
                ' ',
                React.createElement(Select, { inline: true, width: "12", name: "to", placeholder: "", options: lookupSeasonYearData.data, optionsTransform: function (value) { return ({ value: value, label: "" + value }); }, value: toSeasonYear, isOptionDisabled: function (item) {
                        var firstFrom = getYearsBySeason(fromSeasonYear)[0];
                        var firstTo = getYearsBySeason(item.value)[0];
                        return !!fromSeasonYear && firstFrom > firstTo;
                    }, onChange: setToSeasonYear, isLoading: lookupSeasonYearData.requesting })),
            React.createElement(FallbackComponent, { requesting: trendLineData.requesting || lookupSeasonYearData.requesting, error: trendLineData.error, retry: fetchTrendLineData },
                React.createElement(TrendLineContainer, { data: trendLineData.data, keyValue: "seasonYear" }))))));
};
export * from './usetrendlinedata';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
