var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Spinner } from './spinner';
import { Button } from './button';
var FallbackOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  // top: 0;\n  // bottom: 0;\n  // left: 0;\n  // right: 0;\n  // cursor: not-allowed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  \n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: absolute;\n  // top: 0;\n  // bottom: 0;\n  // left: 0;\n  // right: 0;\n  // cursor: not-allowed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  \n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var StyledErrorMsg = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1rem;\n  text-align: center;\n  background-color: #fff;\n  border-radius: 4px;\n  cursor: default;\n"], ["\n  padding: 1rem;\n  text-align: center;\n  background-color: #fff;\n  border-radius: 4px;\n  cursor: default;\n"])));
var StyledWarningIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 3.5rem;\n  height: 3.5rem;\n  margin: 0 auto;\n  color: #ccc;\n  border-radius: 100%;\n  border: 0.125rem solid #ccc;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 3.5rem;\n  height: 3.5rem;\n  margin: 0 auto;\n  color: #ccc;\n  border-radius: 100%;\n  border: 0.125rem solid #ccc;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var ErrorTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 2rem;\n  text-align: center;\n  margin: 0.25rem 0;\n"], ["\n  font-size: 2rem;\n  text-align: center;\n  margin: 0.25rem 0;\n"])));
var ErrorBody = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #9b9b9b;\n  margin: 0.75rem 0;\n"], ["\n  color: #9b9b9b;\n  margin: 0.75rem 0;\n"])));
var ErrorMsg = function (props) {
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    return (React.createElement(StyledErrorMsg, null,
        React.createElement(StyledWarningIcon, null,
            React.createElement(FontAwesomeIcon, { icon: "exclamation", size: "2x" })),
        React.createElement(ErrorTitle, null, (((_a = props.error) === null || _a === void 0 ? void 0 : _a.statusCode) === 502 || ((_b = props.error) === null || _b === void 0 ? void 0 : _b.statusCode) === 504) ? 'Important' : t('apierror.title')),
        React.createElement(ErrorBody, null, (((_c = props.error) === null || _c === void 0 ? void 0 : _c.statusCode) === 502 || ((_d = props.error) === null || _d === void 0 ? void 0 : _d.statusCode) === 504)
            ? 'The system is currently busy, please try again in a couple of minutes.'
            : t('apierror.msg')),
        props.retry && React.createElement(Button, { color: "primary", message: "Retry", onClick: function () { return props.retry && props.retry(); } })));
};
export var FallbackComponent = function (props) {
    var fallback = props.requesting ? (props.fallback || React.createElement(Spinner, { show: true, radius: props.spinnerSize })) : props.error ? (React.createElement(ErrorMsg, { error: props.error, retry: props.retry })) : null;
    if (fallback && !props.overlay)
        return fallback;
    return (React.createElement(React.Fragment, null,
        props.children,
        fallback && props.overlay && React.createElement(FallbackOverlay, null, fallback)));
};
var loadingDotAnimations = keyframes(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  0%, 80%, 100% { opacity: 0; }\n  40% { opacity: 1; }\n"], ["\n  0%, 80%, 100% { opacity: 0; }\n  40% { opacity: 1; }\n"])));
var LoadingDot = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  animation: ", " 1s ease-in-out ", "ms infinite;\n  background-color: currentColor;\n  border-radius: 0.25rem;\n  margin-left: ", ";\n  display: inline-block;\n  height: 0.25rem;\n  width: 0.25rem;\n"], ["\n  animation: ", " 1s ease-in-out ", "ms infinite;\n  background-color: currentColor;\n  border-radius: 0.25rem;\n  margin-left: ", ";\n  display: inline-block;\n  height: 0.25rem;\n  width: 0.25rem;\n"])), loadingDotAnimations, function (props) { return props.delay; }, function (props) { return (props.isOffset ? '0.25rem' : '0'); });
export var ThreeDotLoading = function (props) {
    if (props.loading) {
        return (React.createElement("span", null,
            React.createElement(LoadingDot, { delay: 0 }),
            React.createElement(LoadingDot, { delay: 160, isOffset: true }),
            React.createElement(LoadingDot, { delay: 320, isOffset: true })));
    }
    return React.createElement(React.Fragment, null, props.children);
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
