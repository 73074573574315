var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import styled from 'styled-components';
import { useFilterSetting } from '../state';
import { DATA_SOURCES } from '../configs';
import { mapAgeValues, useLookupSexCode, useLookupRaceCode, useLookupCondition, getConsumptionGroups, getClaimDayLabel, useLookupVaccine, useLookupFluVaccine } from '../lookupdata';
import { ConsumptionGroupTab, ConditionTab, AggregatedSexTab, SexTab, AggregatedRaceTab, RaceTab, AggregatedAgeTab, AgeTab, DataYearTab, DataYearSeasonTab, } from '../filterdialog';
import { FilterSidebarConst } from '../configs/filtersidebar';
import { DELIMITER_SEASON_YEAR } from '../common';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
var StyledFiltersHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  font-weight: 550;\n  padding-top: 1rem;\n"], ["\n  cursor: pointer;\n  font-weight: 550;\n  padding-top: 1rem;\n"])));
var FiltersValue = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #6b778c;\n  width: 17rem;\n  margin: 0.5rem;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n"], ["\n  color: #6b778c;\n  width: 17rem;\n  margin: 0.5rem;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n"])));
var Result = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
var FiltersHeader = function (props) {
    var handleClick = function () {
        props.toggleFilterDialog(props.tabId);
    };
    if (props.isHidden)
        return null;
    return React.createElement(StyledFiltersHeader, { onClick: handleClick }, props.children);
};
export var FilterTab = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var indexHostname = useHostnameIndexInfo();
    var filterSetting = useFilterSetting();
    var lookupPrimaryCondition = useLookupCondition(filterSetting.primaryCoMorbidities)[0];
    var lookupConditionData = useLookupCondition(filterSetting.comorbidities)[0];
    var cmsDataSource = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.cmsDataSource;
    var dataSourceName = filterSetting.isAggregatedDataSource ? filterSetting.dataSourceName : cmsDataSource && DATA_SOURCES[cmsDataSource].label;
    var lookupSexData = useLookupSexCode((_b = filterSetting.demographics) === null || _b === void 0 ? void 0 : _b.sexCodes)[0];
    var lookupRaceData = useLookupRaceCode(filterSetting.raceMethodology, (_c = filterSetting.demographics) === null || _c === void 0 ? void 0 : _c.raceCodes)[0];
    var ageData = mapAgeValues((_d = filterSetting.demographics) === null || _d === void 0 ? void 0 : _d.ages);
    var consumptionGroupClassesDefaultFilter = indexHostname === null || indexHostname === void 0 ? void 0 : indexHostname.defaultFilter.consumptionGroupClasses;
    var consumptionGroups = (_e = getConsumptionGroups(filterSetting.consumptionGroupClasses)) === null || _e === void 0 ? void 0 : _e.map(function (i) { return i.shortTerm; }).join(', ');
    var primaryConditionName = (_g = (_f = lookupPrimaryCondition.labeledValues) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.chronicConditionName;
    var otherContitionName = (_h = lookupConditionData.labeledValues) === null || _h === void 0 ? void 0 : _h.map(function (i) { return i.chronicConditionName; }).filter(function (name) { return name !== primaryConditionName; }).join(', ');
    var claimDayLabel = getClaimDayLabel(filterSetting.claimDay);
    var mainPrimaryConditionName = (_k = (_j = lookupPrimaryCondition.labeledValues) === null || _j === void 0 ? void 0 : _j.filter(function (i) { return !filterSetting.primaryConditionWithClaims || !filterSetting.primaryConditionWithClaims[i.chronicConditionCode]; })) === null || _k === void 0 ? void 0 : _k.map(function (i) {
        return i.chronicConditionName;
    }).join(', ');
    var textMainClaimNumber = filterSetting.claimNumber ?
        " (" + filterSetting.claimNumber + " claim(s)" + (filterSetting.claimNumber && filterSetting.claimNumber > 1 ? " " + claimDayLabel + " days apart" : '') + ")" : '';
    var otherPrimaryConditionNameWithClaim = (_m = (_l = lookupPrimaryCondition.labeledValues) === null || _l === void 0 ? void 0 : _l.filter(function (i) { return filterSetting.primaryConditionWithClaims && filterSetting.primaryConditionWithClaims[i.chronicConditionCode]; })) === null || _m === void 0 ? void 0 : _m.map(function (i, idx) {
        var claimNumber = filterSetting.primaryConditionWithClaims ? filterSetting.primaryConditionWithClaims[i.chronicConditionCode] : '';
        var claim = claimNumber ? "(" + claimNumber + " claim(s)" + ((claimNumber && claimNumber > 1 && claimDayLabel) ? " " + claimDayLabel + " days apart" : '') + ")" : '';
        return i.chronicConditionName + " " + claim;
    }).join(', ');
    var sexLabel = filterSetting.isAggregatedDataSource
        ? filterSetting.filteringCategoryType === 'SEX'
            ? filterSetting.filteringCategoryUiName
            : ''
        : ((_o = lookupSexData.mappedValues) === null || _o === void 0 ? void 0 : _o.length) && lookupSexData.mappedValues.map(function (option) { return option.label; }).join(', ');
    var raceLabel = filterSetting.isAggregatedDataSource
        ? filterSetting.filteringCategoryType === 'RACE'
            ? filterSetting.filteringCategoryUiName
            : ''
        : ((_p = lookupRaceData.mappedValues) === null || _p === void 0 ? void 0 : _p.length) && lookupRaceData.mappedValues.map(function (option) { return option.label; }).join(', ');
    var ageLabel = filterSetting.isAggregatedDataSource
        ? filterSetting.filteringCategoryType === 'AGE'
            ? filterSetting.filteringCategoryUiName
            : ''
        : (ageData === null || ageData === void 0 ? void 0 : ageData.length) && ageData.map(function (option) { return option.label; }).join(', ');
    var dataYearTabId = props.showFluVaccine ? (_q = DataYearSeasonTab.defaultProps) === null || _q === void 0 ? void 0 : _q.tabId : (_r = DataYearTab.defaultProps) === null || _r === void 0 ? void 0 : _r.tabId;
    var dataYear = React.useMemo(function () {
        if (Array.isArray(filterSetting.dataYearSeason) && filterSetting.dataYearSeason.length === 2) {
            return filterSetting.dataYearSeason.join(DELIMITER_SEASON_YEAR);
        }
        return filterSetting.dataYear;
    }, [filterSetting.dataYear, filterSetting.dataYearSeason]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FiltersHeader, { tabId: dataYearTabId, toggleFilterDialog: props.toggleFilterDialog },
            "Patients from ",
            dataSourceName,
            " ",
            dataYear),
        props.showVaccine && React.createElement(VaccineFilterSidebar, { toggleFilterDialog: props.toggleFilterDialog }),
        props.showFluVaccine && React.createElement(InfluenzaVaccineFilterSidebar, { toggleFilterDialog: props.toggleFilterDialog }),
        !filterSetting.isAggregatedDataSource && (React.createElement(React.Fragment, null,
            React.createElement(FiltersHeader, { tabId: (_s = ConditionTab.defaultProps) === null || _s === void 0 ? void 0 : _s.tabId, toggleFilterDialog: props.toggleFilterDialog }, "Conditions"),
            React.createElement(FiltersValue, null,
                React.createElement(Result, null,
                    mainPrimaryConditionName,
                    textMainClaimNumber,
                    (textMainClaimNumber || mainPrimaryConditionName) && otherPrimaryConditionNameWithClaim && ", ",
                    otherPrimaryConditionNameWithClaim,
                    (textMainClaimNumber || primaryConditionName || otherPrimaryConditionNameWithClaim) && otherContitionName && ", ",
                    otherContitionName)))),
        React.createElement(FiltersHeader, { tabId: (_t = ConsumptionGroupTab.defaultProps) === null || _t === void 0 ? void 0 : _t.tabId, toggleFilterDialog: props.toggleFilterDialog, isHidden: Array.isArray(consumptionGroupClassesDefaultFilter) && !consumptionGroupClassesDefaultFilter.length }, "Consumption Group Class"),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, consumptionGroups)),
        React.createElement(FiltersHeader, { tabId: filterSetting.isAggregatedDataSource ? (_u = AggregatedSexTab.defaultProps) === null || _u === void 0 ? void 0 : _u.tabId : (_v = SexTab.defaultProps) === null || _v === void 0 ? void 0 : _v.tabId, toggleFilterDialog: props.toggleFilterDialog }, "Sex"),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, sexLabel)),
        React.createElement(FiltersHeader, { tabId: filterSetting.isAggregatedDataSource ? (_w = AggregatedRaceTab.defaultProps) === null || _w === void 0 ? void 0 : _w.tabId : (_x = RaceTab.defaultProps) === null || _x === void 0 ? void 0 : _x.tabId, toggleFilterDialog: props.toggleFilterDialog }, "Race/Ethnicity"),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, raceLabel)),
        React.createElement(FiltersHeader, { tabId: filterSetting.isAggregatedDataSource ? (_y = AggregatedAgeTab.defaultProps) === null || _y === void 0 ? void 0 : _y.tabId : (_z = AgeTab.defaultProps) === null || _z === void 0 ? void 0 : _z.tabId, toggleFilterDialog: props.toggleFilterDialog }, "Age"),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, ageLabel))));
};
var VaccineFilterSidebar = function (_a) {
    var _b, _c, _d, _e;
    var toggleFilterDialog = _a.toggleFilterDialog;
    var filterSetting = useFilterSetting();
    var lookupVaccine = useLookupVaccine()[0];
    var vaccineCptCodes = filterSetting.vaccineCptCodes;
    var selectingVaccineDiseaseName = (vaccineCptCodes === null || vaccineCptCodes === void 0 ? void 0 : vaccineCptCodes.length) && ((_c = (_b = lookupVaccine.data) === null || _b === void 0 ? void 0 : _b.find(function (i) { return i.cptCode === vaccineCptCodes[0]; })) === null || _c === void 0 ? void 0 : _c.vaccineDiseaseName);
    var allVaccinesInGroup = (_d = lookupVaccine.data) === null || _d === void 0 ? void 0 : _d.filter(function (i) { return i.vaccineDiseaseName === selectingVaccineDiseaseName; });
    var vaccineValue = (vaccineCptCodes === null || vaccineCptCodes === void 0 ? void 0 : vaccineCptCodes.length) === (allVaccinesInGroup === null || allVaccinesInGroup === void 0 ? void 0 : allVaccinesInGroup.length)
        ? selectingVaccineDiseaseName
        : (_e = lookupVaccine.data) === null || _e === void 0 ? void 0 : _e.filter(function (i) { return vaccineCptCodes === null || vaccineCptCodes === void 0 ? void 0 : vaccineCptCodes.some(function (v) { return v === i.cptCode; }); }).map(function (i) { return "" + i.cptCode + (i.vaccineBrandName ? " - " + i.vaccineBrandName : ''); }).join(', ');
    return (React.createElement(React.Fragment, null,
        React.createElement(FiltersHeader, { tabId: FilterSidebarConst.Vaccine.tabId, toggleFilterDialog: toggleFilterDialog }, FilterSidebarConst.Vaccine.title),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, vaccineValue))));
};
var InfluenzaVaccineFilterSidebar = function (_a) {
    var _b;
    var toggleFilterDialog = _a.toggleFilterDialog;
    var filterSetting = useFilterSetting();
    var lookupFluVaccine = useLookupFluVaccine()[0];
    var fluVaccineCptCodes = filterSetting.fluVaccineCptCodes;
    var vaccineValue = (_b = lookupFluVaccine.data) === null || _b === void 0 ? void 0 : _b.filter(function (vaccine) { return fluVaccineCptCodes === null || fluVaccineCptCodes === void 0 ? void 0 : fluVaccineCptCodes.includes(vaccine.hcpcsPctCodes); }).map(function (vaccine) { return vaccine.hcpcsDescription; }).join(', ');
    return (React.createElement(React.Fragment, null,
        React.createElement(FiltersHeader, { tabId: FilterSidebarConst.InfluenzaVaccine.tabId, toggleFilterDialog: toggleFilterDialog }, FilterSidebarConst.InfluenzaVaccine.title),
        React.createElement(FiltersValue, null,
            React.createElement(Result, null, vaccineValue))));
};
var templateObject_1, templateObject_2, templateObject_3;
