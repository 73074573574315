/* 
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved. 
 */
import React from 'react';
import * as Yup from 'yup';
import {
  FilterDialog,
  DataYearTab,
  ConditionTab,
  PrimaryConditionSection,
  AdditionalConditionSection,
  StatisticTab,
  SexTab,
  RaceTab,
  AgeTab,
  AggregatedDemographicTab,
  AggregatedSexTab,
  AggregatedRaceTab,
  AggregatedAgeTab,
  GeoLevelTab,
  PrioritizedConditionSection,
  ConsumptionGroupTab,
  ClaimCondition,
  useHostnameIndexInfo,
} from 'nmqf-common-ui';

interface IndexFilterDialogProps {
  isOpen: boolean;
  toggle: () => void;
  defaultActiveTab?: string;
}

const validationSchema = Yup.object().shape({
  primaryCoMorbidities: Yup.array().nullable().required('Please select one of the primary conditions').min(1, 'Please select one of the primary conditions'),
});

export const IndexFilterDialog: React.FC<IndexFilterDialogProps> = (props) => {
  const indexConfig = useHostnameIndexInfo();
  const { claimCondition } = indexConfig?.generalInformation || {};

  return (
    <FilterDialog
      isOpen={props.isOpen}
      toggle={props.toggle}
      defaultActiveTab={props.defaultActiveTab || DataYearTab.defaultProps?.tabId}
      validationSchema={validationSchema}
    >
      <DataYearTab />
      <ConditionTab>
        <PrimaryConditionSection />
        <PrioritizedConditionSection />
        {claimCondition && <ClaimCondition />}
        <AdditionalConditionSection />
      </ConditionTab>
      <ConsumptionGroupTab />
      <StatisticTab />
      <GeoLevelTab />
      <SexTab />
      <RaceTab />
      <AgeTab />
      <AggregatedDemographicTab />
      <AggregatedSexTab />
      <AggregatedRaceTab />
      <AggregatedAgeTab />
    </FilterDialog>
  );
};
