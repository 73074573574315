/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { Map } from 'immutable';
export var SET_MESSAGE = 'SET_MESSAGE';
// Init state
var initialState = Map();
// Init action handlers
var actionHandlers = {};
actionHandlers[SET_MESSAGE] = function (s, a) { return (a.payload.data ? s.set(a.payload.key, a.payload.data) : s.remove(a.payload.key)); };
export default function messageReducer(state, action) {
    if (state === void 0) { state = initialState; }
    // Get appropriate action handler by type
    var fn = actionHandlers[action.type];
    // Check if handler is existed
    // Then call the handler
    // Otherwise return state
    return fn ? fn(state, action) : state;
}
;
