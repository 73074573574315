var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import { useState, useCallback, useEffect, useRef } from 'react';
import { getIn, setIn, setNestedObjectValues } from 'formik';
import { isEmpty, getValidationSchema, getErrors } from '../utils';
export function castValues(values, validationSchema) {
    return !validationSchema ? values : getValidationSchema(validationSchema).cast(values);
}
var isFirstError = function (errors, name, path) {
    if (path === void 0) { path = []; }
    if (isEmpty(errors)) {
        return false;
    }
    var firstErrorName = Array.isArray(errors) ? "" + errors.findIndex(function (err) { return !isEmpty(err); }) : Object.keys(errors)[0];
    path.push(firstErrorName);
    if (name === path.join('.')) {
        return true;
    }
    var nameDepth = name.split('.');
    if (nameDepth.length !== path.length) {
        return isFirstError(errors[firstErrorName], name, path);
    }
    return false;
};
export var useForm = function (_a) {
    var _b = _a === void 0 ? Object.assign({}) : _a, initialValues = _b.initialValues, validationSchema = _b.validationSchema;
    var _c = useState(Object.assign({}, initialValues)), values = _c[0], setValues = _c[1];
    var _d = useState(Object.assign({})), errors = _d[0], setErrors = _d[1];
    var _e = useState(Object.assign({})), touched = _e[0], setTouched = _e[1];
    var _f = useState(false), isSubmitting = _f[0], setIsSubmitting = _f[1];
    var _g = useState(0), submitCount = _g[0], setSubmitCount = _g[1];
    var isUnMount = useRef(false);
    var initialValuesRef = useRef(Object.assign({}, initialValues));
    var validationSchemaRef = useRef(validationSchema);
    useEffect(function () {
        return function () {
            isUnMount.current = true;
        };
    }, []);
    useEffect(function () {
        validationSchemaRef.current = validationSchema;
        if (typeof validationSchema === 'function') {
            setValues(function (values) { return (__assign({}, values)); });
        }
    }, [validationSchema]);
    useEffect(function () {
        setErrors(getErrors(validationSchemaRef.current, values));
    }, [values]);
    var setInitialValues = useCallback(function (initialValues) {
        initialValuesRef.current = initialValues;
        setValues(Object.assign({}, initialValues));
        setTouched(Object.assign({}));
    }, []);
    var setFieldValue = useCallback(function (value, field) {
        setValues(function (values) { return setIn(values, field, value); });
    }, []);
    var setFieldTouched = useCallback(function (isTouched, field) {
        setTouched(function (touched) { return setIn(touched, field, isTouched); });
    }, []);
    var handleChange = useCallback(function (event) {
        var name = event.target.name;
        var value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFieldValue(value, name);
    }, [setFieldValue]);
    var handleBlur = useCallback(function (event) {
        var name = event.target.name;
        setFieldTouched(true, name);
    }, [setFieldTouched]);
    var handleSubmit = function (submitFormHandler) { return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event === null || event === void 0 ? void 0 : event.preventDefault();
                    if (isSubmitting) {
                        return [2 /*return*/];
                    }
                    setSubmitCount(function (curr) { return curr + 1; });
                    setIsSubmitting(true);
                    setTouched(setNestedObjectValues(initialValuesRef.current, true));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 4, 5]);
                    if (!isEmpty(errors)) return [3 /*break*/, 3];
                    return [4 /*yield*/, submitFormHandler(castValues(values, validationSchemaRef.current))];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    if (!isUnMount.current) {
                        setIsSubmitting(false);
                    }
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }; };
    var handleReset = useCallback(function () {
        setValues(initialValuesRef.current);
        setTouched(Object.assign({}));
    }, []);
    var getError = function (name) { return (getIn(touched, name) ? getIn(errors, name) : undefined); };
    var isFirstFieldError = function (name) { return isFirstError(errors, name); };
    return {
        setInitialValues: setInitialValues,
        values: values,
        handleChange: handleChange,
        handleBlur: handleBlur,
        setFieldValue: setFieldValue,
        setFieldTouched: setFieldTouched,
        handleSubmit: handleSubmit,
        isSubmitting: isSubmitting,
        handleReset: handleReset,
        getError: getError,
        isFirstFieldError: isFirstFieldError,
        submitCount: submitCount,
        hasError: !isEmpty(errors),
    };
};
