var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { useGeneralConfigByDataSource } from "../hooks";
import { useAPI } from "../state";
import { useMemo } from "react";
export var FilterCountTypeEnum;
(function (FilterCountTypeEnum) {
    FilterCountTypeEnum["PRIMARY_CHRONIC_CONDITION"] = "PRIMARY_CHRONIC_CONDITION";
    FilterCountTypeEnum["CHRONIC_CONDITION"] = "CHRONIC_CONDITION";
    FilterCountTypeEnum["SEX_CODE"] = "SEX_CODE";
    FilterCountTypeEnum["RACE_CODE"] = "RACE_CODE";
    FilterCountTypeEnum["RTI_RACE_CODE"] = "RTI_RACE_CODE";
    FilterCountTypeEnum["STATE_CODE"] = "STATE_CODE";
    FilterCountTypeEnum["FLU_VACCINE_CODE"] = "FLU_VACCINE_CODE";
    FilterCountTypeEnum["AGE_GROUP_CODE"] = "AGE_GROUP_CODE";
    FilterCountTypeEnum["CONSUMPTION_GROUP"] = "CONSUMPTION_GROUP";
})(FilterCountTypeEnum || (FilterCountTypeEnum = {}));
var API_BASE_URL_FILTER_COUNT_FROM_STATS = '/api/stats/filter-count';
var API_BASE_URL_FILTER_COUNT_FROM_HOSTNAME_INDEX = '/api/{hostname}/filter-count';
var API_BASE_URL_FILTER_COUNT_FROM_GENERAL_INDEX = '/api/generalindex/filter-count/{hostname}';
var ConditionExclusionConfig = (_a = {},
    _a[FilterCountTypeEnum.PRIMARY_CHRONIC_CONDITION] = 'excludedCcFromFilterCount',
    _a[FilterCountTypeEnum.CHRONIC_CONDITION] = 'excludedCcFromFilterCount',
    _a);
export var useFilterCount = function (filterType, indexConfig) {
    var baseUrl = (function () {
        if (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.useGeneralFilterCount)
            return API_BASE_URL_FILTER_COUNT_FROM_GENERAL_INDEX;
        if (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCountAttachHostname)
            return API_BASE_URL_FILTER_COUNT_FROM_HOSTNAME_INDEX;
        return API_BASE_URL_FILTER_COUNT_FROM_STATS;
    })();
    var url = baseUrl + "/" + filterType;
    var filterCountApi = useAPI(url, { hostname: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.hostname }, 'POST');
    var exclusionConfig = ConditionExclusionConfig[filterType];
    var excludedConditionByDatasource = useGeneralConfigByDataSource(exclusionConfig);
    var excludedConditions = useMemo(function () {
        return excludedConditionByDatasource || (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.excludedCcFromFilterCount) || [];
    }, [excludedConditionByDatasource, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.excludedCcFromFilterCount]);
    return useMemo(function () {
        var states = filterCountApi[0], request = filterCountApi[1], reset = filterCountApi[2];
        var requestStates = [
            states,
            function (body, force, timeout) {
                var finalBody = __assign({}, body);
                if (exclusionConfig) {
                    finalBody.excludedConditions = excludedConditions;
                }
                return request(finalBody, force, timeout);
            },
            reset
        ];
        return requestStates;
    }, [filterCountApi, excludedConditions, exclusionConfig]);
};
