/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useMemo } from "react";
import _ from 'lodash';
import { createSelector } from 'reselect';
import { useAPI } from "../state";
var LOCATOR_QUEST_DIAGNOSTICS_API_URL = '/api/quest-diagnostics/get-quest-diagnostics-sites';
var questDiagnosticsSelector = function (state) { return state === null || state === void 0 ? void 0 : state.questDiagnosticsSites; };
var questDiagnosticsMapSelector = createSelector(questDiagnosticsSelector, function (questDiagnostics) {
    return _.keyBy(questDiagnostics, function (_a) {
        var id = _a.id, site_code = _a.site_code, zip_code = _a.zip_code;
        return id + "-" + site_code + "-" + zip_code;
    });
});
var createQuestDiagnosticsSelector = function (_a) {
    var _b = _a === void 0 ? Object.assign({}) : _a, id = _b.id, site_code = _b.site_code, zip_code = _b.zip_code;
    return createSelector(questDiagnosticsMapSelector, function (map) { return map[id + "-" + site_code + "-" + zip_code]; });
};
export var useQuestDiagnosticsApi = function () {
    return useAPI(LOCATOR_QUEST_DIAGNOSTICS_API_URL, undefined, 'POST');
};
export var useQuestDiagnosticsDetail = function (id) {
    var questDiagnosticsData = useQuestDiagnosticsApi()[0];
    var questDiagnosticsSelector = useMemo(function () { return createQuestDiagnosticsSelector(id); }, [id]);
    return questDiagnosticsSelector(questDiagnosticsData.data);
};
