var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, useContext, useMemo, useCallback, useEffect, useRef } from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import bbox from '@turf/bbox';
import { Map } from 'immutable';
import { v4 } from 'uuid';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useUIValueSessionStorage } from '../storeduivalue';
import { useMount } from '../hooks';
var INITIAL_VIEW_PORT = {
    latitude: 37.70912855295634,
    longitude: -95.77103105174075,
    zoom: 3.4,
    pitch: 0,
    bearing: 0,
};
var DEFAULT_TRANSPARENCY = 70;
var FIT_BOUNDS_PADDING = 70;
var MapContext = React.createContext(Object.assign([]));
var ViewportContext = React.createContext(Object.assign([]));
export var useMapFallback = function (requesting, error, retry) {
    var _a = useContext(MapContext), state = _a[0], updateState = _a[1];
    var fallback = state === null || state === void 0 ? void 0 : state.get('fallback');
    var key = useMemo(function () { return v4(); }, []);
    var getter = function () {
        var fallbackObj = (fallback === null || fallback === void 0 ? void 0 : fallback.toObject()) || {};
        var result = Object.values(fallbackObj).reduce(function (acc, curr) {
            acc.requesting = acc.requesting || curr.requesting;
            acc.error = acc.error || curr.error;
            return acc;
        }, Object.assign({}));
        result.retry = function () {
            Object.values(fallbackObj).forEach(function (curr) {
                if (curr.error) {
                    curr.retry();
                }
            });
        };
        return result;
    };
    var setter = useCallback(function (value) {
        updateState === null || updateState === void 0 ? void 0 : updateState('fallback', function (oldState) { return (value ? oldState.set(key, value) : oldState.remove(key)); });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState]);
    useEffect(function () {
        setter({ requesting: requesting, error: error, retry: retry });
        return setter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requesting, error]);
    return [useMemo(getter, [fallback]), setter];
};
export var MapFallback = function (props) {
    useMapFallback(props.requesting, props.error, props.retry);
    return null;
};
export var useMapRef = function () {
    var _a = useContext(MapContext), mapRef = _a[2];
    return mapRef;
};
export var useViewport = function () {
    var _a;
    var _b = useContext(ViewportContext), viewport = _b[0], updateViewport = _b[1];
    var indexConfig = useHostnameIndexInfo();
    var singleStateBBox = (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.singleState) === null || _a === void 0 ? void 0 : _a.bbox;
    var setter = useCallback(function (value) {
        updateViewport(function (curr) { return (__assign(__assign({}, curr), value)); });
    }, [updateViewport]);
    var resetViewport = function (shouldResetViewport) {
        if (shouldResetViewport === void 0) { shouldResetViewport = true; }
        if (singleStateBBox && (viewport === null || viewport === void 0 ? void 0 : viewport.width) && (viewport === null || viewport === void 0 ? void 0 : viewport.height)) {
            var webMercatorViewport = new WebMercatorViewport(viewport);
            var minLng = singleStateBBox[0], minLat = singleStateBBox[1], maxLng = singleStateBBox[2], maxLat = singleStateBBox[3];
            var _a = webMercatorViewport.fitBounds([
                [minLng, minLat],
                [maxLng, maxLat],
            ], { padding: FIT_BOUNDS_PADDING }), longitude = _a.longitude, latitude = _a.latitude, zoom = _a.zoom;
            var value = { minZoom: zoom, zoom: Math.max(zoom, viewport.zoom || 0) };
            if (shouldResetViewport) {
                value = __assign(__assign({}, value), { longitude: longitude,
                    latitude: latitude,
                    zoom: zoom });
            }
            setter(value);
        }
        else if (shouldResetViewport) {
            setter(INITIAL_VIEW_PORT);
        }
    };
    return [viewport, setter, resetViewport];
};
export var useMapValue = function (key) {
    var _a = useContext(MapContext), state = _a[0], updateState = _a[1];
    var getter = function () { return state === null || state === void 0 ? void 0 : state.get(key); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var setter = useCallback(function (value) { return updateState === null || updateState === void 0 ? void 0 : updateState(key, value); }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return [useMemo(getter, [state]), setter];
};
export var useZoomToFeature = function () {
    var _a = useViewport(), viewport = _a[0], setViewport = _a[1];
    var viewportRef = useRef();
    viewportRef.current = viewport;
    var zoomToFeature = useCallback(function (feature) {
        if (feature) {
            try {
                var webMercatorViewport = new WebMercatorViewport(viewportRef.current);
                var _a = bbox(feature), minLng = _a[0], minLat = _a[1], maxLng = _a[2], maxLat = _a[3];
                var _b = webMercatorViewport.fitBounds([
                    [minLng, minLat],
                    [maxLng, maxLat],
                ], { padding: FIT_BOUNDS_PADDING }), longitude = _b.longitude, latitude = _b.latitude, zoom = _b.zoom;
                setViewport({
                    longitude: longitude,
                    latitude: latitude,
                    zoom: Math.max(zoom - 1.5, viewportRef.current.minZoom || 0),
                });
            }
            catch (e) { }
        }
    }, [setViewport]);
    return zoomToFeature;
};
var INITIAL_VALUE = {
    viewport: INITIAL_VIEW_PORT,
    transparency: DEFAULT_TRANSPARENCY,
    fallback: Map(),
};
var SingleStateViewportComponent = function (props) {
    var _a = useViewport(), resetViewport = _a[2];
    useMount(function () {
        resetViewport(props.shouldResetViewport);
    });
    return null;
};
var ViewportProvider = function (props) {
    var _a = useUIValueSessionStorage('viewport', INITIAL_VIEW_PORT, ['latitude', 'longitude', 'zoom']), viewport = _a[0], setViewport = _a[1], initialSessionValue = _a[2];
    return (React.createElement(ViewportContext.Provider, { value: [viewport, setViewport] },
        !!viewport.width && !!viewport.height && React.createElement(SingleStateViewportComponent, { shouldResetViewport: !initialSessionValue }),
        props.children));
};
export var MapProvider = function (props) {
    var _a = useState(function () { return Map(INITIAL_VALUE); }), state = _a[0], setState = _a[1];
    var mapRef = useRef(null);
    var setter = useCallback(function (key, value) {
        setState(function (curr) { return curr.set(key, typeof value === 'function' ? value(curr.get(key)) : value); });
    }, []);
    var contextValue = useMemo(function () { return [state, setter, mapRef]; }, [state, setter]);
    return (React.createElement(ViewportProvider, null,
        React.createElement(MapContext.Provider, __assign({ value: contextValue }, props))));
};
