var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, Children, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabContent, Card, ListGroup } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useResetFilterEditing, useFilterEditing } from '../state/filterediting';
import { exportFilterSettingPayload, useFilterSetting, useResetFilter } from '../state/filtersettings';
import { Button, AlertMessage, IconButton } from '../common';
import { LeftTabSelectorItem } from './tabselector';
import { useResetViewSetting, useViewValue } from '../state';
import { useDefaultFilter } from '../hostnameindexinfo';
import { getFlattenErrors, getValidationSchema, isEmpty } from '../utils';
import { useStoredUIValue } from '../storeduivalue';
import { useProviderSetting } from '../selectors';
import { RightPanelTypeEnum } from '../enums';
import { useToggle } from '../hooks';
var HAMBURGER_MEDIA_SCREEN = 995;
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    min-height: calc(100vh - 3.5rem);\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    min-height: calc(100vh - 3.5rem);\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var StyledRestoreDefaultButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-decoration: underline;\n"], ["\n  text-decoration: underline;\n"])));
var StyledModalBody = styled(ModalBody)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  padding: 0;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  padding: 0;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var HamburgerGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: none;\n  margin-bottom: -1rem;\n  padding: 0.5rem 0;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  display: none;\n  margin-bottom: -1rem;\n  padding: 0.5rem 0;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var HamburgerButton = styled(function (_a) {
    var show = _a.show, props = __rest(_a, ["show"]);
    return React.createElement(IconButton, __assign({}, props));
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var StyledTabSelectors = styled(function (_a) {
    var show = _a.show, props = __rest(_a, ["show"]);
    return React.createElement(ListGroup, __assign({}, props));
})(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 1rem;\n  padding-right: 0;\n  flex: 1 0 2rem;\n  overflow: auto;\n  .list-group-item:not(.disabled) {\n    cursor: pointer;\n  }\n  @media only screen and (max-width: ", "px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    top: 3rem;\n    padding: 1rem;\n    width: 100%;\n    z-index: 99;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    ", "\n  }\n"], ["\n  padding: 1rem;\n  padding-right: 0;\n  flex: 1 0 2rem;\n  overflow: auto;\n  .list-group-item:not(.disabled) {\n    cursor: pointer;\n  }\n  @media only screen and (max-width: ", "px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    top: 3rem;\n    padding: 1rem;\n    width: 100%;\n    z-index: 99;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    ", "\n  }\n"])), HAMBURGER_MEDIA_SCREEN, function (_a) {
    var show = _a.show;
    return (show ? 'display: block;' : '');
});
var StyledTabContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 3;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n"], ["\n  flex: 3;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n"])));
var validationAgeSchema = Yup.object().shape({
    demographics: Yup.object().shape({
        ages: Yup.array().of(Yup.object().shape({
            isCustom: Yup.boolean(),
            minAge: Yup.number().when('isCustom', {
                is: true,
                then: Yup.number().nullable().required('Custom Age: "From" is Required'),
            }),
            maxAge: Yup.number().when('isCustom', {
                is: true,
                then: Yup.number().nullable().required('Custom Age: "To" is Required'),
            }),
        })),
    }),
});
var RestoreDefaultButton = function () {
    var defaultFilter = useDefaultFilter();
    var resetFilterEditing = useResetFilterEditing();
    var handleClick = function () {
        resetFilterEditing(defaultFilter);
    };
    return React.createElement(StyledRestoreDefaultButton, { color: "link", message: "Restore defaults", onClick: handleClick });
};
export var FilterDialog = function (props) {
    var isOpen = props.isOpen, toggle = props.toggle;
    var _a = useState(props.defaultActiveTab), activeTab = _a[0], setActiveTab = _a[1];
    var _b = useViewValue('geoType'), geoLevel = _b[0], setGeoLevel = _b[1];
    var _c = useViewValue('selectedRegion'), setSelectedRegion = _c[1];
    var filterSetting = useFilterSetting();
    var resetFilterEditing = useResetFilterEditing();
    var editingFilter = useFilterEditing();
    var resetFilter = useResetFilter();
    var _d = useState(), error = _d[0], setError = _d[1];
    var defaultFilter = useDefaultFilter();
    var resetViewSetting = useResetViewSetting();
    var storedUIValueData = useStoredUIValue()[0];
    var _e = useProviderSetting(editingFilter), shouldDisabledProviderSelection = _e[0].disabled, setProviderSetting = _e[1];
    var validationSchemaProps = props.validationSchema ? validationAgeSchema.concat(getValidationSchema(props.validationSchema)) : validationAgeSchema;
    var _f = useToggle(), show = _f[0], toggleShow = _f[1], setShow = _f[2];
    useEffect(function () {
        var _a, _b;
        if (storedUIValueData.session) {
            var filterValue = __assign(__assign({}, defaultFilter), (_a = storedUIValueData.data) === null || _a === void 0 ? void 0 : _a.filterSetting);
            var selectedConditions = __spreadArrays((filterValue.primaryCoMorbidities || []), (filterValue.comorbidities || []));
            filterValue = __assign(__assign({}, filterValue), { primaryCoMorbidities: selectedConditions.filter(function (c) { var _a; return (_a = defaultFilter.indexPrimaryCoMorbidities) === null || _a === void 0 ? void 0 : _a.includes(c); }), comorbidities: selectedConditions.filter(function (c) { var _a; return !((_a = defaultFilter.indexPrimaryCoMorbidities) === null || _a === void 0 ? void 0 : _a.includes(c)); }) });
            var viewSetting = __assign({ geoType: filterValue.geoLevel }, (_b = storedUIValueData.data) === null || _b === void 0 ? void 0 : _b.viewSetting);
            var errors = filterValue.isAggregatedDataSource ? undefined : getFlattenErrors(validationSchemaProps, exportFilterSettingPayload(filterValue));
            resetFilter(__assign(__assign({}, filterValue), { available: isEmpty(errors) }));
            resetViewSetting(viewSetting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedUIValueData.session]);
    useEffect(function () {
        setActiveTab(props.defaultActiveTab);
    }, [props.defaultActiveTab]);
    useEffect(function () {
        if (isOpen) {
            resetFilterEditing(__assign(__assign({}, filterSetting), { geoLevel: geoLevel }));
            setActiveTab(props.defaultActiveTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    var handleOK = function () {
        var errors;
        if (!editingFilter.isAggregatedDataSource) {
            errors = getFlattenErrors(validationSchemaProps, exportFilterSettingPayload(editingFilter));
        }
        setError(errors);
        if (!isEmpty(errors)) {
            return;
        }
        setGeoLevel(editingFilter.geoLevel);
        resetFilter(__assign(__assign({}, editingFilter), { available: true }));
        if (shouldDisabledProviderSelection) {
            setProviderSetting({});
        }
        setSelectedRegion({ type: RightPanelTypeEnum.RegionDetail });
        toggle();
    };
    var toggleTabSelector = function (tabId) {
        setActiveTab(tabId);
        setShow(false);
    };
    var tabSelectors = Children.map(props.children, function (child) {
        return (React.createElement(LeftTabSelectorItem, { activeTab: activeTab, toggleTab: toggleTabSelector, tabId: child.props.tabId, tabName: child.props.tabName, tabSelector: child.props.tabSelector }));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledModal, { size: "lg", unmountOnClose: false, isOpen: isOpen, toggle: toggle, scrollable: true },
            React.createElement(ModalHeader, { toggle: toggle },
                React.createElement(FontAwesomeIcon, { icon: "filter", size: "lg" }),
                React.createElement("span", null, " Filters "),
                React.createElement(RestoreDefaultButton, null)),
            React.createElement(StyledModalBody, null,
                React.createElement(HamburgerGroup, null,
                    React.createElement(HamburgerButton, { color: "#000", icon: show ? 'times' : 'bars', size: "2x", onClick: toggleShow }),
                    React.createElement("h5", { className: "m-0" }, React.Children.map(props.children, function (child) { return (child.props.tabId === activeTab ? child.props.tabName : null); }))),
                React.createElement(StyledTabSelectors, { show: show }, tabSelectors),
                React.createElement(StyledTabContent, null,
                    React.createElement(Card, { body: true, className: "flex-grow-1 overflow-auto" },
                        React.createElement(TabContent, { activeTab: activeTab }, props.children)))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { color: "secondary", onClick: toggle }, "Cancel"),
                ' ',
                React.createElement(Button, { color: "primary", onClick: handleOK }, "Apply"))),
        React.createElement(AlertMessage, { errors: error })));
};
export * from './datayeartab';
export * from './datayearseasontab';
export * from './conditiontab';
export * from './statistictab';
export * from './sextab';
export * from './racetab';
export * from './agetab';
export * from './aggregateddemographictab';
export * from './geoleveltab';
export * from './tabselector';
export * from './consumptiongroup';
export * from './usefiltercount';
export * from './filtercountloading';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
