/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
export var CONSUMPTION_GROUP_OPTIONS = [
    {
        value: 1,
        label: (React.createElement(React.Fragment, null,
            "Crisis - 99",
            React.createElement("sup", null, "th"),
            " percentile")),
        shortTerm: 'Crisis',
    },
    {
        value: 2,
        label: (React.createElement(React.Fragment, null,
            "Heavy - 90 to 98",
            React.createElement("sup", null, "th"),
            " percentile")),
        shortTerm: 'Heavy',
    },
    {
        value: 3,
        label: (React.createElement(React.Fragment, null,
            "Moderate - 75 to 89",
            React.createElement("sup", null, "th"),
            " percentile")),
        shortTerm: 'Moderate',
    },
    {
        value: 4,
        label: (React.createElement(React.Fragment, null,
            "Light - 50 to 74",
            React.createElement("sup", null, "th"),
            " percentile")),
        shortTerm: 'Light',
    },
    {
        value: 5,
        label: (React.createElement(React.Fragment, null,
            "Low - 1 to 49",
            React.createElement("sup", null, "th"),
            " percentile")),
        shortTerm: 'Low',
    },
];
export var getConsumptionGroups = function (values) { return CONSUMPTION_GROUP_OPTIONS.filter(function (option) { return values === null || values === void 0 ? void 0 : values.some(function (v) { return v === option.value; }); }); };
