/*
 * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useMemo } from 'react';
import { useCachedData } from '../state';
import { RaceMethodologyEnum } from '../enums';
import { useMount } from '../hooks';
var API_URL_LOOKUP_RACE_CODE = '/api/general/lookup/get-lookup-race-code';
var API_URL_LOOKUP_RTI_RACE_CODE = '/api/general/lookup/get-lookup-rti-race-code';
export var useLookupRaceCode = function (methodology, values) {
    if (methodology === void 0) { methodology = RaceMethodologyEnum.Standard; }
    var _a = useCachedData(methodology === RaceMethodologyEnum.Standard ? API_URL_LOOKUP_RACE_CODE : API_URL_LOOKUP_RTI_RACE_CODE), lookupData = _a[0], rest = _a.slice(1);
    var data = useMemo(function () { var _a; return (_a = lookupData.data) === null || _a === void 0 ? void 0 : _a.map(function (i) { return (__assign(__assign({}, i), { value: i.raceCode, label: i.raceName, shortName: i.raceName === 'North American Native' ? 'N.Amer Native' : i.raceName })); }).sort(function (a, b) { return parseInt(a.raceCode, 10) - parseInt(b.raceCode, 10); }); }, [lookupData.data]);
    var mappedValues = useMemo(function () { return ((values === null || values === void 0 ? void 0 : values.length) ? data === null || data === void 0 ? void 0 : data.filter(function (i) { return values === null || values === void 0 ? void 0 : values.some(function (v) { return v === i.value; }); }) : undefined); }, [data, values]);
    return __spreadArrays([__assign(__assign({}, lookupData), { data: data, mappedValues: mappedValues })], rest);
};
export var LookupRaceCodeComponent = function () {
    var _a = useLookupRaceCode(RaceMethodologyEnum.Standard), fetchRaceLookupStandard = _a[1];
    var _b = useLookupRaceCode(RaceMethodologyEnum.RTI), fetchRaceLookupRTI = _b[1];
    useMount(function () {
        fetchRaceLookupStandard();
        fetchRaceLookupRTI();
    });
    return null;
};
