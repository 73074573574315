import React from 'react';
import { ThreeDotLoading } from "../common";
import { getDisplayText } from "../utils";
export var FilterCountLoading = function (_a) {
    var loading = _a.loading, value = _a.value;
    return (React.createElement(React.Fragment, null,
        '(',
        React.createElement(ThreeDotLoading, { loading: loading }, getDisplayText(value)),
        ')'));
};
