/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { TabPane, Label } from 'reactstrap';
import { useFilterEditingValue } from '../state';
import { FallbackComponent, CheckboxGroup, ThreeDotLoading } from '../common';
import { useEffectFilterEditingCount } from '../filterbar/useeffectfiltercount';
import { getDisplayText } from '../utils';
import { CMSSourceTabSelector } from './tabselector';
import { useLookupSexCode, LookupSexCodeComponent } from '../lookupdata';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { useFilterCount } from './usefiltercount';
export var SexTab = function (props) {
    var indexConfig = useHostnameIndexInfo();
    var _a = useLookupSexCode(), lookupSexCodeData = _a[0], fetchLookupSexCode = _a[1];
    var _b = useFilterCount('SEX_CODE', indexConfig), sexCodeCountData = _b[0], fetchSexCodeCount = _b[1];
    var _c = useFilterEditingValue('demographics', 'sexCodes'), sexCodes = _c[0], setSexCodes = _c[1];
    useEffectFilterEditingCount(function (body) {
        fetchSexCodeCount(body);
    }, props.filterCountEffectDeps, 'SEX');
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FallbackComponent, { requesting: lookupSexCodeData.requesting, error: lookupSexCodeData.error, retry: fetchLookupSexCode },
            React.createElement(Label, { className: "font-weight-bold" }, "Sex"),
            React.createElement(CheckboxGroup, { options: lookupSexCodeData.data, noSort: true, formatOptionLabel: function (i) {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        i.label,
                        "\u00A0",
                        (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && (React.createElement(React.Fragment, null,
                            '(',
                            React.createElement(ThreeDotLoading, { loading: sexCodeCountData.requesting }, getDisplayText((_a = sexCodeCountData.data) === null || _a === void 0 ? void 0 : _a[i.value])),
                            ')'))));
                }, value: sexCodes, onChange: setSexCodes })),
        React.createElement(LookupSexCodeComponent, null)));
};
SexTab.defaultProps = {
    tabId: 'SexTab',
    tabName: 'Sex',
    tabSelector: CMSSourceTabSelector,
};
