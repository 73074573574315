/* 
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved. 
 */
import React from 'react';
import {
  MapComponent,
  SelectedGeographySource,
  PatientChoroplethLayer,
  ImportingLocationLayers,
  InfoPopup,
  ClinicalTrialLayer,
  ClinicalTrialDetail,
  MapSidebar,
  useHostnameIndexInfo,
  ProviderLayer,
  QuestDiagnosticsSitesLayer,
  QuestDiagnosticsSitesDetails,
} from 'nmqf-common-ui';

export const IndexMapComponent: React.FC = () => {
  const indexConfig = useHostnameIndexInfo();
  const { providerLayer, clinicalTrailsGovLayer, questDiagnosticsLayer } = indexConfig?.generalInformation || {};

  return (
    <MapComponent>
      <SelectedGeographySource>
        <PatientChoroplethLayer />
        {providerLayer ? <ProviderLayer /> : <></>}
        {clinicalTrailsGovLayer ? <ClinicalTrialLayer /> : <></>}
        {questDiagnosticsLayer ? <QuestDiagnosticsSitesLayer /> : <></>}
        <ImportingLocationLayers />
      </SelectedGeographySource>
      <InfoPopup />
      <MapSidebar>
        {clinicalTrailsGovLayer && <ClinicalTrialDetail />}
        {questDiagnosticsLayer && <QuestDiagnosticsSitesDetails />}
      </MapSidebar>
    </MapComponent>
  );
};
