var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useCallback, useEffect, useRef, useState } from 'react';
import Supercluster from 'supercluster';
import { point } from '@turf/helpers';
import { useMapRef } from './mapprovider';
import { groupBy } from '../utils';
var CLUSTER_OPTIONS = {
    radius: 35,
};
var getClusterOptions = function () { return (__assign(__assign({}, CLUSTER_OPTIONS), { map: function (props) { return ({ locations: [props.location], isSameLocation: false }); }, reduce: function (acc, props) {
        acc.locations = __spreadArrays(acc.locations, props.locations);
    } })); };
export var useCluster = function (data, options) {
    var _a, _b;
    var map = (_b = (_a = useMapRef()) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.getMap();
    var indexRef = useRef();
    var _c = useState([]), cookedclusters = _c[0], setCookedClusters = _c[1];
    var recalculateClusters = useCallback(function () {
        var index = indexRef.current;
        if (map && index) {
            var zoom = map.getZoom();
            var bounds = map.getBounds().toArray();
            var bbox = __spreadArrays(bounds[0], bounds[1]);
            var clusters = index.getClusters(bbox, Math.floor(zoom)).map(function (i) {
                if (!i.properties.cluster) {
                    return i;
                }
                var _a = i.properties.locations, point = _a[0], points = _a.slice(1);
                i.properties.isSameLocation = points.every(function (p) { return p.lng === point.lng && p.lat === point.lat; });
                return i;
            });
            var groupByLngLat = groupBy(clusters, function (i) { return JSON.stringify(i.geometry.coordinates); });
            clusters = Object.values(groupByLngLat).map(function (points, idx) {
                if (points.length === 1) {
                    return points[0];
                }
                return point(points[0].geometry.coordinates, {
                    point_count: points.length,
                    point_count_abbreviated: points.length,
                    locations: points.map(function (p) { return p.properties.location; }),
                    isSameLocation: true,
                    cluster: true,
                    cluster_id: idx,
                });
            });
            setCookedClusters(clusters);
        }
    }, [map]);
    useEffect(function () {
        map === null || map === void 0 ? void 0 : map.on('moveend', recalculateClusters);
        return function () {
            map === null || map === void 0 ? void 0 : map.off('moveend', recalculateClusters);
        };
    }, [map, recalculateClusters]);
    useEffect(function () {
        var index = new Supercluster(getClusterOptions());
        indexRef.current = index;
        index.load((data || []).map(function (payload) { return point([payload.lng, payload.lat], { location: payload }); }));
        recalculateClusters();
    }, [data, recalculateClusters]);
    var getClusterExpansionZoom = useCallback(function (clusterId) { return indexRef.current && indexRef.current.getClusterExpansionZoom(clusterId) + 2; }, []);
    return [cookedclusters, getClusterExpansionZoom];
};
