var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Alert, FormFeedback as RSFormFeedback, Modal } from 'reactstrap';
import styled from 'styled-components';
import { useToggle } from '../hooks';
import { useMessage } from '../state';
import { isEmpty } from '../utils';
var StyledMessageBox = styled(Alert)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 1.5;\n"], ["\n  line-height: 1.5;\n"])));
export var MessageBox = function (props) {
    var error = props.error, resprops = __rest(props, ["error"]);
    var _a = useToggle(), isOpen = _a[0], toggle = _a[1], setIsOpen = _a[2];
    var messageBoxRef = useRef(null);
    var errors = useMemo(function () {
        var _a;
        return ((_a = (Array.isArray(props.error) ? props.error : [props.error])) === null || _a === void 0 ? void 0 : _a.map(function (i) { return (typeof i === 'string' ? i : (i === null || i === void 0 ? void 0 : i.message) || (i === null || i === void 0 ? void 0 : i.errorMessage)); }).filter(function (message) { return !!message; })) || [];
    }, [props.error]);
    useEffect(function () {
        if (!isEmpty(errors)) {
            setIsOpen(true);
            setTimeout(function () { var _a; return (_a = messageBoxRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' }); });
        }
        else {
            setIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (React.createElement("div", { ref: messageBoxRef },
        React.createElement(StyledMessageBox, __assign({}, resprops, { color: "danger", isOpen: isOpen, toggle: toggle }), errors.map(function (msg, idx) { return (React.createElement("div", { key: idx }, msg)); }))));
};
export var FormFeedback = function (props) {
    var message = props.message, resprops = __rest(props, ["message"]);
    if (!message)
        return null;
    return (React.createElement(RSFormFeedback, __assign({}, resprops, { className: "d-block " + (props.className || '') }), message));
};
export var AlertMessage = function (props) {
    var errors = props.errors;
    var _a = useToggle(), isOpen = _a[0], toggle = _a[1], setIsOpen = _a[2];
    useEffect(function () {
        setIsOpen(!isEmpty(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (React.createElement(Modal, { isOpen: isOpen },
        React.createElement(StyledMessageBox, { toggle: toggle, color: "danger", className: "m-0 pr-5", isOpen: true, fade: false }, errors === null || errors === void 0 ? void 0 : errors.map(function (i, index) { return React.createElement("div", { key: index }, i); }))));
};
export var MessageId;
(function (MessageId) {
    MessageId["LoginPage"] = "LOGIN_PAGE";
})(MessageId || (MessageId = {}));
export var MessageWithState = function (props) {
    var _a = useMessage(props.messageId), message = _a[0], setMessage = _a[1];
    var clearMessage = useCallback(function () {
        setMessage();
    }, [setMessage]);
    useEffect(function () {
        if (message) {
            var handler_1 = setTimeout(clearMessage, 5000);
            return function () { return clearTimeout(handler_1); };
        }
    }, [message, clearMessage]);
    return (React.createElement(StyledMessageBox, { toggle: clearMessage, color: (message === null || message === void 0 ? void 0 : message.type) === 'success' ? 'success' : 'danger', className: "pr-5", isOpen: !!(message === null || message === void 0 ? void 0 : message.value), fade: false }, message === null || message === void 0 ? void 0 : message.value));
};
var templateObject_1;
