var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { useAPI } from '../state';
import { FallbackComponent } from '../common';
import { useMount } from '../hooks';
import { useAuth } from '../authentication';
import { useState } from 'react';
var GET_INDEX_CONFIGURATION_API_URL = '/api/general/get-index-configuration/{hostname}';
export var extractHostname = function () {
    return window.location.host.split('.')[0];
};
var useFetchIndexConfiguration = function () {
    var hostname = extractHostname();
    return useAPI(GET_INDEX_CONFIGURATION_API_URL, { hostname: hostname });
};
export var HostNameIndexInfoProvider = function (props) {
    var _a = useFetchIndexConfiguration(), data = _a[0], fetchIndexGeneralInfo = _a[1];
    var auth = useAuth();
    var _b = useState(true), isCheckingToken = _b[0], setIsCheckingToken = _b[1];
    useMount(function () {
        fetchIndexGeneralInfo();
        var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, auth.refreshToken()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3:
                        setIsCheckingToken(false);
                        return [2 /*return*/];
                }
            });
        }); };
        refreshToken();
    });
    return (React.createElement(FallbackComponent, { requesting: isCheckingToken || data.requesting === undefined || data.requesting, error: data.error, retry: fetchIndexGeneralInfo }, props.children));
};
export var useIndexName = function () {
    var data = useFetchIndexConfiguration()[0].data;
    return (data === null || data === void 0 ? void 0 : data.indexName) || '';
};
export var useHostnameIndexInfo = function () {
    return useFetchIndexConfiguration()[0].data;
};
export var useDefaultFilter = function () {
    var data = useFetchIndexConfiguration()[0].data;
    return useMemo(function () {
        var _a, _b, _c, _d, _e;
        return (__assign(__assign({}, data === null || data === void 0 ? void 0 : data.defaultFilter), { comorbidities: [], primaryCoMorbidities: ((_a = data === null || data === void 0 ? void 0 : data.defaultFilter.comorbidities) === null || _a === void 0 ? void 0 : _a.slice(0, 1)) || [], available: data === null || data === void 0 ? void 0 : data.generalInformation.filterOnload, singleStateCode: (_b = data === null || data === void 0 ? void 0 : data.generalInformation.singleState) === null || _b === void 0 ? void 0 : _b.stateCode, singleStateAbb: (_c = data === null || data === void 0 ? void 0 : data.generalInformation.singleState) === null || _c === void 0 ? void 0 : _c.stateAbb, indexPrimaryCoMorbidities: data === null || data === void 0 ? void 0 : data.generalInformation.primaryConditions, reportTypes: data === null || data === void 0 ? void 0 : data.generalInformation.reportTypes, useMBSFChronicCondition: data === null || data === void 0 ? void 0 : data.generalInformation.useMBSFChronicCondition, dataYear: ((_d = data === null || data === void 0 ? void 0 : data.defaultFilter) === null || _d === void 0 ? void 0 : _d.dataYearSeason) ? undefined : (_e = data === null || data === void 0 ? void 0 : data.defaultFilter) === null || _e === void 0 ? void 0 : _e.dataYear }));
    }, [data]);
};
export var HostNameMetaTagManager = function () {
    var indexInfo = useHostnameIndexInfo();
    useEffect(function () {
        if (indexInfo) {
            var _a = indexInfo.generalInformation, indexTitle = _a.indexTitle, indexOwner = _a.indexOwner;
            document.title = "" + indexTitle + (indexOwner && " - " + indexOwner);
        }
    }, [indexInfo]);
    return null;
};
