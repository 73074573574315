/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { Map } from 'immutable';
export var FETCH_URL_START = 'FETCH_URL_START';
export var FETCH_URL_SUCCESS = 'FETCH_URL_SUCCESS';
export var FETCH_URL_FAILED = 'FETCH_URL_FAILED';
export var FETCH_URL_RESET = 'FETCH_URL_RESET';
var initialState = Map();
var actionHandlers = {};
actionHandlers[FETCH_URL_START] = function (s, a) {
    return s
        .setIn([a.payload.key + ":" + a.payload.method, 'requesting'], true)
        .removeIn([a.payload.key + ":" + a.payload.method, 'error'])
        .setIn([a.payload.key + ":" + a.payload.method, 'timeout'], a.payload.timeout)
        .setIn([a.payload.key + ":" + a.payload.method, 'abortController'], a.payload.abortController);
};
actionHandlers[FETCH_URL_SUCCESS] = function (s, a) {
    return s
        .setIn([a.payload.key + ":" + a.payload.method, 'requesting'], false)
        .setIn([a.payload.key + ":" + a.payload.method, 'data'], a.payload.data)
        .setIn([a.payload.key + ":" + a.payload.method, 'statusCode'], a.payload.statusCode)
        .removeIn([a.payload.key + ":" + a.payload.method, 'abortController']);
};
actionHandlers[FETCH_URL_FAILED] = function (s, a) {
    return s
        .setIn([a.payload.key + ":" + a.payload.method, 'requesting'], false)
        .removeIn([a.payload.key + ":" + a.payload.method, 'data'])
        .setIn([a.payload.key + ":" + a.payload.method, 'error'], a.payload.error)
        .setIn([a.payload.key + ":" + a.payload.method, 'statusCode'], a.payload.statusCode)
        .removeIn([a.payload.key + ":" + a.payload.method, 'abortController']);
};
actionHandlers[FETCH_URL_RESET] = function (s, a) { return s.remove(a.payload.key + ":" + a.payload.method); };
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    // Get appropriate action handler by type
    var fn = actionHandlers[action.type];
    // Check if handler is existed
    // Then call the handler
    // Otherwise return state
    return fn ? fn(state, action) : state;
}
;
