/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useState, useCallback } from 'react';
export var useToggle = function (initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = useState(initialValue), isOpen = _a[0], setIsOpen = _a[1];
    var toggle = useCallback(function () {
        setIsOpen(function (curr) { return !curr; });
    }, []);
    return [isOpen, toggle, setIsOpen];
};
