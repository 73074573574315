/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { StyledMapSidebar } from './sidebar';
import { useViewValue } from '../state';
import { useQuestDiagnosticsDetail } from '../selectors';
import { IconButton } from '../common';
var StyledQuestDiagnosticsDetail = styled(StyledMapSidebar)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var PanelHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: 0.5rem;\n"], ["\n  position: absolute;\n  right: 0;\n  top: 0.5rem;\n"])));
var LogoHeader = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%\n"], ["\n  width: 100%\n"])));
var QuestDiagnosticsLink = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 500;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"], ["\n  font-weight: 500;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n"])));
var QuestDiagnosticsText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: normal;\n  padding-top: 1rem;\n  padding-right: 0.5rem;\n  text-decoration: underline;\n  font-size: 10pt;\n"], ["\n  font-weight: normal;\n  padding-top: 1rem;\n  padding-right: 0.5rem;\n  text-decoration: underline;\n  font-size: 10pt;\n"])));
var CountyText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #7F7F7F;\n"], ["\n  color: #7F7F7F;\n"])));
var EmptyText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 2rem;\n"], ["\n  height: 2rem;\n"])));
export var QuestDiagnosticsSitesDetails = function () {
    var _a = useViewValue('selectedRegion'), selectedRegion = _a[0], setSelectedRegion = _a[1];
    var id = (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.id) || undefined;
    var selectedQuestDiagnosticsSites = useQuestDiagnosticsDetail(id);
    var clearSelectedQuestDiagnosticsSitesInfo = function () {
        setSelectedRegion();
    };
    if (!selectedQuestDiagnosticsSites)
        return null;
    return (React.createElement(StyledQuestDiagnosticsDetail, null,
        React.createElement(PanelHeader, null,
            React.createElement(IconButton, { onClick: clearSelectedQuestDiagnosticsSitesInfo, icon: faTimesCircle, color: "warning", size: "2x" })),
        React.createElement(LogoHeader, { src: '/assets/images/logos/quest-diagnostics-sites-logo.png', alt: '' }),
        React.createElement("div", { className: "font-weight-bold" }, selectedQuestDiagnosticsSites.site_name),
        React.createElement(CountyText, null, selectedQuestDiagnosticsSites.county),
        React.createElement(EmptyText, null),
        React.createElement("div", null, selectedQuestDiagnosticsSites.address_1),
        React.createElement("div", null, selectedQuestDiagnosticsSites.address_2),
        React.createElement("div", null,
            selectedQuestDiagnosticsSites.city,
            ", ",
            selectedQuestDiagnosticsSites.state_abbreviation,
            ", ",
            selectedQuestDiagnosticsSites.zip_code),
        React.createElement(QuestDiagnosticsLink, { href: "https://www.google.com/maps/search/?api=1&query=" + selectedQuestDiagnosticsSites.lat + "," + selectedQuestDiagnosticsSites.lng, target: "_blank" },
            React.createElement(QuestDiagnosticsText, null, "Directions"),
            React.createElement(FontAwesomeIcon, { icon: faExternalLinkAlt })),
        React.createElement(EmptyText, null),
        React.createElement("div", null,
            "Phone 1: ",
            selectedQuestDiagnosticsSites.phone_1),
        React.createElement("div", null,
            "Phone 2: ",
            selectedQuestDiagnosticsSites.phone_2),
        React.createElement("div", null,
            "Tax: ",
            selectedQuestDiagnosticsSites.fax),
        React.createElement(EmptyText, null),
        React.createElement("div", { className: "font-weight-bold" }, "Hours Of Operation: "),
        React.createElement("span", null, selectedQuestDiagnosticsSites.operation_hours_desc)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
