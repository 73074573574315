var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a, _b, _c;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TabPane, Label, FormGroup, Input } from 'reactstrap';
import { AggregatedSourceTabSelector } from './tabselector';
import { Button, RadioGroup } from '../common';
import { useFilterEditingValue, useFilterEditing } from '../state/filterediting';
import { SexTab } from './sextab';
import { RaceTab } from './racetab';
import { AgeTab } from './agetab';
import { useAggregatedDemogaphic } from './aggregateddatasource';
var StyledClearButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n  margin-left: 0.5rem;\n  pointer-events: auto;\n  text-decoration: underline;\n"], ["\n  padding: 0;\n  margin-left: 0.5rem;\n  pointer-events: auto;\n  text-decoration: underline;\n"])));
var AggregatedCategoryTab = function (props) {
    var textSearch = useFilterEditingValue('textSearch')[0];
    var _a = useFilterEditingValue('filteringCategoryType'), categoryType = _a[0], setCategoryType = _a[1];
    var _b = useFilterEditingValue('filteringCategoryValue'), categoryValue = _b[0], setCategoryValue = _b[1];
    var _c = useFilterEditingValue('filteringCategoryUiName'), setCategoryUiName = _c[1];
    var filterEditing = useFilterEditing();
    var agrregatedData = useAggregatedDemogaphic(filterEditing, props.categoryType)[0];
    var selectingValue = categoryType === props.categoryType ? categoryValue : undefined;
    useEffect(function () {
        setCategoryType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterEditing.dataSourceName]);
    var handleChange = function (value, payload) {
        setCategoryType(props.categoryType);
        setCategoryValue(value);
        setCategoryUiName(payload.label);
    };
    var clear = function () {
        setCategoryType();
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(Label, { className: "font-weight-bold" }, props.title),
        React.createElement(RadioGroup, { input: textSearch, options: agrregatedData.data, optionsTransform: function (i) { return ({ value: i.name, label: i.uiName }); }, value: selectingValue, onChange: handleChange }),
        React.createElement(FormGroup, { check: true, className: "mt-3" },
            React.createElement(Label, { check: true },
                React.createElement(Input, { type: "radio", checked: selectingValue === undefined, onChange: clear }),
                " Clear filter choice"))));
};
export var AggregatedSexTab = function (props) { return React.createElement(AggregatedCategoryTab, __assign({}, props, { title: "Sex", categoryType: "SEX" })); };
export var AggregatedRaceTab = function (props) { return React.createElement(AggregatedCategoryTab, __assign({}, props, { title: "Race", categoryType: "RACE" })); };
export var AggregatedAgeTab = function (props) { return React.createElement(AggregatedCategoryTab, __assign({}, props, { title: "Age", categoryType: "AGE" })); };
export var AggregatedDemographicTab = function () { return null; };
var AggregatedCategoryTabSelector = function (props) {
    var categoryType = props.categoryType, rest = __rest(props, ["categoryType"]);
    var filteringCategoryType = useFilterEditingValue('filteringCategoryType')[0];
    return (React.createElement(AggregatedSourceTabSelector, __assign({}, rest),
        React.createElement(FormGroup, { check: true },
            React.createElement(Label, { check: true },
                React.createElement(Input, { type: "radio", checked: categoryType === filteringCategoryType, onChange: function () { } }),
                " ",
                props.children))));
};
var AggregatedDemographicTabSelector = function (props) {
    var _a = useFilterEditingValue('filteringCategoryType'), setCategoryType = _a[1];
    var handleClear = function () {
        setCategoryType();
    };
    return (React.createElement(AggregatedSourceTabSelector, __assign({}, props, { disabled: true }),
        props.children,
        React.createElement(StyledClearButton, { color: "link", message: "Clear", onClick: handleClear })));
};
AggregatedSexTab.defaultProps = {
    tabId: 'AggregatedSexTab',
    tabName: (_a = SexTab.defaultProps) === null || _a === void 0 ? void 0 : _a.tabName,
    tabSelector: function (props) { return React.createElement(AggregatedCategoryTabSelector, __assign({}, props, { categoryType: "SEX" })); },
};
AggregatedRaceTab.defaultProps = {
    tabId: 'AggregatedRaceTab',
    tabName: (_b = RaceTab.defaultProps) === null || _b === void 0 ? void 0 : _b.tabName,
    tabSelector: function (props) { return React.createElement(AggregatedCategoryTabSelector, __assign({}, props, { categoryType: "RACE" })); },
};
AggregatedAgeTab.defaultProps = {
    tabId: 'AggregatedAgeTab',
    tabName: (_c = AgeTab.defaultProps) === null || _c === void 0 ? void 0 : _c.tabName,
    tabSelector: function (props) { return React.createElement(AggregatedCategoryTabSelector, __assign({}, props, { categoryType: "AGE" })); },
};
AggregatedDemographicTab.defaultProps = {
    tabId: 'AggregatedDemographicTab',
    tabName: 'Demographics',
    tabSelector: AggregatedDemographicTabSelector,
};
var templateObject_1;
