/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useCallback } from 'react';
import { SET_MESSAGE } from './reducer';
export var useMessage = function (messageId) {
    var dispatch = useDispatch();
    var setter = useCallback(function (message) { return dispatch({ type: SET_MESSAGE, payload: { key: messageId, data: message } }); }, [dispatch, messageId]);
    var getter = useCallback(function (state) { return state.message.get(messageId); }, [messageId]);
    return [useMappedState(getter), setter];
};
