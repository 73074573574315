var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { exportFilterSettingPayload, useAPI, useFilterSetting, useViewValue } from '../../state';
import { ClinicalTrialOptionEnum, RightPanelTypeEnum } from '../../enums';
import { LocationLayer } from '../locationlayer';
import { useCustomMapLayerLocationData } from '../../selectors';
var GET_AVAIABLE_MAP_LAYER_LIST_API = '/api/map-layer/get-all-that-belong-to-the-logged-user-index';
var StyledBriefTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"], ["\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n"])));
var StyledFacilityName = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 550 !important;\n"], ["\n  font-weight: 550 !important;\n"])));
var MenuItem = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledBriefTitle, { id: "manage-map-layer-location-" + props.location.id }, props.location.trialName),
        React.createElement(UncontrolledTooltip, { placement: "top", target: "manage-map-layer-location-" + props.location.id }, props.location.trialName),
        React.createElement(StyledFacilityName, null, props.location.locationName)));
};
var SingleImportingLocationLayer = function (props) {
    var _a;
    var _b = useCustomMapLayerLocationData(props.mapLayer.id), mapLayerLocationData = _b[0], fetchMapLayerLocationData = _b[1], clearData = _b[2];
    var selectingType = useViewValue('importingLocationTypes', "" + props.mapLayer.id)[0];
    var show = !!selectingType;
    var filterSetting = useFilterSetting();
    useEffect(function () {
        if (show) {
            var payload = exportFilterSettingPayload(filterSetting);
            fetchMapLayerLocationData({
                comorbidities: selectingType === ClinicalTrialOptionEnum.TrialsRelated ? payload.comorbidities : undefined,
            });
        }
        return clearData;
    }, [clearData, fetchMapLayerLocationData, filterSetting, selectingType, show]);
    return (React.createElement(LocationLayer, { id: RightPanelTypeEnum.CustomMapLayer + "-" + props.mapLayer.id, locations: mapLayerLocationData.data || [], show: show, color: props.mapLayer.displayColor, icon: props.mapLayer.displayIcon, requesting: mapLayerLocationData.requesting, error: mapLayerLocationData.error, retry: fetchMapLayerLocationData, totalCount: (_a = mapLayerLocationData.data) === null || _a === void 0 ? void 0 : _a.length, compareFn: function (a, b) { var _a, _b; return ((_a = a.trialName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.trialName || '')) || ((_b = a.locationName) === null || _b === void 0 ? void 0 : _b.localeCompare(b.locationName || '')) || 0; }, menuItemComponent: MenuItem, name: props.mapLayer.name, idFields: ['id', 'mapLayerId'] }));
};
export var ImportingLocationLayers = React.memo(function () {
    var _a;
    var allMapLayers = useAPI(GET_AVAIABLE_MAP_LAYER_LIST_API)[0];
    return (React.createElement(React.Fragment, null, (_a = allMapLayers.data) === null || _a === void 0 ? void 0 : _a.map(function (ml) { return (React.createElement(SingleImportingLocationLayer, { key: ml.id, mapLayer: ml })); })));
});
export * from './detail';
var templateObject_1, templateObject_2;
