var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAPI } from '../state';
import { FallbackComponent } from '../common';
import { isEmpty } from '../utils';
var GET_SHAREABLE_UI_LINK = '/api/general/ui-setting';
var GET_STRORED_UI_VALUE = '/api/general/ui-setting/{id}';
var UI_VALUE_SESSION_ID = 'UI_VALUE_SESSION_ID';
var getSessionStorageItem = function (key, initialValue) {
    try {
        var item = window.sessionStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    }
    catch (error) {
        return initialValue;
    }
};
export var setUIValueSessionStorage = function (key, value, picks, omits) {
    var _a;
    var storedValue = getSessionStorageItem(UI_VALUE_SESSION_ID);
    var santifiedValue = value;
    if (picks) {
        santifiedValue = _.pick(santifiedValue, picks);
    }
    if (omits) {
        santifiedValue = _.omit(santifiedValue, omits);
    }
    window.sessionStorage.setItem(UI_VALUE_SESSION_ID, JSON.stringify(__assign(__assign({}, storedValue), (_a = {}, _a[key] = santifiedValue, _a))));
};
export var useShareableLink = function () {
    var _a = useAPI(GET_SHAREABLE_UI_LINK, undefined, 'POST'), data = _a[0], fetchData = _a[1], clearData = _a[2];
    var location = useLocation();
    var query = useMemo(function () { return qs.parse(location.search, { ignoreQueryPrefix: true, comma: true }); }, [location.search]);
    var getter = function () {
        var _a;
        try {
            var storedValue = getSessionStorageItem(UI_VALUE_SESSION_ID);
            var _b = ((_a = storedValue === null || storedValue === void 0 ? void 0 : storedValue.viewSetting) === null || _a === void 0 ? void 0 : _a.selectedRegion) || {}, longitude = _b.longitude, latitude = _b.latitude;
            if (!isEmpty(longitude) && !isEmpty(latitude)) {
                storedValue = __assign(__assign({}, storedValue), { viewport: __assign(__assign({}, storedValue.viewport), { longitude: longitude, latitude: latitude }) });
            }
            fetchData(storedValue);
        }
        catch (e) { }
    };
    return [
        __assign(__assign({}, data), { data: data.data && window.location.href.split('?')[0] + "?v=" + data.data.id + (query.title ? "&title=" + query.title : '') }),
        getter,
        clearData,
    ];
};
export var useStoredUIValue = function () {
    var location = useLocation();
    var query = useMemo(function () { return qs.parse(location.search, { ignoreQueryPrefix: true, comma: true }); }, [location.search]);
    var _a = useAPI(GET_STRORED_UI_VALUE, {
        id: query.v,
    }), storedUIValueData = _a[0], fetchStoredUIValueData = _a[1], clearStoredUIValueData = _a[2];
    var sessionUIValue = useMemo(function () { return (query.session === 'true' ? getSessionStorageItem(UI_VALUE_SESSION_ID) : undefined); }, [query.session]);
    var shouldRequest = !!query.v && !sessionUIValue;
    var safeFetchData = useCallback(function () {
        if (shouldRequest) {
            fetchStoredUIValueData();
            return clearStoredUIValueData;
        }
    }, [clearStoredUIValueData, fetchStoredUIValueData, shouldRequest]);
    return [
        __assign(__assign({}, storedUIValueData), { requesting: storedUIValueData.requesting || (shouldRequest && storedUIValueData.requesting === undefined), session: query.session === 'true', data: sessionUIValue || storedUIValueData.data }),
        safeFetchData,
        clearStoredUIValueData,
    ];
};
export var useUIValueSessionStorage = function (key, initialValue, picks) {
    var storedUIValueData = useStoredUIValue()[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var initialSessionValue = useMemo(function () { var _a; return (_a = storedUIValueData.data) === null || _a === void 0 ? void 0 : _a[key]; }, []);
    var _a = useState(function () { return initialSessionValue !== null && initialSessionValue !== void 0 ? initialSessionValue : initialValue; }), storedValue = _a[0], setStoredValue = _a[1];
    var storedValueRef = useRef();
    storedValueRef.current = storedValue;
    var picksString = JSON.stringify(picks);
    var setValue = useCallback(function (value) {
        try {
            var valueToStore = value instanceof Function ? value(storedValueRef.current) : value;
            setStoredValue(valueToStore);
            setUIValueSessionStorage(key, valueToStore, JSON.parse(picksString));
        }
        catch (error) { }
    }, [key, picksString]);
    return [storedValue, setValue, initialSessionValue];
};
var UIValueComponent = function () {
    var _a = useStoredUIValue(), storedUIValueData = _a[0], fetchStoredUIValueData = _a[1];
    var history = useHistory();
    var location = useLocation();
    var query = useMemo(function () { return qs.parse(location.search, { ignoreQueryPrefix: true, comma: true }); }, [location.search]);
    useEffect(fetchStoredUIValueData, [fetchStoredUIValueData]);
    useEffect(function () {
        if (!storedUIValueData.requesting) {
            window.sessionStorage.setItem(UI_VALUE_SESSION_ID, JSON.stringify(storedUIValueData.data));
            var search = qs.stringify(__assign(__assign({}, query), { session: true }));
            history.replace("" + location.pathname + (location.hash ? "#" + location.hash : '') + "?" + search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedUIValueData.requesting]);
    return null;
};
export var FallbackStoredUIValueComponent = function (props) {
    var storedUIValueData = useStoredUIValue()[0];
    return (React.createElement(React.Fragment, null,
        React.createElement(UIValueComponent, null),
        React.createElement(FallbackComponent, __assign({ requesting: storedUIValueData.requesting || !storedUIValueData.session }, props))));
};
