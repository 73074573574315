var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useState, Children, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabContent, Card, ListGroup } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import qs from 'qs';
import { Button, AlertMessage, SimpleCheckbox, IconButton } from '../common';
import { getErrors, getFlattenErrors, isEmpty } from '../utils';
import { LeftTabSelectorItem } from '../filterdialog';
import { useReportsDialogValue, useReportSetting } from './reportsdialogprovider';
import { useHostnameIndexInfo, useIndexName } from '../hostnameindexinfo';
import { GeoLevelEnum } from '../enums';
import { useLookupState } from '../lookupdata';
import { useAuth } from '../authentication';
import { ReportsTab } from './reportstab';
import { useAPI } from '../state';
import { useMount, useToggle } from '../hooks';
import { ReportTypeEnum } from '../enums/reporttypeenum';
export var REPORT_SETTING_API = '/api/general/report-setting';
var HAMBURGER_MEDIA_SCREEN = 995;
var StyledModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    min-height: calc(100vh - 3.5rem);\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    min-height: calc(100vh - 3.5rem);\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var StyledModalBody = styled(ModalBody)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 0;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  padding: 0;\n  @media only screen and (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var HamburgerGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  display: none;\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n    align-items: center;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var HamburgerButton = styled(function (_a) {
    var show = _a.show, props = __rest(_a, ["show"]);
    return React.createElement(IconButton, __assign({}, props));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n  }\n"], ["\n  @media only screen and (max-width: ", "px) {\n    display: flex;\n  }\n"])), HAMBURGER_MEDIA_SCREEN);
var StyledTabSelectors = styled(function (_a) {
    var show = _a.show, props = __rest(_a, ["show"]);
    return React.createElement(ListGroup, __assign({}, props));
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 1rem;\n  padding-right: 0;\n  flex: 1 0 2rem;\n  overflow: auto;\n  .list-group-item:not(.disabled) {\n    cursor: pointer;\n  }\n  min-width: fit-content;\n\n  @media only screen and (max-width: ", "px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    top: 2rem;\n    padding: 1rem;\n    width: 100%;\n    z-index: 99;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    ", "\n  }\n"], ["\n  padding: 1rem;\n  padding-right: 0;\n  flex: 1 0 2rem;\n  overflow: auto;\n  .list-group-item:not(.disabled) {\n    cursor: pointer;\n  }\n  min-width: fit-content;\n\n  @media only screen and (max-width: ", "px) {\n    display: none;\n    position: absolute;\n    left: 0;\n    top: 2rem;\n    padding: 1rem;\n    width: 100%;\n    z-index: 99;\n    background-color: hsla(0, 0%, 0%, 0.3);\n    ", "\n  }\n"])), HAMBURGER_MEDIA_SCREEN, function (_a) {
    var show = _a.show;
    return (show ? 'display: block;' : '');
});
var StyledTabContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 3;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n"], ["\n  flex: 3;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n"])));
var StyledSimpleCheckbox = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  margin-left: 1.5rem;\n"], ["\n  flex: 1;\n  margin-left: 1.5rem;\n"])));
var validationSchema = Yup.object().shape({
    isSeasonStatistic: Yup.bool().nullable(),
    reportType: Yup.string().nullable().required('Report Type is required'),
    firstLevel: Yup.string().nullable().when('reportType', {
        is: ReportTypeEnum.CongressionalDetailAnalysis,
        then: Yup.string().nullable().required('State is required'),
    }),
    secondLevel: Yup.string().nullable().when('reportType', {
        is: ReportTypeEnum.CongressionalDetailAnalysis,
        then: Yup.string().nullable().required('District is required'),
    }),
    zipCodes: Yup.object().when('reportType', {
        is: ReportTypeEnum.ZipCodeDetailAnalysis,
        then: Yup.object().shape({
            zipCode: Yup.array().nullable().when(['startWith', 'allInState'], {
                is: function (startWith, allInState) { return (startWith === null || startWith === void 0 ? void 0 : startWith.length) === 0 && (allInState === null || allInState === void 0 ? void 0 : allInState.length) === 0; },
                then: Yup.array().nullable().required('Please select at least one zip code'),
            }),
            startWith: Yup.array().when(['zipCode', 'allInState'], {
                is: function (zipCode, allInState) { return (zipCode === null || zipCode === void 0 ? void 0 : zipCode.length) === 0 && (allInState === null || allInState === void 0 ? void 0 : allInState.length) === 0; },
                then: Yup.array().nullable().required('Please select at least one zip code'),
            }),
            allInState: Yup.array().when(['zipCode', 'startWith'], {
                is: function (zipCode, startWith) { return (zipCode === null || zipCode === void 0 ? void 0 : zipCode.length) === 0 && (startWith === null || startWith === void 0 ? void 0 : startWith.length) === 0; },
                then: Yup.array().nullable().required('Please select at least one zip code'),
            }),
        }, [['startWith', 'allInState'], ['zipCode', 'allInState'], ['zipCode', 'startWith']])
    }),
    dataSourceType: Yup.string().nullable().required('Data Source is required'),
    dataYear: Yup.number().nullable().when('isSeasonStatistic', {
        is: false,
        then: Yup.number().nullable().required('Data Year is required'),
    }),
    dataYearSeason: Yup.array().nullable().when('isSeasonStatistic', {
        is: true,
        then: Yup.array().nullable().required('Data Year is required').min(1, 'Data Year is required'),
    }),
    fluVaccineCptCodes: Yup.array().nullable().when('isSeasonStatistic', {
        is: true,
        then: Yup.array().nullable().required('Please select at least one of the Vaccines').min(1, 'Please select at least one of the Vaccines'),
    }),
    statistics: Yup.array().nullable().required('Statistic is required').min(1, 'Please select at least one of the Statistic'),
    conditions: Yup.array().when(['isSeasonStatistic', 'conditionClaimOptionals'], {
        is: function (isSeasonStatistic, conditionClaimOptionals) {
            if (!!isSeasonStatistic)
                return false;
            return conditionClaimOptionals === undefined || (conditionClaimOptionals === null || conditionClaimOptionals === void 0 ? void 0 : conditionClaimOptionals.length) === 0;
        },
        then: Yup.array().nullable().required('Condition is Required'),
    }),
    conditionClaimOptionals: Yup.array().when(['isSeasonStatistic', 'conditions'], {
        is: function (isSeasonStatistic, conditions) {
            if (!!isSeasonStatistic)
                return false;
            return conditions === undefined || (conditions === null || conditions === void 0 ? void 0 : conditions.length) === 0;
        },
        then: Yup.array().nullable().required('Condition is Required'),
    }),
    ages: Yup.array().nullable().required('Age is required'),
    sexCodes: Yup.array().nullable().required('Sex is required'),
    raceCodes: Yup.array().nullable().required('Race is required'),
}, [['conditions', 'conditionClaimOptionals']]);
var PRIVATE_REPORT_EXTENSION = (_a = {},
    _a[ReportTypeEnum.CongressionalDetailAnalysis] = { pathname: 'privatecongressionalreportextension', geolevel: GeoLevelEnum.Congressional },
    _a[ReportTypeEnum.ZipCodeDetailAnalysis] = { pathname: 'privatezipcodeanalysisreport', geolevel: GeoLevelEnum.Zip5 },
    _a);
export var ReportsDialogExtension = function (props) {
    var isOpen = props.isOpen, toggle = props.toggle;
    var _a = useState(), activeTab = _a[0], setActiveTab = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useReportSetting(), reportSetting = _c[0], setReportSetting = _c[1];
    var indexName = useIndexName();
    var indexConfig = useHostnameIndexInfo();
    var lookupStateData = useLookupState()[0];
    var auth = useAuth();
    var isUnMount = useRef(false);
    var _d = useState(false), requesting = _d[0], setRequesting = _d[1];
    var _e = useReportsDialogValue('isRememberSettings'), isRememberSettings = _e[0], setIsRememberSettings = _e[1];
    var _f = useAPI(REPORT_SETTING_API), rememberSettingData = _f[0], fetchRememberSettingData = _f[1];
    var _g = useAPI(REPORT_SETTING_API, undefined, 'POST'), fetchRememberSettings = _g[1];
    var _h = useToggle(), show = _h[0], toggleShow = _h[1];
    useEffect(function () {
        if (isOpen) {
            fetchRememberSettingData();
        }
    }, [fetchRememberSettingData, isOpen]);
    useEffect(function () {
        var _a;
        if ((_a = rememberSettingData.data) === null || _a === void 0 ? void 0 : _a.isRememberSettings) {
            setReportSetting(rememberSettingData.data);
        }
    }, [rememberSettingData.data, setReportSetting]);
    var requiredField;
    if (reportSetting) {
        requiredField = Object.keys(getErrors(validationSchema, reportSetting));
    }
    useMount(function () { return function () {
        isUnMount.current = true;
    }; });
    useEffect(function () {
        var _a;
        if (isOpen) {
            setActiveTab((_a = ReportsTab.defaultProps) === null || _a === void 0 ? void 0 : _a.tabId);
        }
    }, [isOpen]);
    var handleOK = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errors, fileNameStateName, filename, search, shortToken, url;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (reportSetting) {
                        errors = getFlattenErrors(validationSchema, reportSetting);
                    }
                    setError(errors);
                    if (!isEmpty(errors)) {
                        return [2 /*return*/];
                    }
                    fileNameStateName = reportSetting.reportType === ReportTypeEnum.CongressionalDetailAnalysis ?
                        [reportSetting.firstLevel && lookupStateData.getNameByStateCode(reportSetting.firstLevel), ("" + (reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.secondLevel)).padStart(2, '0')] :
                        ['zip5'];
                    filename = __spreadArrays([
                        indexName,
                        'CDA'
                    ], fileNameStateName, [
                        new Date().toISOString().slice(0, 10).replace(/-/g, ''),
                    ]).join(' ');
                    search = qs.stringify((__assign(__assign({}, reportSetting), { primaryCormorbidities: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.conditions, primaryConditions: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.conditions, geolevel: PRIVATE_REPORT_EXTENSION[reportSetting.reportType].geolevel, filename: filename, demographics: {
                            ages: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.ages,
                            sexCodes: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.sexCodes,
                            raceCodes: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.raceCodes,
                        }, raceMethodology: reportSetting === null || reportSetting === void 0 ? void 0 : reportSetting.raceMethodology, reporting: (_a = indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation) === null || _a === void 0 ? void 0 : _a.reporting, hostname: indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.hostname })));
                    setRequesting(true);
                    return [4 /*yield*/, auth.exportToken()];
                case 1:
                    shortToken = _b.sent();
                    if (!shortToken) return [3 /*break*/, 3];
                    url = "/api/pdf/statistics/private/" + shortToken + "/" + PRIVATE_REPORT_EXTENSION[reportSetting.reportType].pathname;
                    return [4 /*yield*/, fetch(url, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ query: search })
                        })
                            .then(function (response) { return response.blob(); })
                            .then(function (blob) {
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = filename;
                            link.click();
                        })];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    if (isRememberSettings) {
                        fetchRememberSettings(reportSetting);
                    }
                    else {
                        fetchRememberSettings();
                    }
                    toggle();
                    if (!isUnMount.current) {
                        setRequesting(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleTabSelector = function (tabId) {
        setActiveTab(tabId);
        toggleShow();
    };
    var tabSelectors = Children.map(props.children, function (child) {
        return (React.createElement(LeftTabSelectorItem, { activeTab: activeTab, toggleTab: toggleTabSelector, tabId: child.props.tabId, tabName: child.props.tabName, tabSelector: child.props.tabSelector, showRequiredDot: child.props.tabField.some(function (v) { return requiredField.includes(v); }) }));
    });
    var handleCheckRememberSettings = function (event) {
        setIsRememberSettings(event.target.checked);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledModal, { size: "lg", unmountOnClose: false, isOpen: isOpen, toggle: toggle, scrollable: true },
            React.createElement(ModalHeader, { toggle: toggle },
                React.createElement(FontAwesomeIcon, { icon: "file-alt", size: "lg" }),
                React.createElement("span", null, " Reports ")),
            React.createElement(StyledModalBody, null,
                React.createElement(HamburgerGroup, null,
                    React.createElement(HamburgerButton, { color: "#000", icon: show ? 'times' : 'bars', size: "2x", onClick: toggleShow }),
                    React.createElement("h5", { className: "m-0" }, React.Children.map(props.children, function (child) { return (child.props.tabId === activeTab ? child.props.tabName : null); }))),
                React.createElement(StyledTabSelectors, { show: show }, tabSelectors),
                React.createElement(StyledTabContent, null,
                    React.createElement(Card, { body: true, className: "flex-grow-1 overflow-auto" },
                        React.createElement(TabContent, { activeTab: activeTab }, props.children)))),
            React.createElement(ModalFooter, null,
                React.createElement(StyledSimpleCheckbox, null,
                    React.createElement(SimpleCheckbox, { checked: isRememberSettings, onChange: handleCheckRememberSettings, label: "Remember Settings" })),
                React.createElement(Button, { color: "secondary", disabled: requesting, onClick: toggle }, "Cancel"),
                ' ',
                React.createElement(Button, { color: "primary", loading: requesting, onClick: handleOK }, "Show Report"))),
        React.createElement(AlertMessage, { errors: error })));
};
export * from './datayeartab';
export * from './conditiontab';
export * from './statistictab';
export * from './sextab';
export * from './racetab';
export * from './agetab';
export * from './showbytab';
export * from './reportstab';
export * from './reportsdialogprovider';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
