/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var RoleEnum;
(function (RoleEnum) {
    RoleEnum["GeneralUser"] = "GENERAL_USER";
    RoleEnum["GeneralUserNoDownload"] = "GENERAL_USER_NO_DOWNLOAD";
    RoleEnum["SystemAdmin"] = "SYSTEM_ADMIN";
    RoleEnum["IndexManager"] = "INDEX_MANAGER";
    RoleEnum["SingleIndexDataManager"] = "SINGLE_INDEX_DATA_MANAGER";
    RoleEnum["SingleIndexUserManager"] = "SINGLE_INDEX_USER_MANAGER";
})(RoleEnum || (RoleEnum = {}));
