/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var CLAIM_NUMBERS = [1, 2, 3, 4];
export var CLAIM_DAYS = [
    {
        value: '-29',
        label: 'under 30',
    },
    {
        value: '30-',
        label: '30 or more',
    },
];
export var getClaimDayLabel = function (value) { var _a; return (_a = CLAIM_DAYS.find(function (option) { return option.value === value; })) === null || _a === void 0 ? void 0 : _a.label; };
