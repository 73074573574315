var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { StatisticDataForCard, StatisticDataForNational } from './statisticdataforcard';
import styled from 'styled-components';
import { Card, NationalCard } from '.';
var CardInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 0 0.5rem;\n  align-items: flex-end;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 0 0.5rem;\n  align-items: flex-end;\n"])));
var Space = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-bottom: 0.75rem;\n"], ["\n  padding-bottom: 0.75rem;\n"])));
export var PatientOutOfPocketPartBDrugCostDashBoard = function (props) {
    return (React.createElement(CardInfo, null,
        React.createElement(Card, { viewSetting: props.viewSetting, backgroundColor: props.backgroundColor },
            React.createElement(StatisticDataForCard, { label: 'Beneficiaries', geoId: props.geoId, geoValue: props.data.denominator, nationalDemographicValue: props.data.nationalDemographicDenominator, nationalValue: props.data.nationalDenominator }),
            React.createElement(Space, null),
            React.createElement(StatisticDataForCard, { label: 'Total', geoId: props.geoId, geoValue: props.data.numerator, nationalDemographicValue: props.data.nationalDemographicNumerator, nationalValue: props.data.nationalNumerator, prefix: '$' }),
            React.createElement(StatisticDataForCard, { label: 'Cost per patient', geoId: props.geoId, geoValue: props.data.value, nationalDemographicValue: props.data.nationalDemographic, nationalValue: props.data.national, prefix: '$' })),
        React.createElement(NationalCard, { viewSetting: props.viewSetting },
            React.createElement(StatisticDataForNational, { label: 'Beneficiaries', geoId: props.geoId, geoValue: props.data.denominator, nationalDemographicValue: props.data.nationalDemographicDenominator, nationalValue: props.data.nationalDenominator }),
            React.createElement(Space, null),
            React.createElement(StatisticDataForNational, { label: 'Total', geoId: props.geoId, geoValue: props.data.numerator, nationalDemographicValue: props.data.nationalDemographicNumerator, nationalValue: props.data.nationalNumerator, prefix: '$' }),
            React.createElement(StatisticDataForNational, { label: 'Cost per patient', geoId: props.geoId, geoValue: props.data.value, nationalDemographicValue: props.data.nationalDemographic, nationalValue: props.data.national, prefix: '$' }))));
};
var templateObject_1, templateObject_2;
