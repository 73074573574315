/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var ChronicConditionSource;
(function (ChronicConditionSource) {
    ChronicConditionSource["NMQF_CUSTOM"] = "nmqf_custom";
    ChronicConditionSource["CMS_OTHER"] = "cms_other";
    ChronicConditionSource["CMS_27"] = "cms_27";
    ChronicConditionSource["CMS_30"] = "cms_30";
})(ChronicConditionSource || (ChronicConditionSource = {}));
export var KeyCombineConditionSource;
(function (KeyCombineConditionSource) {
    KeyCombineConditionSource["lessThan2019"] = "lessThan2019";
    KeyCombineConditionSource["greaterOrEqual2019"] = "greaterOrEqual2019";
})(KeyCombineConditionSource || (KeyCombineConditionSource = {}));
