var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React from 'react';
import { getDisplayText } from '../../../utils';
import styled from 'styled-components';
var CardWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var CardValue = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var DataRowForCard = function (props) { return (React.createElement(CardWrapper, null,
    React.createElement(CardValue, null,
        props.label,
        ":"),
    React.createElement(CardValue, null, getDisplayText(props.value, props.suffix, props.prefix)))); };
export var StatisticDataForCard = function (props) {
    var geoId = props.geoId, geoValue = props.geoValue;
    return React.createElement(DataRowForCard, __assign({}, props, { value: geoId && !geoId.endsWith('-') ? geoValue : '-' }));
};
export var StatisticDataForNational = function (props) {
    var nationalValue = props.nationalValue;
    return React.createElement(DataRowForCard, __assign({}, props, { value: nationalValue }));
};
var templateObject_1, templateObject_2;
