var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import { useMemo, useCallback } from 'react';
import { StatisticTypeEnum, GeoLevelEnum, ViewModeEnum } from '../../enums';
import { cookLegendBreaks, getBreakIdx, roundNumber } from '../../utils';
import { useAPI } from '../../state';
import { useViewValue, useFilterSetting, exportFilterSettingPayload } from '../../state';
import { getStatisticOption, processStatisticValue, getStatisticPrecision } from '../../lookupdata';
import { extractHostname, useHostnameIndexInfo } from '../../hostnameindexinfo';
var API_URL_GET_AGGREGATED_DATA = '/api/data-file/get-aggregated-data-for-map';
var API_URL_CALCULATE_STATISTIC = '/api/stats/{hostname}/calculate-statistic-for-map';
var API_URL_CALCULATE_STATISTIC_PROXY = '/api/{hostname}/calculate-statistic-for-map';
export var extractGeneralFilterInfo = function (data, statistic) {
    var statisticConfig = getStatisticOption(statistic);
    var _a = (data || {}).percentage, percentage = _a === void 0 ? statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.percentage : _a;
    return {
        percentage: percentage,
        suffix: percentage ? '%' : '',
        prefix: statisticConfig === null || statisticConfig === void 0 ? void 0 : statisticConfig.preUnit,
    };
};
export var useMapData = function () {
    var _a, _b;
    var indexConfig = useHostnameIndexInfo();
    var filterSetting = useFilterSetting();
    var apiUrl = useMemo(function () {
        if (filterSetting.isAggregatedDataSource)
            return API_URL_GET_AGGREGATED_DATA;
        if (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.calculateStatisticAttachHostname)
            return API_URL_CALCULATE_STATISTIC_PROXY;
        return API_URL_CALCULATE_STATISTIC;
    }, [filterSetting.isAggregatedDataSource, indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.calculateStatisticAttachHostname]);
    var _c = useAPI(apiUrl, { hostname: extractHostname() }, 'POST'), mapData = _c[0], fetchMapData = _c[1], resetState = _c[2];
    var geoLevel = useViewValue('geoType')[0];
    var statistic = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var percentage = extractGeneralFilterInfo((_b = mapData.data) === null || _b === void 0 ? void 0 : _b.mapStatisticResponsingData, statistic).percentage;
    var extractedData = useMemo(function () {
        var _a;
        return mapData.data && __assign(__assign({}, mapData.data.mapStatisticResponsingData), { breakLegendInfo: cookLegendBreaks((_a = mapData.data.mapStatisticResponsingData) === null || _a === void 0 ? void 0 : _a.breakLegendInfo, percentage, getStatisticPrecision(statistic)) });
    }, [mapData.data, percentage, statistic]);
    var fetchMapDataWrapped = useCallback(function () {
        if (filterSetting.forceUpdate) {
            var payload = exportFilterSettingPayload(filterSetting);
            fetchMapData(__assign(__assign({}, payload), { displayOption: { displayOptionType: ViewModeEnum.Map, mapView: { type: geoLevel } } }));
        }
    }, [fetchMapData, filterSetting, geoLevel]);
    return [__assign(__assign({}, mapData), { data: extractedData }), fetchMapDataWrapped, resetState];
};
export var extractGeoData = function (id, filterSetting, geoLevel, data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15;
    if (id === void 0) { id = ''; }
    var isAggregated = filterSetting.isAggregatedDataSource;
    var statistic = (_a = filterSetting.statistic) === null || _a === void 0 ? void 0 : _a.statisticOption;
    var _16 = extractGeneralFilterInfo(data, statistic), percentage = _16.percentage, suffix = _16.suffix, prefix = _16.prefix;
    var value;
    var numerator, denominator, eventCount;
    var geographic, geographicNumerator, geographicDenominator, geographicEventCount;
    var nationalDemographic, nationalDemographicNumerator, nationalDemographicDenominator, nationalDemographicEventCount;
    var nationalAggregatedValue;
    var patientPopulation, nationalPatientPopulation;
    if (isAggregated) {
        var aggregatedId = geoLevel === GeoLevelEnum.Zip3 ? id.split('-')[1] : id;
        value = processStatisticValue((_b = data === null || data === void 0 ? void 0 : data.preAggregatedPopulationStatisticData) === null || _b === void 0 ? void 0 : _b[aggregatedId], percentage, statistic);
        geographic = value;
        nationalAggregatedValue = data === null || data === void 0 ? void 0 : data.nationalAggregatedValue;
        nationalDemographic = nationalAggregatedValue;
    }
    else {
        numerator = roundNumber(processStatisticValue(((_d = (_c = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _c === void 0 ? void 0 : _c.mapPatientCount) === null || _d === void 0 ? void 0 : _d[id]) || ((_f = (_e = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _e === void 0 ? void 0 : _e.mapTotalRateOfAcuteEventSelectedDemographic) === null || _f === void 0 ? void 0 : _f[id]) || ((_h = (_g = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _g === void 0 ? void 0 : _g.mapCostOfAcuteEventSelectedDemographic) === null || _h === void 0 ? void 0 : _h[id]), false, statistic));
        denominator =
            statistic === StatisticTypeEnum.TotalCount
                ? 1
                : ((_k = (_j = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _j === void 0 ? void 0 : _j.mapPatientPopulationCount) === null || _k === void 0 ? void 0 : _k[id]) || ((_m = (_l = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _l === void 0 ? void 0 : _l.mapPatientCountSelectedDemographic) === null || _m === void 0 ? void 0 : _m[id]) || ((_p = (_o = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _o === void 0 ? void 0 : _o.mapPatientCountSelectedDemographic) === null || _p === void 0 ? void 0 : _p[id]);
        value = processStatisticValue(numerator && denominator && numerator / denominator, percentage, statistic);
        patientPopulation = (_r = (_q = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _q === void 0 ? void 0 : _q.mapPatientPopulationCount) === null || _r === void 0 ? void 0 : _r[id];
        eventCount = (_t = (_s = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _s === void 0 ? void 0 : _s.mapCostOfAcuteEventHospitalizationVisit) === null || _t === void 0 ? void 0 : _t[id];
        geographicEventCount = (_v = (_u = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _u === void 0 ? void 0 : _u.mapCostOfAcuteEventHospitalizationVisitAllDemographic) === null || _v === void 0 ? void 0 : _v[id];
        geographicNumerator = roundNumber(processStatisticValue(((_x = (_w = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _w === void 0 ? void 0 : _w.mapPatientCount) === null || _x === void 0 ? void 0 : _x[id]) || ((_z = (_y = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _y === void 0 ? void 0 : _y.mapTotalRateOfAcuteEventAllDemographic) === null || _z === void 0 ? void 0 : _z[id]) || ((_1 = (_0 = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _0 === void 0 ? void 0 : _0.mapCostOfAcuteEventAllDemographic) === null || _1 === void 0 ? void 0 : _1[id]), false, statistic));
        geographicDenominator =
            statistic === StatisticTypeEnum.TotalCount
                ? 1
                : ((_3 = (_2 = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _2 === void 0 ? void 0 : _2.mapPatientPopulationCount) === null || _3 === void 0 ? void 0 : _3[id]) || ((_5 = (_4 = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _4 === void 0 ? void 0 : _4.mapPatientCountAllDemographic) === null || _5 === void 0 ? void 0 : _5[id]) || ((_7 = (_6 = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _6 === void 0 ? void 0 : _6.mapPatientCountAllDemographic) === null || _7 === void 0 ? void 0 : _7[id]);
        geographic = processStatisticValue(geographicNumerator && geographicDenominator && geographicNumerator / geographicDenominator, percentage, statistic);
        nationalDemographicEventCount = (_8 = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _8 === void 0 ? void 0 : _8.nationalDemographicSumUpHospVisit;
        nationalDemographicNumerator = roundNumber(((_9 = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _9 === void 0 ? void 0 : _9.nationalPatientCount) || ((_10 = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _10 === void 0 ? void 0 : _10.nationalDemographicSumUpRateOfAcuteEvent) || ((_11 = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _11 === void 0 ? void 0 : _11.nationalDemographicSumUpCostOfAcuteEvent));
        nationalDemographicDenominator =
            statistic === StatisticTypeEnum.TotalCount
                ? 1
                : ((_12 = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _12 === void 0 ? void 0 : _12.nationalPopulationCount) || ((_13 = data === null || data === void 0 ? void 0 : data.rateOfAcuteEventsStatisticData) === null || _13 === void 0 ? void 0 : _13.nationalDemographicPatientCount) || ((_14 = data === null || data === void 0 ? void 0 : data.costOfAcuteEventsStatisticData) === null || _14 === void 0 ? void 0 : _14.nationalDemographicPatientCount);
        nationalDemographic = processStatisticValue(nationalDemographicNumerator && nationalDemographicDenominator && nationalDemographicNumerator / nationalDemographicDenominator, percentage, statistic);
        nationalPatientPopulation = (_15 = data === null || data === void 0 ? void 0 : data.populationStatisticData) === null || _15 === void 0 ? void 0 : _15.nationalPopulationCount;
    }
    return {
        isAggregated: isAggregated,
        suffix: suffix,
        prefix: prefix,
        breakIdx: getBreakIdx(data === null || data === void 0 ? void 0 : data.breakLegendInfo, value),
        value: value,
        numerator: numerator,
        denominator: denominator,
        eventCount: eventCount,
        patientPopulation: patientPopulation,
        geographic: geographic,
        geographicNumerator: geographicNumerator,
        geographicDenominator: geographicDenominator,
        geographicEventCount: geographicEventCount,
        national: nationalDemographic,
        nationalNumerator: nationalDemographicNumerator,
        nationalDenominator: nationalDemographicDenominator,
        nationalEventCount: nationalDemographicEventCount,
        nationalDemographic: nationalDemographic,
        nationalDemographicNumerator: nationalDemographicNumerator,
        nationalDemographicDenominator: nationalDemographicDenominator,
        nationalDemographicEventCount: nationalDemographicEventCount,
        nationalAggregatedValue: nationalAggregatedValue,
        nationalPatientPopulation: nationalPatientPopulation,
    };
};
export var useExtractGeoData = function () {
    var filterSetting = useFilterSetting();
    var data = useMapData()[0].data;
    var geoLevel = useViewValue('geoType')[0];
    return useCallback(function (id) { return extractGeoData(id, filterSetting, geoLevel, data); }, [data, filterSetting, geoLevel]);
};
