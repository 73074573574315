/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
export var ClinicalTrialOptionEnum;
(function (ClinicalTrialOptionEnum) {
    ClinicalTrialOptionEnum["TrialsRelated"] = "TRIALS_RELATED";
    ClinicalTrialOptionEnum["AllTrials"] = "ALL_TRIALS";
})(ClinicalTrialOptionEnum || (ClinicalTrialOptionEnum = {}));
export var ClinicalTrialStatusEnum;
(function (ClinicalTrialStatusEnum) {
    ClinicalTrialStatusEnum["NotYetRecruiting"] = "NOT_YET_RECRUITING";
    ClinicalTrialStatusEnum["Recruiting"] = "RECRUITING";
    ClinicalTrialStatusEnum["EnrollingByInvitation"] = "ENROLLING_BY_INVITATION";
    ClinicalTrialStatusEnum["ActiveNotRecruiting"] = "ACTIVE_NOT_RECRUITING";
})(ClinicalTrialStatusEnum || (ClinicalTrialStatusEnum = {}));
