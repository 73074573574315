var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/*!
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAPI, useCachedData } from '../state';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, FormFeedback, Row } from 'reactstrap';
import { useAuth } from '../authentication';
import { useForm } from '../hooks';
import { PhoneInput, PHONE_NUMBER_PATTERN, Select, ZIP_CODE_PATTERN, Input } from '../common';
export var NOTIFICATION_PREFERENCES = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
};
var ModalWrapper = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (min-width: 992px) {\n    max-width: 900px !important;\n  }\n"], ["\n  @media (min-width: 992px) {\n    max-width: 900px !important;\n  }\n"])));
export var UserProfile = function (props) {
    var _a;
    var auth = useAuth();
    var _b = useCachedData('/api/general/lookup/get-list-state'), stateData = _b[0], fetchStateData = _b[1];
    var _c = useAPI('/api/user/editProfile', undefined, 'POST'), editProfile = _c[1];
    useEffect(function () {
        if (props.isOpen) {
            fetchStateData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);
    var handleSaveUser = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        firstName: payload.firstName,
                        middleName: payload.middleName,
                        lastName: payload.lastName,
                        organization: payload.organization,
                        address1: payload.address1,
                        address2: payload.address2,
                        city: payload.city,
                        stateAbb: payload.stateAbb,
                        zipcode: payload.zipcode,
                        phoneNumber: payload.phoneNumber,
                        notificationPreference: payload.notificationPreference,
                    };
                    return [4 /*yield*/, editProfile(data)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, auth.refreshToken()];
                case 2:
                    _a.sent();
                    props.toggle();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ModalWrapper, { size: "lg", isOpen: props.isOpen, toggle: props.toggle },
        React.createElement(ModalHeader, { toggle: props.toggle }, "User Profile"),
        React.createElement(UserProfileEditForm, { data: props.principalUser, onCancel: props.toggle, onSave: handleSaveUser, stateList: (_a = stateData.data) === null || _a === void 0 ? void 0 : _a.data })));
};
var validationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().trim().required('Required'),
    lastName: Yup.string().nullable().trim().required('Required'),
    zipcode: Yup.string().nullable().trim().matches(ZIP_CODE_PATTERN, {
        excludeEmptyString: true,
        message: 'Invalid',
    }),
    organization: Yup.string().nullable().trim(),
    address1: Yup.string().nullable().trim(),
    address2: Yup.string().nullable().trim(),
    city: Yup.string().nullable().trim(),
    notificationPreference: Yup.string().nullable().required('Required'),
    phoneNumber: Yup.string()
        .nullable()
        .trim()
        .when('notificationPreference', {
        is: NOTIFICATION_PREFERENCES.SMS,
        then: Yup.string().nullable().required('Required'),
    })
        .matches(PHONE_NUMBER_PATTERN, {
        excludeEmptyString: true,
        message: 'Invalid',
    }),
});
export var UserProfileEditForm = function (props) {
    var _a = useForm({
        validationSchema: validationSchema,
    }), setInitialValues = _a.setInitialValues, values = _a.values, handleChange = _a.handleChange, handleBlur = _a.handleBlur, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting, getError = _a.getError;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        setInitialValues({
            firstName: (_a = props.data) === null || _a === void 0 ? void 0 : _a.firstName,
            middleName: (_b = props.data) === null || _b === void 0 ? void 0 : _b.middleName,
            lastName: (_c = props.data) === null || _c === void 0 ? void 0 : _c.lastName,
            username: (_d = props.data) === null || _d === void 0 ? void 0 : _d.username,
            organization: (_e = props.data) === null || _e === void 0 ? void 0 : _e.organization,
            address1: (_f = props.data) === null || _f === void 0 ? void 0 : _f.address1,
            address2: (_g = props.data) === null || _g === void 0 ? void 0 : _g.address2,
            city: (_h = props.data) === null || _h === void 0 ? void 0 : _h.city,
            stateAbb: (_j = props.data) === null || _j === void 0 ? void 0 : _j.stateAbb,
            zipcode: (_k = props.data) === null || _k === void 0 ? void 0 : _k.zipcode,
            roleName: (_l = props.data) === null || _l === void 0 ? void 0 : _l.role.roleName,
            phoneNumber: (_m = props.data) === null || _m === void 0 ? void 0 : _m.phoneNumber,
            notificationPreference: (_o = props.data) === null || _o === void 0 ? void 0 : _o.notificationPreference,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);
    var onSubmit = handleSubmit(function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onSave(__assign({}, payload))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); });
    return (React.createElement(Form, { onSubmit: onSubmit },
        React.createElement(ModalBody, null,
            React.createElement(Row, null,
                React.createElement(FormGroup, { className: "col-md-4" },
                    React.createElement(Label, null, "First Name"),
                    React.createElement(Input, { type: "text", name: "firstName", value: values.firstName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('firstName') }),
                    React.createElement(FormFeedback, null, getError('firstName'))),
                React.createElement(FormGroup, { className: "col-md-4" },
                    React.createElement(Label, null, "Middle Name"),
                    React.createElement(Input, { type: "text", name: "middleName", value: values.middleName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('middleName') }),
                    React.createElement(FormFeedback, null, getError('middleName'))),
                React.createElement(FormGroup, { className: "col-md-4" },
                    React.createElement(Label, null, "Last Name"),
                    React.createElement(Input, { type: "text", name: "lastName", value: values.lastName, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('lastName') }),
                    React.createElement(FormFeedback, null, getError('lastName')))),
            React.createElement(Row, null,
                React.createElement(FormGroup, { className: "col-md-4" },
                    React.createElement(Label, null, "Mobile phone number"),
                    React.createElement(PhoneInput, { name: "phoneNumber", value: values.phoneNumber, setFieldValue: setFieldValue, setFieldTouched: setFieldTouched, invalid: !!getError('phoneNumber') }),
                    React.createElement(FormFeedback, null, getError('phoneNumber'))),
                React.createElement(FormGroup, { className: "col-md-8" },
                    React.createElement(Label, null, "\u00A0"),
                    React.createElement("div", { className: "pt-2" },
                        React.createElement(Label, { className: "mr-2" }, "Preference for notifications:"),
                        React.createElement(FormGroup, { check: true, className: "d-inline-block mr-2" },
                            React.createElement(Label, { check: true },
                                React.createElement(Input, { type: "radio", name: "notificationPreference", checked: values.notificationPreference === NOTIFICATION_PREFERENCES.EMAIL, onChange: handleChange, onBlur: handleBlur, value: NOTIFICATION_PREFERENCES.EMAIL }),
                                "Email")),
                        React.createElement(FormGroup, { check: true, className: "d-inline-block mr-2" },
                            React.createElement(Label, { check: true },
                                React.createElement(Input, { type: "radio", name: "notificationPreference", checked: values.notificationPreference === NOTIFICATION_PREFERENCES.SMS, onChange: handleChange, onBlur: handleBlur, value: NOTIFICATION_PREFERENCES.SMS }),
                                "SMS Text"))),
                    React.createElement(FormFeedback, { className: "d-lock" }, !!getError('notificationPreference')))),
            React.createElement(Row, null,
                React.createElement(FormGroup, { className: "col-md-6" },
                    React.createElement(Label, null, "Organization Name"),
                    React.createElement(Input, { type: "text", name: "organization", value: values.organization, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('organization') }),
                    React.createElement(FormFeedback, null, getError('organization')))),
            React.createElement(Row, null,
                React.createElement(FormGroup, { className: "col-md-6" },
                    React.createElement(Label, null, "Address 1"),
                    React.createElement(Input, { type: "text", name: "address1", value: values.address1, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('address1') }),
                    React.createElement(FormFeedback, null, getError('address1'))),
                React.createElement(FormGroup, { className: "col-md-6" },
                    React.createElement(Label, null, "Address 2"),
                    React.createElement(Input, { type: "text", name: "address2", value: values.address2, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('address2') }),
                    React.createElement(FormFeedback, null, getError('address2')))),
            React.createElement(Row, null,
                React.createElement(FormGroup, { className: "col-md-6" },
                    React.createElement(Label, null, "City"),
                    React.createElement(Input, { type: "text", name: "city", value: values.city, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('city') }),
                    React.createElement(FormFeedback, null, getError('city'))),
                React.createElement(FormGroup, { className: "col-md-3" },
                    React.createElement(Label, null, "State"),
                    React.createElement(Select, { name: "stateAbb", options: props.stateList, value: values.stateAbb, onChange: setFieldValue, setFieldTouched: setFieldTouched, optionsTransform: function (st) { return (__assign(__assign({}, st), { value: st.stateAbbreviation, label: st.stateName })); }, menuPlacement: "top" }),
                    React.createElement(FormFeedback, null, getError('stateAbb'))),
                React.createElement(FormGroup, { className: "col-md-3" },
                    React.createElement(Label, null, "Zip Code"),
                    React.createElement(Input, { type: "text", name: "zipcode", value: values.zipcode, onChange: handleChange, onBlur: handleBlur, invalid: !!getError('zipcode') }),
                    React.createElement(FormFeedback, null, getError('zipcode'))))),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { type: "button", onClick: props.onCancel, disabled: isSubmitting }, "Cancel"),
            ' ',
            React.createElement(Button, { color: "primary", type: "submit", disabled: isSubmitting }, "Save"))));
};
var templateObject_1;
