var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * *
 *  * Copyright (C) 2022 SHC Holdings LLC. All Rights Reserved.
 *
 */
import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
export var overlayRule = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fff;\n  position: relative;\n  padding: 0.75rem;\n  border-radius: 0.25rem;\n"], ["\n  background-color: #fff;\n  position: relative;\n  padding: 0.75rem;\n  border-radius: 0.25rem;\n"])));
export var StyledPanel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), overlayRule, function (_a) {
    var _b = _a.show, show = _b === void 0 ? true : _b;
    return (!show ? 'display:none;' : '');
});
var StyledCloseButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  background-color: inherit;\n  padding: 0;\n  position: absolute;\n  cursor: pointer;\n  width: 2rem;\n  height: 2rem;\n  top: -0.85rem;\n  right: -0.85rem;\n  border-radius: 100%;\n  border: 0;\n  &:focus {\n    outline: none;\n  }\n  &:hover {\n    opacity: 0.8;\n  }\n"], ["\n  ", "\n  background-color: inherit;\n  padding: 0;\n  position: absolute;\n  cursor: pointer;\n  width: 2rem;\n  height: 2rem;\n  top: -0.85rem;\n  right: -0.85rem;\n  border-radius: 100%;\n  border: 0;\n  &:focus {\n    outline: none;\n  }\n  &:hover {\n    opacity: 0.8;\n  }\n"])), overlayRule);
export var CloseButton = function (props) {
    if (!props.onClick)
        return null;
    return (React.createElement(StyledCloseButton, { onClick: props.onClick },
        React.createElement(FontAwesomeIcon, { icon: faTimes })));
};
var templateObject_1, templateObject_2, templateObject_3;
