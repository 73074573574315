var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect } from 'react';
import { TabPane, Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import { useFilterEditingValue } from '../state';
import { FallbackComponent, CheckboxGroup, Select, ThreeDotLoading } from '../common';
import { getDisplayText } from '../utils';
import { CMSSourceTabSelector } from './tabselector';
import { useEffectFilterEditingCount } from '../filterbar/useeffectfiltercount';
import { useLookupRaceCode, LookupRaceCodeComponent } from '../lookupdata';
import { useHostnameIndexInfo } from '../hostnameindexinfo';
import { RaceMethodologyEnum, DataSourceEnum } from '../enums';
import { IconTooltip } from '../common/icontooltip';
import { useFilterCount } from './usefiltercount';
var METHODOLOGY_OPTIONS = [
    {
        value: RaceMethodologyEnum.Standard,
        label: 'Standard',
    },
    {
        value: RaceMethodologyEnum.RTI,
        label: 'RTI',
    },
];
var StyledMethodologySelect = styled(Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 15rem;\n"], ["\n  width: 15rem;\n"])));
var StyledHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var StyledDiv = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 700;\n  margin-bottom: 0.5rem;\n"], ["\n  font-weight: 700;\n  margin-bottom: 0.5rem;\n"])));
var BlueTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 700;\n  color: #007bff;\n  margin-top: 0.5rem;\n"], ["\n  font-weight: 700;\n  color: #007bff;\n  margin-top: 0.5rem;\n"])));
export var RaceTab = function (props) {
    var indexConfig = useHostnameIndexInfo();
    var cmsDataSource = useFilterEditingValue('statistic', 'cmsDataSource')[0];
    var _a = useFilterEditingValue('raceMethodology'), methodology = _a[0], setMethodology = _a[1];
    var _b = useLookupRaceCode(methodology), lookupRaceCodeData = _b[0], fetchLookupRaceCode = _b[1];
    var _c = useFilterCount('RACE_CODE', indexConfig), raceCodeCountData = _c[0], fetchRaceCodeCount = _c[1];
    var _d = useFilterCount('RTI_RACE_CODE', indexConfig), rtiRaceCodeCountData = _d[0], fetchRTIRaceCodeCount = _d[1];
    var _e = useFilterEditingValue('demographics', 'raceCodes'), raceCodes = _e[0], setRaceCodes = _e[1];
    var countData = methodology === RaceMethodologyEnum.Standard ? raceCodeCountData : rtiRaceCodeCountData;
    useEffectFilterEditingCount(function (body) {
        fetchRaceCodeCount(body);
        fetchRTIRaceCodeCount(body);
    }, props.filterCountEffectDeps, 'RACE');
    useEffect(function () {
        if (cmsDataSource === DataSourceEnum.Medicaid) {
            setMethodology(RaceMethodologyEnum.Standard);
        }
    }, [cmsDataSource, setMethodology]);
    useEffect(function () {
        if (lookupRaceCodeData.data) {
            // setRaceCodes(lookupRaceCodeData.data.map((i) => i.value));
        }
    }, [lookupRaceCodeData.data, setRaceCodes]);
    var HelperText = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Title, null, "What are the methodologies for selecting race?"),
            "There are two options for determining race / ethnicity populations in the Index.",
            React.createElement(BlueTitle, null, "Standard"),
            "Race/Ethnicity data as received from CMS. Race is self-reported and originates with the Social Security Administration. There is known underrepresentation in the Hispanic population since individuals may report their race in other population groups, such as Black or Caucasian.",
            React.createElement(BlueTitle, null, "RTI"),
            "Research Triangle Institute, a non-profit research organization, developed an algorithm for identifying Hispanic and Asian beneficiaries based on surname. The algorithm\u00A0also\u00A0incorporates CCW enhancements that reduce the number of beneficiaries with missing information.\u00A0The result of applying this algorithm is the redistribution of the population demographics among the population groups reported to more accurately estimate the proportion of Hispanic beneficiaries."));
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        cmsDataSource !== DataSourceEnum.Medicaid && (React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(StyledHeader, null,
                React.createElement(StyledDiv, null,
                    React.createElement(Label, { className: "font-weight-bold" }, "Methodology"),
                    React.createElement(StyledMethodologySelect, { name: "methodology", options: METHODOLOGY_OPTIONS, noSort: true, value: methodology, onChange: setMethodology })),
                React.createElement(StyledDiv, null,
                    React.createElement(IconTooltip, { title: React.createElement(HelperText, null), placement: "bottom-end", maxWidth: "min(80vw, 28rem)", maxHeight: "calc(90vh - 10rem)", autohide: false }))))),
        React.createElement(Label, { className: "font-weight-bold" }, "Race"),
        React.createElement(FallbackComponent, { requesting: lookupRaceCodeData.requesting, error: lookupRaceCodeData.error, retry: fetchLookupRaceCode },
            React.createElement(CheckboxGroup, { options: lookupRaceCodeData.data, noSort: true, formatOptionLabel: function (i) {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        i.label,
                        "\u00A0",
                        (indexConfig === null || indexConfig === void 0 ? void 0 : indexConfig.generalInformation.filterCount) && (React.createElement(React.Fragment, null,
                            '(',
                            React.createElement(ThreeDotLoading, { loading: countData.requesting }, getDisplayText((_a = countData.data) === null || _a === void 0 ? void 0 : _a[i.value])),
                            ')'))));
                }, value: raceCodes, onChange: setRaceCodes })),
        React.createElement(LookupRaceCodeComponent, null)));
};
RaceTab.defaultProps = {
    tabId: 'RaceTab',
    tabName: 'Race/Ethnicity',
    tabSelector: CMSSourceTabSelector,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
