/*
 * Copyright (C) 2022 SHC Holdings LLC.  All Rights Reserved.
 */
import React, { useEffect, useMemo } from 'react';
import { TabPane, FormGroup, Label } from 'reactstrap';
import { useReportsDialogValue } from './reportsdialogprovider';
import { CheckboxGroup } from '../common';
import { ConditionType, useLookupConditionSortByType, useSynchronizedConditionReport } from '../lookupdata';
import { useGeneralConfigByDataSource } from '../hooks';
export var ConditionReportsDialogTab = function (props) {
    var _a, _b;
    var dataYear = useReportsDialogValue('dataYear')[0];
    var cmsDataSource = useReportsDialogValue('dataSourceType')[0];
    var lookupData = useLookupConditionSortByType(undefined, dataYear, cmsDataSource)[0];
    var primaryConditions = (_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[ConditionType.Primary];
    var _c = useReportsDialogValue('conditions'), conditions = _c[0], setConditions = _c[1];
    var _d = useReportsDialogValue('conditionClaimOptionals'), conditionsClaimOption = _d[0], setConditionsClaimOption = _d[1];
    var primaryConditionWithoutClaims = useGeneralConfigByDataSource('primaryConditionWithoutClaims', cmsDataSource, undefined, dataYear);
    useEffect(function () {
        if (props.condition) {
            setConditions([props.condition]);
        }
    }, [props.condition, setConditions]);
    var isExistedPrimaryConditionWithoutClaims = useMemo(function () { return Array.isArray(primaryConditionWithoutClaims) && primaryConditionWithoutClaims.length; }, [primaryConditionWithoutClaims]);
    useEffect(function () {
        if (isExistedPrimaryConditionWithoutClaims && conditions.length) {
            setConditions([]);
        }
        else if (!isExistedPrimaryConditionWithoutClaims && (conditionsClaimOption === null || conditionsClaimOption === void 0 ? void 0 : conditionsClaimOption.length)) {
            setConditionsClaimOption([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataYear, cmsDataSource]);
    var conditionSelected = useMemo(function () {
        if (isExistedPrimaryConditionWithoutClaims) {
            return conditionsClaimOption || [];
        }
        return conditions || [];
    }, [conditions, conditionsClaimOption, isExistedPrimaryConditionWithoutClaims]);
    var conditionOption = (_b = lookupData.data) === null || _b === void 0 ? void 0 : _b.filter(function (s) { return s.chronicConditionCode === props.condition; }).map(function (i) { return ({
        value: i.chronicConditionCode,
        label: i.chronicConditionName,
    }); });
    var onChange = function (values) {
        if (isExistedPrimaryConditionWithoutClaims) {
            setConditionsClaimOption(values);
        }
        else {
            setConditions(values);
        }
    };
    return (React.createElement(TabPane, { tabId: props.tabId },
        React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(Label, { className: "font-weight-bold" }, "Primary Conditions"),
            React.createElement(CheckboxGroup, { options: primaryConditions || conditionOption, noAll: true, noSort: true, value: conditionSelected, onChange: onChange, sortValue: lookupData.sortValue })),
        props.hasSecondaryCondition && React.createElement(SecondaryCondition, null)));
};
var SecondaryCondition = function () {
    var _a;
    var dataYear = useReportsDialogValue('dataYear')[0];
    var dataYearSeason = useReportsDialogValue('dataYearSeason')[0];
    var cmsDataSource = useReportsDialogValue('dataSourceType')[0];
    var lookupData = useLookupConditionSortByType(undefined, dataYear, cmsDataSource, dataYearSeason)[0];
    var secondaryConditions = (_a = lookupData.groupedCondition) === null || _a === void 0 ? void 0 : _a[ConditionType.Secondary];
    var _b = useReportsDialogValue('secondaryCormorbidities'), selectingConditions = _b[0], setSelectingConditions = _b[1];
    var doSyncSecondary = useSynchronizedConditionReport(ConditionType.Secondary)[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(doSyncSecondary, [dataYearSeason]);
    return (React.createElement(React.Fragment, null,
        React.createElement("hr", null),
        React.createElement(FormGroup, { tag: "fieldset" },
            React.createElement(Label, { className: "font-weight-bold" }, "Secondary Conditions"),
            React.createElement(CheckboxGroup, { options: secondaryConditions, noSort: true, value: selectingConditions, onChange: setSelectingConditions, sortValue: lookupData.sortValue }))));
};
ConditionReportsDialogTab.defaultProps = {
    tabId: 'ConditionTab',
    tabName: 'Conditions',
    tabField: ['conditions'],
};
